import PropTypes from "prop-types";
import { useContext, useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Box, Drawer, Skeleton, Typography } from "@mui/material";
// hooks
// components
import Scrollbar from "./Scrollbar";
import NavSection from "./NavSection";
//
import useResponsive from "../hooks/useResponsive";
import leftImage from "../../../assets/images/bg/dashboard/left.png";
import rightImage from "../../../assets/images/bg/dashboard/right.png";
import greenThemePersonalAccountAvatar from "../../../assets/images/bg/dashboard/account.svg";
import FEDThemePersonalAccountAvatar from "../../../assets/images/bg/dashboard/personalFED.png";
import greenThemeCompanyAccountAvatar from "../../../assets/images/bg/dashboard/company.svg";
import FEDThemeCompanyAccountAvatar from "../../../assets/images/bg/dashboard/companyFED.png";
import UserContext from "../../../context/UserContext";
import { useMediaQuery } from "react-responsive";
import { typesOfProjects } from "../../../constants/enums";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

// background: "linear-gradient(180deg, #6FE286 0%,#4DAA60 50% , #2B7239 100%)",
const RootStyle = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "14px",
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled("div")(({ theme }) => ({
  display: "flex",
  // width: "2em",
  flexDirection: "column",
  alignItems: "center",
  margin: "0 auto",
  textAlign: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const { userProfile } = useContext(UserContext);
  const isDesktop = useResponsive("up", "lg");
  const isIPadView = useMediaQuery({ query: "(max-width: 1024px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 768px)" });
  // set assets based in active ENV
  const isFED = process.env.REACT_APP_ACTIVE_ENV == typesOfProjects.FED;
  const companyAccountAvatar = isFED
    ? FEDThemeCompanyAccountAvatar
    : greenThemeCompanyAccountAvatar;
  const personalAccountAvatar = isFED
    ? FEDThemePersonalAccountAvatar
    : greenThemePersonalAccountAvatar;

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
        marginTop: { xs: "6rem", md: "0rem" },
      }}
    >
      <Box sx={{ mb: 2 }}>
        <AccountStyle>
          <img
            src={
              userProfile.TypeId
                ? userProfile.TypeId === 1
                  ? personalAccountAvatar
                  : companyAccountAvatar
                : personalAccountAvatar
            }
            alt="photoURL"
          />
          <Typography
            variant="h6"
            sx={{
              color: "#FFF",
              textAlign: "center",
              fontFamily: "Cairo",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "188.9%",
              width: "100%",
            }}
          >
            {userProfile.TypeId ? (
              userProfile.TypeId === 1 ? (
                userProfile.FirstName + " " + userProfile.LastName
              ) : (
                userProfile.LegalName
              )
            ) : (
              <Skeleton />
            )}
          </Typography>
        </AccountStyle>
      </Box>

      <NavSection />
    </Scrollbar>
  );

  return (
    <RootStyle>
      <img
        src={leftImage}
        alt="lebanon"
        style={{
          position: "absolute",
          left: 0,
          top: 8,
        }}
      />
      <img
        src={rightImage}
        alt="lebanon"
        style={{
          position: "absolute",
          right: 0,
          bottom: 0,
        }}
      />
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: {
              background:
                "linear-gradient(180deg, var(--first-linear-green) 0%,var(--med-linear-green) 50%, var(--second-linear-green) 100%)",
              width: DRAWER_WIDTH,
              borderRadius: "14px",
              borderRight: "none",
              borderLeft: "none",
              position: "inherit",
              height: isTablet ? "100%" : isIPadView ? "75%" : "81%",
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          anchor="right"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              background:
                "linear-gradient(180deg, var(--first-linear-green) 0%,var(--med-linear-green) 50%, var(--second-linear-green) 100%)",
              // background: "transparent",

              borderRadius: "14px",
              borderRight: "none",
              borderLeft: "none",
              position: "inherit",
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}

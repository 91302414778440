import React, { useState } from "react";

import { Button } from "@mui/material";
import { StyledModal } from "./styles";

import { successToaster } from "../../../helpers/toasterConfiguration";
import { deleteExhibitor } from "../../../services/fed/fedExhibitors.service";

const DeleteExhibitorModal = (props) => {
  const { onHide, setRefreshData } = props;
  // ui/ux
  const [loading, setLoading] = useState(false);
  //   functions
  // Calling API
  const deleteFunction = async () => {
    setLoading(true);
    const response = await deleteExhibitor(props.editRowIndex);
    if (response.status === 200 || response.status === 204) {
      successToaster("تم الحذف بنجاح");
    }
    setRefreshData((prev) => !prev);
    setLoading(false);
    onHide();
  };
  return (
    <StyledModal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      direction={"rtl"}
    >
      <StyledModal.Header>
        <h4>حذف العارض</h4>
      </StyledModal.Header>
      <StyledModal.Body>
        هل أنت متأكد من أنك تريد القيام بالحذف؟
      </StyledModal.Body>
      <StyledModal.Footer>
        <Button
          sx={{
            borderRadius: "15px",
            background:
              "linear-gradient(180deg, var(--first-linear-green) 0%, var(--second-linear-green) 100%)",
            color: "#FFF",
            textAlign: "center",
            fontFamily: "Cairo",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "normal",

            marginLeft: "1rem",
          }}
          variant="contained"
          onClick={deleteFunction}
          disabled={false}
        >
          {loading ? (
            <span className="indicator-progress">
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          ) : (
            <>تأكيد</>
          )}
        </Button>
        <Button
          sx={{
            borderRadius: "15px",
            background: "linear-gradient(120deg, #f5f5f5 0%, #9e9e9e 100%)",
            color: "#FFF",
            textAlign: "center",
            fontFamily: "Cairo",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "normal",

            marginLeft: "1rem",
          }}
          variant="contained"
          onClick={() => {
            props.onHide();
            // setIsEdit(false);
          }}
          disabled={false}
        >
          إلغاء
        </Button>
      </StyledModal.Footer>
    </StyledModal>
  );
};

export default DeleteExhibitorModal;

import React from "react";
import { TextField, InputLabel /* InputAdornment */ } from "@mui/material";
import classes from "./textfield.module.css";

const CustomPhoneNumber = ({
  label,
  value,
  setValue,
  isRequired,
  type = "number",
  showError,
  lastStep,
}) => {
  return (
    <div className={classes.root}>
      <InputLabel
        className={`${lastStep ? classes.lastStepPhone : ""} ${
          classes.inputLabel
        } ${isRequired ? classes.requiredField : ""} ${
          showError ? classes.errorClass : ""
        }`}
      >
        {label}
      </InputLabel>
      <TextField
        id="phoneMaskInput"
        type={type}
        value={value}
        required={isRequired}
        onChange={(e) => setValue(e.target.value)}
        variant="outlined"
        helperText={showError ? "رقم الهاتف غير صحيح" : ""}
        InputProps={{
          endAdornment: "961+",
        }}
        sx={{
          textAlign: "left !important",
          "&.MuiFormControl-root": {
            width: "60%",
            "& .MuiFormHelperText-root": {
              color: "red",
              textAlign: "right",
            },
          },
          "& .MuiInputBase-input": {
            background: "rgb(237, 239, 240)",
            padding: "0.7rem",
            borderRadius: "12px",

            outline: "none",
          },
          "& .MuiOutlinedInput-root": {
            border: "none",
            padding: "0 10px",
            textAlign: "left",
            background: "rgb(237, 239, 240)",
            borderRadius: "12px",
            border: showError ? "1px solid red" : "none",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
            {
              "-webkit-appearance": "none",
              margin: 0,
            },
          "& input[type=number]": {
            "-moz-appearance": "textfield", // For Firefox
          },
        }}
      />
    </div>
  );
};

export default CustomPhoneNumber;

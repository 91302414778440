export const loadingComponent = (
  <span className="indicator-progress">
    <span className="spinner-border spinner-border-sm align-middle ms-2 error-message"></span>
    &nbsp; يتم التحقق من صحة الرقم
  </span>
);
export const ErrorComponent = (message) => {
  return <span className="text-red error-message">{message}</span>;
};
export const SuccessComponent = (
  <span className="text-green error-message">الرقم المدخل صحيح</span>
);

import React, { useEffect, useState } from "react";
import BannerWithImage from "../../components/banner-with-image";
import bannerImage from "../../assets/images/banner-image.jpg";
import TermsAndConditionContent from "../../components/terms-content";
import { Styles } from "../terms-conditions/styles";
import { Helmet } from "react-helmet";
const PrivacyPolicy = () => {
  return (
    <Styles>
      <Helmet>
        <title>MoET Digital Services - Privacy Policy</title>
      </Helmet>
      {/* <BannerWithImage image={bannerImage} title="سياسة الخصوصية" /> */}
      <BannerWithImage
        image={bannerImage}
        title="Privacy Policy"
        height="55vh"
      />
      <TermsAndConditionContent />
    </Styles>
  );
};

export default PrivacyPolicy;

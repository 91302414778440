import Cookies from "js-cookie";

const COOKIE_NAME = "access_token";

export const setAccessTokenCookie = (token) => {
  Cookies.set(COOKIE_NAME, token, {
    expires: 7,
    secure: true,
    sameSite: "strict",
  });
};

export const setEmailVerifiedCookie = (token) => {
  Cookies.set("EmailVerified", token, {
    expires: 7,
    secure: true,
    sameSite: "strict",
  });
};

export const setMobileVerifiedCookie = (token) => {
  Cookies.set("MobileVerified", token, {
    expires: 7,
    secure: true,
    sameSite: "strict",
  });
};

export const setRefreshCookie = (token) => {
  Cookies.set("RefT", token, {
    expires: 7,
    secure: true,
    sameSite: "strict",
  });
};

export const setUserIdCookie = (token) => {
  Cookies.set("usrId", token, {
    expires: 7,
    secure: true,
    sameSite: "strict",
  });
};

export const getAccessTokenCookie = () => {
  const token = Cookies.get(COOKIE_NAME);
  if (token !== "" && token) {
    return token;
  } else return null;
};

export const getEmailVerifiedCookie = () => {
  const EmailVerified = Cookies.get("EmailVerified");
  return EmailVerified;
};

export const getMobileVerifiedCookie = () => {
  const MobileVerified = Cookies.get("MobileVerified");
  return MobileVerified;
};

export const getRefreshTokenCookie = () => {
  const RefT = Cookies.get("RefT");
  return RefT;
};

export const getUserIdCookie = () => {
  const UsrId = Cookies.get("usrId");
  return UsrId;
};

export const removeAccessTokenCookie = () => {
  Cookies.remove(COOKIE_NAME, { secure: true, sameSite: "strict" });
  Cookies.remove("EmailVerified", { secure: true, sameSite: "strict" });
  Cookies.remove("MobileVerified", { secure: true, sameSite: "strict" });
  Cookies.remove("RefT", { secure: true, sameSite: "strict" });
  Cookies.remove("usrId", { secure: true, sameSite: "strict" });
};

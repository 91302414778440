import React from "react";
import { Styles } from "./styles";
import bgImage from "../../../assets/images/exhibitions-modal.png";
import { Link } from "react-router-dom";
const AddExhibitionSection = () => {
  return (
    <Styles bgImage={bgImage}>
      <p>اضافة المعارض والأسواق المستقبلية الان تحظى بانتشار أوسع ونجاح أكبر</p>
      <Link to="/add-exhibition">ارسال طلب</Link>
    </Styles>
  );
};

export default AddExhibitionSection;

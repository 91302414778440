import { Button } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
const Styles = styled.div`
  padding-top: 4rem;
  padding-bottom: 6rem;

  .number {
    font-size: 8rem;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0;
    color: var(--green);
  }
  .text-declaration {
    font-size: 1.5rem;
    margin-top: -1rem;
    color: var(--green);
  }
`;

const NotFoundPage = () => {
  return (
    <Styles>
      <div className="text-center">
        <div className="number">404</div>
        <div className="text-declaration mb-4">
          عذرا الصفحة التي قمت يطلبها غير موجودة
        </div>
        <div className="home-button">
          <NavLink to="/">
            <Button
              sx={{
                borderRadius: "15px",
                background:
                  "linear-gradient(180deg, var(--first-linear-green) 0%, var(--second-linear-green) 100%)",
                color: "#FFF",
                textAlign: "center",
                fontFamily: "Cairo",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "normal",
              }}
              variant="contained"
            >
              العودة للصفحة الرئيسية
            </Button>
          </NavLink>
        </div>
      </div>
    </Styles>
  );
};

export default NotFoundPage;

export const filesValidation = (
  owner,
  setOwnerError,
  engineNumber,
  setEngineNumberError,
  carRegisterNumber,
  setCarRegisterNumberError,
  backSideImage,
  setBackSideImageError,
  frontSideImage,
  setFrontSideImageError,
  signedCertificateFront,
  setSignedCertificateFrontError,
  SignedCertificateBack,
  setSignedCertificateBackError
) => {
  if (owner === null || owner === "") {
    setOwnerError(true);
  } else setOwnerError(false);

  if (engineNumber === null || engineNumber === "") {
    setEngineNumberError(true);
  } else setEngineNumberError(false);

  if (carRegisterNumber === null || carRegisterNumber === "") {
    setCarRegisterNumberError(true);
  } else setCarRegisterNumberError(false);

  if (backSideImage === null || backSideImage === "") {
    setBackSideImageError(true);
  } else setBackSideImageError(false);

  if (frontSideImage === null || frontSideImage === "") {
    setFrontSideImageError(true);
  } else setFrontSideImageError(false);
  if (signedCertificateFront === null || signedCertificateFront === "") {
    setSignedCertificateFrontError(true);
  } else setSignedCertificateFrontError(false);
  if (SignedCertificateBack === null || SignedCertificateBack === "") {
    setSignedCertificateBackError(true);
  } else setSignedCertificateBackError(false);
};

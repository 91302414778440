import styled from "styled-components";
export const Styles = styled.div`
  /* display: flex; */
  .dialog-button {
    border-radius: "10px";
    /* background: linear-gradient(180deg, var(--first-linear-green) 0%, var(--second-linear-green) 100%); */
    color: #f8f9fa;
    text-align: center;
    font-size: "1rem";
    font-weight: 600;
    transition: all 0.2s ease-out;
    border: 1px solid var(--second-linear-green);
    color: var(--second-linear-green);
    margin-right: 0.5rem;
  }
  .dialog-button:hover {
    border-radius: "10px";
    border: 1px solid #f8f9fa;
    background: #fff;
    color: var(--second-linear-green) !important;

    transition: all 0.2s ease-out;
  }
`;

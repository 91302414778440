import React, { useEffect } from "react";
import { Styles } from "./styles";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import pdfIcon from "../../../../assets/images/pdf-icon.png";
import fileIcon from "../../../../assets/images/file-icon.png";
import environment from "../../../../environment";
const PreviewFile = ({
  type,
  fileType,
  filePath,
  id,
  Guid,
  fileValue,
  setDocumentLink,
  documentLinks,
  documentLink,
  setDocumentLinks,
  fileGuid,
  setAllowNextAfterUploadDocuments,
  data,
  mandatory,
  checkClickAble,
  checkFieldCorrection,
  checkConditionsChange,
}) => {
  const handleRemoveFile = () => {
    document.getElementById(id).value = "";
    if (mandatory) document.getElementById(id).required = true;
    let oldArr = [];
    data?.map((step) => {
      step?.DynamicFormSections?.map((section) => {
        section.DynamicFormFields.map((field) => {
          if (field.Id === id) field.Value = null;
        });
      });
    });
    documentLink?.map((item) => {
      if (item?.Value != fileValue) oldArr.push(item);
    });
    setDocumentLink(oldArr);
    const item = document.getElementById(`single-file-uploader-${id}`);
    item.innerHTML = "اضغط لاختيار الملف";
    checkClickAble(data);
    checkConditionsChange(Guid);
    checkFieldCorrection(id);
  };
  const handleRemoveFiles = () => {
    let oldArr = [];
    let elementsCount = 0;
    documentLinks?.map((item) => {
      if (item?.Value != fileValue) oldArr.push(item);
      if (item?.FieldId == Guid) elementsCount = elementsCount + 1;
    });
    setDocumentLinks(oldArr);
    const item = document.getElementById(`files-uploader-${id}`);
    if (oldArr?.length === 0) item.innerHTML = "اضغط لاختيار الملفات";
    else if (oldArr?.length === 1) item.innerHTML = "تم رفع الملف  بنجاح";
    else item.innerHTML = "تم رفع " + oldArr?.length + " ملفات  بنجاح";
    if (elementsCount == 1 && mandatory)
      if (mandatory) document.getElementById(id).required = true;
    if (
      elementsCount == 1 &&
      document.getElementById(id).hasAttribute("required")
    ) {
      setAllowNextAfterUploadDocuments(true);
      document.getElementById(id).value = "";
    }
    checkConditionsChange(Guid);
    checkFieldCorrection(id);
  };
  const getSrc = () => {
    switch (fileType) {
      case "png":
        return filePath;
      case "jpg":
        return filePath;
      case "jpeg":
        return filePath;
      case "pdf":
        return pdfIcon;
      default:
        return fileIcon;
    }
  };
  useEffect(() => {}, []);
  return (
    <Styles>
      {fileGuid === Guid && (
        <div className="border-green">
          <div
            className="close-icon"
            onClick={type == "single" ? handleRemoveFile : handleRemoveFiles}
          >
            <HighlightOffIcon style={{ color: "var(--red)" }} />
          </div>
          <a
            href={`${environment.IMAGE_URL}${fileValue}`}
            download
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <img src={getSrc()} alt="image" />
            <div className="title">
              <span>
                {fileValue.substring(
                  fileValue.lastIndexOf("/") + 1,
                  fileValue.indexOf("_")
                ).length > 50 ? (
                  <>
                    {fileValue
                      .substring(
                        fileValue.lastIndexOf("/") + 1,
                        fileValue.indexOf("_")
                      )
                      .substring(0, 20)}
                    ...
                  </>
                ) : (
                  fileValue.substring(
                    fileValue.lastIndexOf("/") + 1,
                    fileValue.indexOf("_")
                  )
                )}{" "}
                .{fileType}
              </span>
            </div>
          </a>
        </div>
      )}
    </Styles>
  );
};

export default PreviewFile;

import React from "react";
import MessageStep from ".";
import ReviewData from "../../review-data";

const SubmitStepProvider = ({
  documentUploaded,
  defaultSubmitMessage,
  fieldsData,
  data,
  setActiveSubmitStep,
  setActiveStep,
  usersData,
  setDocumentUploaded,
  dynamicFormDisplayName,
  dynamicFormId,
  dataToSubmit,
  sendData,
  submitLoading,
  setSubmitLoading,
  formDataId,
  documentLink,
  documentLinks,
  successMessageHtml,
  DynamicFormConfirmationType,
  DropDownListExternalDataApilink,
  DropDownListExternalDataLabelName,
  DropDownListExternalDataValueName,
  processId,
  refreshForm,
  setRefreshForm,
  transactionProducts,
}) => {
  return (
    <div className="success-message-container">
      {documentUploaded ? (
        defaultSubmitMessage ? (
          <div>
            <MessageStep />
          </div>
        ) : (
          <div
            className="p-2"
            dangerouslySetInnerHTML={{
              // __html: optimizedData[activeStep]?.StepMessage,
              __html: successMessageHtml,
            }}
          ></div>
        )
      ) : (
        <ReviewData
          // form={Array.from(document?.getElementById("formControl"))}
          fieldsData={fieldsData}
          data={data}
          setActiveSubmitStep={setActiveSubmitStep}
          setActiveStep={setActiveStep}
          usersData={usersData}
          setDocumentUploaded={setDocumentUploaded}
          dynamicFormDisplayName={dynamicFormDisplayName}
          dynamicFormId={dynamicFormId}
          dataToSubmit={dataToSubmit}
          sendData={sendData}
          submitLoading={submitLoading}
          setSubmitLoading={setSubmitLoading}
          formDataId={formDataId}
          documentLink={documentLink}
          documentLinks={documentLinks}
          DynamicFormConfirmationType={DynamicFormConfirmationType}
          processId={processId}
          refreshForm={refreshForm}
          setRefreshForm={setRefreshForm}
          transactionProducts={transactionProducts}
          // DropDownListExternalDataApilink={DropDownListExternalDataApilink}
          // DropDownListExternalDataLabelName={DropDownListExternalDataLabelName}
          // DropDownListExternalDataValueName={DropDownListExternalDataValueName}
        />
      )}
    </div>
  );
};

export default SubmitStepProvider;

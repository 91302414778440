import React from "react";
import AboutUs from ".";

const AboutPlatform = () => {
  return (
    <AboutUs>
      <p>
        هذه المنصة هي أداة جديدة تقدمها وزارة الاقتصاد والتجارة، حيث توفر
        للمستهلكين أداة أكثر كفاءة وسرعة لتقديم الشكوى. بالإضافة إلى ذلك، تقدم
        للشركات والمحترفين نموذجًا آليًا جديدًا لتقديم الطلبات للحصول على
        التراخيص اللازمة. من خلال هذه المنصة الرقمية، سيكون بإمكان المستهلكين
        تقديم الشكوى بسرعة وبشفافية تامة، وستمكن الشركات من تقديم طلباتها من
        مواقع عملها بسرعة وسهولة.
      </p>
    </AboutUs>
  );
};

export default AboutPlatform;

import { createContext } from "react";
import useStateRef from "react-usestateref";

const UserContext = createContext({
  userProfile: {},
  setUserProfile: () => {},
});

export function UserContextProvider(props) {
  const [userProfile, setUserProfile, userProfileRef] = useStateRef([]);

  const context = {
    userProfile: userProfileRef.current,
    setUserProfile: setUserProfile,
  };

  return (
    <UserContext.Provider value={context}>
      {props.children}
    </UserContext.Provider>
  );
}

export default UserContext;

import styled from "styled-components";
export const Styles = styled.div`
  position: relative;
  margin-bottom: 2rem;
  .title {
    position: absolute;
    top: 22vh;
    font-size: 2rem;
    font-weight: 7000;
    color: white;
  }
`;

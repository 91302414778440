import Skeleton from "@mui/material/Skeleton";

// Define the loading columns with skeletons
export const loadingColumns = [
  {
    name: "المعرض",
    selector: (row) => (
      <div style={{ width: "100px" }}>
        <Skeleton variant="text" sx={{ fontSize: "1.2rem", width: "100%" }} />
      </div>
    ),
    width: "auto",
    wrap: true,
  },
  {
    name: "من تاريخ",
    selector: (row) => (
      <div style={{ width: "100px" }}>
        <Skeleton variant="text" sx={{ fontSize: "1.2rem", width: "100%" }} />
      </div>
    ),
    width: "auto",
    wrap: true,
  },
  {
    name: "إلى تاريخ",
    selector: (row) => (
      <div style={{ width: "100px" }}>
        <Skeleton variant="text" sx={{ fontSize: "1.2rem", width: "100%" }} />
      </div>
    ),
    width: "auto",
    wrap: true,
  },
  {
    name: "المكان",
    selector: (row) => (
      <div style={{ width: "100px" }}>
        <Skeleton variant="text" sx={{ fontSize: "1.2rem", width: "100%" }} />
      </div>
    ),
    width: "auto",
    wrap: true,
  },
  {
    name: "عدد الزوار",
    selector: (row) => (
      <div style={{ width: "100px" }}>
        <Skeleton variant="text" sx={{ fontSize: "1.2rem", width: "100%" }} />
      </div>
    ),
    width: "auto",
    wrap: true,
  },
  {
    name: "عدد العارضين",
    selector: (row) => (
      <div style={{ width: "100px" }}>
        <Skeleton variant="text" sx={{ fontSize: "1.2rem", width: "100%" }} />
      </div>
    ),
    width: "auto",
    wrap: true,
  },
  {
    name: "حجم المبيعات",
    selector: (row) => (
      <div style={{ width: "100px" }}>
        <Skeleton variant="text" sx={{ fontSize: "1.2rem", width: "100%" }} />
      </div>
    ),
    width: "auto",
    wrap: true,
  },
];

export default loadingColumns;

import React, { useEffect, useState } from "react";
import { Styles } from "./styles";
import CircleIcon from "@mui/icons-material/Circle";
import { Stack } from "@mui/material";
import { Link } from "react-router-dom";
import { getProcessCategories } from "../../../services/homePage/ProcessCategories.services";
import Loader from "../../loader";

const Services = () => {
  const [services, setServices] = useState([]);
  const [LoadingServices, setLoadingServices] = useState(false);
  const getServices = async () => {
    setLoadingServices(true);
    const response = await getProcessCategories();
    if (response.status === 200) {
      setServices(response.data);
      setLoadingServices(false);
    }
  };

  useEffect(() => {
    getServices();
  }, []);

  return (
    <Styles id="servicesSection" loadingServices={LoadingServices}>
      <section class="services-section">
        <div class="title">
          <Stack alignItems={"start"}>
            <div class="icons">
              <CircleIcon fontSize="small" style={{ color: "#006838" }} />
              <CircleIcon fontSize="small" style={{ color: "#006838" }} />
              &nbsp;
              <h2>خدمات للمحترفين</h2>
            </div>
            <h5>إختار قطاع عملك ثم حدد المعاملة التي ترغب في تقديمها</h5>
          </Stack>
        </div>
        <div class="content">
          {LoadingServices ? (
            <Loader backgroundColor={"#d7e7fe"} />
          ) : (
            <div class="row ml-0 mr-0 w-100">
              {services.map((item) => (
                <div
                  class="col-lg-3 col-md-4 col-sm-12  d-flex single-service-container p-0 "
                  key={item.Id}
                >
                  <Link
                    to={`/service/${item.Id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <div class="single-service">
                      <img src={item.Image} alt={item.Name} />
                      <p class="text">{item.Name}</p>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>
    </Styles>
  );
};

export default Services;

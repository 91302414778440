import styled from "styled-components";
export const Styles = styled.div`
  p {
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-size: 1.5rem;
  }
  /* padding-right: 2.5rem;
  padding-left: 2.5rem; */
  /* text-align: justify; */
  .download-section {
    display: flex;
    gap: 15px;
    justify-content: center;
    img {
      width: 150px;
      height: 44px;
      object-fit: cover;
      /* padding: 1rem; */
      &:hover {
        filter: brightness(0.8);
      }
    }
  }
  @media (max-width: 768px) {
    p {
      padding-top: 10rem;
    }
  }
  @media (max-width: 550px) {
    p {
      padding-top: 8rem;
    }
  }
`;

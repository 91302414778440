import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import logo from "../../assets/images/logo_rtl.png";
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
    borderBottom: "1px solid grey",
    padding: "12rem",
    margin: "12rem",
    direction: "RTL",
  },
  header: {
    padding: "5pt 10pt",
    marginBottom: "10pt",
    height: "50pt",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    color: "red",
  },
});

// Create Document Component
export const MyDocument = () => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <Image src={logo} alt="logo" style={{ width: "100px" }} />
      </View>
      <View style={styles.title}>
        <h1>ljllklk:</h1>
        <h1>ljllklk:</h1>
      </View>
      <View style={styles.section}>
        <Text>Section #2</Text>
        <Text>أحمد</Text>
      </View>
    </Page>
  </Document>
);

import { passwordRegex } from "../../../../constants/types";

export const RegistrationInformationValidation = (
  data,
  setEmailError,
  setPasswordError,
  setPasswordConfirmationError,
  setRegistrationPhoneNumberError
) => {
  const { email, password, passwordConfirmation, registrationPhoneNumber } =
    data;

  function isEmailValid(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  const isValid = isEmailValid(email);

  // const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;

  function isPasswordValid(password) {
    return passwordRegex?.test(password);
  }

  const isValidPassword = isPasswordValid(password);

  if (isValid) {
    setEmailError(false);
  } else setEmailError(true);

  if (!isValidPassword) {
    setPasswordError(true);
  } else setPasswordError(false);

  if (passwordConfirmation !== password) {
    setPasswordConfirmationError(true);
  } else setPasswordConfirmationError(false);

  if (
    registrationPhoneNumber === "" ||
    registrationPhoneNumber.length < 7 ||
    registrationPhoneNumber.length > 8
  ) {
    setRegistrationPhoneNumberError(true);
  } else if (registrationPhoneNumber.length === 7) {
    setRegistrationPhoneNumberError(false);
  } else setRegistrationPhoneNumberError(false);
};

export const ResetPasswordValidation = (
  data,
  setCurrentPasswordError,
  setPasswordError,
  setPasswordConfirmationError
) => {
  const { currentPassword, password, passwordConfirmation } = data;

  function isPasswordValid(password) {
    return passwordRegex?.test(password);
  }
  if (currentPassword === "" || !isPasswordValid(currentPassword)) {
    setCurrentPasswordError(true);
  } else setCurrentPasswordError(false);

  // const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;

  const isValidPassword = isPasswordValid(password);

  if (!isValidPassword) {
    setPasswordError(true);
  } else setPasswordError(false);

  if (passwordConfirmation !== password) {
    setPasswordConfirmationError(true);
  } else setPasswordConfirmationError(false);
};

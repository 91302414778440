import React, { useContext, useEffect, useState } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Logo from "../../assets/images/logo_rtl.png";
import { Link } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import "./header.css";
import { getAccessTokenCookie } from "../../utils/cookie";
import useStateRef from "react-usestateref";
import UserContext from "../../context/UserContext";
import environment from "../../environment";

function IntermediatePageHeader() {
  const [token, setToken, tokenRef] = useStateRef(null);
  const { userProfile } = useContext(UserContext);
  const token2 = getAccessTokenCookie();

  useEffect(() => {
    setToken(getAccessTokenCookie());
  }, [userProfile]);

  const [expanded, setExpanded] = useState(false);

  const handleNavbarItemClick = () => {
    setExpanded(false);
  };

  const handleNavbarItemClickServices = () => {
    setExpanded(false);
  };

  return (
    <Navbar bg="light" variant="dark" expand="lg" expanded={expanded}>
      <Container>
        <Navbar.Brand>
          <Link to="https://www.economy.gov.lb/" target="_blank">
            <img src={Logo} alt="logo" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle
          onClick={() => setExpanded(!expanded)}
          aria-controls="basic-navbar-nav"
          className="custom-toggle-button"
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className={`me-auto`}>
            <div className="nav-link">
              <a
                href={`${environment.PORTAL_URL}`}
                onClick={handleNavbarItemClick}
              >
                الرئيسية
              </a>
            </div>
            <div className="nav-link">
              <a
                href={`${environment.PORTAL_URL}/about/about-platform`}
                onClick={handleNavbarItemClick}
              >
                من نحن
              </a>
            </div>

            <div className="nav-link">
              <NavHashLink
                to={`${environment.PORTAL_URL}/#servicesSection`}
                smooth
                onClick={handleNavbarItemClickServices}
              >
                خدمات المحترفين
              </NavHashLink>
            </div>
            <div className="nav-link">
              <NavHashLink
                to={`${environment.PORTAL_URL}/#complaintsSection`}
                onClick={handleNavbarItemClick}
              >
                شكاوى
              </NavHashLink>
            </div>
            <div className="nav-link">
              <a
                href={`${environment.PORTAL_URL}/statistics-reports`}
                onClick={handleNavbarItemClick}
              >
                احصائيات وتقارير
              </a>
            </div>

            <div className="nav-link">
              <a
                href={`${environment.PORTAL_URL}/contact-us`}
                onClick={handleNavbarItemClick}
              >
                تواصل معنا
              </a>
            </div>

            {tokenRef.current ? (
              <div className="nav-link" id="dashboard-btn">
                <a
                  href={`${environment.PORTAL_URL}/dashboard/in-progress`}
                  onClick={handleNavbarItemClick}
                >
                  حسابي
                </a>
              </div>
            ) : (
              <div className="nav-link" id="login-btn">
                <a
                  href={`${environment.PORTAL_URL}/login`}
                  onClick={handleNavbarItemClick}
                >
                  تسجيل الدخول
                </a>
              </div>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
export default IntermediatePageHeader;

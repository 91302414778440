import { Checkbox, FormControlLabel } from "@mui/material";
import React from "react";
import classes from "./checkboxes.module.css";

const CustomCheckbox = ({ value, onChangeFunc, label, subLabel }) => {
  return (
    <FormControlLabel
      className={`${classes.CheckboxContainer} ${
        value ? classes.checked : ""
      } customCheckbox`}
      control={<Checkbox checked={value} onChange={(e) => onChangeFunc(e)} />}
      label={`${label}  ${subLabel ? subLabel : ""}`}
      sx={{
        "& .MuiTypography-root": {
          color: "#194522",
          textAlign: "center",
          fontFamily: "Cairo",
          fontSize: "18px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "188.9%" /* 34.002px */,
        },
      }}
    />
  );
};

export default CustomCheckbox;

import styled from "styled-components";
// import breakpoints from "../../../constants/breakpoints";
export const Styles = styled.div`
  .swiper-button-next,
  .swiper-button-prev {
    width: 1px !important;
    background-size: 1%;
    display: none;
  }
  .swiper {
    width: 100%;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .button-area {
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 350px;
  }
  .swiper-button {
    display: flex;
    position: relative;
    /* top: calc(50% - 12px); */
    z-index: 10;
    cursor: pointer;
    height: 1px;
  }
  .swiper-button svg {
    width: 4rem;
    height: 5rem;
    color: var(--grey);
    ${({ isAr }) => isAr && "transform: rotate(180deg)"};
  }
  .swiper-slide {
    padding: 0 0 4rem 0;
  }
  .prev-btn {
    ${({ isAr }) => (isAr ? "right: -60px" : "left: -60px")};
  }
  .next-btn {
    ${({ isAr }) => (isAr ? "left: -60px" : "right: -60px")};
  }
  .swiper-button-disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  @media (max-width: 768px) {
    .button-area {
      top: 380px;
    }
    .swiper {
      width: 95%;
    }
    .prev-btn {
      ${({ isAr }) => (isAr ? "right: -40px" : "left: -40px")};
    }
    .next-btn {
      ${({ isAr }) => (isAr ? "left: -40px" : "right: -40px")};
    }
  }
  @media (max-width: 550px) {
    .button-area {
      top: 250px;
    }
    .swiper {
      width: 88%;
    }
    .prev-btn {
      ${({ isAr }) => (isAr ? "right: -20px" : "left: -20px")};
      top: 0vh;
    }
    .next-btn {
      ${({ isAr }) => (isAr ? "left: -20px" : "right: -20px")};
      top: 0vh;
    }
    .swiper-button svg {
      width: 3rem;
      height: 5rem;
      color: var(--grey);
      ${({ isAr }) => isAr && "transform: rotate(180deg)"};
    }
  }
`;

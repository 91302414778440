import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { CircularProgress, TextField, Typography } from "@mui/material";
import classes from "./style.module.css";

import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { ForgotPassword } from "../../constants/endpoints";
import {
  errorToaster,
  successToaster,
} from "../../helpers/toasterConfiguration";
import apiKey from "../../constants/apiKey";

export default function ForgetPassDialog({ setOpenResetDialog, setUserEmail }) {
  const [open, setOpen] = React.useState(false);
  const [fireValidation, setFireValidation] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("صيغة البريد الالكتروني غير صحيحة")
      .required("يرجى ادخال البريد الالكتروني"),
  });

  const initialValues = {
    email: "",
  };

  const handleSubmit = (values) => {
    setLoading(true);
    axios
      .post(ForgotPassword, null, {
        headers: {
          APIKey: apiKey,
        },
        params: {
          Email: values.email,
        },
      })
      .then(() => {
        setOpen(false);
        successToaster("تم ارسال رمز تحقق الى رقم الهاتف المحمول");
        setUserEmail(values.email);
        setOpenResetDialog(true);
        setLoading(false);
      })
      .catch((error) => {
        errorToaster(error.response.data);
        setLoading(false);
      });
  };
  return (
    <div>
      <Typography
        variant="subtitle1"
        onClick={handleClickOpen}
        // sx={{ color: "#439e56", cursor: "pointer" }}
        sx={{ color: "var(--green)", cursor: "pointer" }}
      >
        هل نسيت كلمة المرور؟
      </Typography>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogTitle>يرجى ادخال البريد الالكتروني</DialogTitle>
        <DialogContent sx={{ paddingBottom: 0 }}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={fireValidation}
            validateOnBlur={fireValidation}
            onSubmit={handleSubmit}
          >
            {({ errors, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div>
                  {/* <Field
                    placeholder="البريد الالكتروني"
                    type="email"
                    id="email"
                    name="email"
                    className={classes.inputField}
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error"
                    style={{ color: "red", margin: "1rem 0" }}
                  /> */}
                  <Field
                    className={`${classes.inputField} ${
                      errors?.email ? classes.inputFiledError : ""
                    }`}
                    as={TextField}
                    type="email"
                    name="email"
                    id="email"
                    placeholder={"البريد الاكتروني"}
                    variant="outlined"
                    fullWidth
                    helperText={
                      <ErrorMessage
                        name="email"
                        render={(msg) => (
                          <p
                            style={{
                              color: "red",
                              textAlign: "right",
                              fontSize: "1rem",
                            }}
                          >
                            {msg}
                          </p>
                        )}
                      />
                    }
                    sx={{
                      "& .MuiFormHelperText-root": {
                        textAlign: "right",
                      },
                    }}
                  />
                </div>
                <DialogActions
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "0.5rem 0 1rem 0",
                    padding: "0",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    className={classes.loginButton}
                    onClick={() => setFireValidation(true)}
                  >
                    {loading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      <>تأكيد</>
                    )}
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={handleClose}
                    className={classes.logoutButton}
                  >
                    اغلاق
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}

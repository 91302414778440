import environment from "../../environment";
import {
  PUBLICDELETE,
  PUBLICGET,
  PUBLICPOST,
  PUBLICPUT,
} from "../http.service";

// new endpoints
// transactions
const TransactionsDocumentByIdEndpoint = environment.TransactionsDocumentById;
export const GetFEDTransactionDocumentById = async (id) => {
  const response = await PUBLICGET(
    `${TransactionsDocumentByIdEndpoint}?Id=${id}`,
    true
  );
  return response;
};
const postFEDTransactionDocumentEndpoint =
  environment.PostFEDTransactionDocument;
export const submitFEDTransactionDocument = async (requestBody) => {
  const response = await PUBLICPOST(
    `${postFEDTransactionDocumentEndpoint}`,
    requestBody,
    true
  );
  return response;
};
// draft
const postFEDTransactionDocumentDraftEndpoint =
  environment.PostFEDTransactionDocumentDraft;
export const submitFEDTransactionDocumentAsDraft = async (id) => {
  const response = await PUBLICPUT(
    `${postFEDTransactionDocumentDraftEndpoint}?Id=${id}`,
    {},
    true
  );
  return response;
};
// exhibitors
const exhibitorDetailsEndpoint = environment.exhibitorDetails;
export const GetExhibitorDetails = async (id) => {
  const response = await PUBLICGET(
    `${exhibitorDetailsEndpoint}?Id=${id}`,
    true
  );
  return response;
};
const addExhibitorEndpoint = environment.AddExhibitor;
export const addExhibitor = async (requestBody) => {
  const response = await PUBLICPOST(
    `${addExhibitorEndpoint}`,
    requestBody,
    true
  );
  return response;
};
const updateExhibitorEndpoint = environment.UpdateExhibitor;
export const updateExhibitor = async (requestBody) => {
  const response = await PUBLICPUT(
    `${updateExhibitorEndpoint}`,
    requestBody,
    true
  );
  return response;
};
const deleteExhibitorEndpoint = environment.DeleteExhibitor;
export const deleteExhibitor = async (id) => {
  const response = await PUBLICDELETE(
    `${deleteExhibitorEndpoint}?Id=${id}`,
    true
  );
  return response;
};
//
const addNewExhibitionEndpoint = environment.AddNewExhibition;
export const addNewExhibition = async (requestBody) => {
  const response = await PUBLICPOST(
    `${addNewExhibitionEndpoint}`,
    requestBody,
    true
  );
  return response;
};
const allExhibitionEndpoint = environment.AllExhibition;
export const GetAllExhibition = async (
  page,
  term,
  typeId,
  fromDate,
  toDate
) => {
  const response = await PUBLICGET(
    `${allExhibitionEndpoint}?Page=${page}&Term=${term}&TypeId=${typeId}&FromDate=${fromDate}&ToDate=${toDate}`,
    false
  );
  return response;
};
const homeExhibitionEndpoint = environment.HomeExhibition;
export const GetHomeExhibition = async (page) => {
  const response = await PUBLICGET(
    `${homeExhibitionEndpoint}?Page=${page}`,
    false
  );
  return response;
};
const marketReportsEndpoint = environment.MarketReports;

export const GetMarketReports = async (page, perPage) => {
  const response = await PUBLICGET(
    `${marketReportsEndpoint}?page=${page}&perPage=${perPage}`,
    false
  );
  return response;
};

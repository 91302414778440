import React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import classes from "../textField/textfield.module.css";
import { InputLabel } from "@mui/material";

const SingleCheckbox = ({ value, setValue, label, isRequired }) => {
  const handleChange = (event) => {
    setValue(event.target.checked);
  };

  return (
    <div className={classes.root}>
      <InputLabel
        className={`${classes.inputLabel} ${
          isRequired ? classes.requiredField : ""
        }`}
      >
        {label}
      </InputLabel>
      <FormControlLabel
        className="customSingleCheckbox"
        control={
          <Checkbox
            checked={value}
            onChange={handleChange}
            sx={{
              alignItems: "center",
            }}
          />
        }
      />
    </div>
  );
};

export default SingleCheckbox;

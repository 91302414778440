import { Button } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";

const StepButton = ({ id, text, textSize, func, arrow, type, disabled }) => {
  const location = useLocation();
  const path = location.pathname;
  return type === "forward" ? (
    <Button
      id={id}
      onClick={func}
      sx={
        path === "/dashboard/settings"
          ? {
              borderRadius: "15px",
              paddingLeft: "2rem",
              paddingRight: "2rem",
              width: "fit-content",
              background:
                "linear-gradient(180deg, var(--first-linear-green) 0%, var(--second-linear-green) 100%)",
              color: "#fff",
              textAlign: "center",
              fontSize: textSize ? textSize : "1.2rem",
              fontStyle: "normal",
              fontWeight: 700,
            }
          : {
              color: "var(--second-linear-green)",
              textAlign: "center",
              fontFamily: "Cairo",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "188.9%" /* 34.002px */,
            }
      }
      disabled={disabled}
    >
      {disabled ? (
        <span className="indicator-progress">
          يتم الإرسال &nbsp;
          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>
      ) : (
        text
      )}
      {path === "/dashboard/settings" ? null : arrow}
    </Button>
  ) : type === "dynamic-forward" ? (
    <Button
      id={id}
      onClick={func}
      sx={{
        color: "var(--second-linear-green)",
        textAlign: "center",
        fontFamily: "Cairo",
        fontSize: textSize ? textSize : "18px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "188.9%" /* 34.002px */,
      }}
      disabled={disabled}
    >
      {text}
      {arrow}
    </Button>
  ) : (
    <Button
      id={id}
      onClick={func}
      sx={{
        color: "var(--second-linear-green)",
        textAlign: "center",
        fontFamily: "Cairo",
        fontSize: textSize ? textSize : "18px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "188.9%" /* 34.002px */,
      }}
      disabled={disabled}
    >
      {arrow} {text}
    </Button>
  );
};

export default StepButton;

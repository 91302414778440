import styled from "styled-components";
export const Styles = styled.div`
  margin-bottom: 2rem;
  .section-title {
    display: flex;
    justify-content: space-between;
    margin: 2rem 0 1rem;
    #link-button {
      background: transparent !important;
      color: var(--green);
      text-decoration: none;
      position: relative;
      font-size: 1.1rem;
      font-weight: 600;
    }
    #link-button::before {
      content: "";
      position: absolute;
      top: 70%;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: var(--green); /* Replace with your desired color */
      transform: scaleX(0);
      transition: transform 0.3s cubic-bezier(0.2, 0.8, 0.2, 1); /* Add transition property for smooth animation */
    }
    #link-button:hover::before {
      transform: scaleX(1); /* Expand the line on hover */
    }
  }
  .loader-container {
    min-height: 440px;
  }
`;

import { Button, Typography } from "@mui/material";
import React from "react";

import successIcon from "../../../../assets/images/icons/success-icon.webp";
import { Styles } from "./styles";
import { NavHashLink } from "react-router-hash-link";
import { NavLink } from "react-router-dom";
import { getAccessTokenCookie } from "../../../../utils/cookie";

const MessageStep = () => {
  const token = getAccessTokenCookie();
  return (
    <Styles>
      <div className="step-container d-flex flex-column justify-content-center p-3">
        <div className="content-section text-center pb-4">
          <img src={successIcon} alt="Success icon" />
        </div>
        <div className="content-section text-center">
          <Typography variant="h4" textAlign={"center"}>
            لقد تم تسجيل طلبك بنجاح
          </Typography>
        </div>
        <div className="actions-section d-flex justify-content-center mt-4 mb-4">
          <NavLink>
            <NavHashLink
              to={token ? "/dashboard/in-progress" : "/#servicesSection"}
              smooth
            >
              <Button
                sx={{
                  borderRadius: "15px",
                  background:
                    "linear-gradient(180deg, var(--first-linear-green) 0%, var(--second-linear-green) 100%)",
                  color: "#FFF",
                  textAlign: "center",
                  fontFamily: "Cairo",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "normal",
                }}
                variant="contained"
              >
                الاطلاع على الخدمات
              </Button>
            </NavHashLink>
          </NavLink>
        </div>
      </div>
    </Styles>
  );
};

export default MessageStep;

import React, { useEffect, useState } from "react";
import BannerWithImage from "../../components/banner-with-image";
import bannerImage from "../../assets/images/banner-image.jpg";
import { Styles } from "./styles";
import TermsAndConditionContent from "../../components/terms-content";
const TermsAndConditions = () => {
  return (
    <Styles>
      <BannerWithImage
        image={bannerImage}
        // title="الشروط و الأحكام"
        title="Terms And Conditions"
        height="55vh"
      />
      <TermsAndConditionContent />
    </Styles>
  );
};

export default TermsAndConditions;

import styled from "styled-components";
export const Styles = styled.div`
  .footer-section {
    .first-section {
      background-color: #f5f5f5;
      padding-top: 2rem;
      /* padding-bottom: 2rem; */
      padding-right: 12rem;
      padding-left: 12rem;
      .undp {
        width: 100%;
        img {
          width: 45%;
          cursor: pointer;
          margin-right: 1rem;
          object-fit: cover;
        }
      }
      .moet {
        width: 100%;
        img {
          width: 85%;
          cursor: pointer;
          object-fit: cover;
        }
      }
      ul {
        list-style: none;
        text-align: start;
      }
      ul li,
      ul li a {
        text-decoration: none;
        color: #000;
        font-size: 1.2rem;
      }
      ul li a:hover {
        color: var(--dark-green);
      }
      ul .share-icons {
        display: flex;
        justify-content: space-between;
      }
      ul .share-icons a img {
        width: 80%;
        /* padding-left: 0.8rem; */
        /* padding-right: 0rem; */
      }
      ul li img {
        width: 70%;
      }
      .download-section {
        flex: 1;
        text-align: left;
        display: flex;
        flex-direction: column;
        align-items: center;
        a {
          width: 180px;
          height: 55px;
        }
        img {
          width: 80%;
          /* height: 100%; */
          padding-left: 0.8rem;
          cursor: pointer;
        }
      }
    }
    .second-section {
      background-color: #f5f5f5;
      padding-right: 13rem;
      padding-left: 12rem;
      padding-bottom: 1rem;
      text-align: start;
    }
  }
  @media (max-width: 1024px) and (min-width: 769px) {
    .footer-section .first-section {
      padding-right: 10rem;
      padding-left: 10rem;
      .undp {
        width: 100%;
        img {
          width: 58%;
          cursor: pointer;
          margin-right: 1rem;
          object-fit: cover;
        }
      }
      .moet {
        width: 100%;
        img {
          width: 100%;
          /* height: 170px; */
          /* width: 150px; */
          cursor: pointer;
          object-fit: cover;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .footer-section {
      .first-section {
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
        padding-right: 2rem;
        padding-left: 2rem;
        img {
          width: 55%;
        }
        .undp {
          width: 100%;
          img {
            width: 45%;
            cursor: pointer;
            margin-right: 1rem;
            object-fit: cover;
          }
        }
        .moet {
          width: 100%;
          img {
            width: 85%;
            cursor: pointer;
            object-fit: cover;
          }
        }
        ul {
          padding-right: 0rem;
        }
        ul li,
        ul li a {
          font-size: 0.9rem;
        }
        .download-section {
          text-align: start;
        }
        .download-section a {
          /* width: 180px; */
          width: 100px;
          /* height: 55px; */
          height: 30px;
        }
        .download-section img {
          width: 100%;
        }
      }
      .second-section {
        padding-right: 3rem;
        padding-left: 2rem;
      }
    }
  }
  @media (max-width: 550px) {
    .footer-section {
      .first-section {
        img {
          width: 100%;
        }
        ul {
          padding-right: 0rem;
          padding-top: 1rem;
        }
        ul li,
        ul li a {
          font-size: 1rem;
        }
        .download-section {
          margin-top: 1rem;
          img {
            width: 100%;
            height: 80%;
          }
        }
      }
      .second-section {
        padding-right: 2rem;
        padding-left: 2rem;
      }
    }
  }
`;

import styled from "styled-components";
export const Styles = styled.div`
  background-image: ${({ bgImage }) => `url(${bgImage})`};
  /* background-position: top 8px right 65%; */
  background-size: cover;
  height: 380px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 0.8rem;
  margin-right: 0.8rem;
  /* margin-left: auto;
  margin-right: auto; */
  p {
    color: white;
    font-size: 1.5rem;
    font-weight: 700;
    width: 38%;
    text-align: center;
  }
  a {
    background-color: transparent;
    border: 1px solid white;
    border-radius: 31px;
    color: white;
    font-weight: 600;
    text-decoration: none;
    padding: 0.8rem 1.5rem;
    margin-top: 1.5rem;
    transition: 0.3s;
    &:hover {
      background: linear-gradient(
        180deg,
        var(--first-linear-green) 0%,
        var(--second-linear-green) 100%
      );

      color: white;
    }
  }
`;

import React from "react";
import { Nav, NavItem } from "reactstrap";
import { NavLinkStyles, NavStyles, Styles } from "./styles";
import { Link, useLocation } from "react-router-dom";

const Tabs = ({ children }) => {
  // const { t } = useTranslation(["knowledge-for-all", "common"]);

  const location = useLocation();
  const navData = [
    {
      title: "عن المنصة",
      link: "/about/about-platform",
    },
    {
      title: "مسؤولياتنا",
      link: "/about/our-responsibilities",
    },
    {
      title: "اعمالنا",
      link: "/about/our-works",
    },
    {
      title: "أهدافنا",
      link: "/about/our-goal",
    },
  ];
  const { pathname } = useLocation();
  const nav = () => {
    return (
      <NavStyles>
        <Nav className="mr-auto" navbar>
          {navData.map((navItem, i) => {
            return (
              <NavItem key={i}>
                <NavLinkStyles textlength={navItem?.title?.length}>
                  <Link
                    // to={}
                    to={navItem.link}
                    state={{ from: pathname }}
                    className={location.pathname === navItem.link && "active"}
                  >
                    <span>{navItem.title}</span>
                  </Link>
                </NavLinkStyles>
              </NavItem>
            );
          })}
        </Nav>
      </NavStyles>
    );
  };

  return (
    <Styles>
      <div className="d-flex justify-content-center w-100">{nav()}</div>
    </Styles>
  );
};

export default Tabs;

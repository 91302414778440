import React from "react";
import { Dialog, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import YouTube from "react-youtube-embed";
import classes from "../option3page.module.css";
const VideoPopUp = ({ id }) => {
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  return (
    <>
      <h5 onClick={handleOpenDialog} className={classes.DialogButton}>
        انقر هنا
      </h5>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
        sx={{
          borderRadius: "5px",
          "& .MuiPaper-root": {
            overflow: "visible",
          },
        }}
      >
        <IconButton
          edge="end"
          onClick={handleCloseDialog}
          aria-label="close"
          sx={{
            position: "absolute",
            top: "-10px",
            right: 0,
            zIndex: 3,
            padding: 0,
            backgroundColor: "#fff",
            color: "#000",
            "& :hover": {
              backgroundColor: "#000",
              color: "#fff",
              borderRadius: "50%",
            },
          }}
        >
          <CloseIcon />
        </IconButton>
        <YouTube id={id} className={classes.youtubeIframe} />
      </Dialog>
    </>
  );
};

export default VideoPopUp;

import React from "react";
import { Styles } from "./styles";
import { Stack } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import food from "../../../assets/images/about/Asset-1.webp";
import ads from "../../../assets/images/about/Asset-3.webp";
import products from "../../../assets/images/about/Asset-5.webp";
import prices from "../../../assets/images/about/Asset-7.webp";
import users from "../../../assets/images/about/Asset-8.webp";
import copywrights from "../../../assets/images/about/Asset4.webp";
const ServicePriorities = () => {
  const services = [
    {
      Image: food,
      Name: "سلامة الغذاء",
    },
    { Image: ads, Name: "مكافحة الإعلانات المضللة" },
    { Image: products, Name: "مراقبة جودة السلع" },
    { Image: prices, Name: "مكافحة ارتفاع أسعار السلع والخدمات" },
    { Image: users, Name: "توعية المستهلكين" },
    { Image: copywrights, Name: "مكافحة تقليد السلع" },
  ];
  return (
    <Styles>
      <section className="services-section page-container">
        <div className="title">
          <Stack alignItems={"start"}>
            <div className="icons">
              <CircleIcon
                fontSize="small"
                style={{
                  color: "var(--green)",
                }}
              />
              &nbsp;
              <h2>أولويات خدماتنا</h2>
            </div>
          </Stack>
        </div>
        <div className="content">
          <div className="row ml-0 mr-0 w-100">
            {services.map((item) => (
              <div
                className="col-lg-4 col-md-4 col-sm-12  d-flex single-service-container p-0 "
                key={item.Id}
              >
                <div className="single-service">
                  <img src={item.Image} alt={item.Name} />
                  <p className="text">{item.Name}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Styles>
  );
};

export default ServicePriorities;

import environment from "../../environment";
import { PUBLICGET } from "../http.service";

const ProcessCategoriesUrl = environment.ProcessCategories;
export const getProcessCategories = async () => {
  const response = await PUBLICGET(`${ProcessCategoriesUrl}`, false);
  return response;
};

const ComplaintsUrl = environment.ComplaintsSectionUrl;
export const getComplaintsFunction = async () => {
  const response = await PUBLICGET(`${ComplaintsUrl}`, false);
  return response;
};

const ProcessUrl = environment.Process;
export const getProcess = async (id) => {
  const response = await PUBLICGET(`${ProcessUrl}?CategoryId=${id}`, false);
  return response;
};

import styled from "styled-components";

export const Styles = styled.div`
  /* background-color: var(--light-blue); */
  padding: 1rem;
  margin-top: 2rem;
  font-weight: 500;
  width: 100%;
  .steps-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .step-container {
    display: flex;
    flex-direction: row;
    flex: 1;
  }
  .step {
    /* color: var(--mid-grey); */
    cursor: pointer;
  }
  .form-title {
    color: white;
    width: fit-content;
    /* margin-top: 0.2rem; */
    font-size: 1.2rem;
    text-align: center;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-radius: 12px;
  }
  .step-title {
    color: white;
    width: fit-content;
    /* margin-top: 0.2rem; */
    font-size: 1rem;
    text-align: center;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-radius: 12px;
  }
  .step-title-green {
    background-color: var(--green);
  }
  .step-title-grey {
    background-color: var(--grey);
  }
  .divider {
    width: ${({ stepsLength }) => (stepsLength > 3 ? "60px" : "190px")};
  }
  .divider-green hr {
    border-top: 0px;
    border-bottom: 3px solid var(--green);
    opacity: 1;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .divider-grey hr {
    border-top: 0px;
    border-bottom: 3px solid var(--grey);
    margin-left: 0.5rem;
    margin-right: 0.5rem;

    align-items: center;
  }
  /* ---- RESPONSIVE ---- */
  /* ---- Other Desktop Views ---- */
  @media (max-width: 1200px) {
    .divider-green hr,
    .divider-grey hr {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
  }
  @media (min-width: 769px) and (max-width: 1200px) {
    .main-container {
      margin-left: 4rem !important;
      margin-right: 4rem !important;
      padding-top: 3rem;
      padding-bottom: 6rem;
    }
    .divider {
      width: ${({ stepsLength }) => (stepsLength > 3 ? "30px" : "190px")};
    }
  }
  /* ---- TABLET ---- */
  @media (max-width: 768px) {
    padding-top: 2rem;
    margin-top: 0rem;
    .steps-container {
      margin-top: 0rem;

      /* overflow: hidden; */

      .divider {
        width: ${({ stepsLength }) => (stepsLength > 3 ? "30px" : "90px")};
      }
      .divider-green hr,
      .divider-grey hr {
        margin-left: 0.3rem;
        margin-right: 0.3rem;
      }
      .step-title {
        color: white;
        width: fit-content;
        /* margin-top: 0.2rem; */
        text-align: center;
        font-size: 0.8rem;
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
        padding-left: 1rem;
        padding-right: 1rem;
        border-radius: 12px;
      }
    }
  }
  /* ---- MOBILE ---- */
  @media (max-width: 576px) {
    .steps-container {
      flex-direction: column;
      margin-top: 0rem;
      align-items: center;
      .step-container {
        flex-direction: column;
        align-items: center;
      }
      .divider {
        width: 330px;
      }
      .step-title {
        color: white;
        width: fit-content;
        /* margin-top: 0.2rem; */
        font-size: 1rem;
        text-align: center;
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        border-radius: 12px;
      }
    }
  }
  .wrapper {
    position: relative;
    overflow: hidden;
    width: 576px;
    height: 50px;
    /* border: 1px solid black; */
  }
  #slide {
    position: absolute;
    left: 0px;
    /* width: 100px; */
    /* height: 100px; */
    /* background: blue; */
    transition: 1s;
    transition: 1s;
  }
  /* .wrapper:hover #slide {
  } */
`;

import React, { useEffect, useState } from "react";
import CustomSlider from "../../common/custom-slider";
import CircleIcon from "../../../pages/dashboard/components/CircleIcon";
import ExhibitionCard from "../exhibitions/exhibtion-card";
import { Styles } from "./styles";
import StepButton from "../../../pages/register/components/stepsButton";
import { Link } from "react-router-dom";
import { GetHomeExhibition } from "../../../services/fed/fedExhibitors.service";
import Loader from "../../loader";

const HomeExhibitions = () => {
  const [data, setData] = useState([]);
  // UI/UX
  const [loading, setLoading] = useState(false);
  const getData = async () => {
    setLoading(true);
    const response = await GetHomeExhibition();
    setData(response?.data);
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <Styles id="exhibitions-table">
      {(data?.length > 0 || loading) && (
        <div className="container">
          <div className="section-title">
            <div className="icons justify-content-start">
              <CircleIcon fontSize="small" style={{ color: "#006838" }} />
              &nbsp;
              <h2>جدول المعارض والأسواق</h2>
            </div>
            <Link
              id="link-button"
              to="/exhibitions"

              // func={handleSaveDraft}
              // disabled={draftSubmittingLoading}
            >
              عرض الكل
            </Link>
          </div>
          {loading ? (
            <div className="loader-container">
              <Loader backgroundColor={"transparent"} />
            </div>
          ) : (
            <CustomSlider
              slides={data}
              withAutoPlay
              SlideContentComponent={ExhibitionCard}
              slidesPerViewObj={{
                desktop: 3,
                tablet: 1,
                mobile: 1,
              }}
              withNavigation={false}
            />
          )}
        </div>
      )}
    </Styles>
  );
};

export default HomeExhibitions;

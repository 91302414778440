import React, { useContext, useEffect, useState } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Logo from "../../assets/images/logo_rtl.png";
import { Link } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import "./header.css";
import { getAccessTokenCookie } from "../../utils/cookie";
import useStateRef from "react-usestateref";
import UserContext from "../../context/UserContext";
import { typesOfProjects } from "../../constants/enums";

function Navbar2() {
  const activeEnv = process.env.REACT_APP_ACTIVE_ENV;
  const [token, setToken, tokenRef] = useStateRef(null);
  const { userProfile } = useContext(UserContext);
  const token2 = getAccessTokenCookie();
  const isContactUs = window.location.pathname === "/contact-us";
  const isTermsAndConditions =
    window.location.pathname === "/terms-and-conditions";
  const isPrivacyPolicy = window.location.pathname === "/privacy-policy";
  const isMobileIntermediatePage = window.location.pathname === "/mobileapp";
  useEffect(() => {
    setToken(getAccessTokenCookie());
  }, [userProfile]);

  const [expanded, setExpanded] = useState(false);

  const handleNavbarItemClick = () => {
    setExpanded(false);
  };

  const handleNavbarItemClickServices = () => {
    setExpanded(false);
  };

  return (
    <Navbar bg="light" variant="dark" expand="lg" expanded={expanded}>
      <Container>
        <Navbar.Brand>
          <Link to="https://www.economy.gov.lb/" target="_blank">
            <img src={Logo} alt="logo" />
          </Link>
        </Navbar.Brand>
        {!isContactUs &&
          !isTermsAndConditions &&
          !isPrivacyPolicy &&
          !isMobileIntermediatePage && (
            <Navbar.Toggle
              onClick={() => setExpanded(!expanded)}
              aria-controls="basic-navbar-nav"
              className="custom-toggle-button"
            />
          )}
        {!isContactUs &&
          !isTermsAndConditions &&
          !isPrivacyPolicy &&
          !isMobileIntermediatePage && (
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className={`me-auto`}>
                <Nav.Link>
                  <Link to="/" onClick={handleNavbarItemClick}>
                    الرئيسية
                  </Link>
                </Nav.Link>
                {activeEnv == typesOfProjects.CPD && (
                  <Nav.Link>
                    <Link
                      to="/about/about-platform"
                      onClick={handleNavbarItemClick}
                    >
                      من نحن
                    </Link>
                  </Nav.Link>
                )}

                {activeEnv == typesOfProjects.FED && (
                  <Nav.Link>
                    <Link to="/exhibitions" smooth>
                      جدول المعارض والاسواق
                    </Link>
                  </Nav.Link>
                )}
                {activeEnv == typesOfProjects.CPD && (
                  <Nav.Link>
                    <NavHashLink
                      to="/#servicesSection"
                      smooth
                      onClick={handleNavbarItemClickServices}
                    >
                      خدمات المحترفين
                    </NavHashLink>
                  </Nav.Link>
                )}
                {activeEnv == typesOfProjects.CPD && (
                  <Nav.Link>
                    <NavHashLink
                      to="/#complaintsSection"
                      onClick={handleNavbarItemClick}
                    >
                      شكاوى
                    </NavHashLink>
                  </Nav.Link>
                )}
                <Nav.Link>
                  <Link
                    to="/statistics-reports"
                    onClick={handleNavbarItemClick}
                  >
                    احصائيات وتقارير
                  </Link>
                </Nav.Link>

                {/* <Nav.Link>
              <Link to="/privacy-policy" onClick={handleNavbarItemClick}>
                سياسة الخصوصية
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link to="/terms-and-conditions" onClick={handleNavbarItemClick}>
                الشروط و الأحكام
              </Link>
            </Nav.Link> */}

                <Nav.Link>
                  <Link to="/contact-us" onClick={handleNavbarItemClick}>
                    تواصل معنا
                  </Link>
                </Nav.Link>
                {/* <Nav.Link>
              <Link to="/" onClick={handleNavbarItemClick}>
                اتصل بنا
              </Link>
            </Nav.Link> */}

                {tokenRef.current ? (
                  <Nav.Link id="dashboard-btn">
                    <Link
                      to="/dashboard/in-progress"
                      onClick={handleNavbarItemClick}
                    >
                      حسابي
                    </Link>
                  </Nav.Link>
                ) : (
                  <Nav.Link id="login-btn">
                    <Link to="/login" onClick={handleNavbarItemClick}>
                      تسجيل الدخول
                    </Link>
                  </Nav.Link>
                )}
              </Nav>
            </Navbar.Collapse>
          )}
      </Container>
    </Navbar>
  );
}
export default Navbar2;

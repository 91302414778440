import environment from "../environment";
import {
  getAccessTokenCookie,
  getRefreshTokenCookie,
  getUserIdCookie,
  setAccessTokenCookie,
  setRefreshCookie,
  setUserIdCookie,
} from "../utils/cookie";
import { PUBLICPOST } from "./http.service";

const endpoint = environment.RefreshToken;

export const RefreshTokenFunction = async () => {
  const refreshToken = getRefreshTokenCookie();
  const userId = getUserIdCookie();
  const Token = getAccessTokenCookie();
  /* const response =  */
  await PUBLICPOST(
    endpoint,
    {
      Token: Token,
      RefreshToken: refreshToken,
      UserId: userId,
    },
    false
  ).then((res) => {
    setAccessTokenCookie(res.data.Token);
    setRefreshCookie(res.data.RefreshToken);
    setUserIdCookie(res.data.UserId);
  });
  //   return response;
};

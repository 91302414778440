export const setActiveTheme = () => {
  const mainColor = process.env.REACT_APP_MAIN_COLOR || "#000000"; // default color if not set
  const firstLinearGreen =
    process.env.REACT_APP_FIRST_LINEAR_GREEN || "#6fe286"; // default color if not set
  const medLinearGreen = process.env.REACT_APP_MED_LINEAR_GREEN || "#2b7239"; // default color if not set
  const secondLinearGreen =
    process.env.REACT_APP_SECOND_LINEAR_GREEN || "#2b7239"; // default color if not set

  // Set the CSS variable on the root element
  document.documentElement.style.setProperty("--green", mainColor);
  document.documentElement.style.setProperty(
    "--first-linear-green",
    firstLinearGreen
  );
  document.documentElement.style.setProperty(
    "--med-linear-green",
    medLinearGreen
  );
  document.documentElement.style.setProperty(
    "--second-linear-green",
    secondLinearGreen
  );
};

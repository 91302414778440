import { Button } from "@mui/material";
import React, { useState } from "react";
import { Styles } from "./styles";
import { Link } from "react-router-dom";
import {
  actionsTypes,
  returnedToClientActionEnum,
} from "../../../constants/enums";
import InsurancePolicyModal from "../../../components/FED/insurance-policy-modal";
import ReportsModal from "../../../components/FED/report-modal";

const DashboardTableActions = ({ row, setRefreshData }) => {
  // ui/ux
  const [insuranceModalShow, setInsuranceModalShow] = useState(false);
  const [reportModalShow, setReportModalShow] = useState(false);
  // states
  const [documentId, setDocumentId] = useState(null);
  const [transactionId, setTransactionId] = useState(null);
  const toggleInsuranceModal = (item) => {
    setInsuranceModalShow(true);
    setDocumentId(item.DocumentId);
  };
  const toggleReportModal = (item) => {
    setReportModalShow(true);
    setDocumentId(item.DocumentId);
  };
  return (
    <Styles>
      {row.CertificateURL && (
        <a
          href={row?.CertificateURL}
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <Button className="dialog-button">تحميل الملف</Button>
        </a>
      )}
      {row?.FEDActions?.map((item) => (
        <>
          {item?.DocumentTypeId == actionsTypes?.ExhibitorsList && (
            <Link
              to={`/list-of-exhibitors/${row?.Id}/${item.DocumentId}`}
              style={{ textDecoration: "none" }}
            >
              <Button className="dialog-button">لائحة العارضين</Button>
            </Link>
          )}
          {item?.DocumentTypeId == actionsTypes?.InsurancePolicy && (
            <Button
              className="dialog-button"
              onClick={() => toggleInsuranceModal(item)}
            >
              بوليصة التأمين
            </Button>
          )}
          {item?.DocumentTypeId == actionsTypes?.MarketReport && (
            <Link
              to={`/exhibition-and-fair-report/${row?.Id}/${item.DocumentId}`}
              style={{ textDecoration: "none" }}
            >
              <Button className="dialog-button">تقرير عن السوق</Button>
            </Link>
          )}
        </>
      ))}
      {row?.StatusId == returnedToClientActionEnum && (
        <Link
          to={`/dynamic-form?dynamicFormId=${row?.DynamicFormId}&processId=${row.ProcessId}&formDataId=${row?.DynamicFormDataId}&transactionId=${row?.Id}`}
          style={{ textDecoration: "none" }}
        >
          <Button className="dialog-button">تكملة الطلب</Button>
        </Link>
      )}
      <InsurancePolicyModal
        show={insuranceModalShow}
        onHide={() => setInsuranceModalShow(false)}
        transactionId={row?.Id}
        documentId={documentId}
        setRefreshData={setRefreshData}
        // transactionId={transactionId}
        // editRowIndex={editRowIndex}
        // setRefreshData={setRefreshData}
      />
      {/* <ReportsModal
        show={reportModalShow}
        onHide={() => setReportModalShow(false)}
        transactionId={row?.Id}
        documentId={documentId}
        // transactionId={transactionId}
        // editRowIndex={editRowIndex}
        // setRefreshData={setRefreshData}
      /> */}
    </Styles>
  );
};

export default DashboardTableActions;

import styled from "styled-components";

export const Styles = styled.div`
  padding-top: 2rem;
  padding-right: 2.5rem;
  padding-left: 2.5rem;
  padding-bottom: 2rem;
  text-align: justify;
  font-size: 1.2rem;
  .statistics-title {
    text-align: center;
    font-size: 2.5rem;
  }
  .DialogButton {
    display: inline-block;
    color: #000;
    text-decoration: none;
    position: relative;
    overflow: visible;
    color: #016938;
    cursor: pointer;
  }

  .DialogButton::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -3px;
    width: 100%;
    height: 2px;
    background-color: #016938; /* Replace with your desired border color */
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
  }

  .DialogButton:hover::after {
    transform: scaleX(1); /* Expand the border on hover */
  }
  .DialogButton:hover {
    color: #016938;
    font-weight: 800;
  }
  @media (max-width: 1024px) {
    font-size: 1.4rem;
  }
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

import styled from "styled-components";
export const Styles = styled.div`
  direction: ltr;
  margin-top: 2rem;
  margin-bottom: 2rem;
  .content-section {
    margin-top: 2rem;
    p {
      font-size: 1.2rem;
    }
    h3 {
      font-weight: 600;
    }
  }

  ol {
    font-size: 1.2rem;
    li {
      font-size: 1.2rem;
      font-weight: 600;
    }
  }
  ul > li {
    font-size: 1.2rem;
    font-weight: 500;
  }
  a {
    color: var(--green);
    text-decoration: none;
  }
`;

import React, { useEffect, useState } from "react";
import { Styles } from "./styles";
import { FormControlLabel, Radio, RadioGroup, Stack } from "@mui/material";
import environment from "../../../../environment";

const PrettyStyle = ({
  Id,
  Name,
  Placeholder,
  DefaultValue,
  PicklistStyle,
  PicklistId,
  PicklistFilterType,
  PicklistFilterFieldGuid,
  PicklistFilterParentOptionId,
  Guid,
  allPicklists,
  listData,
  prettyStyleData,
  checkConditionsChange,
  checkClickAble,
  data,
  columnSize,
  checkFieldCorrection,
  lockAllFieldsExceptAttachments,
}) => {
  const [mainData, setMainData] = useState([]);
  return (
    <Styles>
      <div className="pretty-list-container row">
        <RadioGroup
          className="customRadioButtons d-flex flex-row row"
          aria-label="prettyStyle"
          name={Name}
          // value={value}
          id={Id}
          onChange={(e) => {
            checkConditionsChange(Guid);
            checkClickAble(data);
            checkFieldCorrection(Id);
          }}
          onBlur={() => checkFieldCorrection(Id)}
          defaultValue={DefaultValue}
        >
          {console.log("prettyStyleData")}
          {console.log(prettyStyleData)}
          {PicklistFilterType === "FilterBaseOnFieldValue"
            ? prettyStyleData?.map((opt) => (
                <div
                  className={
                    columnSize === 12
                      ? "single-item col-sm-12 col-md-6 col-lg-4"
                      : "single-item col-sm-12 col-md-6 col-lg-6"
                  }
                  style={{ padding: "1rem" }}
                >
                  <div className="single-item">
                    <label className="form-check-image">
                      <div className="form-check-wrapper image-container">
                        <img
                          src={`${environment.IMAGE_URL}${opt?.Img}`}
                          alt={opt?.Name}
                        />
                      </div>
                      <FormControlLabel
                        control={
                          <Radio disabled={lockAllFieldsExceptAttachments} />
                        }
                        labelPlacement="end"
                        label={opt?.Name}
                        name={Name}
                        id={Id}
                        value={opt.Id}
                        // defaultChecked={opt.Id == DefaultValue}
                        sx={{
                          "&.MuiFormControlLabel-root": {
                            marginRight: 0,
                            minHeight: "85px",
                          },
                        }}
                      />
                    </label>
                  </div>
                </div>
              ))
            : PicklistFilterType === "FilterBaseOnFieldValue"
            ? prettyStyleData?.map((opt) => (
                <div
                  className={
                    columnSize === 12
                      ? "single-item col-sm-12 col-md-6 col-lg-4"
                      : "single-item col-sm-12 col-md-6 col-lg-6"
                  }
                  style={{ padding: "1rem" }}
                >
                  <div className="single-item">
                    <label className="form-check-image">
                      <div className="form-check-wrapper image-container">
                        <img
                          src={`${environment.IMAGE_URL}${opt?.Img}`}
                          alt={opt?.Name}
                        />
                      </div>
                      <FormControlLabel
                        control={
                          <Radio disabled={lockAllFieldsExceptAttachments} />
                        }
                        labelPlacement="end"
                        label={opt?.Name}
                        name={Name}
                        id={Id}
                        value={opt.Id}
                        // defaultChecked={opt.Id == DefaultValue}
                        sx={{
                          "&.MuiFormControlLabel-root": {
                            marginRight: 0,
                            minHeight: "85px",
                          },
                        }}
                      />
                    </label>
                  </div>
                </div>
              ))
            : prettyStyleData?.map((opt) => (
                <div
                  className={
                    columnSize === 12
                      ? "single-item col-sm-12 col-md-6 col-lg-4"
                      : "single-item col-sm-12 col-md-6 col-lg-6"
                  }
                  style={{ padding: "1rem" }}
                >
                  <div className="single-item">
                    <label className="form-check-wrapper">
                      <div className="image-container">
                        <img
                          src={`${environment.IMAGE_URL}${opt?.Img}`}
                          alt={opt?.Name}
                        />
                      </div>
                      <FormControlLabel
                        control={
                          <Radio disabled={lockAllFieldsExceptAttachments} />
                        }
                        labelPlacement="end"
                        label={opt?.Name}
                        name={Name}
                        id={Id}
                        value={opt.Id}
                        // defaultChecked={opt.Id == DefaultValue}
                        sx={{
                          "&.MuiFormControlLabel-root": {
                            marginRight: 0,
                            minHeight: "85px",
                          },
                        }}
                      />
                    </label>
                  </div>
                </div>
              ))}
        </RadioGroup>
      </div>
    </Styles>
  );
};

export default PrettyStyle;

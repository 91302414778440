import React, { useEffect, useState } from "react";
import RequestInfo from "../../../components/FED/request-information";
import { Button, Skeleton } from "@mui/material";
import CustomDataTable from "../../../components/common/data-table";
import { useMediaQuery } from "react-responsive";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import { demoExhibitorsData } from "../../../constants/demoData";
import AddNewExhibitor from "../../../components/FED/add-new-exhibitor-modal";
import { Styles } from "./styles";
import { successToaster } from "../../../helpers/toasterConfiguration";
import { useNavigate, useParams } from "react-router-dom";
import DeleteExhibitor from "../../../components/FED/delete-exhibitor-modal";
import DeleteExhibitorModal from "../../../components/FED/delete-exhibitor-modal";
import {
  GetFEDTransactionDocumentById,
  submitFEDTransactionDocument,
  submitFEDTransactionDocumentAsDraft,
} from "../../../services/fed/fedExhibitors.service";
import { actionsTypes } from "../../../constants/enums";
import StepButton from "../../register/components/stepsButton";
const loadingColumns = [
  {
    name: "اسم الشركة",
    selector: (row) => (
      <div style={{ width: "100px" }}>
        <Skeleton variant="text" sx={{ fontSize: "1.2rem", width: "100%" }} />
      </div>
    ),
    wrap: true,
  },
  {
    name: "القطاع",
    selector: (row) => (
      <div style={{ width: "100px" }}>
        <Skeleton variant="text" sx={{ fontSize: "1.2rem", width: "100%" }} />
      </div>
    ),
    wrap: true,
  },
  {
    name: "المنتجات",
    selector: (row) => (
      <div style={{ width: "100px" }}>
        <Skeleton variant="text" sx={{ fontSize: "1.2rem", width: "100%" }} />
      </div>
    ),
    // width: "300px",
    wrap: true,
  },
  {
    name: "ذات قيادة نسائية",
    selector: (row) => (
      <div style={{ width: "100px" }}>
        <Skeleton variant="text" sx={{ fontSize: "1.2rem", width: "100%" }} />
      </div>
    ),
    width: "auto",
    wrap: true,
  },
  {
    cell: (row, cell) => (
      <div className="d-flex">
        <Skeleton
          variant="circular"
          width={35}
          height={35}
          sx={{ marginLeft: "1rem" }}
        />
        <Skeleton variant="circular" width={35} height={35} />
      </div>
    ),
    name: "الاجراءات",
    //   center: true,
    //   width: "30ch",
  },
];
const ListOfExhibitors = () => {
  const navigate = useNavigate();
  const { transactionId, documentId } = useParams();
  const isMobileView = useMediaQuery({ query: "(max-width: 550px)" });
  // states
  const [data, setData] = useState(false);
  const [editRowIndex, setEditRowIndex] = useState();
  const [isEdit, setIsEdit] = useState(false);
  //
  const [loading, setLoading] = useState(true);
  const [refreshData, setRefreshData] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [draftSubmittingLoading, setDraftSubmittingLoading] = useState(false);
  const [disabledButton, setDisabledButton] = useState(null);
  const [addModalShow, setAddModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [isEmptyListError, setIsEmptyListError] = useState(false);
  // functions
  const onAdd = (emptyObject) => {
    // setTransactionProducts((prev) => [...prev, emptyObject]);
  };

  const onDelete = (row) => {
    setDeleteModalShow(true);
  };
  const backToProfile = () => {
    navigate("/dashboard/in-progress", { replace: true });
  };
  const columns = [
    {
      name: "اسم الشركة",
      selector: (row) => row.CompanyName,
      width: isMobileView ? "200px" : "auto",
      wrap: true,
    },
    {
      name: "القطاع",
      selector: (row) => row.Sector,
      width: isMobileView ? "180px" : "auto",
      wrap: true,
    },
    {
      name: "المنتجات",
      selector: (row) => row.Products,
      width: "auto",
      wrap: true,
    },
    {
      name: "العنوان",
      selector: (row) => row.Address,
      width: "auto",
      wrap: true,
    },
    {
      name: "رقم الهاتف",
      selector: (row) => (
        <p dir="ltr"> {`+961-${row.PhoneNumber}`.toString()}</p>
      ),
      width: "auto",
      wrap: true,
    },
    {
      name: "القيادة نسائية",
      selector: (row) => row.WomenLed,
      width: "auto",
      wrap: true,
    },
    {
      cell: (row, cell) => (
        <div>
          <EditIcon
            onClick={() => {
              row.index = row.index;
              setEditRowIndex(row?.Id);
              setIsEdit(true);
              setAddModalShow(true);
            }}
            style={{ color: "var(--second-linear-green)", cursor: "pointer" }}
          />
          <ClearIcon
            onClick={() => {
              setEditRowIndex(row?.Id);
              onDelete(row);
            }}
            style={{ color: "var(--red)", cursor: "pointer" }}
          />
        </div>
      ),
      name: "الاجراءات",
      //   center: true,
      //   width: "30ch",
    },
  ];
  // Calling API
  // post data
  const submit = async () => {
    if (data?.Exhibitors?.length > 0) {
      setIsEmptyListError(false);
      setSubmitLoading(true);
      let requestBody = {
        TransactionId: transactionId,
        DocumentTypeId: actionsTypes.ExhibitorsList,
      };
      const response = await submitFEDTransactionDocument(requestBody);
      if (response?.status === 200 || response?.status === 204) {
        successToaster(response?.data?.Data);
        backToProfile();
      } else {
        console.log(response);
      }
      setSubmitLoading(false);
    } else {
      setIsEmptyListError(true);
    }
  };
  const submitDraft = async () => {
    setDraftSubmittingLoading(true);
    const response = await submitFEDTransactionDocumentAsDraft(data?.Id);
    if (response?.status === 200 || response?.status === 204) {
      successToaster(response?.data?.Data);
      backToProfile();
    }
    setDraftSubmittingLoading(false);
  };
  // get data
  const getData = async () => {
    setLoading(true);
    const response = await GetFEDTransactionDocumentById(documentId);
    setData(response.data);
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, [refreshData]);
  return (
    <Styles>
      <div className="container">
        <RequestInfo
          autoNumber={data?.AutoNumber}
          transactionDate={data?.TransactionDate}
          exhibitionName={data?.ExhibitionName}
          openDate={data?.OpenDate}
          loading={loading}
          status={data?.Status}
          statusId={data?.StatusId}
          rejectionReason={data?.RejectionReason}
        />

        <div className="request-page-section">
          <div className="section-title d-flex justify-content-center">
            <h4>لائحة العارضين</h4>
            <Button
              sx={{
                borderRadius: "15px",
                background:
                  "linear-gradient(180deg, var(--first-linear-green) 0%, var(--second-linear-green) 100%)",
                color: "#FFF",
                textAlign: "center",
                fontFamily: "Cairo",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "normal",
                marginLeft: "1rem",
                marginRight: "0rem !important",
                marginTop: "1rem !important",
              }}
              variant="contained"
              onClick={() => {
                setIsEdit(false);
                setAddModalShow(true);
              }}
              disabled={false}
            >
              إضافة عارض
            </Button>
          </div>

          <CustomDataTable
            columns={loading ? loadingColumns : columns}
            data={loading ? [1, 2, 3, 4, 5] : data?.Exhibitors}
            // onRowClicked={(row) => {
            //   handleRowClicked(row);
            // }}
          />
        </div>
        {isEmptyListError && (
          <div className="text-center text-red mb-3">
            يجب تعبئة لائحة العارضين قبل التأكيد
          </div>
        )}
        <div className="request-actions">
          {/* <StepButton
            id="draft-button"
            type="dynamic-forward"
            text={
              draftSubmittingLoading ? (
                <span className="indicator-progress">
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  &nbsp; يتم الحفظ
                </span>
              ) : (
                "الحفظ كمسودة"
              )
            }
            textSize="1.4rem"
            // func={handleSaveDraft}
            // disabled={draftSubmittingLoading}
          /> */}
          <Button
            sx={{
              borderRadius: "15px",
              background:
                "linear-gradient(180deg, var(--first-linear-green) 0%, var(--second-linear-green) 100%)",
              color: "#FFF",
              textAlign: "center",
              fontFamily: "Cairo",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
              marginLeft: "1rem",
              marginBottom: "1rem",
            }}
            variant="contained"
            onClick={() => submit()}
            disabled={submitLoading || draftSubmittingLoading}
          >
            {submitLoading ? (
              <span className="indicator-progress">
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                &nbsp; يتم الحفظ
              </span>
            ) : (
              <>تأكيد</>
            )}
          </Button>
          <Button
            sx={{
              borderRadius: "15px",
              background:
                "linear-gradient(180deg, var(--first-linear-green) 0%, var(--second-linear-green) 100%)",
              color: "#FFF",
              textAlign: "center",
              fontFamily: "Cairo",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
              marginLeft: "1rem",
              marginBottom: "1rem",
            }}
            variant="contained"
            onClick={() => submitDraft()}
            disabled={submitLoading || draftSubmittingLoading}
          >
            {draftSubmittingLoading ? (
              <span className="indicator-progress">
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                &nbsp; يتم الحفظ
              </span>
            ) : (
              <>الحفظ كمسودة</>
            )}
          </Button>

          <Button
            sx={{
              borderRadius: "15px",
              background: "linear-gradient(120deg, #f5f5f5 0%, #9e9e9e 100%)",
              color: "#FFF",
              textAlign: "center",
              fontFamily: "Cairo",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
              marginLeft: "1rem",
              marginBottom: "1rem",
            }}
            variant="contained"
            onClick={() => backToProfile()}
            disabled={submitLoading || draftSubmittingLoading}
          >
            إلغاء
          </Button>
        </div>
        <AddNewExhibitor
          show={addModalShow}
          isEdit={isEdit}
          onHide={() => setAddModalShow(false)}
          transactionId={transactionId}
          editRowIndex={editRowIndex}
          setRefreshData={setRefreshData}
        />
        <DeleteExhibitorModal
          show={deleteModalShow}
          onHide={() => setDeleteModalShow(false)}
          editRowIndex={editRowIndex}
          setRefreshData={setRefreshData}
        />
      </div>
    </Styles>
  );
};

export default ListOfExhibitors;

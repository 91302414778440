import React, { useEffect, useState } from "react";
// import SiteNavbar from "../../components/navbar/SiteNavbar";
import Footer from "../../components/footer/Footer";
import Navbar2 from "../../components/navbar/header";
import { UserContextProvider } from "../../context/UserContext";
import ScrollToTop from "../../helpers/ScrollToTop";
import IntermediatePageHeader from "../../components/navbar/intermediatePageHeader";
import { useLocation } from "react-router-dom";
const PagesProvider = ({ children }) => {
  const location = useLocation();
  const [visibleFooter, setVisibleFooter] = useState(true);
  const isMobileForm = window.location.pathname === "/mobile-form";
  const isContactUs = window.location.pathname === "/contact-us";
  const isTermsAndConditions =
    window.location.pathname === "/terms-and-conditions";
  const isPrivacyPolicy = window.location.pathname === "/privacy-policy";
  const isMobileIntermediatePage = window.location.pathname === "/mobileapp";
  // useEffect(() => {
  //   const script = document.createElement("script");
  //   if (!isMobileForm) {
  //     script.src = "//js-eu1.hs-scripts.com/143306459.js";
  //     script.async = true;
  //     document.body.appendChild(script);
  //   }
  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  useEffect(() => {
    const script = document.createElement("script");
    if (!isMobileForm) {
      script.src =
        "https://downloads-global.3cx.com/downloads/livechatandtalk/v1/callus.js";
      script.async = true;
      script.id = "tcx-callus-js";
      document.body.appendChild(script);
    }
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  const checkVisibility = () => {
    setVisibleFooter(
      !isMobileForm &&
        !isContactUs &&
        !isTermsAndConditions &&
        !isPrivacyPolicy &&
        !isMobileIntermediatePage
    );
  };
  useEffect(() => {
    checkVisibility();
  }, [location]);
  return (
    <UserContextProvider>
      {!isMobileForm && <Navbar2 />}
      <main className="view-container">{children}</main>
      {visibleFooter && <Footer />}
      <ScrollToTop />
    </UserContextProvider>
  );
};

export default PagesProvider;

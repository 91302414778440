import React from "react";
import { Styles } from "./styles";
import logo from "../../assets/images/logo.webp";
import UNDPLogo from "../../assets/images/UNDP_logo.webp";
import twitterIcon from "../../assets/images/icons/Twitter.webp";
import instaIcon from "../../assets/images/icons/Insta.webp";
import facebookIcon from "../../assets/images/icons/FB.webp";
import googlePlay from "../../assets/images/icons/google play.png";
import appStore from "../../assets/images/icons/App store.png";
import { Link } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import { useMediaQuery } from "react-responsive";
import { typesOfProjects } from "../../constants/enums";

const Footer = () => {
  const activeEnv = process.env.REACT_APP_ACTIVE_ENV;
  const isIPadView = useMediaQuery({ query: "(max-width: 1024px)" });

  return (
    <Styles>
      <section className="footer-section">
        <div className="first-section">
          <div className="row ml-0 mr-0">
            <div
              className={
                isIPadView
                  ? "col-md-10 col-lg-10 col-sm-12"
                  : "col-md-10 col-lg-8 col-sm-12"
              }
            >
              <div className="row ml-0 mr-0">
                <div className="col-sm-12 col-md-5 col-lg-4 pr-0 d-flex">
                  <div className="undp">
                    <img src={UNDPLogo} alt="undp" />
                  </div>
                  <div className="moet">
                    <Link to="https://www.economy.gov.lb/" target="_blank">
                      <img src={logo} alt="undp" />
                    </Link>
                  </div>
                </div>
                <div className="col-sm-12 col-md-7 col-lg-8 d-flex">
                  <div className="col-6 pl-0 pr-0">
                    <ul>
                      <li>
                        <Link to="/">الصفحة الرئيسية</Link>
                      </li>

                      {activeEnv == typesOfProjects.CPD && (
                        <li>
                          <Link to="/about/about-platform">من نحن</Link>
                        </li>
                      )}
                      {activeEnv == typesOfProjects.FED && (
                        <li>
                          <Link to="/exhibitions" smooth>
                            جدول المعارض والاسواق
                          </Link>
                        </li>
                      )}

                      {activeEnv == typesOfProjects.CPD && (
                        <li>
                          <Link to="/statistics-reports">احصائيات وتقارير</Link>
                        </li>
                      )}

                      {activeEnv == typesOfProjects.CPD && (
                        <li>
                          <NavHashLink to="/#servicesSection" smooth>
                            خدمات المحترفين
                          </NavHashLink>
                        </li>
                      )}
                      {activeEnv == typesOfProjects.FED && (
                        <li>
                          <Link to="/contact-us">تواصل معنا</Link>
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className="col-6 pl-0 pr-0">
                    <ul>
                      {activeEnv == typesOfProjects.CPD && (
                        <li>
                          <NavHashLink to="/#complaintsSection">
                            شكاوى
                          </NavHashLink>
                        </li>
                      )}
                      {activeEnv == typesOfProjects.FED && (
                        <li>
                          <Link to="/statistics-reports">احصائيات وتقارير</Link>
                        </li>
                      )}
                      <li>
                        <Link to="/privacy-policy">سياسة الخصوصية</Link>
                      </li>
                      <li>
                        <Link to="/terms-and-conditions">الشروط و الأحكام</Link>
                      </li>
                      {activeEnv == typesOfProjects.CPD && (
                        <li>
                          <Link to="/contact-us">تواصل معنا</Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-4 col-lg-4 col-sm-12">
              <div className="row">
                <div className="col-lg-5 col-md-6 col-6">
                  <ul>
                    <li>تابعنا</li>
                    <li className="share-icons">
                      <a href="/" target="_blank">
                        <img src={twitterIcon} alt="Twitter" />
                      </a>
                      <a href="/" target="_blank">
                        <img src={instaIcon} alt="Instgrame" />
                      </a>
                      <a href="/" target="_blank">
                        <img src={facebookIcon} alt="Facebook" />
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-6 col-md-6 col-6 download-section">
                  <a href="/">
                    <img src={googlePlay} alt="google store" />
                  </a>
                  <a href="/">
                    <img src={appStore} alt="apple store" />
                  </a>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="second-section">&copy; وزارة الاقتصاد و التجارة</div>
      </section>
    </Styles>
  );
};

export default Footer;

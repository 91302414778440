import { Box, Grid, Stack } from "@mui/material";
import React from "react";
import CustomTextField from "../../../../components/elements/textField";
import CustomPhoneNumber from "../../../../components/elements/phoneNumber";
import CustomPasswordInput from "../../../../components/elements/password";
import StepButton from "../../components/stepsButton";
import GoogleRecaptcha from "../../../../components/common/GoogleRecapcha";

const RegistrationInfosStep = ({
  handleBack,
  handleNext,
  email,
  setEmail,
  emailError,
  registrationPhoneNumber,
  setRegistrationPhoneNumber,
  registrationPhoneNumberError,
  setRegistrationPhoneNumberError,
  password,
  setPassword,
  passwordError,
  setPasswordError,
  passwordConfirmation,
  setPasswordConfirmation,
  passwordConfirmationError,
  setPasswordConfirmationError,
  loadingRegistration,
  setCaptchaValue,
  setCaptchaExpired,
}) => {
  // captcha
  function getCaptchaInfo(val) {
    setCaptchaValue(val.value);
    setCaptchaExpired(val.expired);
  }
  return (
    <Stack
      alignItems={"center"}
      justifyContent={"space-between"}
      marginTop={5}
      marginBottom={1}
      sx={{ flex: 1 }}
    >
      <Grid
        container
        rowGap={4}
        columnGap={2}
        justifyContent={"flex-start"}
        alignItems={"center"}
      >
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <h2>معلومات عن التسجيل الالكتروني</h2>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <CustomTextField
            label="البريد الالكتروني"
            value={email}
            setValue={setEmail}
            isRequired={true}
            showError={emailError}
            type="email"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <CustomPhoneNumber
            label="رقم الهاتف"
            value={registrationPhoneNumber}
            setValue={setRegistrationPhoneNumber}
            isRequired={true}
            type="number"
            lastStep={true}
            setPhoneNumberError={setRegistrationPhoneNumberError}
            showError={registrationPhoneNumberError}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <CustomPasswordInput
            label="كلمة السر"
            value={password}
            setValue={setPassword}
            isRequired={true}
            showError={passwordError}
            setShowError={setPasswordError}
            type="password"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <CustomPasswordInput
            label="تأكيد كلمة السر"
            value={passwordConfirmation}
            setValue={setPasswordConfirmation}
            isRequired={true}
            showError={passwordConfirmationError}
            setShowError={setPasswordConfirmationError}
            type="confirm_password"
          />
        </Grid>
      </Grid>
      <Box sx={{ textAlign: "center", marginTop: "1rem" }}>
        <GoogleRecaptcha getCaptchaInfo={getCaptchaInfo} />
      </Box>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ margin: "2rem 0 0 0" }}
      >
        <StepButton
          disabled={loadingRegistration}
          type="backward"
          text={"رجوع"}
          func={handleBack}
          arrow={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="7"
              height="9"
              viewBox="0 0 7 13"
              fill="none"
              style={{ margin: "0 0.3rem" }}
            >
              <path
                d="M1 1L5.08579 5.08579C5.86683 5.86683 5.86684 7.13316 5.08579 7.91421L1 12"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          }
        />
        <StepButton
          disabled={loadingRegistration}
          type="forward"
          text="التالي"
          func={() => {
            handleNext({
              email: email,
              password: password,
              passwordConfirmation: passwordConfirmation,
              registrationPhoneNumber: registrationPhoneNumber,
            });
          }}
          arrow={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="7"
              height="9"
              viewBox="0 0 7 13"
              fill="none"
              style={{ margin: "0 0.3rem" }}
            >
              <path
                d="M5.5 1L1.41421 5.08579C0.633166 5.86683 0.633165 7.13316 1.41421 7.91421L5.5 12"
                stroke="var(--second-linear-green)"
                strokeLinecap="round"
                strokeWidth="3"
              />
            </svg>
          }
        />
      </Stack>
    </Stack>
  );
};

export default RegistrationInfosStep;

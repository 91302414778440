import environment from "../environment";
import { CustomGet, GET, GETWITHBODY, POST, POSTForm } from "./http.service";

const endpoint = environment.DYNAMIC_FORM_ENDPOINT;
const products_grid_endpoint = environment.productsGrid;
const save_endpoint = environment.SAVE_DYNAMIC_FORM_ENDPOINT;
const save_files_endpoint = environment.SAVE_FILES_ENDPOINT;
const save_file_endpoint = environment.SAVE_FILE_ENDPOINT;
const save_pdf_endpoint = environment.SAVE_BLOB_ENDPOINT;

export const getProductsGrid = async (transactionId) => {
  const response = await GET(
    `${products_grid_endpoint}/${transactionId}`,
    true
  );
  return response;
};
export const getDynamicForm = async (formId, formDataId) => {
  if (formDataId) {
    const response = await GET(
      `${endpoint}/RenderFields/${formId}?formDataId=${formDataId}`,
      false
    );
    return response;
  } else {
    const response = await GET(`${endpoint}/RenderFields/${formId}`, false);
    return response;
  }
};
export const submitDynamicForm = async (status, requestBody, tok) => {
  // `${endpoint}/SaveFormsData`,
  if (status === "draft") {
    const response = await POST(
      `${save_endpoint}/SaveFormsData`,
      requestBody,
      true,
      tok
    );
    return response;
  } else {
    const response = await POST(
      `${save_endpoint}/SaveFormsData`,
      requestBody,
      true,
      tok
    );
    return response;
  }
};
export const updateRequestDocuments = async (requestBody, tok) => {
  const response = await POST(
    "Transactions/UpdateRequestDocuments",
    requestBody,
    true,
    tok
  );
  return response;
};
export const uploadBasePdf = async (requestBody, tok) => {
  const response = await POST(`${save_pdf_endpoint}`, requestBody, true, tok);
  return response;
};
export const uploadAssignedDocument = async (requestBody, tok) => {
  const response = await POSTForm(
    `${endpoint}/UploadSignatureFile`,
    requestBody,
    true,
    tok
  );
  return response;
};
export const saveFiles = async (requestBody, tok) => {
  const response = await POSTForm(
    `${save_files_endpoint}`,
    requestBody,
    true,
    tok
  );
  return response;
};
export const saveFile = async (requestBody, tok) => {
  const response = await POSTForm(
    `${save_file_endpoint}`,
    requestBody,
    true,
    tok
  );
  return response;
};
export const generateOTPCodeForFormData = async (phoneNumber) => {
  // `${save_endpoint}/SaveFormsData`,
  const response = await POST(
    `${endpoint}/GenerateOTPCodeForFormData?mobileNo=${phoneNumber}`,
    {},
    true
  );
  return response;
};
export const CheckOTPCodeForFormData = async (OTPToken, code) => {
  // `${save_endpoint}/SaveFormsData`,
  const response = await POST(
    `${endpoint}/CheckOTPCodeForFormData?token=${OTPToken}&code=${code}`,
    {},
    true
  );
  return response;
};
export const getLoadData = async (requestBody, tok) => {
  const response = await POST(
    `${endpoint}/LoadFormDataByLoadField`,
    requestBody,
    true,
    tok
  );
  return response;
};
export const checkIfDataExistAPI = async (requestBody, tok) => {
  const response = await POST(
    `${endpoint}/CheckFormDataByLoadField`,
    requestBody,
    true,
    tok
  );
  return response;
};
const chassisCheck_Endpoint = environment.chassisCheck;
export const checkChassisNumberValidation = async (chassisNumber, tok) => {
  const response = await GET(
    `${chassisCheck_Endpoint}?ChasissNumber=${chassisNumber}`,
    true,
    tok
  );
  return response;
};
export const checkChassisNumberValidationMobileForm = async (
  chassisNumber,
  tok
) => {
  const response = await CustomGet(
    `${chassisCheck_Endpoint}?ChasissNumber=${chassisNumber}`,
    tok
  );
  return response;
};
const counterNumberCheck_Endpoint = environment.counterNumberCheck;
export const checkCounterNumberValidation = async (counterNumber, tok) => {
  const response = await GET(
    `${counterNumberCheck_Endpoint}?CounterNumber=${counterNumber}`,
    true,
    tok
  );
  return response;
};
export const checkCounterNumberValidationMobileForm = async (
  counterNumber,
  tok
) => {
  const response = await CustomGet(
    `${counterNumberCheck_Endpoint}?CounterNumber=${counterNumber}`,
    tok
  );
  return response;
};

import styled from "styled-components";

export const Styles = styled.div`
  padding-top: 2rem;
  text-align: justify;
  .main-content {
    padding-top: 1rem;
    padding-bottom: 2rem;
    p {
      font-size: 1.4rem;
    }
  }
  .video-frame {
    margin-bottom: 3rem;
  }
  @media (max-width: 1200px) {
  }
  @media (max-width: 900px) {
  }

  @media (max-width: 768px) {
  }
  @media (max-width: 550px) {
    .main-content {
      padding-top: 0;
    }
  }
`;

import {
  Button,
  Container,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Skeleton,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import classes from "./servicesDetail.module.css";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import serviceConditions from "../../assets/images/servicesDetails/serviceConditions.svg";
import serviceTime from "../../assets/images/servicesDetails/serviceTime.svg";
import servicePrice from "../../assets/images/servicesDetails/servicePrice.svg";
import cer1 from "../../assets/images/servicesDetails/cer1.svg";
import cer2 from "../../assets/images/servicesDetails/cer2.svg";
import requiredAttachments from "../../assets/images/servicesDetails/ServicesDocuments.svg";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { getProcess } from "../../services/homePage/ProcessCategories.services";
import { Link, useParams } from "react-router-dom";
import Loader from "../../components/loader";
import { getServiceVideoLinks } from "../../helpers/getServiceVideoLinks";
import VideoPopUp from "../../components/section2/videoPopup";

const ServicesDetailsPage = () => {
  const [expanded, setExpanded] = useState(false);
  const { id } = useParams();
  const videoLinks = getServiceVideoLinks(id);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [processes, setProcesses] = useState({ Name: "", Data: [] });
  const [isLoadingProcesses, setIsLoadingProcesses] = useState(false);
  const getOtherServiceVideo = (id) => {
    let videoId = id == 26 ? "3QciwzAk2f4" : "7O2bm3iqCqw";
    if (id == 26 || id == 28)
      return (
        <Stack direction={"row"} spacing={5} style={{ marginBottom: "1rem" }}>
          <VideoPopUp id={videoId} />
          <h5
            style={{
              margin: "0 0.5rem",
            }}
          >
            لمشاهدة الفيديو التوضيحي
          </h5>
        </Stack>
      );
  };
  const getProcesses = async (id) => {
    setIsLoadingProcesses(true);
    const response = await getProcess(id);

    setProcesses(response.data);
    setIsLoadingProcesses(false);
  };
  useEffect(() => {
    getProcesses(id);
  }, [id]);

  return isLoadingProcesses ? (
    <Loader />
  ) : (
    <div style={{ background: "#F8F9FA" }}>
      <Container maxWidth="lg" sx={{ padding: "2rem 0" }}>
        <Stack>
          <p className={classes.serviceTitle}>{processes.Name}</p>
          {id != 9 &&
            videoLinks?.map((item, index) => (
              <Stack direction={"row"} spacing={5} sx={{ marginTop: "1rem" }}>
                <VideoPopUp id={item} index={index + 1} />
                <h5
                  style={{
                    margin: "0 0.5rem",
                    // color: "#004496"
                  }}
                >
                  لمشاهدة الفيديو التوضيحي
                </h5>
              </Stack>
            ))}
          <div>
            {processes.Data?.map((item) => (
              <Accordion
                key={item.Id}
                expanded={expanded === item.Id}
                onChange={handleChange(item.Id)}
                sx={{
                  "&.MuiAccordion-root": {
                    borderRadius: "25px",
                    background: "#FFF",
                    margin: "1rem 0",
                    boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.05)",
                  },
                  "&::before": {
                    content: "none", // Set content to 'none' to remove the ::before line
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        borderRadius: "26px",
                        background:
                          expanded === item.Id ? "#59CA70" : "#004496",
                        color: "#fff",
                      }}
                    />
                  }
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <p className={classes.AccordionTitle}>{item.Name}</p>
                </AccordionSummary>
                <AccordionDetails>
                  {getOtherServiceVideo(item?.Id)}
                  {/* here */}
                  <Grid
                    container
                    rowGap={2}
                    columnGap={2}
                    justifyContent={"space-between"}
                    className={classes.detailsContainerFlexBox}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3.7}
                      className={classes.detailsContainer}
                    >
                      <div className={classes.serviceDetailsBox}>
                        <Stack
                          justifyContent={"flex-start"}
                          alignItems={"center"}
                        >
                          <img
                            width={47}
                            height={47}
                            src={serviceConditions}
                            alt="Service Conditions"
                          />
                          <p className={classes.DetailsTitle}>شروط الخدمة</p>
                          <p className={classes.DetailsSubTitle}>
                            {item.Conditions}
                          </p>
                        </Stack>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3.7}
                      className={classes.detailsContainer}
                    >
                      <div className={classes.serviceDetailsBox}>
                        <Stack justifyContent={"center"} alignItems={"center"}>
                          <img
                            width={47}
                            height={47}
                            src={serviceTime}
                            alt="Service Time"
                          />
                          <p className={classes.DetailsTitle}>
                            الوقت المفترض لانتهاء الخدمة
                          </p>
                          <p className={classes.DetailsSubTitle}>
                            {item.Duration === 0
                              ? "حالا"
                              : item.Duration === null
                              ? "غير محدد"
                              : item.Duration === 1
                              ? item.Duration + " " + "يوم"
                              : item.Duration === 2
                              ? "يومان"
                              : item.Duration > 2 && item.Duration <= 10
                              ? item.Duration + " " + "أيام"
                              : item.Duration + " " + "يوم"}
                          </p>
                        </Stack>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3.7}
                      className={classes.detailsContainer}
                    >
                      <div className={classes.serviceDetailsBox}>
                        <Stack justifyContent={"center"} alignItems={"center"}>
                          <img
                            width={47}
                            height={47}
                            src={servicePrice}
                            alt="Service Price"
                          />
                          <p className={classes.DetailsTitle}>رسوم الخدمة</p>
                          <p className={classes.DetailsSubTitle}>
                            {item.Fees?.toLocaleString()} ليرة
                          </p>
                        </Stack>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className={classes.attachmentsContainer}>
                        <Stack
                          columnGap={1}
                          direction={"row"}
                          alignItems={"center"}
                        >
                          <img
                            width={40}
                            height={40}
                            src={requiredAttachments}
                            alt="required Attachments"
                          />
                          <p className={classes.DetailsTitle}>
                            المستندات المطلوبة
                          </p>
                        </Stack>
                        <Grid container>
                          {item.Documents.length !== 0 ? (
                            item.Documents.map((doc, index) => (
                              <Grid item xs={12} sm={6} md={4} key={index}>
                                <div
                                  className={
                                    classes.serviceAttachmentsDetailsBox
                                  }
                                >
                                  <Stack
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                  >
                                    <img
                                      width={47}
                                      height={59}
                                      src={doc.File}
                                      alt={doc.Name}
                                    />
                                    <p
                                      className={`${classes.DetailsSubTitle} w-75 mt-2`}
                                    >
                                      {doc.Name}{" "}
                                    </p>
                                  </Stack>
                                </div>
                              </Grid>
                            ))
                          ) : (
                            <Grid item xs={12}>
                              <div
                                className={classes.serviceAttachmentsDetailsBox}
                              >
                                <p
                                  className={`${classes.DetailsSubTitle}  mt-2`}
                                >
                                  لا يوجد
                                </p>
                              </div>
                            </Grid>
                          )}
                        </Grid>
                      </div>
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                      <Link
                        to={`/dynamic-form?dynamicFormId=${item?.RelatedFormId}&processId=${item.Id}`}
                        // to={"/dynamic-form"}
                        // state={{
                        //   dynamicFormId: item?.RelatedFormId,
                        //   processId: item.Id,
                        // }}
                        style={{ textDecoration: "none" }}
                      >
                        <Button
                          sx={{
                            borderRadius: "12px",
                            background:
                              "linear-gradient(180deg, var(--first-linear-green) 0%, var(--second-linear-green) 100%)",
                            color: "#fff",
                            textAlign: "center",
                            fontFamily: "Cairo",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "normal",
                          }}
                          endIcon={
                            <KeyboardBackspaceIcon
                              sx={{ marginRight: "0.7rem" }}
                            />
                          }
                          variant="contained"
                        >
                          ابدأ الخدمة
                        </Button>
                      </Link>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </Stack>
      </Container>
    </div>
  );
};

export default ServicesDetailsPage;

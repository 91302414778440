export const AddressStepValidation = (
  data,
  setGovernorateError,
  setStreetError,
  setJudiciaryError,
  setBuildingError,
  setTownError,
  setFloorError,
  setPOBoxError,
  setPhoneNumberError
) => {
  const {
    governorate,
    street,
    judiciary,
    building,
    town,
    floor,
    POBox,
    phoneNumber,
  } = data;
  if (governorate === "") {
    setGovernorateError(true);
  } else setGovernorateError(false);

  if (street === "") {
    setStreetError(true);
  } else setStreetError(false);

  if (judiciary === "") {
    setJudiciaryError(true);
  } else setJudiciaryError(false);

  if (building === "") {
    setBuildingError(true);
  } else setBuildingError(false);

  if (town === "") {
    setTownError(true);
  } else setTownError(false);

  if (floor === "") {
    setFloorError(true);
  } else setFloorError(false);

  setPOBoxError(false);

  if (phoneNumber === "" || phoneNumber.length < 7 || phoneNumber.length > 8) {
    setPhoneNumberError(true);
  } else if (phoneNumber.length === 7) {
    setPhoneNumberError(false);
  } else setPhoneNumberError(false);
};

import React from "react";
import { Styles } from "./styles";
import { Col, Row } from "react-bootstrap";
import exhibitionImage from "../../../../assets/images/exhibition.png";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PersonIcon from "@mui/icons-material/Person";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import environment from "../../../../environment";
const ExhibitionCard = ({ data }) => {
  const {
    Attachment,
    FromDate,
    Id,
    Location,
    Name,
    Orgenizer,
    Products,
    Sectors,
    ToDate,
    Type,
  } = data;
  const fromDate = "14/05/2024";
  const toDate = "14/05/2024";
  return (
    <Styles>
      <div className="card-image">
        {Attachment && (
          <img src={`${environment.IMAGE_URL}${Attachment}`} alt={Name} />
        )}
        {/* <img src={exhibitionImage} alt="title" /> */}
        <div className="title">
          <h4>{Name}</h4>
          <span>
            {Sectors?.map((item, index) => (
              <>
                {item?.Name}
                {index != Sectors?.length - 1 && ", "}
              </>
            ))}
          </span>
        </div>
      </div>
      <div className="card-data">
        <Row>
          <Col sm={2} className="icon" id="calender-icon">
            <CalendarMonthIcon
              sx={{
                fontSize: "1.5rem",
                color: "var(--yellow)",
              }}
            />
          </Col>
          <Col sm={10} className="text-content" id="calender-content">
            <span>من {FromDate}</span>
            <span>إلى {ToDate}</span>
          </Col>
        </Row>
        <Row>
          <Col sm={2} className="icon d-flex" id="location">
            <LocationOnIcon
              sx={{
                fontSize: "1.5rem",
                color: "var(--yellow)",
              }}
            />
          </Col>
          <Col sm={10} className="text-content">
            {Location}
          </Col>
        </Row>
        <Row>
          <Col sm={2} className="icon align-items-start" id="person">
            <PersonIcon
              sx={{
                fontSize: "1.5rem",
                color: "var(--yellow)",
              }}
            />
          </Col>
          <Col sm={10} className="text-content justify-content-start">
            {Orgenizer}
          </Col>
        </Row>
      </div>
    </Styles>
  );
};

export default ExhibitionCard;

import styled from "styled-components";
export const Styles = styled.div`
  background-image: ${({ image }) => `url(${image});`};
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 80vh; */
  min-height: 530px;
  background-position: 60% 30%;
  section {
  }
  .stansards-section .title {
    padding-top: 3rem;

    h2 {
      font-size: 2rem;
      font-weight: bold;
      color: #fff;
    }
    h5 {
      font-size: 1.6rem;
      color: #fff;
    }
  }
  .content {
    padding-left: 0rem;
    padding-right: 0rem;
    display: flex;
    justify-content: center;

    .service-card {
      p {
        min-height: 115px;
        font-size: 1.4rem;
        padding: 1.5rem;
        background-color: #fff;
        border-radius: 12px;
        text-align: center;
      }
      margin-bottom: 2rem;
    }
  }

  @media (max-width: 900px) {
    min-height: 500px;
  }
  @media (max-width: 900px) {
    /* height: 65vh; */
    .content {
      .service-card {
        p {
          font-size: 1.2rem;
          padding: 1rem;
        }
      }
    }
  }

  @media (max-width: 768px) {
    /* height: 92vh; */

    .stansards-section .title {
      padding-top: 3rem;
      padding-right: 0rem;

      h2 {
        font-size: 2rem;
        font-weight: bold;
        color: #fff;
      }
      h5 {
        font-size: 1.5rem;
        color: #fff;
      }
    }
    .content {
      .service-card {
        p {
          min-height: 118px;
          font-size: 1.2rem;
          padding: 1rem;
        }
      }
    }
  }
  @media (max-width: 550px) {
    height: auto;
    padding-bottom: 2rem;
    .content {
      .service-card {
        p {
          min-height: 90px;
          font-size: 1.2rem;
          padding: 1rem;
        }
      }
    }
  }
`;

import styled from "styled-components";

export const Styles = styled.div`
  padding-left: 13rem;
  padding-right: 13rem;
  margin-bottom: 4rem;
  .success-message-container {
    /* height:0vh; */
    /* padding-top: 12vh; */
  }
  .custome-stepper-container {
    /* background-color: var(--green); */
    /* padding: 1rem; */
    /* color: white; */
  }
  .inputLabel {
    align-self: flex-start;
    min-width: 130px !important;
    white-space: nowrap !important;
    padding-left: 0.5rem !important;
    /* font-family: Cairo !important; */
    color: #000 !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 188.9% !important; /* 34.002px */
  }
  .form-label {
    color: #000 !important;
    font-size: 18px !important;
    margin-top: 1rem;
    @media (max-width: 768px) {
      margin-top: 0rem;
    }
  }
  .uploader-placeholder {
    font-size: 0.9rem !important;
    /* margin-top: 0.6rem; */
  }
  .date-placeholder {
    font-size: 0.9rem !important;
    margin-top: 0;
    margin-bottom: 0;
    padding-right: 0rem !important;
  }
  .calender-icon {
    z-index: 100;
  }
  .date-custome-component {
    display: flex;

    label {
      width: 100%;
      z-index: 100;
      .MuiSvgIcon-root {
        margin-top: -0.3rem;
      }
    }
    .date-picker {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      height: 0px;
    }
  }
  .loader-text {
    /* margin-top: 0.5rem !important; */
    font-size: 0.5rem !important;
  }
  input[type="time"],
  input[type="date"],
  input[type="tel"] {
    direction: ltr !important;
    text-align: end !important;
  }
  .hidden-step,
  .hidden-section,
  .hidden-field {
    display: none;
  }
  /* .visible-section, */
  .visible-field {
    display: block;
  }
  .suffix-text {
    font-size: 0.8rem;
  }
  h3 {
    color: var(--dark-blue);
  }
  hr {
    color: var(--dark-blue);
  }
  select {
    background-color: var(--field-BG) !important;
    border-radius: 12px;
    border: 0px;
    color: #000 !important;
    height: 45px !important;
    text-align: right !important;
    option {
      text-align: right !important;
    }
  }

  .picklist-loader-dropdown {
    background: var(--field-BG);
    border-right: 0px solid;
    border-radius: 0px 12px 12px 0px;
  }
  .custome-loading-select {
    border-radius: 12px 0 0 12px !important;
  }
  select option:hover {
    /* background-color: red !important; */
  }
  /* select option:checked,
  select option:focus {
    background: var(--med-linear-green) !important;
  } */
  select:focus {
    color: unset !important;
    border-color: unset !important;
    outline: 0;
    box-shadow: 0 0 0 0 !important;
  }
  .custom-select ul li:active,
  .custom-select ul li:hover,
  .custom-select ul li:focus {
    /* background-color: green !important; */
  }
  button {
    margin: 0 1.5rem;
  }
  #draft-button {
    background: transparent !important;
  }
  #draft-button::before {
    content: "";
    position: absolute;
    top: 80%;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--green); /* Replace with your desired color */
    transform: scaleX(0);
    transition: transform 0.3s cubic-bezier(0.2, 0.8, 0.2, 1); /* Add transition property for smooth animation */
  }

  #draft-button:hover::before {
    transform: scaleX(1); /* Expand the line on hover */
  }
  #download-link {
    color: var(--green);
  }
  /* button {
    background-color: var(--dark-blue);
    border: solid 2px var(--dark-blue);
    margin-left: 1rem;
  }
  button:hover {
    background-color: var(--light-blue);
    border: solid 2px var(--dark-blue);
    color: var(--dark-blue);
  } */
  @media (max-width: 1200px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 500px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .text-success {
    --bs-text-opacity: 1;
    color: var(--second-linear-green) !important;
  }
`;

import React, { useEffect, useState } from "react";
import classes from "./option3page.module.css";
import Cards3 from "../option-three-components/cards/Cards3";
import CircleIcon from "@mui/icons-material/Circle";
import { Container, Grid, Stack } from "@mui/material";
import VideoPopUp from "./videoPopup";
import { getComplaintsFunction } from "../../services/homePage/ProcessCategories.services";
import Loader from "../loader";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const Compaints = () => {
  const [LoadingData, setLoadingData] = useState(false);
  const isIPadView = useMediaQuery({ query: "(max-width: 1024px)" });

  const [data, setData] = useState([]);
  const getComplaints = async () => {
    setLoadingData(true);
    const response = await getComplaintsFunction();
    if (response.status === 200) {
      setData(response.data);
      setLoadingData(false);
    } else {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    getComplaints();
  }, []);
  return (
    <div className={`${classes.sec2bg} ${classes.bg}`} id="complaintsSection">
      <Container
        maxWidth="lg"
        sx={{
          margin: "0 auto",
          py: 3,
        }}
      >
        <div className={`title ${classes.titleBox}`}>
          <div className="icons">
            <CircleIcon fontSize="small" style={{ color: "#006838" }} />
            &nbsp;
            <h2>شكاوى</h2>
          </div>
          <h5>
            إبدأ بتحديد القطاع الاقتصادي للشكوى ومن ثم إملأ المعلومات الضرورية
          </h5>
          <Stack direction={"row"} spacing={5}>
            <VideoPopUp id="m-RUO0K_T8Y" />
            <h5 style={{ margin: "0 0.5rem" }}>لمشاهدة الفيديو التوضيحي</h5>
          </Stack>
        </div>
        {LoadingData ? (
          <Loader />
        ) : (
          <>
            <Grid
              container
              sx={{ py: 0 }}
              rowGap={6}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {data.Sectors?.map((item, index) =>
                item.Id === 2712 || item.Id === 2859 ? null : (
                  <Grid item xs={12} sm={6} md={isIPadView ? 4 : 3} key={index}>
                    <Cards3 data={item} />
                  </Grid>
                )
              )}
            </Grid>
            <Grid
              container
              marginTop={"2rem"}
              marginBottom={"1rem"}
              marginRight={"1.2rem"}
              justifyContent={"start"}
              alignItems={"start"}
              md={{
                marginRight: "4rem",
              }}
              sm={{
                justifyContent: "center",
                marginRight: "0rem",
              }}
            >
              <span className={classes.others}>
                إذا لم تجد القطاع المعني، الرجاء
                <Link
                  style={{ textDecoration: "none" }}
                  to="/dynamic-form?dynamicFormId=20&processId=32&Sector=2859&SubSector=2860"
                  key="2860"
                  className={classes.DialogButton}
                >
                   النقر هنا
                </Link>
              </span>
            </Grid>
          </>
        )}
      </Container>
    </div>
  );
};

export default Compaints;

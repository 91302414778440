import React from "react";
import { Styles } from "./styles";
import { Col, FormLabel, Row } from "react-bootstrap";
import { Skeleton, StepButton } from "@mui/material";
import { FED_TransactionDocumentStatus } from "../../../constants/enums";

const RequestInfo = ({
  autoNumber,
  transactionDate,
  exhibitionName,
  openDate,
  loading,
  status,
  statusId,
  rejectionReason,
}) => {
  const dataList = [
    { label: "رقم الطلب", value: autoNumber },
    { label: "تاريخ الطلب", value: transactionDate },
    { label: "اسم المعرض", value: exhibitionName },
    { label: "تاريخ الافتتاح", value: openDate },
    { label: "حالة الطلب", value: status },
    { label: "سبب الرفض", value: rejectionReason },
  ];
  const renderRejectReason = (item) => {
    if (statusId == FED_TransactionDocumentStatus?.Rejected) {
      return (
        <Row>
          <Col xm="12" sm={"4"} md={"4"} lg={"2"}>
            <FormLabel for={"Name"} className="field-title">
              {item?.label}
            </FormLabel>
          </Col>
          <Col xm="12" sm={"8"} md={"8"} lg={"8"}>
            <FormLabel className="field-value">{item?.value}</FormLabel>
          </Col>
        </Row>
      );
    }
  };
  return (
    <Styles>
      <div className="d-flex justify-content-start">
        <h4>معلومات الطلب</h4>
      </div>
      {dataList?.slice(0, 5).map((item, index) => (
        <>
          <Row>
            <Col xm="12" sm={"4"} md={"4"} lg={"2"}>
              <FormLabel for={"Name"} className="field-title">
                {item?.label}
              </FormLabel>
            </Col>
            <Col xm="12" sm={"8"} md={"8"} lg={"8"}>
              {loading ? (
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1.2rem", width: "50%" }}
                />
              ) : (
                <FormLabel className="field-value">{item?.value}</FormLabel>
              )}
            </Col>
          </Row>
          {renderRejectReason(dataList[5])}
        </>
      ))}
    </Styles>
  );
};

export default RequestInfo;

import React from "react";

const TestPayment = () => {
  return (
    <div>
      <h1>test</h1>
    </div>
  );
};

export default TestPayment;

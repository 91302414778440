import React, { useEffect, useState } from "react";

import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { Col, Form, FormGroup, FormLabel } from "react-bootstrap";
import {
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { StyledModal } from "./styles";
import { getPicklists } from "../../../services/picklists.services";
import {
  getSectorsOptions,
  getWomenLetOptions,
} from "../../../services/register/picklists.services";
import {
  errorToaster,
  successToaster,
} from "../../../helpers/toasterConfiguration";
import {
  GetExhibitorDetails,
  addExhibitor,
  updateExhibitor,
} from "../../../services/fed/fedExhibitors.service";

const AddNewExhibitor = (props) => {
  const { onHide, isEdit, transactionId, editRowIndex, setRefreshData } = props;
  // data
  const [data, setData] = useState({});
  const [sectorOptions, setSectorOptions] = useState([]);
  const [womenLetOptions, setWomenLetOptions] = useState([]);
  // ui/ux
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [sectorLoading, setSectorLoading] = useState(true);
  const [womenLetLoading, setWomenLoading] = useState(true);
  const [validateOnChange, setValidateOnChange] = useState(false);
  // values
  const [feminiseCompanyCheckbox, setFeminiseCompanyCheckbox] = useState(false);
  const [selectedSector, setSelectedSector] = useState("");
  const [selectedWomenLet, setSelectedWomenLet] = useState("");

  //   functions
  const handleSectorChange = (event) => {
    form.setFieldValue("Sector", event?.target?.value);
    form.validateForm();
  };
  const handleWomenLetChange = (event) => {
    form.setFieldValue("WomenLet", event?.target?.value);
    form.validateForm();
  };
  //  Form
  const validationSchema = Yup.object({
    // profileImage: Yup.string().required(t("Profile image is required")),
    CompanyName: Yup.string().required("اسم الشركة مطلوب"),
    Sector: Yup.string().required("اسم القطاع مطلوب"),
    Products: Yup.string().required("حقل المنتجات مطلوب"),
    Address: Yup.string().required("حقل العنوان مطلوب"),
    PhoneNumber: Yup.string().required("رقم الهاتف مطلوب"),
    WomenLet: feminiseCompanyCheckbox
      ? Yup.string().required("الحقل مطلوب")
      : Yup.string(),
  });
  // WomenLet: Yup.string(),
  const isFieldsValid = () => {
    return (
      form.values.CompanyName?.length !== 0 &&
      form.values.Sector?.length !== 0 &&
      form.values.Products?.length !== 0 &&
      form.values.Address?.length !== 0 &&
      form.values.PhoneNumber?.length !== 0 &&
      ((feminiseCompanyCheckbox &&
        form.values.WomenLet?.length !== 0 &&
        form.values.WomenLet) ||
        !feminiseCompanyCheckbox)
    );
  };

  const submitForm = async (values) => {
    const pass = isFieldsValid();
    if (pass) {
      setSubmitLoading(true);
      let requestBody = {
        Id: editRowIndex,
        // TransactionId: 4828,
        TransactionId: transactionId,
        CompanyName: form.values.CompanyName,
        Products: form.values.Products,
        SectorId: form.values.Sector,
        Address: form.values.Address,
        PhoneNumber: form.values.PhoneNumber.toString(),
        WomenLedId: feminiseCompanyCheckbox ? form.values.WomenLet : null,
      };
      if (isEdit) {
        const response = await updateExhibitor(requestBody);
        if (response.status === 200 || response.status === 204) {
          successToaster("تم تحديث بيانات العارض بنجاح");
          onHide();
          setRefreshData((prev) => !prev);
        } else errorToaster(response?.data?.Message);
      } else {
        const response = await addExhibitor(requestBody);
        if (response.status === 200 || response.status === 204) {
          // successToaster(response?.Data?.Message);
          successToaster("تمت إضافة العارض بنجاح");
          onHide();
          setRefreshData((prev) => !prev);
        } else errorToaster(response?.data?.Message);
      }
      setSubmitLoading(false);
    } else form.validateForm();
  };
  const form = useFormik({
    isInitialValidP: false,
    validateOnBlur: validateOnChange,
    validateOnChange: validateOnChange,
    onSubmit: (values) => {
      submitForm(values);
    },
    validationSchema,
    initialValues: {
      // profileImage: {},
      CompanyName: "",
      Sector: "",
      Products: "",
      WomenLet: "",
      PhoneNumber: "",
      Address: "",
    },
  });

  // Calling API
  const getData = async () => {
    setLoading(true);
    const response = await GetExhibitorDetails(editRowIndex);
    form.setFieldValue("CompanyName", response?.data?.CompanyName);
    form.setFieldValue("Sector", response?.data?.SectorId);
    form.setFieldValue("Products", response?.data?.Products);
    form.setFieldValue("WomenLet", response?.data?.WomenLedId);
    form.setFieldValue("Address", response?.data?.Address);
    form.setFieldValue(
      "PhoneNumber",
      // parseInt(response?.data?.PhoneNumber, 10)
      response?.data?.PhoneNumber
    );
    if (response?.data?.WomenLedId) {
      setFeminiseCompanyCheckbox(true);
    } else {
      setFeminiseCompanyCheckbox(false);
    }
    setData(response.data);
    setLoading(false);
  };
  const getSectorData = async () => {
    setSectorLoading(true);
    const response = await getSectorsOptions();
    setSectorOptions(response.data);
    setSectorLoading(false);
  };
  const getWomenLetData = async () => {
    setWomenLoading(true);
    const response = await getWomenLetOptions();
    setWomenLetOptions(response.data);
    setWomenLoading(false);
  };
  useEffect(() => {
    getSectorData();
    getWomenLetData();
    setValidateOnChange(false);
    if (isEdit) {
      getData();
    } else {
      form.resetForm();
      setFeminiseCompanyCheckbox(false);
      // form.setFieldValue("CompanyName", "");
      // form.setFieldValue("Sector", "");
      // form.setFieldValue("Products", "");
      // form.setFieldValue("WomenLet", "");
    }
  }, [props.show]);
  return (
    <StyledModal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      direction={"rtl"}
    >
      <StyledModal.Header>
        {isEdit ? <h4>تعديل العارض</h4> : <h4>إضافة العارض</h4>}
      </StyledModal.Header>
      <StyledModal.Body>
        <Formik>
          <Form
            onSubmit={form.handleSubmit}
            className="w-100 row justify-content-start"
          >
            <FormGroup id="input-group" className="row">
              <Col sm="12" md="12" lg="3">
                <FormLabel for={"CompanyName"} required className="">
                  اسم الشركة<span className="text-red">*</span>
                </FormLabel>
              </Col>
              <Col sm="12" md="12" lg="9">
                <TextField
                  name={"CompanyName"}
                  type={"text"}
                  value={loading ? "" : form.values.CompanyName}
                  required={true}
                  onChange={form.handleChange}
                  variant="outlined"
                  sx={{
                    "&.MuiFormControl-root": {
                      width: "100%",
                      "& .MuiFormHelperText-root": {
                        color: "#d1202f",
                        textAlign: "right",
                      },
                    },
                    "& .MuiInputBase-input": {
                      background: "var(--field-BG)",
                      padding: "0.7rem",
                      borderRadius: "12px",
                      outline: "none",
                      border:
                        form.errors.CompanyName &&
                        validateOnChange &&
                        !submitLoading
                          ? "1px solid var(--red)"
                          : "0",
                    },
                    "& .MuiOutlinedInput-root": {
                      border: "none",
                      "&:hover, &:focus": {
                        border: "none",
                      },
                      "&.Mui-focused": {
                        border: "none",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                        outline: "none",
                        borderRadius: "12px",
                      },
                    },
                  }}
                />
                <div className="error-msg">
                  {validateOnChange &&
                    !submitLoading &&
                    form.errors.CompanyName}
                </div>
              </Col>
            </FormGroup>
            <FormGroup id="input-group" className="row">
              <Col sm="12" md="12" lg="3">
                <FormLabel for={"Sector"} required className="">
                  اسم القطاع
                  <span className="text-red">*</span>
                </FormLabel>
              </Col>
              <Col sm="12" md="12" lg="9">
                <Select
                  value={loading ? "" : form.values.Sector}
                  onChange={handleSectorChange}
                  sx={{
                    borderRadius: "12px",
                    "&.MuiInputBase-root": {
                      width: "100%",
                      border:
                        form.errors.Sector && validateOnChange && !submitLoading
                          ? "1px solid var(--red)"
                          : "0",
                    },
                    "& .MuiSelect-select": {
                      background: "rgb(237, 239, 240)",
                      padding: "0.7rem",
                      borderRadius: "12px",
                      // "&:hover": {
                      //   backgroundColor: "#67d57d",
                      // },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                      outline: "none",
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        "& .MuiMenuItem-root": {
                          textWrap: "pretty",
                        },
                        "& .MuiMenuItem-root.Mui-selected": {
                          backgroundColor: "var(--second-linear-green)",
                        },
                        "& .MuiMenuItem-root:hover": {
                          backgroundColor: "var(--first-linear-green)",
                        },
                        "& .MuiMenuItem-root.Mui-selected:hover": {
                          backgroundColor: "var(--med-linear-green)",
                        },
                        // maxHeight: 'unset',
                      },
                    },
                  }}
                >
                  {/* <MenuItem value={null} disabled>
            يرجى الاختيار
          </MenuItem> */}
                  {sectorOptions?.map((item) => (
                    <MenuItem
                      value={item.Id}
                      key={item.Id}
                      onClick={() => {
                        setSelectedSector(item?.Name);
                      }}
                    >
                      {item.Name}
                    </MenuItem>
                  ))}
                </Select>
                <div className="error-msg">
                  {validateOnChange && !submitLoading && form.errors.Sector}
                </div>
              </Col>
            </FormGroup>
            <FormGroup id="input-group" className="row">
              <Col sm="12" md="12" lg="3">
                <FormLabel for={"Products"} required className="">
                  المنتجات<span className="text-red">*</span>
                </FormLabel>
              </Col>
              <Col sm="12" md="12" lg="9">
                <TextField
                  //   id={id}
                  value={loading ? "" : form.values.Products}
                  name={"Products"}
                  type={"text"}
                  //   defaultValue={DefaultValue}
                  required={true}
                  onChange={form.handleChange}
                  variant="outlined"
                  //   helperText={
                  //     Boolean(form.errors.Products) &&
                  //     ValidateOnChange &&
                  //     form.errors.Products
                  //   }
                  sx={{
                    "&.MuiFormControl-root": {
                      width: "100%",
                      "& .MuiFormHelperText-root": {
                        color: "#d1202f",
                        textAlign: "right",
                      },
                    },
                    "& .MuiInputBase-input": {
                      background: "var(--field-BG)",
                      padding: "0.7rem",
                      borderRadius: "12px",
                      outline: "none",
                      border:
                        form.errors.Products &&
                        validateOnChange &&
                        !submitLoading
                          ? "1px solid var(--red)"
                          : "0",
                    },
                    "& .MuiOutlinedInput-root": {
                      border: "none",
                      "&:hover, &:focus": {
                        border: "none",
                      },
                      "&.Mui-focused": {
                        border: "none",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                        outline: "none",
                        borderRadius: "12px",
                      },
                    },
                  }}
                />
                <div className="error-msg">
                  {validateOnChange && !submitLoading && form.errors.Products}
                </div>
              </Col>
            </FormGroup>
            <FormGroup id="input-group" className="row">
              <Col sm="12" md="12" lg="3">
                <FormLabel for={"Address"} required className="">
                  العنوان<span className="text-red">*</span>
                </FormLabel>
              </Col>
              <Col sm="12" md="12" lg="9">
                <TextField
                  //   id={id}
                  value={loading ? "" : form.values.Address}
                  name={"Address"}
                  type={"text"}
                  //   defaultValue={DefaultValue}
                  required={true}
                  onChange={form.handleChange}
                  variant="outlined"
                  sx={{
                    "&.MuiFormControl-root": {
                      width: "100%",
                      "& .MuiFormHelperText-root": {
                        color: "#d1202f",
                        textAlign: "right",
                      },
                    },
                    "& .MuiInputBase-input": {
                      background: "var(--field-BG)",
                      padding: "0.7rem",
                      borderRadius: "12px",
                      outline: "none",
                      border:
                        form.errors.Address &&
                        validateOnChange &&
                        !submitLoading
                          ? "1px solid var(--red)"
                          : "0",
                    },
                    "& .MuiOutlinedInput-root": {
                      border: "none",
                      "&:hover, &:focus": {
                        border: "none",
                      },
                      "&.Mui-focused": {
                        border: "none",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                        outline: "none",
                        borderRadius: "12px",
                      },
                    },
                  }}
                />
                <div className="error-msg">
                  {validateOnChange && !submitLoading && form.errors.Address}
                </div>
              </Col>
            </FormGroup>
            <FormGroup id="input-group" className="row">
              <Col sm="12" md="12" lg="3">
                <FormLabel for={"PhoneNumber"} required className="">
                  رقم الهاتف
                  <span className="text-red">*</span>
                </FormLabel>
              </Col>
              <Col sm="12" md="12" lg="9">
                <TextField
                  id="phoneMaskInput"
                  type={"number"}
                  name={"PhoneNumber"}
                  value={loading ? "" : form.values.PhoneNumber}
                  required={true}
                  onChange={form.handleChange}
                  variant="outlined"
                  InputProps={{
                    endAdornment: "961+",
                  }}
                  sx={{
                    textAlign: "left !important",
                    "&.MuiFormControl-root": {
                      width: "99%",
                      "& .MuiFormHelperText-root": {
                        color: "red",
                        textAlign: "right",
                      },
                    },
                    "& .MuiInputBase-input": {
                      background: "var(--field-BG)",
                      padding: "0.7rem",
                      borderRadius: "12px",

                      outline: "none",
                    },
                    "& .MuiOutlinedInput-root": {
                      border: "none",
                      padding: "0 10px",
                      textAlign: "left",
                      background: "var(--field-BG)",
                      borderRadius: "12px",
                      border:
                        form.errors.PhoneNumber &&
                        validateOnChange &&
                        !submitLoading
                          ? "1px solid var(--red)"
                          : "0",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "0",
                    },
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                    "& input[type=number]": {
                      "-moz-appearance": "textfield", // For Firefox
                    },
                  }}
                />

                <div className="error-msg">
                  {validateOnChange &&
                    !submitLoading &&
                    form.errors.PhoneNumber}
                </div>
              </Col>
            </FormGroup>
            <FormGroup id="input-group" className="row align-items-center">
              <Col sm="12" md="12" lg="12">
                <FormLabel for={"WomenLet"} className="d-contents">
                  شركة قيادتها نسائية
                </FormLabel>
                <FormControlLabel
                  className="customSingleCheckbox d-contents"
                  control={
                    <Checkbox
                      checked={feminiseCompanyCheckbox}
                      onChange={(event) => {
                        setFeminiseCompanyCheckbox(event.target.checked);
                        console.log(event.target.checked);
                      }}
                      sx={{
                        alignItems: "center",
                      }}
                    />
                  }
                />
              </Col>
            </FormGroup>
            {feminiseCompanyCheckbox && (
              <FormGroup id="input-group" className="row">
                <Col sm="12" md="12" lg="3">
                  <FormLabel for={"WomenLet"} required className="">
                    القيادة نسائية
                    {feminiseCompanyCheckbox && (
                      <span className="text-red">*</span>
                    )}
                  </FormLabel>
                </Col>
                <Col sm="12" md="12" lg="9">
                  <Select
                    value={loading ? "" : form.values.WomenLet}
                    onChange={handleWomenLetChange}
                    //   helperText={
                    //     Boolean(form.errors.WomenLet) &&
                    //     ValidateOnChange &&
                    //     form.errors.WomenLet
                    //   }
                    sx={{
                      borderRadius: "12px",
                      "&.MuiInputBase-root": {
                        width: "100%",
                        border:
                          feminiseCompanyCheckbox &&
                          form.errors.WomenLet &&
                          validateOnChange
                            ? "1px solid var(--red)"
                            : "0",
                      },
                      "& .MuiSelect-select": {
                        background: "rgb(237, 239, 240)",
                        padding: "0.7rem",
                        borderRadius: "12px",
                        // "&:hover": {
                        //   backgroundColor: "#67d57d",
                        // },
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                        outline: "none",
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          "& .MuiMenuItem-root.Mui-selected": {
                            backgroundColor: "var(--second-linear-green)",
                          },
                          "& .MuiMenuItem-root:hover": {
                            backgroundColor: "var(--first-linear-green)",
                          },
                          "& .MuiMenuItem-root.Mui-selected:hover": {
                            backgroundColor: "var(--med-linear-green)",
                          },
                        },
                      },
                    }}
                  >
                    {womenLetOptions?.map((item) => (
                      <MenuItem
                        value={item.Id}
                        key={item.Id}
                        onClick={() => setSelectedWomenLet(item?.Name)}
                      >
                        {item.Name}
                      </MenuItem>
                    ))}
                  </Select>
                  <div className="error-msg">
                    {feminiseCompanyCheckbox &&
                      validateOnChange &&
                      !submitLoading &&
                      form.errors.WomenLet}
                  </div>
                </Col>
              </FormGroup>
            )}
          </Form>
        </Formik>
      </StyledModal.Body>
      <StyledModal.Footer>
        <Button
          type="submit"
          sx={{
            borderRadius: "15px",
            background:
              "linear-gradient(180deg, var(--first-linear-green) 0%, var(--second-linear-green) 100%)",
            color: "#FFF",
            textAlign: "center",
            fontFamily: "Cairo",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "normal",

            marginLeft: "1rem",
          }}
          variant="contained"
          // onClick={submitForm}
          onClick={() => {
            setValidateOnChange(true);
            submitForm();
            // form.handleSubmit();
          }}
          disabled={false}
        >
          {loading || submitLoading ? (
            <span className="indicator-progress">
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          ) : isEdit ? (
            "تحديث البيانات"
          ) : (
            "إضافة"
          )}
        </Button>
        <Button
          sx={{
            borderRadius: "15px",
            background: "linear-gradient(120deg, #f5f5f5 0%, #9e9e9e 100%)",
            color: "#FFF",
            textAlign: "center",
            fontFamily: "Cairo",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "normal",

            marginLeft: "1rem",
          }}
          variant="contained"
          onClick={() => {
            props.onHide();
            // setisEdit(false);
          }}
          disabled={false}
        >
          إلغاء
        </Button>
      </StyledModal.Footer>
    </StyledModal>
  );
};

export default AddNewExhibitor;

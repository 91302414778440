import { Button, Stack, Typography } from "@mui/material";
import React from "react";

import classes from "./style.module.css";
import ValidationForm from "../../../elements/validationForm";

const ConfirmationBox = ({
  showError,
  setShowError,
  label,
  labelValue,
  errorMessage,
  inputRefs,
  values,
  setValues,
  resendFunction,
  timeLeft,
  disabled,
}) => {
  return (
    <Stack
      justifyContent={"space-evenly"}
      alignItems={"center"}
      spacing={1}
      sx={{
        width: { xs: "100%", sm: "345px", md: "387px" },
        height: "300px",
        flexShrink: 0,
        borderRadius: "12px",
        background: "linear-gradient(180deg, #F5FAFF 0%, #E2EEFA 100%)",
        border: showError ? "1px solid red" : "none",
        margin: "0 auto",
      }}
    >
      <Stack alignItems={"center"}>
        <Typography variant="p" className={classes.title}>
          {label}
        </Typography>
        <Typography
          variant="p"
          sx={{ direction: "ltr" }}
          className={classes.value}
        >
          {labelValue}
        </Typography>
      </Stack>
      <ValidationForm
        showError={showError}
        setShowError={setShowError}
        inputRefs={inputRefs}
        values={values}
        setValues={setValues}
      />
      <Stack alignItems={"center"}>
        <Typography variant="p" className={classes.title}>
          {errorMessage}
        </Typography>
        <Button
          className={`${classes.title} ${classes.resend} ${
            disabled ? classes.resendBlock : ""
          }`}
          variant="p"
          onClick={resendFunction}
          disabled={disabled}
        >
          {disabled ? (
            <p>يمكنك طلب الرمز مرة اخرة بعد {timeLeft}</p>
          ) : (
            " أرسل مرة اخرى"
          )}
        </Button>
      </Stack>
    </Stack>
  );
};

export default ConfirmationBox;

import styled from "styled-components";

export const BannerStyles = styled.div`
  .page-banner {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .breadcrumbs {
    position: absolute;
    top: 8rem;
    right: 2.5rem;
  }
  .title {
    position: absolute;
    top: 14rem;
    right: 2.5rem;
  }
  @media (max-width: 1200px) {
    padding-top: 0.8rem;
    .page-banner {
      width: 100%;
      img {
        height: 300px;
        object-fit: cover;
      }
    }
    .title {
      position: absolute;
      top: 220px;
      right: 2.5rem;
      padding-right: 0;
    }
  }
  @media (max-width: 768px) {
    padding-top: 6.5rem;
    .page-banner {
      width: 100%;
      img {
        height: 300px;
        object-fit: cover;
      }
    }
    .title {
      position: absolute;
      top: 220px;
      right: 2.5rem;
      padding-right: 0;
    }
  }
`;

import styled from "styled-components";

export const Styles = styled.div`
  .pretty-list-container {
    display: flex;
    flex-wrap: wrap;
    .single-item .form-check-wrapper {
      margin: 0rem;
      margin-top: 1rem;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-height: 300px;
      width: 100%;
      background: linear-gradient(180deg, #f5faff 0%, #e2eefa 100%);
      border: 1px solid var(--green);
      border-radius: 12px;
      cursor: pointer;
      .image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        /* @media (max-width: 1200px) {
          width: 220px;
        }
        @media (max-width: 992px) {
          width: 165px;
        }
        @media (max-width: 768px) {
          width: 190px;
        }
        @media (max-width: 576px) {
          width: 260px;
        } */
        img {
          border-radius: 50px;
          border: 1px solid var(--green);
          width: 100%;
          height: 120px;
          cursor: pointer;
          object-fit: cover;
        }
      }
    }
    .single-item .form-check-wrapper:hover {
      background: linear-gradient(rgb(245, 250, 255) 0%, rgb(184 192 199) 100%);
    }
  }
  @media (max-width: 550px) {
    .pretty-list-container {
      justify-content: center;
      .single-item {
        padding: 0 !important;
        padding-bottom: 0.5rem !important;
      }
    }
  }
`;

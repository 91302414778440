import { useContext, useEffect } from "react";
import UserContext from "../../context/UserContext";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PersonalInformation from "../register/steps/step2";
import {
  getAgesOptions,
  getCitiesOptions,
  getCompanyTypeOptions,
  getCountriesOptions,
  getDistrictsOptions,
  getFeminineCompanyOptions,
  getGovernorateOptions,
  getTTypeOptions,
  getTradeTypeOptions,
} from "../../services/register/picklists.services";
import {
  CompanyAccountValidation,
  personalAccountValidation,
} from "../register/steps/step2/step2Validation";
import useState from "react-usestateref"; // see this line
import AddressStep from "../register/steps/step3";
import { AddressStepValidation } from "../register/steps/step3/adressStepValidation";
import Step4CheckBox from "../register/steps/step4";
import ApplicantInformation from "../register/steps/step5";
import { ApplicantInformationValidation } from "../register/steps/step5/applicantValidation";
import StepButton from "../register/components/stepsButton";
import { Grid, Stack } from "@mui/material";
import AttachmentStep from "../register/steps/step6";
import {
  CompanyAttachmentsValidation,
  PersonalAttachmentsValidation,
} from "../register/steps/step6/attachmentValidation";
import CustomPasswordInput from "../../components/elements/password";
import { ResetPasswordValidation } from "../register/steps/step7/validation";
import axios from "axios";
import { ResetPassword, USERPROFILE } from "../../constants/endpoints";
import { getAccessTokenCookie } from "../../utils/cookie";
import {
  errorToaster,
  successToaster,
} from "../../helpers/toasterConfiguration";
import Loader from "../../components/loader";
import { RefreshTokenFunction } from "../../services/refreshToken.services";
import useStateRef from "react-usestateref";
import environment from "../../environment";
import encryptMessage from "../../utils/encryptMessage";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ProfileSettingsPage() {
  const selectedTab = {
    fontSize: "1rem",
    fontWeight: 600,
    "&.Mui-selected": {
      background:
        "linear-gradient(180deg, var(--first-linear-green) 0%, var(--second-linear-green) 100%)",
      color: "#fff",
    },
    "&:hover": {
      background:
        "linear-gradient(180deg, var(--first-linear-green) 0%, var(--second-linear-green) 100%)",
      color: "#fff",
    },
  };
  const { userProfile } = useContext(UserContext);

  const [fetching, setFetching] = useState(false);
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  /* person account info */
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError, usernameErrorRef] = useState(false);
  const [middleName, setMiddleName] = useState("");
  const [middleNameError, setMiddleNameError, middleNameErrorRef] =
    useState(false);
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError, lastNameErrorRef] = useState(false);
  const [cardId, setCardId] = useState("");
  const [cardIdError, setCardIdError, cardIdErrorRef] = useState(false);
  const [nationality, setNationality] = useState("");
  const [nationalityError, setNationalityError, nationalityErrorRef] =
    useState(false);
  const [age, setAge] = useState("");
  const [ageError, setAgeError, ageErrorRef] = useState(false);
  const [specialNeed, setSpecialNeed] = useState(false);
  const [selectedSex, setSelectedSex] = useState("M");
  const [selectedSexError, setSelectedSexError, selectedSexErrorRef] =
    useState(false);

  /* company account info */
  const [legalName, setLegalName] = useState("");
  const [legalNameError, setLegalNameError, legalNameErrorRef] =
    useState(false);
  const [companyAccountType, setCompanyAccountType] = useState("");
  const [
    companyAccountTypeError,
    setCompanyAccountTypeError,
    companyAccountTypeErrorRef,
  ] = useState(false);
  const [companyType, setCompanyType] = useState("");
  const [companyTypeError, setCompanyTypeError, companyTypeErrorRef] =
    useState(false);
  const [businessType, setBusinessType] = useState("");
  const [businessTypeError, setBusinessTypeError, businessTypeErrorRef] =
    useState(false);
  const [businessName, setBusinessName] = useState("");
  const [businessNameError, setBusinessNameError, businessNameErrorRef] =
    useState(false);
  const [financialNumber, setFinancialNumber] = useState("");
  const [
    financialNumberError,
    setFinancialNumberError,
    financialNumberErrorRef,
  ] = useState(false);
  const [feminiseCompanyCheckbox, setFeminiseCompanyCheckbox] = useState(false);
  const [feminiseCompanySelect, setFeminiseCompanySelect] = useState("");
  const [
    feminiseCompanySelectError,
    setFeminiseCompanySelectError,
    feminiseCompanySelectErrorRef,
  ] = useStateRef("");
  const [authorizedSignatoryName, setAuthorizedSignatoryName] = useState("");
  const [
    authorizedSignatoryNameError,
    setAuthorizedSignatoryNameError,
    authorizedSignatoryNameErrorRef,
  ] = useState(false);
  const [personType, setPersonType] = useState("");
  const [personTypeError, setPersonTypeError, personTypeErrorRef] =
    useState(false);
  const [authorizedSignatoryPhoneNumber, setAuthorizedSignatoryPhoneNumber] =
    useState("");
  const [
    authorizedSignatoryPhoneNumberError,
    setAuthorizedSignatoryPhoneNumberError,
    authorizedSignatoryPhoneNumberErrorRef,
  ] = useState(false);
  const [authorizedSignatoryNationality, setAuthorizedSignatoryNationality] =
    useState("");
  const [
    authorizedSignatoryNationalityError,
    setAuthorizedSignatoryNationalityError,
    authorizedSignatoryNationalityErrorRef,
  ] = useState(false);

  /*  ADDRESS INFORMATION */
  const [governorate, setGovernorate] = useState();
  const [governorateError, setGovernorateError, governorateErrorRef] =
    useState(false);

  const [street, setStreet] = useState("");
  const [streetError, setStreetError, streetErrorRef] = useState(false);

  const [judiciary, setJudiciary] = useState();
  const [judiciaryError, setJudiciaryError, judiciaryErrorRef] =
    useState(false);

  const [building, setBuilding] = useState("");
  const [buildingError, setBuildingError, buildingErrorRef] = useState(false);

  const [town, setTown] = useState();
  const [townError, setTownError, townErrorRef] = useState(false);

  const [floor, setFloor] = useState("");
  const [floorError, setFloorError, floorErrorRef] = useState(false);
  const [POBox, setPOBox] = useState("");
  const [POBoxError, setPOBoxError, POBoxErrorRef] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError, phoneNumberErrorRef] =
    useState(false);

  const [countriesOptions, setCountriesOptions] = useState([]);
  const [governorateOptions, setGovernorateOptions] = useState([]);
  const [districtsOptions, setDistrictsOptions] = useState([]);
  const [districtsOptionsLoading, setDistrictsOptionsLoading] = useState(false);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [citiesOptionsLoading, setCitiesOptionsLoading] = useState(false);

  const [AgeOptions, setAgeOptions] = useState([]);
  const [companyTypeOptions, setCompanyTypeOptions] = useState([]);
  const [tTypeOptions, setTTypeOptions] = useState([]);
  const [tradeTypeOptions, setTradeTypeOptions] = useState([]);
  const [feminineCompanyOptions, setFeminineCompanyOptions] = useState([]);
  /* Step 4 checkBox */
  const [step4check, setStep4check] = useState(false);
  /* Step 5 */

  const [applicantName, setApplicantName] = useState("");
  const [applicantNameError, setApplicantNameError, applicantNameErrorRef] =
    useState(false);

  const [applicantMiddleName, setApplicantMiddleName] = useState("");
  const [
    applicantMiddleNameError,
    setApplicantMiddleNameError,
    applicantMiddleNameErrorRef,
  ] = useState(false);

  const [applicantLastName, setApplicantLastName] = useState("");
  const [
    applicantLastNameError,
    setApplicantLastNameError,
    applicantLastNameErrorRef,
  ] = useState(false);

  const [applicantCardId, setApplicantCardId] = useState("");
  const [
    applicantCardIdError,
    setApplicantCardIdError,
    applicantCardIdErrorRef,
  ] = useState(false);

  /* Attachment Step */
  /* person account attachments */

  /* المفوض بالتوقيع / صاحب العلاقة */
  const [personConcernedIdentity, setPersonConcernedIdentity] = useState(null);
  const [personConcernedIdentityName, setPersonConcernedIdentityName] =
    useState(null);
  const [
    personConcernedIdentityError,
    setPersonConcernedIdentityError,
    personConcernedIdentityErrorRef,
  ] = useState(false);

  /* مقدم الطلب */
  const [applicantIdentity, setApplicantIdentity] = useState(null);
  const [applicantIdentityName, setApplicantIdentityName] = useState(null);
  const [
    applicantIdentityError,
    setApplicantIdentityError,
    applicantIdentityErrorRef,
  ] = useState(false);

  /* وكالة التفويض */
  const [authorizationPhoto, setAuthorizationPhoto] = useState(null);
  const [authorizationPhotoName, setAuthorizationPhotoName] = useState(null);

  const [
    authorizationPhotoError,
    setAuthorizationPhotoError,
    authorizationPhotoErrorRef,
  ] = useState(false);

  /* company account attachments */
  const [commercialPhoto, setCommercialPhoto] = useState(null);
  const [commercialPhotoName, setCommercialPhotoName] = useState(null);
  const [
    commercialPhotoError,
    setCommercialPhotoError,
    commercialPhotoErrorRef,
  ] = useState(false);

  const [commercialRegisterPhoto, setCommercialRegisterPhoto] = useState(null);
  const [commercialRegisterPhotoName, setCommercialRegisterPhotoName] =
    useState(null);

  const [
    commercialRegisterPhotoError,
    setCommercialRegisterPhotoError,
    commercialRegisterPhotoErrorRef,
  ] = useState(false);

  // هويات الورثة
  const [IdentitiesOfHeirs, setIdentitiesOfHeirs] = useState(null);
  const [IdentitiesOfHeirsName, setIdentitiesOfHeirsName] = useState(null);
  const [
    IdentitiesOfHeirsError,
    setIdentitiesOfHeirsError,
    IdentitiesOfHeirsErrorRef,
  ] = useState(false);

  // حصر إرث
  const [InventoryOfInheritance, setInventoryOfInheritance] = useState(null);
  const [InventoryOfInheritanceName, setInventoryOfInheritanceName] =
    useState(null);
  const [
    InventoryOfInheritanceError,
    setInventoryOfInheritanceError,
    InventoryOfInheritanceErrorRef,
  ] = useState(false);
  // حكم قضائي
  const [JudicialRulings, setJudicialRulings] = useState(null);
  const [JudicialRulingsName, setJudicialRulingsName] = useState(null);
  const [
    JudicialRulingsError,
    setJudicialRulingsError,
    JudicialRulingsErrorRef,
  ] = useState(false);
  // صورة الهوية الخلفية
  const [OwnerIdentityBack, setOwnerIdentityBack] = useState(null);
  const [OwnerIdentityBackName, setOwnerIdentityBackName] = useState(null);
  const [
    OwnerIdentityBackError,
    setOwnerIdentityBackError,
    OwnerIdentityBackErrorRef,
  ] = useState(false);
  // صورة عن وكالة المفوض بالتوقيع
  const [AuthorizedSignatoryIdentity, setAuthorizedSignatoryIdentity] =
    useState(null);
  const [AuthorizedSignatoryIdentityName, setAuthorizedSignatoryIdentityName] =
    useState(null);
  const [
    AuthorizedSignatoryIdentityError,
    setAuthorizedSignatoryIdentityError,
    AuthorizedSignatoryIdentityErrorRef,
  ] = useState(false);

  const getCompanyType = async () => {
    const response = await getCompanyTypeOptions();
    if (response.status === 200) {
      setCompanyTypeOptions(response.data);
    }
  };
  const getTType = async () => {
    const response = await getTTypeOptions();
    if (response.status === 200) {
      setTTypeOptions(response.data);
    }
  };
  const getTradeType = async () => {
    const response = await getTradeTypeOptions();
    if (response.status === 200) {
      setTradeTypeOptions(response.data);
    }
  };
  const getFeminineCompany = async () => {
    const response = await getFeminineCompanyOptions();
    if (response.status === 200) {
      setFeminineCompanyOptions(response.data);
    }
  };
  const getCountries = async () => {
    const response = await getCountriesOptions();
    if (response.status === 200) {
      setCountriesOptions(response.data);
    }
  };

  const getAges = async () => {
    const response = await getAgesOptions();
    if (response.status === 200) {
      setAgeOptions(response.data);
    }
  };

  const getGovernorate = async () => {
    const response = await getGovernorateOptions();
    if (response.status === 200) {
      setGovernorateOptions(response.data);
    }
  };

  const getDistricts = async (id) => {
    setDistrictsOptionsLoading(true);
    setCitiesOptionsLoading(true);
    const response = await getDistrictsOptions(id);
    if (response.status === 200) {
      setDistrictsOptions(response.data);
      setDistrictsOptionsLoading(false);
      setCitiesOptionsLoading(false);
    }
  };

  const getCities = async (id) => {
    setCitiesOptionsLoading(true);
    const response = await getCitiesOptions(id);
    if (response.status === 200) {
      setCitiesOptions(response.data);
      setCitiesOptionsLoading(false);
    }
  };
  //
  useEffect(() => {
    if (governorate !== userProfile.GovernorateId) {
      setJudiciary("");
      setTown("");
    }
  }, [governorate]);

  useEffect(() => {
    setFetching(true);

    getCountries();
    getGovernorate();
    getAges();
    getTType();
    getTradeType();
    getCompanyType();
    getFeminineCompany();
    setFetching(false);
  }, []);
  useEffect(() => {
    setFetching(true);

    getDistricts(userProfile.GovernorateId);

    userProfile.TypeId === 1 ? setChecked1(true) : setChecked2(true);
    setUsername(userProfile.FirstName);
    setMiddleName(userProfile.MiddleName);
    setLastName(userProfile.LastName);
    setCardId(userProfile.IdentityNo);
    setNationality(userProfile.NationalityId);
    setAge(userProfile.Age);
    setSpecialNeed(userProfile.IsHandicapped);
    setSelectedSex(userProfile.Gender);
    setGovernorate(userProfile.GovernorateId);

    setLegalName(userProfile.LegalName);
    setCompanyAccountType(userProfile.TypeId);
    setCompanyType(userProfile.CompanyTypeId);
    setBusinessType(userProfile.TradeTypeId);
    setBusinessName(userProfile.TradeName);
    setFinancialNumber(userProfile.FinancialFigure);
    setFeminiseCompanyCheckbox(userProfile.IsFeminineCompany);
    setFeminiseCompanySelect(userProfile.FeminineCompanyTypeId);
    setAuthorizedSignatoryName(userProfile.FirstName);
    setAuthorizedSignatoryPhoneNumber(
      userProfile.PhoneNo ? userProfile.PhoneNo.slice(4) : ""
    );
    setPersonType(userProfile.Title);
    setAuthorizedSignatoryNationality(userProfile.NationalityId);
    setStreet(userProfile.Street);
    setJudiciary(userProfile.DistrictId);
    setBuilding(userProfile.Building);
    setTown(userProfile.CityId);
    setFloor(userProfile.Floor);
    setPOBox(userProfile.PostalCode);
    setPhoneNumber(
      userProfile.AddressMobileNo ? userProfile.AddressMobileNo.slice(4) : ""
    );
    setStep4check(userProfile.IsDifferentApplicant);
    setApplicantName(userProfile.ApplicantFirstName);
    setApplicantMiddleName(userProfile.ApplicantMiddleName);
    setApplicantLastName(userProfile.ApplicantLastName);
    setApplicantCardId(userProfile.ApplicantIdentityNo);
    setPersonConcernedIdentity(userProfile.OwnerIdentity);
    setApplicantIdentity(userProfile.ApplicantIdentity);
    setAuthorizationPhoto(userProfile.Authorization);
    setCommercialPhoto(userProfile.CommercialAnnouncement);
    setCommercialRegisterPhoto(userProfile.CommercialRegister);

    // **************
    setIdentitiesOfHeirs(userProfile.IdentitiesOfHeirs);
    setInventoryOfInheritance(userProfile.InventoryOfInheritance);
    setJudicialRulings(userProfile.JudicialRulings);
    // **************
    setOwnerIdentityBack(userProfile.OwnerIdentityBack);
    setAuthorizedSignatoryIdentity(userProfile.AuthorizedSignatoryIdentity);

    setFetching(false);
  }, [userProfile]);

  useEffect(() => {
    governorate !== "" && getDistricts(governorate);
  }, [governorate]);

  useEffect(() => {
    judiciary !== "" && getCities(judiciary);
  }, [judiciary]);

  const [PersonalInformationLoading, setPersonalInformationLoading] =
    useState(false);
  const handleSavePersonalInformation = (infos) => {
    if (checked1) {
      personalAccountValidation(
        infos.personAccount,
        setUsernameError,
        setMiddleNameError,
        setLastNameError,
        setCardIdError,
        setNationalityError,
        setAgeError,
        setSelectedSexError
      );

      if (
        usernameErrorRef.current ||
        middleNameErrorRef.current ||
        lastNameErrorRef.current ||
        cardIdErrorRef.current ||
        nationalityErrorRef.current ||
        ageErrorRef.current ||
        selectedSexErrorRef.current
      ) {
        return;
      } else handleSaveData();
    } else {
      CompanyAccountValidation(
        infos.companyAccount,
        setLegalNameError,
        setCompanyAccountTypeError,
        setCompanyTypeError,
        setBusinessTypeError,
        setBusinessNameError,
        setFinancialNumberError,
        setAuthorizedSignatoryNameError,
        setPersonTypeError,
        setAuthorizedSignatoryPhoneNumberError,
        setAuthorizedSignatoryNationalityError,
        setFeminiseCompanySelectError
      );
      if (
        legalNameErrorRef.current ||
        companyAccountTypeErrorRef.current ||
        companyTypeErrorRef.current ||
        businessTypeErrorRef.current ||
        businessNameErrorRef.current ||
        financialNumberErrorRef.current ||
        authorizedSignatoryNameErrorRef.current ||
        personTypeErrorRef.current ||
        authorizedSignatoryPhoneNumberErrorRef.current ||
        authorizedSignatoryNationalityErrorRef.current ||
        feminiseCompanySelectError.current
      ) {
        return;
      } else handleSaveData();
    }
  };

  const handleSaveAddress = (infos) => {
    AddressStepValidation(
      infos,
      setGovernorateError,
      setStreetError,
      setJudiciaryError,
      setBuildingError,
      setTownError,
      setFloorError,
      setPOBoxError,
      setPhoneNumberError
    );

    if (
      governorateErrorRef.current ||
      streetErrorRef.current ||
      judiciaryErrorRef.current ||
      buildingErrorRef.current ||
      townErrorRef.current ||
      floorErrorRef.current ||
      POBoxErrorRef.current ||
      phoneNumberErrorRef.current
    ) {
      return;
    } else handleSaveData();
  };

  const handleSaveStep5 = (infos) => {
    if (step4check) {
      ApplicantInformationValidation(
        infos,
        setApplicantNameError,
        setApplicantMiddleNameError,
        setApplicantLastNameError,
        setApplicantCardIdError
      );
      if (
        applicantNameErrorRef.current ||
        applicantMiddleNameErrorRef.current ||
        applicantLastNameErrorRef.current ||
        applicantCardIdErrorRef.current
      ) {
        return;
      } else handleSaveData();
    } else handleSaveData();
  };

  const handleAttachmentStep = (infos) => {
    if (checked1) {
      if (step4check) {
        PersonalAttachmentsValidation(
          infos.personAccount,
          setPersonConcernedIdentityError,
          setApplicantIdentityError,
          setAuthorizationPhotoError
        );
      } else {
        PersonalAttachmentsValidation(
          infos.personAccount,
          setPersonConcernedIdentityError,
          () => {},
          () => {}
        );
      }

      if (
        personConcernedIdentityErrorRef.current ||
        applicantIdentityErrorRef.current ||
        authorizationPhotoErrorRef.current
      ) {
        return;
      } else handleSaveData();
    } else {
      if (step4check) {
        CompanyAttachmentsValidation(
          infos.companyAccount,
          setPersonConcernedIdentityError,
          setApplicantIdentityError,
          setAuthorizationPhotoError,
          setCommercialPhotoError,
          setCommercialRegisterPhotoError
        );
      } else {
        CompanyAttachmentsValidation(
          infos.companyAccount,
          setPersonConcernedIdentityError,
          () => {},
          () => {},
          setCommercialPhotoError,
          setCommercialRegisterPhotoError
        );
      }
      if (
        personConcernedIdentityErrorRef.current ||
        applicantIdentityErrorRef.current ||
        authorizationPhotoErrorRef.current ||
        commercialPhotoErrorRef.current ||
        commercialRegisterPhotoErrorRef.current
      ) {
        return;
      } else handleSaveData();
    }
  };

  const [currentPassword, setCurrentPassword] = useState("");
  const [
    currentPasswordError,
    setCurrentPasswordError,
    currentPasswordErrorRef,
  ] = useState(false);

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError, passwordErrorRef] = useState(false);

  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [
    passwordConfirmationError,
    setPasswordConfirmationError,
    passwordConfirmationErrorRef,
  ] = useState(false);

  const handleSavePassword = (infos) => {
    ResetPasswordValidation(
      infos,
      setCurrentPasswordError,
      setPasswordError,
      setPasswordConfirmationError
    );

    if (
      currentPasswordErrorRef.current ||
      passwordErrorRef.current ||
      passwordConfirmationErrorRef.current
    ) {
      return;
    } else {
      document.body.style.cursor = "wait";
      const userToken = getAccessTokenCookie();
      setPersonalInformationLoading(true);

      axios
        .put(
          ResetPassword,
          {
            CurrentPassword: encryptMessage(
              currentPassword,
              environment.PUBKEY
            ),
            NewPassword: encryptMessage(password, environment.PUBKEY),
            PasswordConfirmation: encryptMessage(
              passwordConfirmation,
              environment.PUBKEY
            ),
          },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              "X-Version": "2",
            },
          }
        )
        .then((response) => {
          if (response.status === 401) {
            RefreshTokenFunction();
          }
          successToaster("تم تغيير كلمة المرور بنجاح");
          setPassword("");
          setCurrentPassword("");
          setPasswordConfirmation("");
        })
        .catch((error) => {
          errorToaster(error.response.data);
        })
        .finally(() => {
          document.body.style.cursor = "default";
          setPersonalInformationLoading(false);
        });
    }
  };

  const handleSaveData = () => {
    setPersonalInformationLoading(true);
    const data = {
      LastName: lastName,
      FirstName: checked1 ? username : authorizedSignatoryName,
      MiddleName: middleName,
      IdentityNo: cardId,
      NationalityId: checked1 ? nationality : authorizedSignatoryNationality,
      Age: age,
      Gender: selectedSex,
      IsHandicapped: specialNeed,
      GovernorateId: governorate,
      DistrictId: judiciary,
      CityId: town,
      PostalCode: POBox,
      Street: street,
      Building: building,
      Floor: floor,
      PhoneNo: "+961" + authorizedSignatoryPhoneNumber.split(" ").join(""),
      IsDifferentApplicant: step4check,
      ApplicantLastName: applicantLastName,
      ApplicantFirstName: applicantName,
      ApplicantMiddleName: applicantMiddleName,
      ApplicantIdentityNo: applicantCardId,
      AddressMobileNo: "+961" + phoneNumber,
      LegalName: legalName,
      TypeId: companyAccountType === "" ? null : companyAccountType,
      CompanyTypeId: companyType === "" ? null : companyType,
      TradeTypeId: checked1 ? null : businessType,
      TradeName: checked1 ? null : businessName,
      FinancialFigure: checked1 ? null : financialNumber,
      IsFeminineCompany: feminiseCompanyCheckbox,
      FeminineCompanyTypeId: feminiseCompanySelect,
      OwnerIdentity:
        personConcernedIdentity === userProfile.OwnerIdentity
          ? ""
          : personConcernedIdentity,
      OwnerIdentityName:
        personConcernedIdentity === userProfile.OwnerIdentity
          ? ""
          : personConcernedIdentityName,

      ApplicantIdentity:
        applicantIdentity === userProfile.ApplicantIdentity
          ? ""
          : applicantIdentity,
      ApplicantIdentityName:
        applicantIdentity === userProfile.ApplicantIdentity
          ? ""
          : applicantIdentityName,

      Authorization:
        authorizationPhoto === userProfile.Authorization
          ? ""
          : authorizationPhoto,
      AuthorizationName:
        authorizationPhoto === userProfile.Authorization
          ? ""
          : authorizationPhotoName,
      CommercialAnnouncement:
        commercialPhoto === userProfile.CommercialAnnouncement
          ? ""
          : commercialPhoto,
      CommercialAnnouncementName:
        commercialPhoto === userProfile.CommercialAnnouncement
          ? ""
          : commercialPhotoName,

      CommercialRegister:
        commercialRegisterPhoto === userProfile.CommercialRegister
          ? ""
          : commercialRegisterPhoto,
      CommercialRegisterName: commercialRegisterPhotoName,
      // هويات الورثة
      IdentitiesOfHeirs:
        IdentitiesOfHeirs === userProfile.IdentitiesOfHeirs
          ? ""
          : IdentitiesOfHeirs,
      IdentitiesOfHeirsName: IdentitiesOfHeirsName,
      // حصر إرث
      InventoryOfInheritance:
        InventoryOfInheritance === userProfile.InventoryOfInheritance
          ? ""
          : InventoryOfInheritance,
      InventoryOfInheritanceName: InventoryOfInheritanceName,
      // حكم قضائي
      JudicialRulings:
        JudicialRulings === userProfile.JudicialRulings ? "" : JudicialRulings,
      JudicialRulingsName: JudicialRulingsName,
      // صورة الهوية الخلفية
      OwnerIdentityBack:
        OwnerIdentityBack === userProfile.OwnerIdentityBack
          ? ""
          : OwnerIdentityBack,
      OwnerIdentityBackName: OwnerIdentityBackName,
      //
      AuthorizedSignatoryIdentity:
        AuthorizedSignatoryIdentity === userProfile.AuthorizedSignatoryIdentity
          ? ""
          : AuthorizedSignatoryIdentity,
      AuthorizedSignatoryIdentityName: AuthorizedSignatoryIdentityName,
      //
      Title: personType,
    };
    const userToken = getAccessTokenCookie();
    axios
      .put(USERPROFILE, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "X-Version": "2",
        },
      })
      .then((response) => {
        if (response.status === 401) {
          RefreshTokenFunction();
        }
        successToaster("تم تعديل المعلومات بنجاح");
        window.location.reload();
      })
      .catch((error) => {
        errorToaster(error /* .response.data */);
      })
      .finally(() => {
        setPersonalInformationLoading(false);
      });
  };
  return userProfile.length === 0 || fetching ? (
    <Loader />
  ) : (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          scrollButtons="auto"
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: "transparent",
            },
            "& .MuiTabs-scroller ": {
              overflow: "auto !important",
            },
            "& ::-webkit-scrollbar-thumb": {
              // background: "var(--green)",
              background: "var(--second-linear-green)",
              borderRadius: "10px",
            },
            marginTop: { xs: "1rem", md: "0rem" },
            overflow: "auto",
          }}
        >
          <Tab sx={selectedTab} label="المعلومات الشخصية" {...a11yProps(0)} />
          <Tab sx={selectedTab} label="العنوان" {...a11yProps(1)} />
          {/* <Tab sx={selectedTab} label="مقدم الطلب" {...a11yProps(2)} /> */}
          <Tab sx={selectedTab} label="المستندات المطلوبة" {...a11yProps(2)} />
          <Tab sx={selectedTab} label="تغيير كلمة المرور" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <PersonalInformation
          PersonalInformationLoading={PersonalInformationLoading}
          feminineCompanyOptions={feminineCompanyOptions}
          tTypeOptions={tTypeOptions}
          tradeTypeOptions={tradeTypeOptions}
          companyTypeOptions={companyTypeOptions}
          handleBack={null}
          username={username}
          setUsername={setUsername}
          usernameError={usernameError}
          middleName={middleName}
          setMiddleName={setMiddleName}
          middleNameError={middleNameError}
          lastName={lastName}
          setLastName={setLastName}
          lastNameError={lastNameError}
          cardId={cardId}
          setCardId={setCardId}
          cardIdError={cardIdError}
          nationality={nationality}
          setNationality={setNationality}
          nationalityError={nationalityError}
          age={age}
          setAge={setAge}
          ageError={ageError}
          selectedSex={selectedSex}
          setSelectedSex={setSelectedSex}
          selectedSexError={selectedSexError}
          specialNeed={specialNeed}
          setSpecialNeed={setSpecialNeed}
          checked1={checked1}
          checked2={checked2}
          legalName={legalName}
          setLegalName={setLegalName}
          legalNameError={legalNameError}
          companyAccountType={companyAccountType}
          setCompanyAccountType={setCompanyAccountType}
          companyAccountTypeError={companyAccountTypeError}
          companyType={companyType}
          setCompanyType={setCompanyType}
          companyTypeError={companyTypeError}
          businessType={businessType}
          setBusinessType={setBusinessType}
          businessTypeError={businessTypeError}
          businessName={businessName}
          setBusinessName={setBusinessName}
          businessNameError={businessNameError}
          financialNumber={financialNumber}
          setFinancialNumber={setFinancialNumber}
          financialNumberError={financialNumberError}
          feminiseCompanyCheckbox={feminiseCompanyCheckbox}
          setFeminiseCompanyCheckbox={setFeminiseCompanyCheckbox}
          feminiseCompanySelect={feminiseCompanySelect}
          feminiseCompanySelectError={feminiseCompanySelectError}
          setFeminiseCompanySelect={setFeminiseCompanySelect}
          authorizedSignatoryName={authorizedSignatoryName}
          setAuthorizedSignatoryName={setAuthorizedSignatoryName}
          authorizedSignatoryNameError={authorizedSignatoryNameError}
          authorizedSignatoryPhoneNumber={authorizedSignatoryPhoneNumber}
          setAuthorizedSignatoryPhoneNumber={setAuthorizedSignatoryPhoneNumber}
          authorizedSignatoryPhoneNumberError={
            authorizedSignatoryPhoneNumberError
          }
          setAuthorizedSignatoryPhoneNumberError={
            setAuthorizedSignatoryPhoneNumberError
          }
          personType={personType}
          setPersonType={setPersonType}
          personTypeError={personTypeError}
          authorizedSignatoryNationality={authorizedSignatoryNationality}
          setAuthorizedSignatoryNationality={setAuthorizedSignatoryNationality}
          authorizedSignatoryNationalityError={
            authorizedSignatoryNationalityError
          }
          AgeOptions={AgeOptions}
          handleNext={handleSavePersonalInformation}
          nationalityOptions={countriesOptions}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <AddressStep
          PersonalInformationLoading={PersonalInformationLoading}
          citiesOptionsLoading={citiesOptionsLoading}
          districtsOptionsLoading={districtsOptionsLoading}
          governorateOptions={governorateOptions}
          districtsOptions={districtsOptions}
          citiesOptions={citiesOptions}
          handleStepThree={handleSaveAddress}
          handleBack={null}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          phoneNumberError={phoneNumberError}
          setPhoneNumberError={setPhoneNumberError}
          governorate={governorate}
          setGovernorate={setGovernorate}
          governorateError={governorateError}
          street={street}
          setStreet={setStreet}
          streetError={streetError}
          judiciary={judiciary}
          setJudiciary={setJudiciary}
          judiciaryError={judiciaryError}
          building={building}
          setBuilding={setBuilding}
          buildingError={buildingError}
          town={town}
          setTown={setTown}
          townError={townError}
          floor={floor}
          setFloor={setFloor}
          floorError={floorError}
          POBox={POBox}
          setPOBox={setPOBox}
          POBoxError={POBoxError}
        />
      </CustomTabPanel>
      {/* <CustomTabPanel value={value} index={2}>
        <Step4CheckBox
          handleBack={null}
          handleNext={null}
          step4check={step4check}
          setStep4check={setStep4check}
        />

        {step4check ? (
          <ApplicantInformation
            handleBack={null}
            handleStepFive={null}
            applicantName={applicantName}
            setApplicantName={setApplicantName}
            applicantNameError={applicantNameError}
            applicantMiddleName={applicantMiddleName}
            setApplicantMiddleName={setApplicantMiddleName}
            applicantMiddleNameError={applicantMiddleNameError}
            applicantLastName={applicantLastName}
            setApplicantLastName={setApplicantLastName}
            applicantLastNameError={applicantLastNameError}
            applicantCardId={applicantCardId}
            setApplicantCardId={setApplicantCardId}
            applicantCardIdError={applicantCardIdError}
          />
        ) : null}

        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ margin: "2rem 0 0 0" }}
        >
          <StepButton
            disabled={PersonalInformationLoading}
            type="forward"
            text={"حفظ"}
            func={() => {
              handleSaveStep5({
                applicantName: applicantName,
                applicantMiddleName: applicantMiddleName,
                applicantLastName: applicantLastName,
                applicantCardId: applicantCardId,
              });
            }}
            arrow={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="9"
                viewBox="0 0 7 13"
                fill="none"
                style={{ margin: "0 0.3rem" }}
              >
                <path
                  d="M1 1L5.08579 5.08579C5.86683 5.86683 5.86684 7.13316 5.08579 7.91421L1 12"
                  stroke="var(--second-linear-green)"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            }
          />
        </Stack>
      </CustomTabPanel> */}
      <CustomTabPanel value={value} index={2}>
        <AttachmentStep
          PersonalInformationLoading={PersonalInformationLoading}
          step4check={step4check}
          checked1={checked1}
          checked2={checked2}
          handleBack={null}
          handleAttachmentStep={handleAttachmentStep}
          personConcernedIdentity={personConcernedIdentity}
          setPersonConcernedIdentity={setPersonConcernedIdentity}
          personConcernedIdentityError={personConcernedIdentityError}
          setPersonConcernedIdentityName={setPersonConcernedIdentityName}
          applicantIdentity={applicantIdentity}
          setApplicantIdentity={setApplicantIdentity}
          applicantIdentityError={applicantIdentityError}
          setApplicantIdentityName={setApplicantIdentityName}
          authorizationPhoto={authorizationPhoto}
          setAuthorizationPhoto={setAuthorizationPhoto}
          authorizationPhotoError={authorizationPhotoError}
          setAuthorizationPhotoName={setAuthorizationPhotoName}
          commercialPhoto={commercialPhoto}
          setCommercialPhoto={setCommercialPhoto}
          commercialPhotoError={commercialPhotoError}
          setCommercialPhotoName={setCommercialPhotoName}
          commercialRegisterPhoto={commercialRegisterPhoto}
          setCommercialRegisterPhoto={setCommercialRegisterPhoto}
          commercialRegisterPhotoError={commercialRegisterPhotoError}
          setCommercialRegisterPhotoName={setCommercialRegisterPhotoName}
          //
          IdentitiesOfHeirs={IdentitiesOfHeirs}
          setIdentitiesOfHeirs={setIdentitiesOfHeirs}
          IdentitiesOfHeirsError={IdentitiesOfHeirsError}
          setIdentitiesOfHeirsName={setIdentitiesOfHeirsName}
          //
          InventoryOfInheritance={InventoryOfInheritance}
          setInventoryOfInheritance={setInventoryOfInheritance}
          InventoryOfInheritanceError={InventoryOfInheritanceError}
          setInventoryOfInheritanceName={setInventoryOfInheritanceName}
          //
          JudicialRulings={JudicialRulings}
          setJudicialRulings={setJudicialRulings}
          JudicialRulingsError={JudicialRulingsError}
          setJudicialRulingsName={setJudicialRulingsName}
          //
          OwnerIdentityBack={OwnerIdentityBack}
          setOwnerIdentityBack={setOwnerIdentityBack}
          OwnerIdentityBackError={OwnerIdentityBackError}
          setOwnerIdentityBackName={setOwnerIdentityBackName}
          //
          AuthorizedSignatoryIdentity={AuthorizedSignatoryIdentity}
          setAuthorizedSignatoryIdentity={setAuthorizedSignatoryIdentity}
          AuthorizedSignatoryIdentityError={AuthorizedSignatoryIdentityError}
          setAuthorizedSignatoryIdentityName={
            setAuthorizedSignatoryIdentityName
          }
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <Grid
          container
          rowGap={4}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ marginTop: "2rem" }}
        >
          <Grid item xs={12}>
            <CustomPasswordInput
              label="كلمة المرور الحالية"
              value={currentPassword}
              setValue={setCurrentPassword}
              isRequired={true}
              showError={currentPasswordError}
              setShowError={setCurrentPasswordError}
              type="currentPassword"
            />
          </Grid>
          <Grid item xs={12}>
            <CustomPasswordInput
              label="كلمة المرور الجديدة"
              value={password}
              setValue={setPassword}
              isRequired={true}
              showError={passwordError}
              setShowError={setPasswordError}
              type="password"
            />
          </Grid>
          <Grid item xs={12}>
            <CustomPasswordInput
              label="تأكيد كلمة المرور"
              value={passwordConfirmation}
              setValue={setPasswordConfirmation}
              isRequired={true}
              showError={passwordConfirmationError}
              setShowError={setPasswordConfirmationError}
              type="confirm_password"
            />
          </Grid>
        </Grid>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ margin: "2rem 0 0 0" }}
        >
          <StepButton
            disabled={PersonalInformationLoading}
            type="forward"
            text={"حفظ"}
            func={() => {
              handleSavePassword({
                currentPassword: currentPassword,
                password: password,
                passwordConfirmation: passwordConfirmation,
              });
            }}
            arrow={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="9"
                viewBox="0 0 7 13"
                fill="none"
                style={{ margin: "0 0.3rem" }}
              >
                <path
                  d="M1 1L5.08579 5.08579C5.86683 5.86683 5.86684 7.13316 5.08579 7.91421L1 12"
                  stroke="var(--second-linear-green)"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            }
          />
        </Stack>
      </CustomTabPanel>
    </Box>
  );
}

import styled from "styled-components";

export const Styles = styled.div`
  /* .step-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  } */
  padding-top: 10vh;
  @media (max-width: 768px) {
    min-height: 70vh;
    padding-top: 18vh;
    margin-top: 0vh;
  }
  @media (max-width: 500px) {
    min-height: 60vh;
    padding-top: 16vh;
    margin-top: 0vh;
  }
`;

import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import classes from "./popup.module.css";
import { Box, Stack } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { Link } from "react-router-dom";

function SimpleDialog(props) {
  const { onClose, open } = props;
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      sx={{
        "& .MuiPaper-root": {
          overflowY: "visible",
          padding: "2rem",
          borderRadius: "50px",
        },
      }}
    >
      <div className={`${classes.cardContainer}`}>
        <Stack
          className={`${classes.face} `}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <div className={classes.card3Image}>
            <img
              src={props.item.item.Tags[0]?.Value}
              alt="sector icon"
              width={"50px"}
              height={"50px"}
            />
          </div>

          <Typography
            gutterBottom
            variant="h6"
            sx={{ paddingTop: "0.5rem", textAlign: "center" }}
          >
            {props.item.item.Name}
          </Typography>

          <Box>
            {props.item.item.SubSectors.map((item, index) => (
              <Link
                style={{ textDecoration: "none" }}
                to={`/dynamic-form?dynamicFormId=20&processId=32&Sector=${props.item.item.Id}&SubSector=${item.Id}`}
                key={index}
              >
                <Typography
                  variant="body2"
                  color="text.secondary"
                  fontWeight={400}
                  fontSize={"1.2rem"}
                  className={classes.cursorEffect}
                >
                  <span style={{ marginLeft: "0.3rem" }}>
                    <CircleIcon
                      style={{ color: "#006838", fontSize: "0.5rem" }}
                    />
                  </span>
                  {item.Name}
                </Typography>
              </Link>
            ))}
          </Box>
        </Stack>
      </div>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function PopupDialog(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        className={classes.hero_button}
      >
        المزيد
      </Button>
      <SimpleDialog open={open} onClose={handleClose} item={props} />
    </div>
  );
}

import React, { useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import { Stack } from "@mui/material";
import classes from "../confirmationBox/style.module.css";

const ValidationForm = ({
  showError,
  setShowError,
  values,
  setValues,
  inputRefs,
}) => {
  const handleChange = (index, event) => {
    let value = event.target.value;
    // Restrict input to one digit
    value = value.slice(0, 1).replace(/[^0-9]/g, "");

    // Update the current TextField's value
    event.target.value = value;

    const newValues = [...values];
    newValues[index] = value;
    setValues(newValues);

    // Move focus to the next TextField if a value is entered
    if (value && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }
  };

  // Validate the input when the last TextField is filled
  const handleLastTextFieldBlur = () => {
    const isFilled = values.every((value) => value.length === 1);
    if (isFilled) {
      setShowError(false);
    } else {
      setShowError(true);
    }
  };

  return (
    <Stack direction={"row-reverse"} sx={{ padding: "0 1rem" }}>
      {inputRefs.map((ref, index) => (
        <TextField
          key={index}
          inputRef={ref}
          onChange={(event) => handleChange(index, event)}
          onBlur={handleLastTextFieldBlur}
          variant="outlined"
          type="number"
          required
          sx={{
            textAlign: "center",
            margin: "0 0.5rem",
            "& .MuiInputBase-input ": {
              height: { xs: "0.9em", sm: "1em", md: "1em" },
              width: { xs: "0.7em", sm: "1em", md: "1em" },
              border: showError ? "1px solid red" : "",
              color: "#004496 ",
              fontSize: "25px",
              fontWeight: 600,

              padding: { xs: "0.4rem", sm: "0.3rem", md: "0.5rem" },
              textAlign: "center",
            },

            "& input[type=number]": {
              "-moz-appearance": "textfield", // Remove spinner in Firefox
              "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
                "-webkit-appearance": "none", // Remove spinner in Webkit browsers (e.g., Chrome, Safari)
                margin: 0,
              },
            },
          }}
          inputProps={{
            maxLength: 1,
            inputMode: "numeric",
            pattern: "[0-9]",
          }}
          className={classes.inputValue}
        />
      ))}
    </Stack>
  );
};

export default ValidationForm;

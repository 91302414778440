import React, { useEffect, useState } from "react";
import { Styles } from "./styles";
import googlePlay from "../../assets/images/icons/google play.png";
import appStore from "../../assets/images/icons/App store.png";
const IntermediatePage = () => {
  const [deviceType, setDeviceType] = useState("");
  useEffect(() => {
    const userAgent = navigator.userAgent;
    if (/android/i.test(userAgent)) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=tech.cloudsystems.moet";
      setDeviceType("Android");
    } else if (/iPad|iPhone|iPod/i.test(userAgent)) {
      window.location.href =
        "https://apps.apple.com/us/app/moet-digital-services/id6483808579";
      setDeviceType("iOS");
    } else {
      setDeviceType("Unknown");
    }
  }, []);
  return (
    <Styles>
      <div className="container text-center">
        <p>
          مرحبًا بك في التطبيق المحمول لمديرية حماية المستهلك في وزارة الاقتصاد
          والتجارة. يسهل تطبيقنا عملية تقديم الشكاوى وخدمات المحترفين المتعلقة
          بمديرية حماية المستهلك. قم بتنزيل تطبيق حماية المستهلك الآن من متجر
          التطبيقات أو جوجل بلاي وساهم في حماية حقوقك ومجتمعك كمستهلك، وتابع سير
          طلباتك مباشرةً من خلال التطبيق
        </p>
        {/* {deviceType} */}
        <div className="download-section">
          <a href="https://play.google.com/store/apps/details?id=tech.cloudsystems.moet">
            <img src={googlePlay} alt="google store" />
          </a>
          <a href="https://apps.apple.com/us/app/moet-digital-services/id6483808579">
            <img src={appStore} alt="apple store" />
          </a>
        </div>
      </div>
    </Styles>
  );
};

export default IntermediatePage;

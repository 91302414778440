import PropTypes from "prop-types";
// material
import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Breadcrumbs,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Iconify from "../../../components/Iconify";
import CircleIcon from "./CircleIcon";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
import useStateRef from "react-usestateref";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------
const buttonStyle = {
  width: "8rem",
  // margin: "0 1rem",
  margin: "0 1rem",

  // padding: "0.4rem",
  borderRadius: "10px",
  background: "#F8F9FA",
  color: "#000",
  textAlign: "center",
  fontFamily: "Cairo",
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "188.9%",
  "&:hover": {
    background:
      "linear-gradient(180deg, var(--first-linear-green) 0%, var(--second-linear-green) 100%)",
    color: "#fff",
  },
};
const settingButtonStyle = {
  width: "8rem",
  // margin: "0 1rem",
  margin: "0 1rem 0 0",

  // padding: "0.4rem",
  borderRadius: "10px",
  background: "#F8F9FA",
  color: "#000",
  textAlign: "center",
  fontFamily: "Cairo",
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "188.9%",
  "&:hover": {
    background:
      "linear-gradient(180deg, var(--first-linear-green) 0%, var(--second-linear-green) 100%)",
    color: "#fff",
  },
};
const controlButtonStyle = {
  width: "8rem",
  // margin: "0 1rem",
  margin: "0 0 0 1rem",

  // padding: "0.4rem",
  borderRadius: "10px",
  background: "#F8F9FA",
  color: "#000",
  textAlign: "center",
  fontFamily: "Cairo",
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "188.9%",
  "&:hover": {
    background:
      "linear-gradient(180deg, var(--first-linear-green) 0%, var(--second-linear-green) 100%)",
    color: "#fff",
  },
};

const activeButton = {
  width: "8rem",
  borderRadius: "10px",
  background:
    "linear-gradient(180deg, var(--first-linear-green) 0%, var(--second-linear-green) 100%)",
  color: "#fff",
  textAlign: "center",
  fontFamily: "Cairo",
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "188.9%",
  transition: "all 0.5s ease-out",

  "&:hover": {
    background:
      "linear-gradient(180deg, var(--first-linear-green) 0%, var(--second-linear-green) 100%)",
    color: "#fff",
  },
};
// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const theme = useTheme();
  const location = useLocation();
  const mediaQuery768 = useMediaQuery(theme.breakpoints.down("769"));
  const RootStyle = styled(AppBar)(({ theme }) => ({
    boxShadow: "none",
    position: "absolute",
    // backdropFilter: "blur(6px)",
    left: 0,
    top: mediaQuery768 ? "7rem" : "2rem",
    right: "auto",
    WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
    backgroundColor:
      /* alpha(theme.palette.background.default, 0.72) */ "transparent",
    [theme.breakpoints.up("lg")]: {
      width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
    },
    zIndex: "5",
  }));

  const [isReviewOrder, setIsReviewOrder, ReviewOrderRef] = useStateRef(false);
  useEffect(() => {
    if (window.location.pathname.startsWith("/dashboard/review-order")) {
      setIsReviewOrder(true);
    } else {
      setIsReviewOrder(false);
    }
  }, [window.location.pathname]);

  const handleBack = () => {
    window.history.back();
  };

  return (
    <RootStyle>
      <ToolbarStyle>
        <IconButton
          onClick={onOpenSidebar}
          sx={{ mr: 1, color: "text.primary", display: { lg: "none" } }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
        <Stack flex={1}>
          <Stack direction={"row"} alignItems={"center"} spacing={3}>
            <CircleIcon size="10" CustomStyle={{ marginTop: "0.5rem" }} />
            <CircleIcon size="10" CustomStyle={{ marginTop: "0.5rem" }} />
            &nbsp; &nbsp;
            <Typography
              variant="h4"
              sx={{
                color: "#000",
                textAlign: "center",
                fontFamily: "Cairo",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "normal",
              }}
            >
              حسابي
            </Typography>
          </Stack>
          <Breadcrumbs
            separator={<NavigateBeforeIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link
              style={{
                color: "#000",
                textAlign: "center",
                fontFamily: "Cairo",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "188.9%" /* 30.224px */,
                textDecoration: "none",
              }}
              to={"/"}
              // href={"/"}
              color="inherit"
            >
              الرئيسية
            </Link>
            <Link
              style={{
                // color: "var(--green)",
                color: "var(--green)",
                textAlign: "center",
                fontFamily: "Cairo",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "188.9%" /* 30.224px */,
                textDecoration: "none",
              }}
              href={"/dashboard/settings"}
              color="inherit"
            >
              حسابي
            </Link>
          </Breadcrumbs>
          <Stack
            direction={"row"}
            justifyContent={{
              xs: "flex-start",
              sm: "flex-end",
              md: "flex-end",
            }}
            margin={{ xs: "0.45rem 0", sm: "0 0" }}
            alignItems={"center"}
          >
            {ReviewOrderRef.current === true ? (
              <Button sx={buttonStyle} variant="contained" onClick={handleBack}>
                رجوع
              </Button>
            ) : (
              <>
                <Link
                  to={"/dashboard/in-progress"}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    sx={
                      location.pathname !== "/dashboard/settings"
                        ? activeButton
                        : controlButtonStyle
                    }
                    variant="contained"
                  >
                    لوحة التحكم
                  </Button>
                </Link>
                <Link
                  to={"/dashboard/settings"}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    sx={
                      location.pathname !== "/dashboard/settings"
                        ? settingButtonStyle
                        : activeButton
                    }
                    variant="contained"
                  >
                    الاعدادات
                  </Button>
                </Link>
              </>
            )}
          </Stack>
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}

import { allowedFilesExtensions } from "../constants/enums";

export const CheckFileSizeValidation = (file, fileSizeLimit) => {
  let fileSizeLimitNew = fileSizeLimit;
  if (
    fileSizeLimitNew === 0 ||
    fileSizeLimitNew === null ||
    fileSizeLimitNew === undefined
  )
    fileSizeLimitNew = 10000000;

  if (file?.size < fileSizeLimitNew || file?.size == fileSizeLimitNew) {
    return true;
  } else return false;
};
export const CheckFileTypeValidationInDynamicForm = (
  file,
  allowedExtensions
) => {
  // console.log(allowedExtensions?.length);
  // let allowedExtensionsNew = allowedExtensions;
  // if (allowedExtensions?.length === 0 || !allowedExtensions) {
  //   allowedExtensionsNew = allowedFilesExtensions;
  // }
  // console.log(allowedExtensionsNew);
  // var extension = file?.name
  //   ?.substr(file?.name?.lastIndexOf("."))
  //   .toLowerCase();
  // if (allowedExtensionsNew?.indexOf(extension) === -1) {
  //   return false;
  // } else return true;
  return true;
};
export const CheckFileTypeValidation = (file, allowedExtensions) => {
  let allowedExtensionsNew = allowedExtensions;
  var extension = file?.name
    ?.substr(file?.name?.lastIndexOf("."))
    .toLowerCase();
  if (allowedExtensionsNew?.indexOf(extension) === -1) {
    return false;
  } else return true;
};
export const CheckFilesCount = (files, count) => {
  // if (files.length > count) return true;
  // else return false;
  return true;
};

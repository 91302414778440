import React from "react";
import { Styles } from "./styles";
import PageBanner from "../../components/banner";
// import bannerImage from "../../assets/images/Cover.webp";
import BannerWithImage from "../../components/banner-with-image";
import Tabs from "../../components/tabs";
import bannerImage from "../../assets/images/banner-image.jpg";
import icon from "../../assets/images/icons/Asset 1.png";
import ServicePriorities from "../../components/about-us/service-priorities";
import ServiceStandards from "../../components/about-us/service-standards";
import VideoPlayer from "../../components/video-player";

const AboutUs = ({ children }) => {
  return (
    <>
      <BannerWithImage image={bannerImage} title="من نحن" />
      <Tabs />
      <Styles>
        <div className="main-content page-container">{children}</div>
        <div className="video-frame">
          <VideoPlayer id={"ja1JeZ2BU00"} />
        </div>
        <ServicePriorities />
        <ServiceStandards />
      </Styles>
    </>
  );
};

export default AboutUs;

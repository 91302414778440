import { Navigate, Route, Routes } from "react-router-dom";
import PagesProvider from "./pages/pagesProvider/PagesProvider";
import HomeProvider from "./pages/home/HomeProvider";
import LoginPage from "./pages/login";
import RegisterPage from "./pages/register";
import Dashboard from "./pages/dashboard";
import ServicesDetailsPage from "./pages/servicesDetails";
import MainDynamicForm from "./pages/dynamic-form";
import ProfileSettingsPage from "./pages/profileSettings";
import ConfirmAccount from "./pages/confirmAccount";
import DashboardInProgressOrders from "./pages/dashboard/sections/inProgressOrders";
import DashboardCompletedOrders from "./pages/dashboard/sections/completedOrders";
import DashboardDraftOrders from "./pages/dashboard/sections/draftOrders";
import NotFoundPage from "./pages/not-found";
import ReviewOrder from "./pages/reviewOrder";
import AboutUs from "./pages/about-us";
import StatisticsAndReports from "./pages/statistics-reports";
import MobileForm from "./pages/mobileForm";
import Submite from "./pages/mobileForm/Submite";
import Draft from "./pages/mobileForm/Draft";
import AboutPlatform from "./pages/about-us/AboutPlatform";
import Responsibilities from "./pages/about-us/Responsibilities";
import Works from "./pages/about-us/Works";
import OurGoal from "./pages/about-us/OurGoal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TermsAndConditions from "./pages/terms-conditions";
import PrivacyPolicy from "./pages/privacy-policy";
import ContactUs from "./pages/contact-us";
import TestPayment from "./pages/TestPayment";
import RetrieveOrderPage from "./pages/payment/retrieve-order";
import IntermediatePage from "./pages/intermediate-page";
import ExhibitionAndFairReport from "./pages/FED-pages/exhibition-and-fair-report";
import InsurancePolicy from "./pages/FED-pages/insurance-policy";
import ListOfExhibitors from "./pages/FED-pages/list-of-exhibitors";
import { setActiveTheme } from "./helpers/setActiveTheme";
import AllExhibitions from "./pages/FED-pages/all-exhibitions";
import AddExhibition from "./pages/FED-pages/add-exhibtion";
import { typesOfProjects } from "./constants/enums";

function App() {
  const activeEnv = process.env.REACT_APP_ACTIVE_ENV;
  setActiveTheme();
  return (
    <>
      <PagesProvider>
        <Routes>
          <Route path="retrieve-order" element={<RetrieveOrderPage />} />
          <Route path="payment" element={<TestPayment />} />
          <Route path="mobile-form" element={<MobileForm />} />
          <Route path="draft" element={<Draft />} />
          <Route path="submit" element={<Submite />} />
          <Route path="service/:id" element={<ServicesDetailsPage />} />
          <Route path="/">
            <Route index element={<HomeProvider />} />
            <Route
              // path="dynamic-form/:dynamicFormId/:processId/:sectorId?/:subsectoreId?/:formDataId?"
              path="dynamic-form"
              element={<MainDynamicForm />}
            />
            {/* auth cycle */}
            <Route path="login" element={<LoginPage />} />
            <Route path="register" element={<RegisterPage />} />
            <Route path="confirm-account" element={<ConfirmAccount />} />
            {/* profie & dashboard  */}
            <Route path="dashboard" element={<Dashboard />}>
              <Route
                path="in-progress"
                element={<DashboardInProgressOrders />}
              />
              <Route path="drafts" element={<DashboardDraftOrders />} />
              <Route path="completed" element={<DashboardCompletedOrders />} />
              <Route path="settings" element={<ProfileSettingsPage />} />
              <Route path="review-order/:formId" element={<ReviewOrder />} />
            </Route>
            <Route path="404" element={<NotFoundPage />} />
            {/* <Route path="about" element={<AboutUs />} /> */}
            {activeEnv == typesOfProjects.CPD && (
              <Route path="about/about-platform" element={<AboutPlatform />} />
            )}
            {activeEnv == typesOfProjects.CPD && (
              <Route
                path="about/our-responsibilities"
                element={<Responsibilities />}
              />
            )}
            {activeEnv == typesOfProjects.CPD && (
              <Route path="about/our-works" element={<Works />} />
            )}
            {activeEnv == typesOfProjects.CPD && (
              <Route path="about/our-goal" element={<OurGoal />} />
            )}

            <Route
              path="statistics-reports"
              element={<StatisticsAndReports />}
            />
          </Route>
          <Route path="terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="mobileapp" element={<IntermediatePage />} />
          <Route
            path="exhibition-and-fair-report/:transactionId/:documentId"
            element={<ExhibitionAndFairReport />}
          />
          <Route
            path="insurance-policy/:transactionId/:documentId"
            element={<InsurancePolicy />}
          />
          <Route
            path="list-of-exhibitors/:transactionId/:documentId"
            element={<ListOfExhibitors />}
          />
          <Route path="exhibitions" element={<AllExhibitions />} />
          <Route path="add-exhibition" element={<AddExhibition />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </PagesProvider>
      <ToastContainer />
    </>
  );
}

export default App;

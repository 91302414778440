import { Modal } from "react-bootstrap";
import styled from "styled-components";
export const StyledModal = styled(Modal)`
  .modal {
    width: 50%;
  }

  .modal-header {
  }
  .modal-body {
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    #input-group {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
    .error-container {
      height: 5vh;
    }
  }
  .modal-footer {
    display: flex;
    justify-content: space-between;
  }
  @media (max-width: 550px) {
    .modal-content {
      position: absolute;
      top: 0;
    }
  }
`;

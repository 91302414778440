import React from "react";
import AboutUs from ".";

const OurGoal = () => {
  return (
    <AboutUs>
      <p>
        الهدف الرئيسي لمديرية حماية المستهلك هو تأمين إطار حديث لحماية المستهلك
        في لبنان، بهدف حماية مصالح المستهلكين وتعزيز بيئة تجارية آمنة وعادلة
        ومنصفة للمستهلكين والتجار على حد سواء. دور المديرية يتمثل في ضمان تنفيذ
        تشريعات حماية المستهلك، وتقديم المشورة للمستهلكين والشركات.
      </p>
    </AboutUs>
  );
};

export default OurGoal;

import React, { useEffect, useState } from "react";
import { Form, FormGroup } from "react-bootstrap";
import { pascalCaseToCamelCase } from "../../../helpers/caseConverter";
import * as ReactDOMServer from "react-dom/server";
import InputMask from "react-input-mask";
import moment from "moment/moment";
import { BeatLoader } from "react-spinners";
import {
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import DropdownListComponent from "../dropdown-list/dropdown-list2";
import useStateRef from "react-usestateref";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { getAccessTokenCookie } from "../../../utils/cookie";
import FilesUploader from "../files-uploaders";
import {
  findFileValueByGuid,
  findGuidByName,
  findIdByGuid,
} from "../../../helpers/dynamic-form/functionsOnElements";
import { getPicklists } from "../../../services/picklists.services";
import TagComponent from "../tag-component";
import DropdownListExternalData from "../../dynamic-form/dropdown-list/dropdown-external-date";
import {
  checkIfDataExistAPI,
  getLoadData,
} from "../../../services/dynamicForm.services";
import {
  ErrorComponent,
  SuccessComponent,
  loadingComponent,
} from "../../common/ValidationMessagesComponents";
import GridTable from "../../dynamic-form/grid-table";
import { useMediaQuery } from "react-responsive";
const InputComponent = ({
  allData,
  Name,
  id,
  Guid,
  FieldType,
  Mandatory,
  Visibility,
  Placeholder,
  DefaultValue,
  MultipleValues,
  CustomFormat,
  PicklistStyle,
  PicklistId,
  PicklistFilterType,
  PicklistFilterFieldGuid,
  PicklistFilterParentOptionId,
  PicklistMultipleSelection,
  IsYesNoSingleCheckbox,
  YesLabel,
  NoLabel,
  MinValidationMessage,
  MaxValidationMessage,
  MinCharCount,
  MaxCharCount,
  NumberType,
  MinNumValue,
  MaxNumValue,
  MinDateValue,
  MaxDateValue,
  MinTimeValue,
  MaxTimeValue,
  OtherAttachmentsLimit,
  AttachmentFileSizeLimit,
  AttachmentFileAllowedFormats,
  data,
  usersData,
  userLoading,
  checkClickAble,
  checkConditionsChange,
  checkFieldCorrection,
  columnSize,
  documentLinks,
  setDocumentLinks,
  documentLink,
  setDocumentLink,
  setAllowNextAfterUploadDocuments,
  setUploadFilesLoading,
  uploadFilesLoading,
  setUploadFileLoading,
  uploadFileLoading,
  activeStep,
  sectorId,
  subsectoreId,
  DropdownListInfoBoxTag,
  DropdownListFieldGuid,
  PicklistFilterTag,
  PicklistFieldFilterTag,
  queryParams,
  LoadFormDataSourceFieldId,
  LoadFormDataBaseFieldGuid,
  LoadFormDataLoadByUser,
  LoadFormDataSourceDynamicFormId,
  dynamicFormId,
  setoptimizedData,
  setLoading,
  DropDownListExternalDataApilink,
  DropDownListExternalDataLabelName,
  DropDownListExternalDataValueName,
  handleOpen,
  handleClose,
  setNotificationWithLoader,
  setNotificationisLoading,
  setNotificationMessage,
  // for field value validation
  setFieldValidationError,
  // important for grid table
  applyChangesOnField,
  transactionProducts,
  setTransactionProducts,
  // this parameter represent the case when request returned to client
  lockAllFieldsExceptAttachments,
}) => {
  const [value, setValue, valueRef] = useStateRef(false);

  // States
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [ParentInfoBoxValue, setParentInfoBoxValue] = useState(false);
  const [infoBoxTag, setInfoBoxTag] = useState(false);
  // UI/UX
  const [tagLoading, setTagLoading] = useState(false);

  const isMobileView = useMediaQuery({ query: "(max-width: 576px)" });

  // Functions
  const checkCharCount = (value, minCount, maxCount) => {
    // const element = document.getElementById(id);
    if (value.length > maxCount) {
      setIsError(true);
      // element.classList.add("error-border");
      setErrorMessage(MaxValidationMessage);
    } else if (value.length < minCount) {
      setIsError(true);
      // element.classList.add("error-border");
      setErrorMessage(MinValidationMessage);
    } else {
      setIsError(false);
      // element.classList.remove("error-border");
    }
  };
  const checkNumberValidation = (id, value, min, max) => {
    const element = document.getElementById(id);
    if (value > max) {
      setIsError(true);
      element.classList.add("error-border");
      setErrorMessage(MaxValidationMessage);
    } else if (value < min) {
      setIsError(true);
      element.classList.add("error-border");
      setErrorMessage(MinValidationMessage);
    } else {
      element.classList.remove("error-border");
      setIsError(false);
    }
  };
  const checkTime = (id) => {
    let element = document.getElementById(id);
    if (!element?.checkValidity()) {
      setIsError(true);
      setErrorMessage(MinValidationMessage + " & " + MaxValidationMessage);
      // element.classList.add("error-border");
    } else {
      setIsError(false);
      // element.classList.remove("error-border");
      setErrorMessage(MinValidationMessage + " & " + MaxValidationMessage);
    }
  };
  function checkEmailCorrection(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);
    if (isValidEmail) {
    }
    setErrorMessage(isValidEmail ? "" : "صيغة البريد الالكتروني غير صحيحة");
    setIsError(!isValidEmail);
  }
  // datePickerHandler
  const datePickerHandler = (event, id) => {
    const ele = document.getElementById(`date-picker-${id}`);
    const comp = (
      <>
        <CalendarMonthIcon /> العام - الشهر - اليوم
      </>
    );
    const dateComp = (
      <>
        <CalendarMonthIcon /> {event.target.value}
      </>
    );
    if (event.target.value) {
      ele.innerHTML = ReactDOMServer.renderToString(dateComp);
    } else {
      ele.innerHTML = ReactDOMServer.renderToString(comp);
    }
  };
  const getData = async () => {
    const response = await getPicklists();
    setTimeout(() => {
      data?.map((step) => {
        step?.DynamicFormSections?.map((section) => {
          section.DynamicFormFields.map((field) => {
            checkConditionsChange(field.Guid);
          });
        });
      });
    }, 1000);
    return response;
  };
  const getFieldsData = () => {
    let requestArr = [];
    const dataArr = new Map();
    const form = document.getElementById("formControl");
    Array?.from(form?.elements)?.forEach(async (input) => {
      let requestBody = {};
      // let dataResult = {};
      input.checkValidity();
      // let inputName = input?.name;
      if (input.type === "radio") {
        if (input.checked) {
          requestBody["Value"] = input.value;
          requestBody["FieldId"] = findGuidByName(data, input.name);
          dataArr.set(input?.name, input?.value);
        }
      } else if (input.type === "time") {
        requestBody["Value"] = moment(input.value, ["H:mm"]).format("h:mm A");
        requestBody["FieldId"] = findGuidByName(data, input.name);
        dataArr.set(input?.name, input?.value);
      } else if (input.type === "file") {
        let fileValue;
        if (input.hasAttribute("multiple")) {
          fileValue = findFileValueByGuid(
            documentLinks,
            findGuidByName(data, input.name)
          );
        } else {
          fileValue = findFileValueByGuid(
            documentLink,
            findGuidByName(data, input.name)
          );
        }
        dataArr.set(input?.name, fileValue);
      } else if (input.type === "checkbox") {
        if (input.value === "IsYesNoSingleCheckbox") {
          if (input.checked) {
            requestBody["Value"] = "True";
            requestBody["FieldId"] = findGuidByName(data, input.name);
          } else {
            requestBody["Value"] = "False";
            requestBody["FieldId"] = findGuidByName(data, input.name);
          }
        } else if (input.checked) {
          requestBody["Value"] = input?.value;
          requestBody["FieldId"] = findGuidByName(data, input.name);
          let selectedValues =
            dataArr.get(input?.name) != undefined
              ? dataArr.get(input?.name)
              : [];
          selectedValues.push(input.value);
          dataArr.set(input?.name, selectedValues);
        }
      } else {
        requestBody["Value"] = input.value;
        requestBody["FieldId"] = findGuidByName(data, input.name);
        dataArr.set(input?.name, input?.value);
      }
      if (requestBody.FieldId && requestBody.Value) {
        requestArr.push(requestBody);
      }
    });
    // return requestArr;
    return dataArr;
  };
  const reRenderDataFN = (newData) => {
    // initialize notification modal
    setNotificationWithLoader(true);
    // setNotificationisLoading(true)
    setNotificationMessage(
      "تم إدخال الحقل مسبقا .. يرجى الانتظار بينما يتم جلب البيانات    "
    );

    // open modal notification
    handleOpen();
    setLoading(true);
    // set new data
    setoptimizedData(newData);
    // clode modal notification
    setTimeout(() => {
      setLoading(false);
    }, 500);
    setTimeout(() => {
      handleClose();
    }, 1500);
  };
  // handle DropdownListInfoBox value
  useEffect(() => {
    setTimeout(() => {
      const elementValueId = findIdByGuid(data, DropdownListFieldGuid);
      // find the selected value of parent element
      const ele = document.getElementById(elementValueId.id);
      if (ele !== null)
        ele.addEventListener("input", async function (event) {
          // set the value of parent element to filter tags depending on it
          setTagLoading(true);
          const response = await getData();
          if (response.status === 200) {
            response?.data?.map((picklist) => {
              if (picklist?.Id == elementValueId?.PicklistId)
                picklist?.Options?.map((option) => {
                  if (option.Id == ele?.value) {
                    option?.Tags?.map((tag) => {
                      if (tag?.TypeId == DropdownListInfoBoxTag) {
                        setInfoBoxTag(tag);
                      }
                    });
                  }
                });
            });
          }
          setTagLoading(false);
        });
    }, 1500);
  }, []);
  useEffect(() => {
    setValue(queryParams.get("token"));
  }, []);
  // check if exist Functions
  const loadingMessageGenerator = (targetId) => {
    let validateMessageElement = document.getElementById(
      `validate-${targetId}`
    );
    validateMessageElement.innerHTML =
      ReactDOMServer.renderToString(loadingComponent);
  };
  const successMessageGenerator = (targetId) => {
    let validateMessageElement = document.getElementById(
      `validate-${targetId}`
    );
    validateMessageElement.innerHTML =
      ReactDOMServer.renderToString(SuccessComponent);
  };
  const errorMessageGenerator = (targetId, message) => {
    let validateMessageElement = document.getElementById(
      `validate-${targetId}`
    );
    const errorComp = ErrorComponent(message);
    validateMessageElement.innerHTML = ReactDOMServer.renderToString(errorComp);
  };
  useEffect(() => {
    checkConditionsChange(Guid);
  }, [1000]);
  if (FieldType === "YesNoRadioButton") {
    if (IsYesNoSingleCheckbox)
      return (
        <FormControlLabel
          value="IsYesNoSingleCheckbox"
          control={
            <Checkbox
              disabled={lockAllFieldsExceptAttachments}
              className="IsYesNoSingleCheckbox"
              // checked={opt.Id == DefaultValue}
              onChange={() => {
                checkConditionsChange(Guid);
                checkClickAble(data);
                checkFieldCorrection(id);
              }}
              onBlur={() => checkFieldCorrection(id)}
              defaultChecked={DefaultValue === "True" ? true : false}
              // defaultChecked={DefaultValue}
            />
          }
          sx={{
            "& .MuiTypography-root": {
              color: "#194522",
              textAlign: "center",
              fontFamily: "Cairo",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "188.9%" /* 34.002px */,
            },
          }}
          // labelPlacement="end"
          id={id}
          name={Name}
          aria-label="YesNoRadioButton"
        />
      );
    else
      return (
        <RadioGroup
          className={
            isMobileView
              ? "customRadioButtons d-flex flex-column"
              : "customRadioButtons d-flex flex-row"
          }
          aria-label="YesNoRadioButton"
          name={Name}
          // value={value}
          id={id}
          onChange={() => {
            checkConditionsChange(Guid);
            checkClickAble(data);
            checkFieldCorrection(id);
          }}
          onBlur={() => checkFieldCorrection(id)}
          defaultValue={DefaultValue}
        >
          <FormControlLabel
            control={<Radio disabled={lockAllFieldsExceptAttachments} />}
            labelPlacement="end"
            label={YesLabel}
            name={Name}
            id={id}
            value="True"
            sx={{
              "&.MuiFormControlLabel-root": {
                marginRight: 0,
              },
            }}
          />
          <FormControlLabel
            control={<Radio disabled={lockAllFieldsExceptAttachments} />}
            labelPlacement="end"
            label={NoLabel}
            name={Name}
            id={id}
            value="False"
            sx={{
              "&.MuiFormControlLabel-root": {
                marginRight: isMobileView ? "0rem" : "1rem",
              },
            }}
          />
        </RadioGroup>
      );
  } else if (FieldType === "DropdownList") {
    return (
      <>
        <DropdownListComponent
          Name={Name}
          Id={id}
          DefaultValue={DefaultValue}
          MultipleValues={MultipleValues}
          Placeholder={Placeholder}
          PicklistStyle={PicklistStyle}
          PicklistId={PicklistId}
          PicklistFilterType={PicklistFilterType}
          PicklistFilterFieldGuid={PicklistFilterFieldGuid}
          PicklistFilterParentOptionId={PicklistFilterParentOptionId}
          PicklistMultipleSelection={PicklistMultipleSelection}
          Guid={Guid}
          Mandatory={Mandatory}
          checkConditionsChange={checkConditionsChange}
          checkClickAble={checkClickAble}
          checkFieldCorrection={checkFieldCorrection}
          data={data}
          columnSize={columnSize}
          activeStep={activeStep}
          sectorId={sectorId}
          subsectoreId={subsectoreId}
          PicklistFilterTag={PicklistFilterTag}
          PicklistFieldFilterTag={PicklistFieldFilterTag}
          lockAllFieldsExceptAttachments={lockAllFieldsExceptAttachments}
        />
      </>
    );
  } else if (FieldType === "UserList") {
    return (
      <>
        {userLoading ? (
          <div className="picklist-loader">
            <BeatLoader color="var(--green)" />
          </div>
        ) : (
          <select
            disabled={lockAllFieldsExceptAttachments}
            name={Name}
            id={id}
            className="p-2 mb-0 w-100"
            type={pascalCaseToCamelCase(FieldType)}
            required={Mandatory}
            onChange={() => {
              checkConditionsChange(Guid);
              checkClickAble(data);
              checkFieldCorrection(id);
            }}
            onBlur={() => checkFieldCorrection(id)}
          >
            <option value="">
              {userLoading ? (
                <BeatLoader color="var(--green)" />
              ) : Placeholder ? (
                Placeholder
              ) : (
                "يرجى اختيار أحد العناصر"
              )}
            </option>
            {usersData?.map((item) =>
              item.Id == DefaultValue ? (
                <option value={item.Id} selected>
                  {item?.UserName}
                </option>
              ) : (
                <option value={item.Id}>{item?.UserName}</option>
              )
            )}
          </select>
        )}
      </>
    );
  } else if (FieldType === "TextMultiline") {
    return (
      <>
        <TextField
          disabled={lockAllFieldsExceptAttachments}
          id={id}
          name={Name}
          multiline
          rows={3}
          defaultValue={DefaultValue}
          required={Mandatory}
          onChange={(event) => {
            checkConditionsChange(Guid);
            checkCharCount(event.target.value, MinCharCount, MaxCharCount);
            checkClickAble(data);
            checkFieldCorrection(id);
          }}
          variant="outlined"
          helperText={isError && errorMessage}
          sx={{
            "&.MuiFormControl-root": {
              width: "100%",
              "& .MuiFormHelperText-root": {
                color: "#d1202f",
                textAlign: "right",
              },
            },
            "& .MuiInputBase-input": {
              background: "var(--field-BG)",
              padding: "0.7rem",
              borderRadius: "12px",
              outline: "none",
            },
            "& .MuiOutlinedInput-root": {
              padding: "0",
              border: "none",
              "&:hover, &:focus": {
                border: "none",
              },
              "&.Mui-focused": {
                border: "none",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            },
          }}
        />
      </>
    );
  } else if (FieldType === "Number") {
    return (
      <>
        <TextField
          disabled={lockAllFieldsExceptAttachments}
          id={id}
          name={Name}
          type={pascalCaseToCamelCase(FieldType)}
          defaultValue={DefaultValue}
          required={Mandatory}
          placeholder={Placeholder}
          InputProps={{ inputProps: { min: MinNumValue, max: MaxNumValue } }}
          onChange={(event) => {
            checkConditionsChange(Guid);
            checkFieldCorrection(id);
            checkNumberValidation(
              id,
              event.target.value,
              MinNumValue,
              MaxNumValue
            );
            checkClickAble(data);
          }}
          variant="outlined"
          helperText={isError && errorMessage}
          sx={{
            "&.MuiFormControl-root": {
              width: "100%",
              "& .MuiFormHelperText-root": {
                color: "#d1202f",
                textAlign: "right",
              },
            },
            "& .MuiInputBase-input": {
              background: "var(--field-BG)",
              padding: "0.7rem",
              borderRadius: "12px",
              outline: "none",
            },
            "& .MuiOutlinedInput-root": {
              border: "none",
              "&:hover, &:focus": {
                border: "none",
              },
              "&.Mui-focused": {
                border: "none",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            },
          }}
        />
        {isError && <div className="error-msg">{errorMessage}</div>}
      </>
    );
  } else if (FieldType === "PhoneNumber") {
    return (
      <>
        <FormGroup id="input-group" className="input-group">
          <InputMask
            disabled={lockAllFieldsExceptAttachments}
            className="p-2 mb-0 rounded-0 form-control"
            type="tel"
            id={id}
            name={Name}
            required={Mandatory}
            defaultValue={DefaultValue}
            onChange={(event) => {
              checkConditionsChange(Guid);
              checkClickAble(data);
              checkFieldCorrection(id);
            }}
            onBlur={() => checkFieldCorrection(id)}
            mask="+\9\61 99 999 999"
          ></InputMask>
        </FormGroup>
        {isError && <div className="error-msg">{errorMessage}</div>}
      </>
    );
  } else if (FieldType === "Date") {
    return (
      <>
        <div className="row p-0 m-0 date-container" id={`date-time-${id}`}>
          <div
            className={
              columnSize === 12
                ? "col-lg-4 col-md-8 col-sm-12 d-flex"
                : "col-12"
            }
          >
            <FormGroup
              id="input-group"
              className="input-group col-5 date-custome-component"
            >
              <Form.Label
                className="form-control date-placeholder"
                htmlFor={id}
                id={`date-picker-${id}`}
                role="button"
                onClick={() => {
                  if (!lockAllFieldsExceptAttachments) {
                    let ele = document.getElementById(id);
                    ele.popperPlacement = "bottom left";
                    ele.showPicker();
                    document.getElementById(id).showPicker();
                  }
                }}
                style={{
                  cursor: lockAllFieldsExceptAttachments
                    ? "not-allowed"
                    : "pointer",
                }}
              >
                {DefaultValue ? (
                  <>
                    <CalendarMonthIcon />
                    {moment(DefaultValue).format("DD-MM-YYYY")}
                  </>
                ) : (
                  <>
                    <CalendarMonthIcon />
                    &nbsp; اليوم - الشهر - العام
                  </>
                )}
              </Form.Label>
              <Form.Control
                disabled={lockAllFieldsExceptAttachments}
                id={id}
                name={Name}
                type={pascalCaseToCamelCase(FieldType)}
                required={Mandatory}
                className="p-2 mb-0 rounded-0 form-control date-picker"
                placeholder={Placeholder}
                min={MinDateValue?.substring(0, 10)}
                max={MaxDateValue?.substring(0, 10)}
                defaultValue={moment(DefaultValue).format("YYYY-MM-DD")}
                onChange={(event) => {
                  datePickerHandler(event, id);
                  checkConditionsChange(Guid);
                  checkClickAble(data);
                  checkFieldCorrection(id, FieldType);
                }}
                onBlur={() => checkFieldCorrection(id, FieldType)}
                popperPlacement="bottom"
              />
            </FormGroup>
          </div>
        </div>
        {isError && <div className="error-msg">{errorMessage}</div>}
      </>
    );
  } else if (FieldType === "Time") {
    return (
      <>
        <div className="row p-0 m-0 date-container" id={`date-time-${id}`}>
          <div
            className={
              columnSize === 12 ? "col-lg-4 col-md-8 col-sm-12" : "col-12"
            }
          >
            <FormGroup id="input-group" className="input-group col-5">
              <Form.Control
                disabled={lockAllFieldsExceptAttachments}
                id={id}
                name={Name}
                type="time"
                required={Mandatory}
                className="p-2 mb-0 rounded-0 form-control"
                placeholder={Placeholder}
                defaultValue={moment(DefaultValue).format("HH:mm")}
                min={moment(MinTimeValue, ["h:mm A"]).format("HH:mm")}
                max={moment(MaxTimeValue, ["h:mm A"]).format("HH:mm")}
                onChange={(event) => {
                  checkConditionsChange(Guid);
                  checkClickAble(data);
                  checkFieldCorrection(id);
                  checkTime(id);
                }}
                onBlur={() => checkFieldCorrection(id)}
              />
            </FormGroup>
          </div>
        </div>

        {/* {isError && (
          <div className="error-msg">
            يرجى اختيار وقت بين {MinTimeValue} و {MaxTimeValue}
          </div>
        )} */}
        {isError && <div className="error-msg">{errorMessage}</div>}
      </>
    );
  } else if (FieldType === "Text") {
    return (
      <>
        <TextField
          disabled={lockAllFieldsExceptAttachments}
          id={id}
          name={Name}
          type={pascalCaseToCamelCase(FieldType)}
          defaultValue={DefaultValue}
          required={Mandatory}
          onChange={(event) => {
            checkConditionsChange(Guid);
            checkCharCount(event.target.value, MinCharCount, MaxCharCount);
            checkClickAble(data);
            checkFieldCorrection(id);
          }}
          variant="outlined"
          helperText={isError && errorMessage}
          sx={{
            "&.MuiFormControl-root": {
              width: "100%",
              "& .MuiFormHelperText-root": {
                color: "#d1202f",
                textAlign: "right",
              },
            },
            "& .MuiInputBase-input": {
              background: "var(--field-BG)",
              padding: "0.7rem",
              borderRadius: "12px",
              outline: "none",
            },
            "& .MuiOutlinedInput-root": {
              border: "none",
              "&:hover, &:focus": {
                border: "none",
              },
              "&.Mui-focused": {
                border: "none",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            },
          }}
        />
      </>
    );
  } else if (FieldType === "Attachment" || FieldType === "OtherAttachments") {
    return (
      <>
        <FilesUploader
          Name={Name}
          id={id}
          Guid={Guid}
          FieldType={FieldType}
          Mandatory={Mandatory}
          Placeholder={Placeholder}
          DefaultValue={DefaultValue}
          MultipleValues={MultipleValues}
          OtherAttachmentsLimit={OtherAttachmentsLimit}
          AttachmentFileSizeLimit={AttachmentFileSizeLimit}
          AttachmentFileAllowedFormats={AttachmentFileAllowedFormats}
          data={data}
          documentLinks={documentLinks}
          setDocumentLinks={setDocumentLinks}
          documentLink={documentLink}
          setDocumentLink={setDocumentLink}
          setAllowNextAfterUploadDocuments={setAllowNextAfterUploadDocuments}
          setUploadFilesLoading={setUploadFilesLoading}
          uploadFilesLoading={uploadFilesLoading}
          setUploadFileLoading={setUploadFileLoading}
          uploadFileLoading={uploadFileLoading}
          checkClickAble={checkClickAble}
          checkConditionsChange={checkConditionsChange}
          checkFieldCorrection={checkFieldCorrection}
          isError={isError}
          setIsError={setIsError}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          valueRef={valueRef}
          columnSize={columnSize}
        />
      </>
    );
  } else if (FieldType === "Email") {
    return (
      <>
        <TextField
          disabled={lockAllFieldsExceptAttachments}
          id={id}
          name={Name}
          type={pascalCaseToCamelCase(FieldType)}
          defaultValue={DefaultValue}
          required={Mandatory}
          onChange={(event) => {
            checkConditionsChange(Guid);
            checkCharCount(event.target.value, MinCharCount, MaxCharCount);
            checkClickAble(data);
            checkFieldCorrection(id);
            checkEmailCorrection(event.target.value);
          }}
          variant="outlined"
          helperText={isError && errorMessage}
          sx={{
            "&.MuiFormControl-root": {
              width: "100%",
              margin: "0 !important",
              "& .MuiFormHelperText-root": {
                color: "#d1202f",
                textAlign: "right",
              },
              marginRight: "14px",
            },
            "& .MuiInputBase-input": {
              background: "var(--field-BG)",
              padding: "0.7rem",
              borderRadius: "12px",
              // border: showError ? "1px solid #d1202f" : "none",
              outline: "none",
            },
            "& .MuiOutlinedInput-root": {
              border: "none",
              padding: "0rem",
              "&:hover, &:focus": {
                border: "none",
              },
              "&.Mui-focused": {
                border: "none",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            },
          }}
        />
      </>
    );
  } else if (FieldType === "DropdownListInfoBox") {
    return (
      <>
        {tagLoading ? (
          <div className="picklist-loader">
            <BeatLoader color="var(--green)" />
          </div>
        ) : (
          <TagComponent
            Id={infoBoxTag?.Id}
            Value={infoBoxTag?.Value}
            TypeId={infoBoxTag?.TypeId}
            TypeName={infoBoxTag?.TypeName}
          />
        )}
      </>
    );
  } else if (FieldType === "Hidden") {
    return (
      <>
        <TextField
          id={id}
          name={Name}
          type={pascalCaseToCamelCase(FieldType)}
          // defaultValue={DefaultValue}
          value={queryParams.get(Name)}
          required={Mandatory}
          onChange={(event) => {
            checkConditionsChange(Guid);
            checkCharCount(event.target.value, MinCharCount, MaxCharCount);
            checkClickAble(data);
            checkFieldCorrection(id);
          }}
          variant="outlined"
        />
      </>
    );
  } else if (FieldType === "LoadFormData") {
    const elementValueId = findIdByGuid(data, LoadFormDataBaseFieldGuid);
    const ele = document.getElementById(elementValueId.id);
    if (ele !== null)
      ele.addEventListener("input", async function (event) {
        const val = document.getElementById(
          findIdByGuid(data, LoadFormDataBaseFieldGuid).id
        );
        const requestBody = {
          DynamicFormId: dynamicFormId,
          SourceDynamicFormId: LoadFormDataSourceDynamicFormId,
          LoadFieldGuid: Guid,
          BaseFieldValue: val.value,
        };
        const enteredData = getFieldsData();
        function searchValueByLabel(label) {
          return enteredData.get(label);
        }

        data?.map((step) => {
          step.DynamicFormSections.map((section) => {
            section.DynamicFormFields?.map((field) => {
              const filedValue = searchValueByLabel(field.Name);
              if (filedValue?.length > 0) {
                if (
                  field.FieldType == "DropdownList" &&
                  field.PicklistStyle == "CheckboxList"
                ) {
                  field.MultipleValues = filedValue;
                } else if (field.FieldType == "OtherAttachments") {
                  field.MultipleValues = filedValue;
                } else if (Array.isArray(filedValue)) {
                  field.Value = filedValue[0];
                } else field.DefaultValue = filedValue;
              }
            });
          });
        });
        const response = await getLoadData(requestBody, valueRef.current);
        if (response.status === 200) {
          response?.data?.map((item) => {
            data?.map((step) => {
              step.DynamicFormSections.map((section) => {
                section.DynamicFormFields?.map((field) => {
                  if (field.Name == item?.Name && item?.Value?.length > 0) {
                    field.DefaultValue = item?.Value;
                  }
                });
              });
            });
          });

          reRenderDataFN(data);
        }
      });
  } else if (FieldType === "DropdownListExternalData") {
    return (
      <div>
        <DropdownListExternalData
          DropDownListExternalDataApilink={DropDownListExternalDataApilink}
          Name={Name}
          Id={id}
          Guid={Guid}
          Mandatory={Mandatory}
          Placeholder={Placeholder}
          checkConditionsChange={checkConditionsChange}
          checkClickAble={checkClickAble}
          checkFieldCorrection={checkFieldCorrection}
          DropDownListExternalDataLabelName={DropDownListExternalDataLabelName}
          DropDownListExternalDataValueName={DropDownListExternalDataValueName}
          lockAllFieldsExceptAttachments={lockAllFieldsExceptAttachments}
        />
      </div>
    );
  } else if (FieldType === "CheckIfDataExist") {
    const elementValueId = findIdByGuid(data, LoadFormDataBaseFieldGuid);
    const ele = document.getElementById(elementValueId.id);
    if (ele !== null) {
      ele.addEventListener("blur", async function (event) {
        const val = document.getElementById(elementValueId.id);
        const requestBody = {
          BaseFieldValue: val.value,
          LoadFieldGuid: Guid,
        };
        setFieldValidationError(true);
        loadingMessageGenerator(elementValueId.id);
        const response = await checkIfDataExistAPI(
          requestBody,
          valueRef.current
        );
        if (response.status === 200) {
          setFieldValidationError(false);
          // successToaster("الرقم المدخل صحيح يمكنك متابعة عملية تقديم الطلب");
          successMessageGenerator(elementValueId.id);
        } else {
          // errorToaster(response?.data);
          errorMessageGenerator(elementValueId.id, response?.data);
        }
      });
    }
  } else if (FieldType === "Grid" && dynamicFormId == 30) {
    return (
      <GridTable
        transactionProducts={transactionProducts}
        setTransactionProducts={setTransactionProducts}
        data={data}
        applyChangesOnField={applyChangesOnField}
        checkClickAble={checkClickAble}
        viewOnly={lockAllFieldsExceptAttachments}
      />
    );
  }
};

export default InputComponent;

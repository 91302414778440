import React from "react";
import AboutUs from "./index";

const Works = () => {
  return (
    <AboutUs>
      <p>
        نلتزم بمعاملة الجميع بالإنصاف والاحترام والأمانة، ونتحمل المسؤولية عن
        أعمالنا بكل جدية. نحن نقوم بتحليل مشاكل المستهلكين ونصمم خدماتنا وفقًا
        لاحتياجاتهم. كما نتعاون مع الإدارات والوكالات الحكومية الأخرى لضمان
        فعالية أكبر في معالجة الشكاوى.
      </p>
    </AboutUs>
  );
};

export default Works;

import React, { useEffect } from "react";
import { Col, Form, FormGroup } from "react-bootstrap";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import { saveFile, saveFiles } from "../../../services/dynamicForm.services";
import { errorToaster } from "../../../helpers/toasterConfiguration";
import environment from "../../../environment";
import PreviewFile from "./preview-file";
import {
  CheckFileSizeValidation,
  CheckFileTypeValidation,
  CheckFileTypeValidationInDynamicForm,
} from "../../../helpers/checkFileValidation";
import * as ReactDOMServer from "react-dom/server";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useMediaQuery } from "react-responsive";
const FilesUploader = ({
  Name,
  id,
  Guid,
  FieldType,
  Mandatory,
  Placeholder,
  DefaultValue,
  MultipleValues,
  OtherAttachmentsLimit,
  AttachmentFileSizeLimit,
  AttachmentFileAllowedFormats,
  data,
  documentLinks,
  setDocumentLinks,
  documentLink,
  setDocumentLink,
  setAllowNextAfterUploadDocuments,
  setUploadFilesLoading,
  uploadFilesLoading,
  setUploadFileLoading,
  uploadFileLoading,
  //
  checkClickAble,
  checkConditionsChange,
  checkFieldCorrection,
  //
  isError,
  setIsError,
  errorMessage,
  setErrorMessage,
  valueRef,
  columnSize,
}) => {
  const isIPadView = useMediaQuery({ query: "(max-width: 1024px)" });
  const isTabletView = useMediaQuery({ query: "(max-width: 550px)" });
  const isMobileView = useMediaQuery({ query: "(max-width: 550px)" });
  const defaultAttachmentFileAllowedFormats = ["pdf", "png"];
  const saveUploadedFile = async (event, imageSelectorId) => {
    //  clear the current item when file uploader fired
    let oldArr = [];
    documentLink?.map((item) => {
      if (item?.FieldId != Guid) oldArr.push(item);
    });
    setDocumentLink(oldArr);
    // get element to set the result message
    const item = document.getElementById(imageSelectorId);
    // check file validation (SIZE)
    const fileSizeStatus = CheckFileSizeValidation(
      event?.target?.files[0],
      AttachmentFileSizeLimit
    );
    if (fileSizeStatus) {
      // check file validation (TYPE)
      const fileTypeStatus = CheckFileTypeValidationInDynamicForm(
        event?.target?.files[0],
        AttachmentFileAllowedFormats
      );
      if (fileTypeStatus) {
        if (event?.target?.value?.length > 0) {
          setUploadFileLoading(true);
          let responseLink = {};
          const formData = new FormData();
          formData.append("file", event?.target?.files[0]);
          item.innerHTML = `
        <div className="picklist-loader">
          <div class="spinner-border spinner-border-sm text-success" role="status">
          </div>      
           &nbsp;
          <span className="loader-text">يرجى الانتظار يتم رفع الملف</span>
        </div>
    `;
          const response = await saveFile(formData, valueRef.current);
          if (response?.status === 200) {
            responseLink["Value"] = response?.data?.url;
            responseLink["FieldId"] = Guid;
            setDocumentLink((current) => [...current, responseLink]);
            setAllowNextAfterUploadDocuments(false);
            item.innerHTML = "تم رفع الملف بنجاح";
          } else {
            document.getElementById(id).value = "";
            errorToaster("حدث خطأ أثناء رفع الملف ... حاول مرة أخرى");
            if (Placeholder) item.innerHTML = { Placeholder };
            else item.innerHTML = "اضغط لاختيار الملف";
            setAllowNextAfterUploadDocuments(true);
          }
        } else {
          setAllowNextAfterUploadDocuments(true);
          item.innerHTML = "اضغط لاختيار الملف";
        }
      } else {
        const errorComponent = (
          <>
            <ErrorOutlineIcon style={{ color: "var(--red)" }} />
            &nbsp;
            <span>
              {`يرجى اختيار الملف بأحد الصيغ التالية: ${
                AttachmentFileAllowedFormats.length > 0
                  ? AttachmentFileAllowedFormats
                  : defaultAttachmentFileAllowedFormats
              }`}
            </span>
          </>
        );
        item.innerHTML = ReactDOMServer.renderToString(errorComponent);
        document.getElementById(id).value = "";
        setAllowNextAfterUploadDocuments(true);
      }
    } else {
      const errorComponent = (
        <>
          <ErrorOutlineIcon style={{ color: "var(--red)" }} />
          &nbsp;
          <span>{`يرجى اختيار الملف بحجم مساوي ل ${
            AttachmentFileSizeLimit
              ? AttachmentFileSizeLimit / 1000000
              : 10000000 / 1000000
          }MB أو أقل`}</span>
        </>
      );
      item.innerHTML = ReactDOMServer.renderToString(errorComponent);

      document.getElementById(id).value = "";
      setAllowNextAfterUploadDocuments(true);
    }
    setUploadFileLoading(false);
  };
  const saveUploadedFiles = async (event, imageSelectorId) => {
    let oldArr = [];
    documentLinks?.map((item) => {
      if (item?.FieldId != Guid) oldArr.push(item);
    });
    setDocumentLinks(oldArr);
    const item = document.getElementById(imageSelectorId);
    // FIRST: check files count
    // if (Array.from(event?.target?.files).length <= OtherAttachmentsLimit) {
    if (Array.from(event?.target?.files).length <= 10) {
      // SECOND: check file validation (SIZE)
      let filesSizeStatus = true;
      Array.from(event?.target?.files)?.map((file) => {
        const fileSizeStatus = CheckFileSizeValidation(
          file,
          AttachmentFileSizeLimit
        );
        if (!fileSizeStatus) filesSizeStatus = false;
      });
      if (filesSizeStatus) {
        //THIRD: check file validation (TYPE)
        let filesTypesStatus = true;
        Array.from(event?.target?.files)?.map((file) => {
          const fileTypeStatus = CheckFileTypeValidationInDynamicForm(
            file,
            AttachmentFileAllowedFormats
          );
          if (!fileTypeStatus) filesTypesStatus = false;
        });

        if (filesTypesStatus) {
          if (event?.target?.value?.length > 0) {
            setUploadFilesLoading(true);
            let responseLink = {};
            const formData = new FormData();
            Array.from(event?.target?.files).forEach((file) => {
              formData.append("files", file);
            });
            item.innerHTML = `<div className="picklist-loader">
                <div class="spinner-border spinner-border-sm text-success" role="status">
              </div>      
              &nbsp;
              <span className="loader-text">يرجى الانتظار يتم رفع الملفات</span>
              </div>`;
            const response = await saveFiles(formData, valueRef.current);
            if (response?.status === 200) {
              response?.data?.map((singleLink) => {
                let responseLink = {
                  Value: singleLink,
                  FieldId: Guid,
                };
                setDocumentLinks((current) => [...current, responseLink]);
              });

              setAllowNextAfterUploadDocuments(false);
              if (event?.target?.files?.length > 1)
                item.innerHTML =
                  "تم رفع " + event?.target?.files?.length + " ملفات  بنجاح";
              else item.innerHTML = "تم رفع الملف بنجاح";
            } else {
              document.getElementById(id).value = "";
              if (Placeholder) item.innerHTML = { Placeholder };
              else item.innerHTML = "اضغط لاختيار الملفات";
              setAllowNextAfterUploadDocuments(true);
              errorToaster("حدث خطأ أثناء رفع الملفات ... حاول مرة أخرى");
            }
          } else {
            setAllowNextAfterUploadDocuments(true);
            item.innerHTML = "اضغط لاختيار الملفات";
          }
        } else {
          const errorComponent = (
            <>
              <ErrorOutlineIcon style={{ color: "var(--red)" }} />
              &nbsp;
              <span>
                {`يرجى اختيار الملفات بأحد الصيغ التالية: ${
                  AttachmentFileAllowedFormats?.length > 0
                    ? AttachmentFileAllowedFormats
                    : defaultAttachmentFileAllowedFormats
                }`}
              </span>
            </>
          );
          item.innerHTML = ReactDOMServer.renderToString(errorComponent);
          document.getElementById(id).value = "";
          setAllowNextAfterUploadDocuments(true);
        }
      } else {
        const errorComponent = (
          <>
            <ErrorOutlineIcon style={{ color: "var(--red)" }} />
            &nbsp;
            <span>{`يرجى اختيار كل ملف بحجم مساوي ل ${
              AttachmentFileSizeLimit
                ? AttachmentFileSizeLimit / 1000000
                : 10000000 / 1000000
            }MB أو أقل`}</span>
          </>
        );
        item.innerHTML = ReactDOMServer.renderToString(errorComponent);
        document.getElementById(id).value = "";
        setAllowNextAfterUploadDocuments(true);
      }
    } else {
      const errorComponent = (
        <>
          <ErrorOutlineIcon style={{ color: "var(--red)" }} />
          &nbsp;
          {/* {`لا يمكنك رفع أكثر من ${OtherAttachmentsLimit}`} */}
          <span>
            {`لا يمكنك رفع أكثر من ${10}`}
            &nbsp;
            {10 > 1 && 10 < 11 ? "ملفات" : "ملف"}
          </span>
        </>
      );
      item.innerHTML = ReactDOMServer.renderToString(errorComponent);
      document.getElementById(id).value = "";
      setAllowNextAfterUploadDocuments(true);
    }

    setUploadFilesLoading(false);
  };
  useEffect(() => {
    if (DefaultValue) {
      let oldFile = {};
      oldFile["Value"] = DefaultValue;
      oldFile["FieldId"] = Guid;
      setDocumentLink((current) => [...current, oldFile]);
      setAllowNextAfterUploadDocuments(false);
    }
    if (MultipleValues) {
      MultipleValues?.map((document) => {
        let oldFile = {
          Value: document,
          FieldId: Guid,
        };
        setDocumentLinks((current) => [...current, oldFile]);
      });
      setAllowNextAfterUploadDocuments(false);
    }
  }, []);
  return FieldType == "Attachment" ? (
    <>
      <FormGroup id="input-group" className="input-group">
        <Form.Label
          className="form-control uploader-placeholder"
          htmlFor={id}
          id={`single-file-uploader-${id}`}
          role="button"
        >
          <AttachFileOutlinedIcon />| &nbsp;
          {Placeholder ? Placeholder : "اضفط لاختيار الملف"}
        </Form.Label>
        <Form.Control
          id={id}
          name={Name}
          type="file"
          label="test"
          accept=".pdf, image/*"
          required={DefaultValue ? false : Mandatory}
          // defaultValue={DefaultValue}
          className="p-2 mb-0 rounded-0 form-control"
          placeholder={"Placeholder"}
          style={{ display: "none" }}
          onChange={(event) => {
            checkConditionsChange(Guid);
            checkClickAble(data);
            checkFieldCorrection(id);
            saveUploadedFile(event, `single-file-uploader-${id}`);
          }}
          onBlur={() => checkFieldCorrection(id)}
        />
      </FormGroup>
      {documentLink?.length > 0 && (
        <div className="files-preview">
          {documentLink?.map((file) => (
            <div
              className={
                isMobileView
                  ? "col-6"
                  : isTabletView
                  ? columnSize == "12"
                    ? "col-4"
                    : "col-6"
                  : isIPadView
                  ? columnSize == "12"
                    ? "col-4"
                    : "col-6"
                  : columnSize == "12"
                  ? "col-3"
                  : "col-4"
              }
              style={{ paddingLeft: "16px" }}
            >
              <PreviewFile
                data={data}
                id={id}
                Guid={Guid}
                mandatory={Mandatory}
                type="single"
                fileType={file.Value?.substring(
                  file?.Value.lastIndexOf(".") + 1
                )}
                filePath={`${environment.IMAGE_URL}/${file.Value}`}
                fileValue={file?.Value}
                fileGuid={file?.FieldId}
                documentLink={documentLink}
                setDocumentLink={setDocumentLink}
                setAllowNextAfterUploadDocuments={
                  setAllowNextAfterUploadDocuments
                }
                checkFieldCorrection={checkFieldCorrection}
                checkConditionsChange={checkConditionsChange}
                checkClickAble={checkClickAble}
              />
            </div>
          ))}
        </div>
      )}
      {isError && <div className="error-msg">{errorMessage}</div>}
    </>
  ) : (
    <>
      <FormGroup id="input-group" className="input-group">
        <Form.Label
          className="form-control uploader-placeholder"
          htmlFor={id}
          id={`files-uploader-${id}`}
        >
          <AttachFileOutlinedIcon />| &nbsp;
          {Placeholder ? Placeholder : "اضغط لاختيار الملفات"}
        </Form.Label>
        <Form.Control
          id={id}
          name={Name}
          type="file"
          // required={Mandatory}
          required={MultipleValues ? false : Mandatory}
          className="p-2 mb-0 rounded-0 form-control"
          placeholder={Placeholder}
          multiple
          style={{ display: "none" }}
          onChange={(event) => {
            checkConditionsChange(Guid);
            // checkFilesValidation(event.target.files, OtherAttachmentsLimit);
            checkClickAble(data);
            checkFieldCorrection(id);
            saveUploadedFiles(event, `files-uploader-${id}`);
          }}
          onBlur={() => checkFieldCorrection(id)}
        />
      </FormGroup>
      {documentLinks?.length > 0 && (
        <div className="files-preview row">
          {documentLinks?.map((file) => (
            <div
              className={
                isMobileView
                  ? "col-6"
                  : isTabletView
                  ? columnSize == "12"
                    ? "col-4"
                    : "col-6"
                  : isIPadView
                  ? columnSize == "12"
                    ? "col-4"
                    : "col-6"
                  : columnSize == "12"
                  ? "col-3"
                  : "col-4"
              }
              // className={
              //   isIPadView ? "col-6" : columnSize == "12" ? "col-3" : "col-4"
              // }
            >
              <PreviewFile
                data={data}
                id={id}
                Guid={Guid}
                mandatory={Mandatory}
                type="multiple"
                fileType={file.Value?.substring(
                  file?.Value.lastIndexOf(".") + 1
                )}
                filePath={`${environment.IMAGE_URL}/${file?.Value}`}
                fileValue={file?.Value}
                fileGuid={file?.FieldId}
                documentLinks={documentLinks}
                setDocumentLinks={setDocumentLinks}
                setAllowNextAfterUploadDocuments={
                  setAllowNextAfterUploadDocuments
                }
                checkFieldCorrection={checkFieldCorrection}
                checkConditionsChange={checkConditionsChange}
                checkClickAble={checkClickAble}
              />
            </div>
          ))}
        </div>
      )}
      {isError && <div className="error-msg">{errorMessage}</div>}
    </>
  );
};

export default FilesUploader;

import styled from "styled-components";
export const Styles = styled.div`
  box-shadow: 0px 6px 18px 0px #0000001a;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  &:hover {
    transform: translateY(-3px);
    transition: 0.3s;
  }
  .card-image {
    position: relative;
    height: 200px;
    object-fit: cover;
    z-index: 1;
    margin: 0.4rem;
    margin-bottom: 0rem;
    img {
      height: 200px;
      width: 100%;
      background-color: white;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      object-fit: cover;
    }
    .title {
      position: absolute;
      height: 200px;
      width: 100%;
      top: 0px;
      z-index: 2;
      font-weight: 700;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.1);
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      span {
        font-size: 1rem;
      }
    }
    .type {
      position: absolute;
      height: 200px;
      top: 0px;
    }
  }
  .card-data {
    .row {
      margin: 0 0.6rem;
    }
    .text-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      /* min-height: 55px; */
      font-weight: 700;
    }
    .icon {
      background-color: var(--green);
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 50px;
      border: 1px solid var(--green);
      /* padding: 1rem 0; */
    }
    #calender-icon,
    #calender-content {
      padding-top: 1.2rem;
    }

    #calender {
      /* height: 65px; */
    }
    /* #calender {
      display: flex;
      justify-content: center;
      align-items: end;
    }
    #location {
      display: flex;
      justify-content: center;
      align-items: center;
    } */
  }
`;

import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import attachmentIcon from "../../../assets/images/icons/attachmentIcon.svg";
import classes from "../textField/textfield.module.css";
import { useLocation } from "react-router-dom";
import { CheckFileTypeValidation } from "../../../helpers/checkFileValidation";
import { allowedFilesExtensions } from "../../../constants/enums";
import pdfIcon from "../../../assets/images/pdf-icon.png";
import fileIcon from "../../../assets/images/file-icon.png";
import environment from "../../../environment";
const CustomAttachment = ({
  width,
  height,
  isRequired,
  showError,
  label,
  value,
  setValue,
  setImageName,
  showImage = false,
}) => {
  const [previewImage, setPreviewImage] = useState(null);
  const [typeError, setTypeError] = useState(false);
  const [imageDeleted, setImageDeleted] = useState(true);
  const [isPDF, setIsPDF] = useState();
  const location = useLocation();
  const path = location.pathname;

  const imageRef = useRef(null);

  const getSrc = (fileType, renderImage) => {
    let imageToRender = "";
    if (fileType === "png") {
      setPreviewImage(renderImage);
      setIsPDF(false);
    } else if (fileType === "jpg") {
      setPreviewImage(renderImage);
      setIsPDF(false);
      setIsPDF(false);
    } else if (fileType === "jpeg") {
      setPreviewImage(renderImage);
      setIsPDF(false);
    } else if (fileType === "pdf") {
      setPreviewImage(pdfIcon);
      setIsPDF(true);
    }
    // else {
    //   setPreviewImage(fileIcon);
    //   // imageToRender = fileIcon;
    // }
    return imageToRender;
  };

  useEffect(() => {
    if (path === "/dashboard/settings" || showImage) {
      // setPreviewImage(value);
      getSrc(value?.substring(value.lastIndexOf(".") + 1), value);
      if (value) {
        setImageDeleted(false);
      }
    }
  }, []);

  return (
    <label htmlFor={label} style={{ cursor: "pointer", zIndex: 98 }}>
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        spacing={4}
        sx={{
          width: width ? width : "276px",
          height: height ? height : "236px",
          flexShrink: 0,
          borderRadius: "12px",
          background: "linear-gradient(180deg, #F5FAFF 0%, #E2EEFA 100%)",
          border: showError || typeError ? "1px solid red" : "none",
          margin: "0 auto",
        }}
      >
        {previewImage ? (
          <Box
            sx={{
              position: "relative",
              width: "150px",
              height: "150px",
              zIndex: 99,
            }}
          >
            <a
              href={
                value?.substring(value.lastIndexOf(".") + 1) === "pdf" && value
              }
              download
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <img
                // src={previewImage}
                src={previewImage}
                alt="Uploaded"
                style={{
                  position: "absolute",
                  top: isPDF ? "5%" : "0%",
                  left: isPDF ? "5%" : "0%",
                  width: isPDF ? "90%" : "100%",
                  height: isPDF ? "90%" : "100%",

                  objectFit: "cover",
                }}
              />
            </a>
            <Typography
              sx={{
                cursor: "pointer",
                position: "absolute",
                top: 0,
                right: "-10%",
                borderRadius: "50%",
                backgroundColor: "#000",
                color: "#fff",
                padding: " 0 0.4rem",
              }}
              onClick={() => {
                setValue(null);
                setPreviewImage(null);
                setImageDeleted(true);
                if (imageRef.current) {
                  imageRef.current.value = "";
                }
              }}
            >
              X
            </Typography>
          </Box>
        ) : (
          <Box>
            <img src={attachmentIcon} alt="attachment Icon" />
          </Box>
        )}

        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{
            marginTop: "0 !important",
          }}
        >
          {imageDeleted && (
            <input
              ref={imageRef}
              id={label}
              accept="image/*"
              type="file"
              style={{ display: "none" }}
              onChange={(event) => {
                if (
                  CheckFileTypeValidation(
                    event.target.files[0],
                    allowedFilesExtensions
                  )
                ) {
                  setTypeError(false);
                  setImageDeleted(false);
                  const file = event.target.files[0];
                  const reader = new FileReader();
                  reader.onloadend = () => {
                    const base64Data = reader.result.replace(
                      /^data:image\/(png|jpeg|jpg);base64,/,
                      ""
                    );

                    getSrc(
                      file.name?.substring(file.name.lastIndexOf(".") + 1),
                      reader.result
                    );

                    setValue(base64Data);
                    setImageName(file.name);
                  };
                  if (file) {
                    reader.readAsDataURL(file); // Read the image file as Data URL
                  }
                } else setTypeError(true);
                // setValue(event.target.files[0]);
              }}
            />
          )}

          <label
            style={{ cursor: "pointer", textAlign: "center" }}
            className={`${classes.inputLabel} ${
              isRequired ? classes.requiredField : ""
            } ${showError || typeError ? classes.errorClass : ""}`}
          >
            <Typography
              variant="p"
              sx={{
                color: showError || typeError ? "red" : "",
              }}
            >
              {label}
            </Typography>
          </label>
        </Stack>
      </Stack>
    </label>
  );
};

export default CustomAttachment;

import styled from "styled-components";
export const Styles = styled.div`
  .section-title {
    position: relative;
    /* min-height: 75px; */
    padding-bottom: 2rem;
    button {
      position: absolute;
      left: 0;
    }
  }
  #draft-button {
    background: transparent !important;
  }
  #draft-button::before {
    content: "";
    position: absolute;
    top: 80%;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--green); /* Replace with your desired color */
    transform: scaleX(0);
    transition: transform 0.3s cubic-bezier(0.2, 0.8, 0.2, 1); /* Add transition property for smooth animation */
  }

  #draft-button:hover::before {
    transform: scaleX(1); /* Expand the line on hover */
  }
`;

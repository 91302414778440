import { Stack } from "@mui/material";
import React from "react";
import CircleIcon from "@mui/icons-material/Circle";
import { Styles } from "./styles";
import ServiceImage from "../../../assets/images/pic2.jpg";
const ServiceStandards = () => {
  const standards = [
    "التحقيق في جميع شكاوى المستهلكين",
    "تقديم المشورة لأصحاب الأعمال التجارية.",
    "التعاون مع الإدارات الأخرى لتحقيق أهدافنا",
    "تنفيذ برنامج تفتيش دوري للشركات التجارية بناءً على تقييم المخاطر.",
    "تقديم المشورة والمساعدة فيما يتعلق باستفسارات المستهلكين حيثما أمكن ذلك",
  ];
  return (
    <Styles image={ServiceImage}>
      <section className="stansards-section page-container">
        <div className="title">
          <Stack alignItems={"start"}>
            <div className="icons">
              <CircleIcon
                fontSize="small"
                style={{
                  color: "var(--green)",
                }}
              />
              <CircleIcon
                fontSize="small"
                style={{
                  color: "var(--green)",
                }}
              />
              &nbsp;
              <h2>معايير الخدمة</h2>
            </div>
            <h5>نحن نلتزم بالقيام بالخطوات التالية:</h5>
          </Stack>
        </div>
        <div className="content row">
          {standards?.map((item, index) => (
            <div
              className={
                index === 3 || index === 4
                  ? "col-xl-6 col-lg-5 col-md-6 col-sm-12 service-card"
                  : "col-lg-4 col-md-4 col-sm-12 service-card"
              }
              key={index}
            >
              <p>{item}</p>
            </div>
          ))}
        </div>
      </section>
    </Styles>
  );
};

export default ServiceStandards;

import React from "react";
import { TextField, InputLabel /* InputAdornment */ } from "@mui/material";
import classes from "./textfield.module.css";

const CustomTextField = ({
  label,
  value,
  setValue,
  isRequired,
  type = "text",
  showError,
  inDialog = false,
  inReportsForms = false,
  multiline = false,
  rows = 1,
}) => {
  return (
    <div
      // className={classes.root}
      className={`
        ${classes.root} 
        ${inDialog ? classes.responsiveView : ""} 
        `}
    >
      <InputLabel
        className={`
        ${classes.inputLabel} 
        ${isRequired ? classes.requiredField : ""} 
        ${showError ? classes.errorClass : ""}
        `}
        sx={{
          minWidth: type === "email" ? "170px !important" : "",
          // width: inReportsForms && "50% !important",
        }}
      >
        {label}
      </InputLabel>
      <TextField
        multiline={multiline}
        rows={rows}
        type={type}
        value={value}
        required={isRequired}
        onChange={(e) => setValue(e.target.value)}
        variant="outlined"
        helperText={
          type === "email" && showError
            ? "صيغة البريد الالكتروني غير صحيحة"
            : ""
        }
        sx={{
          "&.MuiFormControl-root": {
            width: { xs: "100%", sm: "60%", md: "60%" },
            "& .MuiFormHelperText-root": {
              color: "red",
              textAlign: "right",
            },
          },
          "& .MuiInputBase-input": {
            background: "rgb(237, 239, 240)",
            padding: "0.7rem",
            borderRadius: "12px",
            border: showError ? "1px solid red" : "none",
            outline: "none",
          },
          "& .MuiOutlinedInput-root": {
            border: "none",
            "&:hover, &:focus": {
              border: "none",
            },
            "&.Mui-focused": {
              border: "none",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          },
        }}
      />
    </div>
  );
};

export default CustomTextField;

import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import CustomAttachment from "../../../components/elements/attachment";
import { sendStepCertificate } from "../../../services/profile/profile.services";
import useStateRef from "react-usestateref";
import { getAccessTokenCookie } from "../../../utils/cookie";
import {
  errorToaster,
  successToaster,
} from "../../../helpers/toasterConfiguration";
import { filesValidation } from "./AttachmentValidation";
import CustomTextField from "../../../components/elements/textField";
const Styles = styled.div`
  .dialog-button {
    border-radius: "10px";
    /* background: linear-gradient(180deg, var(--first-linear-green) 0%, var(--second-linear-green) 100%); */
    color: #f8f9fa;
    text-align: center;
    font-size: "1rem";
    font-weight: 600;
    transition: all 0.2s ease-out;
    border: 1px solid var(--second-linear-green);
    color: var(--second-linear-green);
  }
  .dialog-button:hover {
    border-radius: "10px";
    border: 1px solid #f8f9fa;
    background: #fff;
    color: var(--second-linear-green) !important;

    transition: all 0.2s ease-out;
  }
  .card-container {
    width: 100%;
  }
  .message {
    font-size: 1.9rem;
    /* display: flex;
    justify-content: center; */
  }
`;
const DialogStyles = styled.div`
  .card-container {
    width: 100%;
    .message {
      font-size: 1.4rem;
      margin-bottom: 1rem !important;
    }

    .file-uploader {
      display: flex;
      justify-content: center;
    }
  }
  .button-container {
    display: flex;

    justify-content: center;
    .confirm-button {
      border-radius: "10px";
      background: linear-gradient(
        180deg,
        var(--first-linear-green) 0%,
        var(--second-linear-green) 100%
      );
      text-align: center;
      font-size: "1rem";
      font-weight: 600;
      transition: all 0.5s ease-out;
      border: 1px solid var(--first-linear-green);
      color: #fff;
      margin-left: 0.5rem;
      &:hover {
        border-radius: "10px";
        border: 1px solid var(--second-linear-green);
        background: #fff;
        color: var(--second-linear-green) !important;
        transition: all 0.5s ease-out;
      }
    }
    .cancel-button {
      border-radius: "10px";
      text-align: center;
      font-size: "1rem";
      font-weight: 600;
      transition: all 0.5s ease-out;
      background: var(--grey);
      border: 1px solid var(--grey);
      color: #fff;
      margin-left: 1rem;
      &:hover {
        border-radius: "10px";
        border: 1px solid var(--grey);
        background: #fff;
        color: var(--grey) !important;
        transition: all 0.5s ease-out;
      }
    }
  }
`;
function SimpleDialog(props) {
  const {
    onClose,
    open,
    Id,
    CurrentStepId,
    ProcessId,
    refreshData,
    setRefreshData,
  } = props;
  const [value, setValue, valueRef] = useStateRef(false);
  // first file
  const [owner, setOwner] = useState(null);
  const [ownerError, setOwnerError] = useState(false);
  // second file
  const [engineNumber, setEngineNumber] = useState(null);
  const [engineNumberError, setEngineNumberError] = useState(false);
  // third file
  const [carRegisterNumber, setCarRegisterNumber] = useState(null);
  const [carRegisterNumberError, setCarRegisterNumberError] = useState(false);
  // fourth file
  const [backSideImage, setBackSideImage] = useState(null);
  const [backSideImageName, setBackSideImageName] = useState(null);
  const [backSideImageError, setBackSideImageError] = useState(false);
  // fivth file
  const [frontSideImage, setFrontSideImage] = useState(null);
  const [frontSideImageName, setFrontSideImageName] = useState(null);
  const [frontSideImageError, setFrontSideImageError] = useState(false);

  const [signedCertificateFront, setSignedCertificateFront] = useState(null);
  const [signedCertificateFrontName, setSignedCertificateFrontName] =
    useState(null);
  const [signedCertificateFrontError, setSignedCertificateFrontError] =
    useState(false);

  const [signedCertificateBack, setSignedCertificateBack] = useState(null);
  const [signedCertificateBackName, setSignedCertificateBackName] =
    useState(null);
  const [signedCertificateBackError, setSignedCertificateBackError] =
    useState(false);

  const [isSubmit, setIsSubmit] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const isSubmiteCheck = () => {
    if (
      ownerError ||
      engineNumberError ||
      carRegisterNumberError ||
      backSideImageError ||
      frontSideImageError ||
      signedCertificateFrontError ||
      signedCertificateBackError ||
      owner === null ||
      owner === "" ||
      engineNumber === null ||
      engineNumber === "" ||
      carRegisterNumber === null ||
      carRegisterNumber === "" ||
      backSideImage === null ||
      backSideImage === "" ||
      frontSideImage === null ||
      frontSideImage === "" ||
      signedCertificateBack === "" ||
      signedCertificateBack == null ||
      signedCertificateFront === "" ||
      signedCertificateFront === null
    )
      setIsSubmit(false);
    else setIsSubmit(true);
  };
  const handleClose = () => {
    onClose();
  };
  const handleSubmit = async () => {
    if (ProcessId == 14) {
      isSubmiteCheck();
      filesValidation(
        owner,
        setOwnerError,
        engineNumber,
        setEngineNumberError,
        carRegisterNumber,
        setCarRegisterNumberError,
        backSideImage,
        setBackSideImageError,
        frontSideImage,
        setFrontSideImageError,
        signedCertificateFront,
        setSignedCertificateFrontError,
        signedCertificateBack,
        setSignedCertificateBackError
      );
      if (isSubmit) {
        setSubmitLoading(true);
        const requestBody = {
          Id: Id,
          CurrentStepId: CurrentStepId,
          Owner: owner,
          EngineNumber: engineNumber,
          CarRegistrationNumber: carRegisterNumber,
          BackVehicleLicenseFile: backSideImage,
          BackVehicleLicenseFileName: backSideImageName,
          FrontVehicleLicenseFile: frontSideImage,
          FrontVehicleLicenseFileName: frontSideImageName,
          SignedCertificateFront: signedCertificateFront,
          SignedCertificateFrontName: signedCertificateFrontName,
          SignedCertificateBack: signedCertificateBack,
          SignedCertificateBackName: signedCertificateBackName,
        };
        const response = await sendStepCertificate(
          requestBody,
          valueRef.current
        );
        if (response?.status === 200) {
          successToaster("تم تأكيد الطلب بنجاح");
          setRefreshData(!refreshData);
          handleClose();
        } else errorToaster("عذرا لم تتم العملية");
        setSubmitLoading(false);
      }
    } else {
      setSubmitLoading(true);
      const requestBody = {
        Id: Id,
        CurrentStepId: CurrentStepId,
        // File: signaturedCertificate,
        // FileName: signaturedCertificateName,
      };

      const response = await sendStepCertificate(requestBody, valueRef.current);
      if (response?.status === 200) {
        successToaster("تم تأكيد الطلب بنجاح");
        setRefreshData(!refreshData);
        handleClose();
      } else errorToaster("عذرا لم تتم العملية");
      setSubmitLoading(false);
    }
  };
  useEffect(() => {
    setValue(getAccessTokenCookie());
  }, []);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      // fullWidth={true}
      fullScreen={ProcessId == 14 && fullScreen}
      scroll={"body"}
      maxWidth="lg"
      sx={{
        "& .MuiPaper-root": {
          overflowY: "visible",
          padding: "2rem",
          borderRadius: "17px",
          direction: "rtl",
          textAlign: "start",
        },
      }}
    >
      <DialogStyles>
        <DialogContent
          sx={{
            overflowY: "unset",
          }}
        >
          <div className="card-container">
            {ProcessId == 14 ? (
              <div>
                <p className="message text-center">
                  لاتمام الطلب يرجى رفع الوثائق التالية:
                </p>
                <Row className="mt-4">
                  <Col sm="4" md="6" lg="6" className="p-2">
                    <CustomTextField
                      label="اسم المالك"
                      value={owner}
                      setValue={setOwner}
                      isRequired={true}
                      showError={ownerError}
                      inDialog={true}
                    />
                  </Col>
                  <Col sm="4" md="6" lg="6" className="p-2">
                    <CustomTextField
                      label="رقم المحرك"
                      value={engineNumber}
                      setValue={setEngineNumber}
                      isRequired={true}
                      showError={engineNumberError}
                      inDialog={true}
                    />
                  </Col>
                  <Col sm="4" md="6" lg="6" className="p-2">
                    <CustomTextField
                      label="رقم تسجيل السيارة"
                      value={carRegisterNumber}
                      setValue={setCarRegisterNumber}
                      isRequired={true}
                      showError={carRegisterNumberError}
                      inDialog={true}
                    />
                  </Col>
                </Row>
                <div className="file-uploader mt-4">
                  <Row className="justify-content-center w-100">
                    <Col
                      sm="4"
                      md="6"
                      lg="4"
                      className="d-flex justify-content-center p-2"
                    >
                      <CustomAttachment
                        // width="150px"
                        height="180px"
                        label="صورة الوجه الخلفي للرخصة"
                        value={backSideImage}
                        setValue={setBackSideImage}
                        isRequired={true}
                        showError={backSideImageError}
                        setImageName={setBackSideImageName}
                      />
                    </Col>
                    <Col
                      sm="4"
                      md="6"
                      lg="4"
                      className="d-flex justify-content-center p-2"
                    >
                      <CustomAttachment
                        // width="150px"
                        height="180px"
                        label="صورة الوجه الأمامي للرخصة"
                        value={frontSideImage}
                        setValue={setFrontSideImage}
                        isRequired={true}
                        showError={frontSideImageError}
                        setImageName={setFrontSideImageName}
                      />
                    </Col>
                    <Col
                      sm="4"
                      md="6"
                      lg="4"
                      className="d-flex justify-content-center p-2"
                    >
                      <CustomAttachment
                        // width="150px"
                        height="180px"
                        label="صورة الوجه الأمامي للشهادة المصدقة"
                        value={signedCertificateFront}
                        setValue={setSignedCertificateFront}
                        isRequired={true}
                        showError={signedCertificateFrontError}
                        setImageName={setSignedCertificateFrontName}
                      />
                    </Col>
                    <Col
                      sm="4"
                      md="6"
                      lg="4"
                      className="d-flex justify-content-center p-2"
                    >
                      <CustomAttachment
                        // width="150px"
                        height="180px"
                        label="صورة الوجه الخلفي للشهادة المصدقة"
                        value={signedCertificateBack}
                        setValue={setSignedCertificateBack}
                        isRequired={true}
                        showError={signedCertificateBackError}
                        setImageName={setSignedCertificateBackName}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            ) : (
              <div>
                <p className="message">
                  لاتمام الطلب يرجى التأكيد عند الانتهاء من القيام بالتصليحات.
                </p>
              </div>
            )}
          </div>
        </DialogContent>
        <div className="button-container">
          <Button
            className="confirm-button"
            onClick={handleSubmit}
            autoFocus
            disabled={submitLoading}
            color="error"
          >
            {submitLoading ? (
              <span className="indicator-progress">
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                &nbsp; يتم الحفظ
              </span>
            ) : (
              "تأكيد"
            )}
          </Button>
          <Button
            className="cancel-button"
            variant="outlined"
            color="error"
            disabled={submitLoading}
            onClick={handleClose}
          >
            إلغاء
          </Button>
        </div>
      </DialogStyles>
    </Dialog>
  );
}
const ConfirmDialog = ({
  Id,
  CurrentStepId,
  ProcessId,
  setPage,
  refreshData,
  setRefreshData,
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <Styles>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        className="dialog-button"
      >
        تأكيد الطلب
      </Button>
      <SimpleDialog
        open={open}
        onClose={handleClose}
        Id={Id}
        CurrentStepId={CurrentStepId}
        ProcessId={ProcessId}
        refreshData={refreshData}
        setRefreshData={setRefreshData}
      />
    </Styles>
  );
};

export default ConfirmDialog;

import React, { useEffect } from "react";
import { Styles } from "./styles";
import Services from "../../components/home/services-section/Services";
import O1HeroSection from "../../components/option-1-components/heroSection";
import Compaints from "../../components/section2";
import { successToaster } from "../../helpers/toasterConfiguration";
import { typesOfProjects } from "../../constants/enums";
import FEDHeroSection from "../../components/FED/home-page/heroSection";
import HomeExhibitions from "../../components/FED/home-exhibitions";
import AddExhibitionSection from "../../components/FED/add-exhibition";

const HomeProvider = () => {
  const activeEnv = process.env.REACT_APP_ACTIVE_ENV;
  const mainColor = process.env.REACT_APP_MAIN_COLOR || "#000000"; // default color if not set
  // code for moving view port to hash link
  const hashLink = window.location.hash;
  useEffect(() => {
    if (hashLink) {
      window.location.hash = hashLink;
    }
  }, [hashLink]);
  return (
    <Styles>
      {activeEnv == typesOfProjects.CPD && <O1HeroSection />}
      {activeEnv == typesOfProjects.FED && <FEDHeroSection />}
      {activeEnv == typesOfProjects.CPD && <Compaints />}
      {activeEnv == typesOfProjects.CPD && <Services />}
      {activeEnv == typesOfProjects.FED && <HomeExhibitions />}
      {activeEnv == typesOfProjects.FED && <AddExhibitionSection />}
    </Styles>
  );
};

export default HomeProvider;

import { Box, Button, Stack, Typography } from "@mui/material";
import classes from "./cards3.module.css";
import CircleIcon from "@mui/icons-material/Circle";
import PopupDialog from "./popup-dialog";
import { Link } from "react-router-dom";

const Cards3 = (props) => {
  return (
    <div
      style={{
        maxWidth: 250,
        minHeight: 250,
        maxHeight: 250,
        margin: "0 auto",
      }}
      className={`${classes.cardContainer}`}
    >
      <Stack className={`${classes.face} `} alignItems={"center"}>
        <div className={classes.card3Image}>
          <img
            src={props.data.Tags[0]?.Value}
            alt="sector icon"
            width={"50px"}
            height={"50px"}
          />
        </div>
        <Typography
          gutterBottom
          variant="h6"
          sx={{ paddingTop: "0.5rem", textAlign: "center" }}
        >
          {props.data.Name}
        </Typography>
        <Box sx={{ padding: "0 1rem" }}>
          {props.data.SubSectors.slice(0, 2).map((item, index) => (
            <Link
              style={{ textDecoration: "none" }}
              to={`/dynamic-form?dynamicFormId=20&processId=32&Sector=${props.data.Id}&SubSector=${item.Id}`}
              key={index}
            >
              <Typography
                variant="body2"
                color="text.secondary"
                fontWeight={400}
                fontSize={"1.2rem"}
                className={classes.cursorEffect}
                sx={{ margin: "0 auto" }}
              >
                <span style={{ marginLeft: "0.3rem" }}>
                  <CircleIcon
                    style={{ color: "#006838", fontSize: "0.5rem" }}
                  />
                </span>
                {item.Name}
              </Typography>
            </Link>
          ))}
        </Box>
        {props.data.SubSectors.length > 2 && <PopupDialog item={props.data} />}
      </Stack>
    </div>
  );
};

export default Cards3;

import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import classes from "../textField/textfield.module.css";
import { Skeleton } from "@mui/material";
import { useMediaQuery } from "react-responsive";

const CustomSelect = ({
  label,
  value,
  setValue,
  isRequired,
  showError,
  options = [{ Id: 1, Name: "option1" }],
  isLoading = false,
}) => {
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const isTabletView = useMediaQuery({ query: "(max-width: 768px)" });
  const isMobileView = useMediaQuery({ query: "(max-width: 550px)" });

  return (
    <div className={classes.root}>
      {label && (
        <InputLabel
          className={`${classes.inputLabel} ${
            isRequired ? classes.requiredField : ""
          }  ${showError ? classes.errorClass : ""}`}
        >
          {label}
        </InputLabel>
      )}
      {isLoading ? (
        <Skeleton sx={{ width: "60%", height: "2.8rem" }} />
      ) : (
        <Select
          value={value}
          onChange={handleChange}
          sx={{
            borderRadius: "12px",
            "&.MuiInputBase-root": {
              width: isMobileView ? "100%" : isTabletView ? "60%" : "60%",
            },
            "& .MuiSelect-select": {
              background: "rgb(237, 239, 240)",
              padding: "0.7rem",
              borderRadius: "12px",
              // "&:hover": {
              //   backgroundColor: "#67d57d",
              // },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: showError ? "1px solid red" : "none",
              outline: "none",
            },
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                "& .MuiMenuItem-root.Mui-selected": {
                  backgroundColor: "var(--second-linear-green)",
                },
                "& .MuiMenuItem-root:hover": {
                  backgroundColor: "var(--first-linear-green)",
                },
                "& .MuiMenuItem-root.Mui-selected:hover": {
                  backgroundColor: "var(--med-linear-green)",
                },
              },
            },
          }}
        >
          {/* <MenuItem value={null} disabled>
            يرجى الاختيار
          </MenuItem> */}
          {options?.map((item) => (
            <MenuItem value={item.Id} key={item.Id}>
              {item.Name}
            </MenuItem>
          ))}
        </Select>
      )}
    </div>
  );
};

export default CustomSelect;

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import classes from "./style.module.css";

import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { ConfirmNewPassword } from "../../constants/endpoints";
import {
  errorToaster,
  successToaster,
} from "../../helpers/toasterConfiguration";
import apiKey from "../../constants/apiKey";
import { passwordRegex } from "../../constants/types";
import encryptMessage from "../../utils/encryptMessage";
import environment from "../../environment";
import { CircularProgress, TextField } from "@mui/material";
import { useState } from "react";

export default function ResetPassDialog({ open, setOpen, userEmail }) {
  const [fireValidation, setFireValidation] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }
    setOpen(false);
  };

  const validationSchema = Yup.object().shape({
    // email: Yup.string()
    //   .email("صيغة البريد الالكتروني غير صحيحة")
    //   .required("يرجى ادخال البريد الالكتروني"),
    code: Yup.string().required("يرجى إدخال رمز التحقق"),
    password: Yup.string()
      .min(8, "كلمة المرور يجب أن تكون على الاقل 8 محارف")
      .matches(
        passwordRegex,
        "يجب أن تحتوي كلمة المرور على الأقل حرف كبير، وحرف صغير، ورقم واحد، وأحد الرموز الخاصة (@، #، $، %، ^، &، +، =)"
      )
      // .matches(/[A-Z]/, "كلمة المرور يجب أن تحوى على الاقل محرف كبير")
      // .matches(/[0-9]/, "كلمة المرور يجب أن تحوي على الأقل رقم واحد")
      // .matches(
      //   /[!@#$%^&*(),.?":{}|<>]/,
      //   "كلمة المرور يجب أن تحوي على الأقل محرف مميز واحد"
      // )
      .required("يرجى ادخال كلمة المرور"),
  });

  const initialValues = {
    email: "",
    code: "",
    password: "",
  };

  const handleSubmit = (values) => {
    setLoading(true);
    document.body.style.cursor = "wait";
    let encryptPass = encryptMessage(values.password, environment.PUBKEY);
    const data = {
      // Email: values.email,
      Email: userEmail,
      NewPassword: encryptPass,
      Code: values.code,
    };
    axios
      .post(ConfirmNewPassword, data, {
        headers: {
          APIKey: apiKey,
          "X-Version": "2",
        },
      })
      .then(() => {
        setOpen(false);
        successToaster("تم تغيير كلمة المرور بنجاح");
      })
      .catch((error) => {
        errorToaster(error.response.data);
      })
      .finally(() => {
        document.body.style.cursor = "default";
        setLoading(false);
      });
  };
  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle>إعادة ضبط كلمة المرور</DialogTitle>
        <DialogContent sx={{ paddingBottom: 0 }}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={fireValidation}
            validateOnBlur={fireValidation}
            onSubmit={handleSubmit}
          >
            {({ errors, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div>
                  <Field
                    className={`${classes.inputField} ${
                      errors?.code ? classes.inputFiledError : ""
                    }`}
                    placeholder="رمز التحقق"
                    as={TextField}
                    type="text"
                    id="code"
                    name="code"
                    variant="outlined"
                    fullWidth
                    helperText={
                      <ErrorMessage
                        name="code"
                        render={(msg) => (
                          <p
                            style={{
                              color: "red",
                              textAlign: "right",
                              fontSize: "1rem",
                            }}
                          >
                            {msg}
                          </p>
                        )}
                      />
                    }
                    sx={{
                      "& .MuiFormHelperText-root": {
                        textAlign: "right",
                      },
                    }}
                  />
                  {/* <div className={`${classes.fieldContainer}`}>
                    <label htmlFor="code">رمز التحقق</label>
                    <Field
                      placeholder="رمز التحقق"
                      type="text"
                      id="code"
                      name="code"
                      className={classes.inputField}
                    />
                    <ErrorMessage
                      name="code"
                      component="div"
                      className="error"
                      style={{ color: "red", margin: "1rem 0" }}
                    />
                  </div> */}
                  <Field
                    className={`${classes.inputField} ${
                      errors?.password ? classes.inputFiledError : ""
                    }`}
                    as={TextField}
                    placeholder="كلمة المرور الجديدة"
                    type="password"
                    id="password"
                    name="password"
                    variant="outlined"
                    fullWidth
                    helperText={
                      <ErrorMessage
                        name="password"
                        render={(msg) => (
                          <p
                            style={{
                              color: "red",
                              textAlign: "right",
                              fontSize: "1rem",
                            }}
                          >
                            {msg}
                          </p>
                        )}
                      />
                    }
                    sx={{
                      "& .MuiFormHelperText-root": {
                        textAlign: "right",
                      },
                    }}
                  />
                  {/* <div className={`${classes.fieldContainer}`}>
                    <label htmlFor="password">كلمة المرور الجديدة</label>
                    <Field
                      placeholder="كلمة المرور الجديدة"
                      type="password"
                      id="password"
                      name="password"
                      className={classes.inputField}
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="error"
                      style={{ color: "red", margin: "1rem 0" }}
                    />
                  </div> */}
                </div>
                <DialogActions
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "0.5rem 0 1rem 0",
                    padding: "0",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    className={classes.loginButton}
                    onClick={() => setFireValidation(true)}
                  >
                    {loading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      <>تأكيد</>
                    )}
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={handleClose}
                    className={classes.logoutButton}
                  >
                    اغلاق
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}

import styled from "styled-components";

export const Styles = styled.div`
  .page-container {
    padding-left: 0;
  }
  .services-section {
    background-color: var(--mid-blue);
    .title {
      padding-top: 3rem;
      padding-bottom: 3rem;
      h2 {
        font-size: 2rem;
        font-weight: bold;
        color: #242216;
      }
    }
    .content {
      padding-right: 1rem;
      padding-left: 0rem;
      padding-bottom: 3rem;
      .single-service {
        display: flex;
        flex-direction: column;
        align-items: start;
        padding: 0rem 0rem 2rem 5rem;
        /* cursor: pointer; */
        img {
          width: 70px;
          height: 70px;
        }
        /* img:hover {
          box-shadow: 0px 0px 20px 0px rgba(137, 138, 140, 0.13);
          -webkit-box-shadow: 0px 0px 20px 0px rgba(137, 138, 140, 0.13);
          -moz-box-shadow: 0px 0px 20px 0px rgba(137, 138, 140, 0.13);
          opacity: 0.7;
        } */
        p {
          text-align: start;
          padding-top: 1rem;
          font-weight: 400;
          font-size: 1.6rem;
          color: #242216;
        }
      }
    }
  }
  /* ************************** Responsive *************************** */

  @media (max-width: 1200px) {
    .services-section .content .single-service {
      padding: 0rem 0rem 2rem 2rem !important;
    }
  }
  @media (max-width: 900px) {
    .services-section {
      .content {
        padding-bottom: 3rem;
        .single-service {
          padding: 1rem !important;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .services-section {
      .title {
        padding-top: 2rem;
        padding-bottom: 2rem;
        padding-right: 0rem;
        h2 {
          font-size: 2rem;
        }
      }
      .content {
        .single-service-container {
          justify-content: center;
        }
        .single-service {
          padding: 1rem;
          align-items: center;
        }
        img {
          width: 65px !important;
          height: 65px !important;
        }
        p {
          font-size: 1.2rem !important;
        }
      }
    }
  }
  @media (max-width: 550px) {
    .services-section {
      .content {
        .single-service {
          padding: 1rem;
          align-items: center;
          img {
            width: 70px !important;
            height: 70px !important;
          }
          p {
            font-size: 1.5rem !important;
            text-align: center;
          }
        }
      }
    }
  }
`;

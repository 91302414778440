import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { InputLabel } from "@mui/material";
import classes from "../textField/textfield.module.css";

const CustomRadioButtons = ({ label, isRequired, value, setValue }) => {
  const handleSexChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div className={`${classes.root} customRadioButtons`}>
      <InputLabel
        className={`${classes.inputLabel} ${
          isRequired ? classes.requiredField : ""
        }`}
      >
        {label}
      </InputLabel>
      <RadioGroup
        row
        aria-label="sex"
        name="sex"
        value={value}
        onChange={handleSexChange}
      >
        <FormControlLabel
          value="M"
          control={<Radio />}
          label="ذكر"
          labelPlacement="start"
          sx={{
            "&.MuiFormControlLabel-root": {
              marginRight: 0,
            },
          }}
        />
        <FormControlLabel
          value="F"
          control={<Radio />}
          label="انثى"
          labelPlacement="start"
        />
      </RadioGroup>
    </div>
  );
};

export default CustomRadioButtons;

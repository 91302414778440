import React from "react";
import { Modal } from "react-bootstrap";
// import { Styles } from "./styles";
import classes from "./notification.module.css";
import { BeatLoader } from "react-spinners";

const NotificationModal = ({
  isOpen,
  handleClose,
  handleOpen,
  message,
  withloader,
  isLoading,
}) => {
  return (
    <Modal
      show={isOpen}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Body className={`${classes.content}`}>
        <div className="text-center picklist-loader-dropdown pt-2 pe-2">
          <div
            class="spinner-border spinner-border-lg text-success"
            role="status"
          ></div>
        </div>
        <div className={`${classes.message}`}>{message}</div>
      </Modal.Body>
    </Modal>
  );
};

export default NotificationModal;

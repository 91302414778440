export let FormConditions = {
  Execute: function (condtion, formData) {
    if (condtion.Blocks && condtion.Blocks.length != 0) {
      let conditionResult;
      for (let i = 0; i < condtion.Blocks.length; i++) {
        let block = condtion.Blocks[i];
        if (block.Rows && block.Rows.length != 0) {
          let blockResult;
          for (let j = 0; j < block.Rows.length; j++) {
            let row = block.Rows[j];
            let firstValue = formData[row.FieldName];
            let secondValue =
              row.ValueType == "Text" ? row.Value : formData[row.ValueName];
            let rowResult = this.executeOperator(
              firstValue,
              secondValue,
              row.Operator
            );
            if (blockResult == null) {
              blockResult = rowResult;
            }
            if (row.LogicalConnector != null) {
              if (row.LogicalConnector == "And") {
                blockResult = blockResult && rowResult;
              } else if (row.LogicalConnector == "Or") {
                blockResult = blockResult || rowResult;
              }
            }
          }
          if (conditionResult == null) {
            conditionResult = blockResult;
          }
          if (block.LogicalConnector != null) {
            if (block.LogicalConnector == "And") {
              conditionResult = conditionResult && blockResult;
            } else if (block.LogicalConnector == "Or") {
              conditionResult = conditionResult || blockResult;
            }
          }
        }
      }
      return conditionResult;
    }
    return true;
  },
  executeOperator: function (
    firstValue,
    secondValue,
    operator,
    fieldType,
    valueType
  ) {
    if (operator == "Equals") {
      return firstValue == secondValue;
    } else if (operator == "Not Equals") {
      return firstValue != secondValue;
    } else if (operator == "Greater Than") {
      return firstValue > secondValue;
    } else if (operator == "Less Than") {
      return firstValue < secondValue;
    } else if (operator == "Greater Than Or Equal To") {
      return firstValue >= secondValue;
    } else if (operator == "Less Than Or Equal To") {
      return firstValue <= secondValue;
    }
  },
};

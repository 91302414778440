import styled from "styled-components";

export const Styles = styled.div`
  position: relative;
  /* top: -1.5rem; */
  margin-top: 0.2rem;

  img {
    width: 100%;
    height: 15vh;
    padding: 0rem;
    margin-bottom: 0.2rem;
    object-fit: contain;

    /* height: 200px; */
  }
  .close-icon {
    position: relative;
    top: 0rem;
    z-index: 1;
    svg {
      cursor: pointer;
    }
  }
  .title {
    width: 100%;
    padding-bottom: 0rem;
    overflow: hidden;
    color: white;
    font-size: 0.8rem;
    word-break: break-all;
    padding: 0.5rem;
    border: 1px solid var(--green);
    background-color: var(--green);
    border-radius: 12px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
`;

import { Grid, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import React from "react";
import StepButton from "../../components/stepsButton";
import CustomTextField from "../../../../components/elements/textField";
import CustomSelect from "../../../../components/elements/select";
import CustomRadioButtons from "../../../../components/elements/radioButtons";
import SingleCheckbox from "../../../../components/elements/singleCheckBox";
// import CustomPhoneNumber from "../../../../components/elements/phoneNumber";
import { useLocation } from "react-router-dom";
import CustomPhoneNumber from "../../../../components/elements/phoneNumber";

const PersonalInformation = ({
  PersonalInformationLoading,
  feminineCompanyOptions,
  companyTypeOptions,
  tTypeOptions,
  tradeTypeOptions,
  handleBack,
  username,
  setUsername,
  usernameError,
  middleName,
  setMiddleName,
  middleNameError,
  lastName,
  setLastName,
  lastNameError,
  cardId,
  setCardId,
  cardIdError,
  nationality,
  setNationality,
  nationalityError,
  age,
  setAge,
  ageError,
  selectedSex,
  setSelectedSex,
  selectedSexError,
  specialNeed,
  setSpecialNeed,
  checked1,
  checked2,

  legalName,
  setLegalName,
  legalNameError,
  companyAccountType,
  setCompanyAccountType,
  companyAccountTypeError,
  companyType,
  setCompanyType,
  companyTypeError,
  businessType,
  setBusinessType,
  businessTypeError,
  businessName,
  setBusinessName,
  businessNameError,
  financialNumber,
  setFinancialNumber,
  financialNumberError,
  feminiseCompanyCheckbox,
  setFeminiseCompanyCheckbox,
  feminiseCompanySelect,
  feminiseCompanySelectError,
  setFeminiseCompanySelect,
  authorizedSignatoryName,
  setAuthorizedSignatoryName,
  authorizedSignatoryNameError,
  personType,
  setPersonType,
  personTypeError,
  authorizedSignatoryPhoneNumber,
  setAuthorizedSignatoryPhoneNumber,
  authorizedSignatoryPhoneNumberError,
  setAuthorizedSignatoryPhoneNumberError,
  authorizedSignatoryNationality,
  setAuthorizedSignatoryNationality,
  authorizedSignatoryNationalityError,
  handleNext,
  nationalityOptions,
  AgeOptions,
}) => {
  const location = useLocation();
  const path = location.pathname;
  return (
    <Stack
      alignItems={"center"}
      justifyContent={"space-between"}
      marginTop={5}
      marginBottom={1}
      sx={{ flex: 1 }}
    >
      <Grid container rowGap={4}>
        {path === "/dashboard/settings" ? null : (
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            {checked1 && <h2>معلومات شخصية</h2>}
            {checked2 && <h2>معلومات عن صاحب العلاقة</h2>}
          </Grid>
        )}
        {checked1 && (
          <>
            <Grid item xs={12} sm={6}>
              <CustomTextField
                label={"الاسم"}
                value={username}
                setValue={setUsername}
                isRequired={true}
                showError={usernameError}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTextField
                label={"الشهرة"}
                value={lastName}
                setValue={setLastName}
                isRequired={true}
                showError={lastNameError}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTextField
                label={"اسم الاب"}
                value={middleName}
                setValue={setMiddleName}
                isRequired={true}
                showError={middleNameError}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTextField
                label={"رقم الهوية"}
                value={cardId}
                setValue={setCardId}
                isRequired={true}
                showError={cardIdError}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomSelect
                label={"الجنسية"}
                value={nationality}
                setValue={setNationality}
                showError={nationalityError}
                isRequired={true}
                options={nationalityOptions}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomSelect
                label={"العمر"}
                value={age}
                setValue={setAge}
                showError={ageError}
                isRequired={true}
                options={AgeOptions}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomRadioButtons
                value={selectedSex}
                setValue={setSelectedSex}
                label={"الجنس"}
                isRequired={true}
                showError={selectedSexError}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <SingleCheckbox
                value={specialNeed}
                setValue={setSpecialNeed}
                label="ذوي الإحتياجات الخاصة"
              />
            </Grid>
          </>
        )}

        {checked2 && (
          <>
            <Grid item xs={12} sm={6}>
              <CustomTextField
                label={"الاسم القانوني"}
                value={legalName}
                setValue={setLegalName}
                isRequired={true}
                showError={legalNameError}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomSelect
                label={"النوع"}
                value={companyAccountType}
                setValue={setCompanyAccountType}
                isRequired={true}
                showError={companyAccountTypeError}
                options={tTypeOptions}
              />
            </Grid>
            {companyAccountType === 1092 && (
              <Grid item xs={12} sm={6}>
                <CustomSelect
                  label={"نوع الشركة"}
                  value={companyType}
                  setValue={setCompanyType}
                  isRequired={true}
                  showError={companyTypeError}
                  options={companyTypeOptions}
                />
              </Grid>
            )}

            <Grid item xs={12} sm={6}>
              <CustomSelect
                label={"نوع التجارة"}
                value={businessType}
                setValue={setBusinessType}
                isRequired={true}
                showError={businessTypeError}
                options={tradeTypeOptions}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomTextField
                label={"الاسم التجاري"}
                value={businessName}
                setValue={setBusinessName}
                isRequired={true}
                showError={businessNameError}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTextField
                label={"الرقم المالي"}
                value={financialNumber}
                setValue={setFinancialNumber}
                isRequired={true}
                showError={financialNumberError}
              />
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"space-between"}
              >
                <SingleCheckbox
                  value={feminiseCompanyCheckbox}
                  setValue={setFeminiseCompanyCheckbox}
                  label="شركة قيادتها نسائية"
                />
                {feminiseCompanyCheckbox && (
                  <Grid item xs={10}>
                    <CustomSelect
                      label={null}
                      value={feminiseCompanySelect}
                      setValue={setFeminiseCompanySelect}
                      isRequired={true}
                      showError={feminiseCompanySelectError}
                      options={feminineCompanyOptions}
                    />
                  </Grid>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <h4>المفوض بالتوقيع</h4>
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomTextField
                label={"الاسم"}
                value={authorizedSignatoryName}
                setValue={setAuthorizedSignatoryName}
                isRequired={true}
                showError={authorizedSignatoryNameError}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomSelect
                label={"الجنسية"}
                value={authorizedSignatoryNationality}
                setValue={setAuthorizedSignatoryNationality}
                isRequired={true}
                showError={authorizedSignatoryNationalityError}
                options={nationalityOptions}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomTextField
                label={"الصفة"}
                value={personType}
                setValue={setPersonType}
                isRequired={true}
                showError={personTypeError}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomPhoneNumber
                label={"رقم الهاتف"}
                isRequired={true}
                type="number"
                value={authorizedSignatoryPhoneNumber}
                setValue={setAuthorizedSignatoryPhoneNumber}
                setPhoneNumberError={setAuthorizedSignatoryPhoneNumberError}
                showError={authorizedSignatoryPhoneNumberError}
              />
            </Grid>
          </>
        )}
      </Grid>

      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ margin: "2rem 0 0 0" }}
      >
        {path === "/dashboard/settings" ? null : (
          <StepButton
            type="backward"
            text={"رجوع"}
            func={handleBack}
            arrow={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="9"
                viewBox="0 0 7 13"
                fill="none"
                style={{ margin: "0 0.3rem" }}
              >
                <path
                  d="M1 1L5.08579 5.08579C5.86683 5.86683 5.86684 7.13316 5.08579 7.91421L1 12"
                  stroke="#000"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            }
          />
        )}
        <StepButton
          disabled={PersonalInformationLoading}
          type="forward"
          text={path === "/dashboard/settings" ? "حفظ" : "التالي"}
          func={() =>
            handleNext({
              personAccount: {
                username: username,
                middleName: middleName,
                lastName: lastName,
                cardId: cardId,
                nationality: nationality,
                age: age,
                selectedSex: selectedSex,
                specialNeed: specialNeed,
              },
              companyAccount: {
                legalName: legalName,
                companyAccountType: companyAccountType,
                companyType: companyType,
                businessType: businessType,
                businessName: businessName,
                financialNumber: financialNumber,
                feminiseCompanyCheckbox: feminiseCompanyCheckbox,
                feminiseCompanySelect: feminiseCompanySelect,
                authorizedSignatoryName: authorizedSignatoryName,
                authorizedSignatoryNationality: authorizedSignatoryNationality,
                personType: personType,
                authorizedSignatoryPhoneNumber: authorizedSignatoryPhoneNumber,
              },
            })
          }
          arrow={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="7"
              height="9"
              viewBox="0 0 7 13"
              fill="none"
              style={{ margin: "0 0.3rem" }}
            >
              <path
                d="M5.5 1L1.41421 5.08579C0.633166 5.86683 0.633165 7.13316 1.41421 7.91421L5.5 12"
                stroke="var(--second-linear-green)"
                strokeLinecap="round"
                strokeWidth="3"
              />
            </svg>
          }
        />
      </Stack>
    </Stack>
  );
};

export default PersonalInformation;

import { toast } from "react-toastify";
export const successToaster = (message) =>
  toast.success(message || "Process successfully done", {
    position: "top-right",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    // color: "ff0000",
    theme: "colored",
  });
export const errorToaster = (message) =>
  toast.error(message || "Process Failed!", {
    position: "top-right",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });

import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Button, DialogActions, Typography } from "@mui/material";
import successIcon from "../../../../assets/images/icons/success-icon.webp";
import { useNavigate } from "react-router-dom";

const SuccessDialog = ({ openSuccessDialog, setOpenSuccessDialog, href }) => {
  const navigate = useNavigate();
  const handleClickButton = () => {
    navigate(href, { replace: true });
    setOpenSuccessDialog(false);
  };
  return (
    <Dialog
      open={openSuccessDialog}
      sx={{ "& .MuiPaper-root": { padding: "1rem" } }}
    >
      <DialogContent>
        <div className="content-section text-center pb-4">
          <img src={successIcon} alt="Success icon" />
        </div>
        <Typography variant="h4" textAlign={"center"}>
          لقد تم إنشاء حسابك بنجاح في مديرية حماية المستهلك
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          sx={{
            borderRadius: "15px",
            background:
              "linear-gradient(180deg, var(--first-linear-green) 0%, var(--second-linear-green) 100%)",
            color: "#FFF",
            textAlign: "center",
            fontFamily: "Cairo",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "normal",
          }}
          variant="contained"
          onClick={handleClickButton}
        >
          الاطلاع على الخدمات
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SuccessDialog;

import React from "react";
import { BeatLoader } from "react-spinners";
import styled from "styled-components";
const Styles = styled.div`
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "white"};
  .main-container {
    height: ${(props) => (props.height ? props.height : "50vh")};
    padding-top: ${(props) => (props.paddingTop ? props.paddingTop : "10rem")};
  }
`;
const Loader = (props) => {
  const { color, backgroundColor, height, paddingTop } = props;
  return (
    <Styles
      backgroundColor={backgroundColor}
      height={height}
      paddingTop={paddingTop}
    >
      <div className="main-container text-center">
        <BeatLoader color={color ? color : "var(--green)"} />
      </div>
    </Styles>
  );
};

export default Loader;

import styled from "styled-components";
export const Styles = styled.div`
  .section-title {
    /* padding-bottom: 1rem; */
  }
  #input-group {
    padding-top: 0rem;
    margin-bottom: 1.5rem;
  }
  /* .MuiFormLabel-root, */
  .MuiInputLabel-root {
    /* width: 500px !important; */
  }
  .textfield_root__iEnAV,
  .textfield_root {
    flex-direction: column !important;
  }
  .MuiFormControl-root,
  .MuiTextField-root,
  .MuiFormControl-root {
    width: 100% !important;
  }
  .MuiOutlinedInput-root {
    padding: 0;
  }
`;

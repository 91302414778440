import React from "react";
import { Styles } from "./styles";

const TermsAndConditionContent = () => {
  var today = new Date();
  var options = { month: "long", day: "numeric", year: "numeric" };
  var formattedDate = today.toLocaleDateString("en-US", options);
  return (
    <Styles>
      <div className="container">
        <div className="content-section">
          <h3>MoET Digital Services - Privacy Policy</h3>
          <p>
            This Privacy Policy outlines how the Consumer Protection Directorate
            (CPD) within the Economic and Trade Ministry handles information
            collected through the "MoET Digital Services" mobile application
            (hereafter referred to as the "App"). By using the App, you agree to
            the terms outlined in this Privacy Policy.
          </p>
        </div>
        <div className="content-section">
          <h3>Responsible Entity</h3>
          <p>
            The Consumer Protection Directorate (CPD) in the Economic and Trade
            Ministry, as named in the app’s Google Play listing, manages the
            App.
          </p>
        </div>
        <div className="content-section">
          <h3>Information Collection and Use</h3>
          <ol>
            <li>User Complaints (Consumers):</li>
            <ul>
              <li>
                Consumers may submit complaints anonymously without registering.
              </li>
              <li>
                Information collected may include details of the complaint. No
                personally identifiable information is required for submitting
                complaints.
              </li>
            </ul>
            {/* 2 services requests */}
            <li>Service Requests (Professionals):</li>
            <ul>
              <li>
                Professionals must register an account to use certain
                functionalities of the App.
              </li>
              <li>
                Personal information collected during registration includes
                name, email address, phone number, and other relevant details
                necessary for service provision.
              </li>
              <li>
                This information is utilized to authenticate users and
                facilitate efficient processing of service requests.
              </li>
            </ul>
          </ol>
          <p></p>
        </div>
        <div className="content-section">
          <h3>How We Use Collected Information</h3>
          <ol>
            <li>User Complaints (Consumers):</li>
            <ul>
              <li>
                Information pertaining to complaints is used solely for
                addressing and resolving the issues raised.
              </li>
            </ul>
            {/* 2 services requests */}
            <li>Service Requests (Professionals):</li>
            <ul>
              <li>
                Information collected from professionals is employed for user
                authentication, communication, and service fulfillment.
              </li>
              <li>
                We may use this information to enhance our services and inform
                users about relevant updates and changes.
              </li>
            </ul>
          </ol>
          <p></p>
        </div>
        {/* ********************************************** */}
        <div className="content-section">
          <h3>Data Security</h3>
          <p>
            We implement robust security measures to safeguard the information
            collected via the MoET Digital Services App. These include, but are
            not limited to, encryption of data in transit and at rest. Despite
            our efforts, no security measures are completely infallible.
          </p>
        </div>
        {/* ********************************************** */}
        <div className="content-section">
          <h3>Sharing Your Information</h3>
          <ul>
            <li>
              Information submitted anonymously is not shared with any third
              parties.
            </li>
            <li>
              Personal information may be disclosed to public authorities in
              response to lawful requests for purposes such as national security
              or law enforcement.
            </li>
          </ul>
        </div>
        {/* ************************************************* */}
        {/* <div className="content-section">
          <h3>Changes to This Privacy Policy</h3>
          <p>
            We reserve the right to update or change our Privacy Policy at any
            time. Any changes will be effective immediately upon posting the
            revised Privacy Policy on the App. Your continued use of the App
            after any such changes constitutes your acceptance of the revised
            Privacy Policy.
          </p>
        </div> */}
        {/* ********************************************** */}
        <div className="content-section">
          <h3>Data Retention and Deletion</h3>
          <p>
            We retain personal information only as long as necessary for the
            fulfillment of the purposes outlined in this policy, unless a longer
            retention period is required or permitted by law. Users can contact
            us at any time to request the deletion of their personal data.
          </p>
        </div>
        <div className="content-section">
          <h3>Conditions for Mobile App Use</h3>
          <ol>
            <li>Consumer Use:</li>
            <p>
              Consumers submitting complaints must ensure that the information
              provided is accurate to the best of their knowledge.
            </p>
            {/* 2.	Professional Use*/}
            <li>Professional Use:</li>
            <p>
              Professionals are required to register an account and are
              responsible for providing complete and accurate information. They
              must also maintain the confidentiality of their account
              credentials and are responsible for all activities that occur
              under their account.
            </p>
          </ol>
        </div>
        {/* ********************************************** */}
        <div className="content-section">
          <h3>Contact Us</h3>
          <p>
            If you have any questions about this Privacy Policy or the data
            handling practices of the MoET Digital Services App, please contact
            us via email at
            <a href="mailto:apps@economy.gov.lb">apps@economy.gov.lb</a>.
          </p>
        </div>
        <div className="content-section">
          <h3>Effective Date </h3>
          <p>
            This Privacy Policy is effective as of June 20, 2024, and will
            remain in effect except with respect to any changes in its
            provisions in the future, which will be in effect immediately after
            being posted on this page.
          </p>
        </div>
        <div className="content-section">
          <h3>Acknowledgement</h3>
          <p>
            By using the MoET Digital Services App, you acknowledge that you
            have read this Privacy Policy and agree to be bound by it.
          </p>
        </div>
      </div>
    </Styles>
  );
};

export default TermsAndConditionContent;

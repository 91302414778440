import environment from "../environment";

// const baseUrl = "https://dw.cloudsystems.tech/publicapi/Api/";

// const baseUrl = environment.PUBLIC_URL;
const baseUrl = environment.API_URL;
export const LOGIN = `${baseUrl}/Users/Login`;
export const Register = `${baseUrl}/Users/Register`;
export const VerifyEmailCode = `${baseUrl}/Users/VerifyAccount/Email`;
export const VerifyPhoneCode = `${baseUrl}/Users/VerifyAccount/Mobile`;
export const SendOTPEmail = `${baseUrl}/Users/SendOTP/Email`;
export const SendOTPMobile = `${baseUrl}/Users/SendOTP/Mobile`;

/* Lookups */

export const GetAllCountries = `${baseUrl}/Countries/All`;

export const ResetPassword = `${baseUrl}/Users/Password`;

export const ForgotPassword = `${baseUrl}/Users/ResetPassword`;

export const ConfirmNewPassword = `${baseUrl}/Users/ForgetPassword`;

export const USERPROFILE = `${baseUrl}/Users/Profile`;

export const personalAccountValidation = (
  data,
  setUsernameError,
  setMiddleNameError,
  setLastNameError,
  setCardIdError,
  setNationalityError,
  setAgeError,
  setSelectedSexError
) => {
  const {
    username,
    middleName,
    lastName,
    cardId,
    nationality,
    age,
    selectedSex,
  } = data;

  if (username === "") {
    setUsernameError(true);
  } else setUsernameError(false);

  if (middleName === "") {
    setMiddleNameError(true);
  } else setMiddleNameError(false);

  if (lastName === "") {
    setLastNameError(true);
  } else setLastNameError(false);

  if (cardId === "") {
    setCardIdError(true);
  } else setCardIdError(false);

  if (nationality === "") {
    setNationalityError(true);
  } else setNationalityError(false);

  if (age === "") {
    setAgeError(true);
  } else setAgeError(false);

  if (selectedSex === "") {
    setSelectedSexError(true);
  } else setSelectedSexError(false);
};

export const CompanyAccountValidation = (
  data,
  setLegalNameError,
  setCompanyAccountTypeError,
  setCompanyTypeError,
  setBusinessTypeError,
  setBusinessNameError,
  setFinancialNumberError,
  setAuthorizedSignatoryNameError,
  setPersonTypeError,
  setAuthorizedSignatoryPhoneNumberError,
  setAuthorizedSignatoryNationalityError,
  setFeminiseCompanySelectError
) => {
  const {
    legalName,
    companyAccountType,
    companyType,
    businessType,
    authorizedSignatoryNationality,
    businessName,
    financialNumber,
    authorizedSignatoryName,
    personType,
    authorizedSignatoryPhoneNumber,
    feminiseCompanyCheckbox,
    feminiseCompanySelect,
  } = data;

  if (feminiseCompanyCheckbox) {
    if (feminiseCompanySelect === "") {
      setFeminiseCompanySelectError(true);
    } else setFeminiseCompanySelectError(false);
  } else setFeminiseCompanySelectError(false);

  if (legalName === "") {
    setLegalNameError(true);
  } else setLegalNameError(false);

  if (companyAccountType === "") {
    setCompanyAccountTypeError(true);
  } else setCompanyAccountTypeError(false);

  if (companyAccountType === 1092) {
    if (companyType === "") {
      setCompanyTypeError(true);
    } else setCompanyTypeError(false);
  } else setCompanyTypeError(false);

  if (businessType === "") {
    setBusinessTypeError(true);
  } else setBusinessTypeError(false);

  if (authorizedSignatoryNationality === "") {
    setAuthorizedSignatoryNationalityError(true);
  } else setAuthorizedSignatoryNationalityError(false);

  if (businessName === "") {
    setBusinessNameError(true);
  } else setBusinessNameError(false);

  if (financialNumber === "") {
    setFinancialNumberError(true);
  } else setFinancialNumberError(false);

  if (authorizedSignatoryName === "") {
    setAuthorizedSignatoryNameError(true);
  } else setAuthorizedSignatoryNameError(false);

  if (personType === "") {
    setPersonTypeError(true);
  } else setPersonTypeError(false);

  if (
    authorizedSignatoryPhoneNumber === "" ||
    authorizedSignatoryPhoneNumber.length < 7 ||
    authorizedSignatoryPhoneNumber.length > 8
  ) {
    setAuthorizedSignatoryPhoneNumberError(true);
  } else if (authorizedSignatoryPhoneNumber.length === 7) {
    setAuthorizedSignatoryPhoneNumberError(false);
  } else setAuthorizedSignatoryPhoneNumberError(false);
};

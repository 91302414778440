import { Stack } from "@mui/material";
import React from "react";
import CustomCheckbox from "../../../../components/elements/checkbox";
import StepButton from "../../components/stepsButton";

const Step1 = ({
  handleStep1Submit,
  checked1,
  checked2,
  setChecked1,
  setChecked2,
}) => {
  const handleChangeNormalAccount = (e) => {
    setChecked1(e.target.checked);
    setChecked2(false);
  };

  const handleChangeCompanyAccount = (e) => {
    setChecked1(false);
    setChecked2(e.target.checked);
  };
  return (
    <Stack
      alignItems={"center"}
      justifyContent={"space-between"}
      marginTop={3}
      marginBottom={1}
      sx={{ flex: 1 }}
    >
      <Stack sx={{ marginTop: "5rem" }}>
        <CustomCheckbox
          value={checked1}
          label="شخص طبيعي"
          onChangeFunc={handleChangeNormalAccount}
        />
        <CustomCheckbox
          value={checked2}
          label="شخص معنوي"
          subLabel="(شركة /مؤسسة /جمعية)"
          onChangeFunc={handleChangeCompanyAccount}
        />
      </Stack>

      <StepButton
        type={"forward"}
        text={"التالي"}
        func={handleStep1Submit}
        arrow={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="7"
            height="9"
            viewBox="0 0 7 13"
            fill="none"
            style={{ margin: "0 0.3rem" }}
          >
            <path
              d="M5.5 1L1.41421 5.08579C0.633166 5.86683 0.633165 7.13316 1.41421 7.91421L5.5 12"
              stroke="var(--second-linear-green)"
              strokeLinecap="round"
              strokeWidth="3"
            />
          </svg>
        }
        //
      />
    </Stack>
  );
};

export default Step1;

import { Box, Button, Stack, Typography } from "@mui/material";
import classes from "./optionOne.module.css";
import { createTheme, ThemeProvider, useMediaQuery } from "@mui/material";
import { useMediaQuery as useResponsiveMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0, // Extra small devices (portrait phones)
      sm: 600, // Small devices (landscape phones)
      md: 991, // Medium devices (tablets)
      lg: 1280, // Large devices (desktops)
      xl: 1920, // Extra large devices (large desktops)
    },
  },
});

const FEDHeroSection = ({ pathname }) => {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const isIPadView = useResponsiveMediaQuery({ query: "(max-width: 1024px)" });

  const scrollToSection = (id) => {
    const targetSection = document.getElementById(id);
    targetSection.scrollIntoView({ behavior: "smooth" });
  };

  const forwardToFirstForm = () => {
    navigate("/dynamic-form?dynamicFormId=43&processId=33", { replace: false });
  };

  const forwardToSecondForm = () => {
    navigate("/dynamic-form?dynamicFormId=45&processId=35", { replace: false });
  };
  return (
    <ThemeProvider theme={theme}>
      <Stack
        direction={isSmallScreen ? "column" : "row"}
        height={isSmallScreen ? "max-content" : "90vh"}
        // height={isIPadView ? "60vh" : isSmallScreen ? "max-content" : "90vh"}
      >
        <Box
          width={"100%"}
          position="relative"
          className={`${classes.Buisness}  ${classes.option2BgBuisness}`}
        >
          <div className={classes.backDrop1} />
          <Stack
            className={classes.hero_text_box}
            spacing={6}
            alignItems={"center"}
          >
            <Typography variant="h2" className={classes.hero_text}>
              طلب ترخيص أسواق
            </Typography>
            <Typography
              variant="p"
              className={classes.hero_sub_text}
              sx={{ width: "70%" }}
            >
              طلب ترخيص للشركات اللبنانية لإقامة وتنظيم أسواق على الأراضي
              اللبنانية
            </Typography>
            <Button
              variant="outlined"
              className={classes.hero_button}
              onClick={() => forwardToFirstForm()}
            >
              ارسال طلب
            </Button>
          </Stack>
        </Box>
        <Box
          width={"100%"}
          position="relative"
          className={`${classes.Consumer} ${classes.option3BgConsumer}`}
        >
          <div className={classes.backDrop2} />
          <Stack
            className={classes.hero_text_box}
            spacing={6}
            alignItems={"center"}
            sx={{ marginTop: isIPadView ? "13.3rem" : "10rem" }}
          >
            <Typography variant="h2" className={classes.hero_text}>
              طلب ترخيص معارض
            </Typography>
            <Typography variant="p" className={classes.hero_sub_text}>
              طلب ترخيص للشركات اللبنانية لإقامة وتنظيم معارض على الأراضي
              اللبنانية
            </Typography>
            <Button
              variant="outlined"
              className={classes.hero_button}
              onClick={() => forwardToSecondForm()}
            >
              ارسال طلب
            </Button>
          </Stack>
        </Box>
      </Stack>
    </ThemeProvider>
  );
};

export default FEDHeroSection;

import React, { useEffect, useRef, useState } from "react";
import { Button, Col, FormLabel, Row } from "react-bootstrap";

import useStateRef from "react-usestateref";
import ConfirmationBox from "../../elements/confirmationBox";
import { generateOTPCodeForFormData } from "../../../../services/dynamicForm.services";
import {
  errorToaster,
  successToaster,
} from "../../../../helpers/toasterConfiguration";

const OTPForm = ({
  stepGuid,
  PhoneNumberFieldGuid,
  data,
  handleBack,
  activeStep,
  submitButtonState,
  submitButtonLabel,
  handleSubmit,
  valuesPhone,
  setValuesPhone,
  OTPToken,
  setOTPToken,
  disabledMobile,
  setDisabledMobile,
  disabledCheckOTP,
  setDisabledCheckOTP,
  codeValidated,
  setCodeValidated,
  allOTPs,
  setAllOTPs,
}) => {
  const [phoneNumberField, setPhoneNumberField] = useState();
  const [
    showErrorPhoneValidation,
    setShowErrorPhoneValidation,
    showErrorPhoneValidationRef,
  ] = useStateRef(false);
  const inputRefsPhone = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];
  // UI/UX
  // const [disabledMobile, setDisabledMobile] = useState(false);
  const [timeLeftMobile, setTimeLeftMobile] = useState(0);
  // Function
  const sendMessage = async (phoneNumber) => {
    const otpObject = {
      Token: "",
      Code: valuesPhone?.join(""),
      StepGuid: stepGuid,
    };
    setDisabledCheckOTP(true);
    const response = await generateOTPCodeForFormData(
      phoneNumber?.replace(/\s/g, "")
    );
    if (response.status === 200) {
      successToaster("تم ارسال رمز التحقق بنجاح الى رقم الهاتف");
      setOTPToken(response.data?.token);
      otpObject.Token = response.data?.token;
      setAllOTPs((prevArr) => [...prevArr, otpObject]);
    } else {
      errorToaster("عذرا حدث خطأ ما");
    }
    // setDisabledCheckOTP(false);
  };

  const resendFunction = () => {
    setDisabledMobile(true);
    setTimeLeftMobile(30);
    let phoneNumber = document.getElementById(phoneNumberField?.Id)?.value;
    sendMessage(phoneNumber);
  };
  // TimeOut area
  useEffect(() => {
    if (timeLeftMobile <= 0) return;
    const timerId = setTimeout(() => {
      setTimeLeftMobile((prevTime) => prevTime - 1);
    }, 1000);
    return () => clearTimeout(timerId); // Clear timeout if the component is unmounted
  }, [timeLeftMobile]);
  // Button enable control
  useEffect(() => {
    const isFilledPhone = valuesPhone?.every((value) => value.length === 1);
    if (timeLeftMobile === 0 && disabledMobile) {
      setDisabledMobile(false);
    }
    if (timeLeftMobile === 0 && disabledMobile && isFilledPhone) {
      setDisabledCheckOTP(false);
    }
  }, [timeLeftMobile, disabledMobile]);
  // Request code on load
  useEffect(() => {
    if (
      data[activeStep]?.StepType === "OTP" &&
      !disabledMobile &&
      !codeValidated
    ) {
      setDisabledMobile(true);
      setTimeLeftMobile(30);
      // get phone number element
      let phoneNumber = "";
      data.map((step) => {
        step.DynamicFormSections.map((section) => {
          section.DynamicFormFields.map((field) => {
            if (field.Guid === PhoneNumberFieldGuid) {
              setPhoneNumberField(field);
              phoneNumber = document.getElementById(field?.Id)?.value;
            }
          });
        });
      });
      // 1. Send entered phone number to request message
      // let phoneNumber = document.getElementById(phoneNumberField?.Id)?.value;
      sendMessage(phoneNumber);
    }
  }, [activeStep]);
  useEffect(() => {
    setCodeValidated(false);
    const isFilledPhone = valuesPhone?.every((value) => value.length === 1);
    if (isFilledPhone) {
      // setDisabledMobile(false);
      setDisabledCheckOTP(false);
    } else {
      // setDisabledMobile(true);
      setDisabledCheckOTP(true);
    }
  }, [valuesPhone]);

  return (
    <>
      <Row id={"sectionGuid"}>
        <Col xs="12">
          <ConfirmationBox
            label="أدخل الرمز الذي تم إرساله إلى رقم الهاتف"
            // labelValue={"phoneLabelValue"}
            errorMessage="لم تصلني الرسالة النصية"
            showError={showErrorPhoneValidation}
            setShowError={setShowErrorPhoneValidation}
            inputRefs={inputRefsPhone}
            values={valuesPhone}
            setValues={setValuesPhone}
            resendFunction={resendFunction}
            disabled={disabledMobile}
            timeLeft={timeLeftMobile}
          />
        </Col>
      </Row>
    </>
  );
};

export default OTPForm;

import axios from "axios";
import { CustomGet, GET, POST } from "../http.service";

export const initializeSessionSecond = async (
  merchantId,
  amount,
  orderUniqueId,
  description,
  authToken
) => {
  const apiUrl = `https://epayment.areeba.com/api/rest/version/66/merchant/${merchantId}/session`;
  const requestBody = {
    apiOperation: "INITIATE_CHECKOUT",
    interaction: {
      operation: "PURCHASE",
      merchant: {
        name: "Your merchant name",
        address: {
          line1: "200 Sample St",
          line2: "1234 Example Town",
        },
      },
    },
    order: {
      currency: "USD",
      // id: `${orderUniqueId}`,
      // amount: `${amount}`,
      id: orderUniqueId,
      amount: amount,
      description: `${description}`,
    },
  };
  // Define request options
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*", // Allow requests from any origin
      // 'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE', // Specify allowed methods if needed
      // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept', // Specify allowed headers if needed
      // 'Accept_Language': lang, // Include additional headers if needed
      // 'Authorization': `Bearer ${userToken}`, // Include authorization header if needed
      Authorization: `Basic ${authToken}`, // Include authorization header
    },
    body: JSON.stringify(requestBody),
  };

  // Perform the POST request
  fetch(apiUrl, requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json(); // Parse JSON response
    })
    .then((data) => {
      // Handle success
    })
    .catch((error) => {
      // Handle error
    });
};
export const initializeSession = async (
  merchantId,
  amount,
  orderUniqueId,
  description,
  authToken
) => {
  let requestResponse = {};
  const requestBody = {
    apiOperation: "INITIATE_CHECKOUT",
    interaction: {
      operation: "PURCHASE",
      merchant: {
        name: "Your merchant name",
        address: {
          line1: "200 Sample St",
          line2: "1234 Example Town",
        },
      },
    },
    order: {
      currency: "USD",
      // id: `${orderUniqueId}`,
      // amount: `${amount}`,
      id: orderUniqueId,
      amount: amount,
      description: `${description}`,
    },
  };
  axios
    .post(
      `https://epayment.areeba.com/api/rest/version/66/merchant/${merchantId}/session`,
      requestBody,
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          // "Content-Type": "application/json",
          // "Access-Control-Allow-Origin": "*", // Allow requests from any origin
          // "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE", // Specify allowed methods
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept", // Specify allowed headers

          // Accept_Language: lang,
          // Authorization: `Bearer ${userToken}`,
          Authorization: `Basic ${authToken}`,
        },
      }
    )
    .then((response) => {
      // Handle success
      requestResponse = response;
      console.log("*** Response:", response.data);
    })
    .catch((error) => {
      // Handle error
      console.error("---- Error:", error);
    });
  //   const response = await POST(
  //     `https://epayment.areeba.com/api/rest/version/78/merchant/${merchantId}/session`,
  //     requestBody,
  //     false
  //   );
  // return response;
  return requestResponse;
};
export const retrieveOrderFn = async (merchantId, orderUniqueId) => {
  let requestResponse = {};
  axios
    .get(
      `https://epayment.areeba.com/api/rest/version/78/merchant/${merchantId}/order/${orderUniqueId}`,
      {
        headers: {
          Authorization: `Basic bWVyY2hhbnQudGVzdDIyMjIwNzcwMTAwMTo3YzQ3OTkwYjU0M2ZmMzRhYjc3NGI0Y2FlNTE1MmVhNQ==`,
        },
      }
    )
    .then((response) => {
      // Handle success
      requestResponse = response;
    })
    .catch((error) => {
      // Handle error
      console.error("Error:", error);
    });
  return requestResponse;
};
//  API to save the service record
export const saveServiceRecord = async (requestBody, token) => {
  const response = await POST("Payments", requestBody, true, token);
  return response;
};
//  API to save the payment transaction
export const savePaymentTransaction = async (requestBody) => {
  const response = await POST(requestBody, true);
  return response;
};
//  API to get paid order details
export const getPaidOrderDetails = async (orderId) => {
  const response = await GET(`Payments/Sessions/Orders/${orderId}`, true);
  return response;
};

// API to get session details
export const getSessionDetails = async (id, token) => {
  const response = await CustomGet(`Payments/Sessions/${id}`, token);
  return response;
};
export const getMobileSessionDetails = async (id, token) => {
  const response = await CustomGet(`Payments/Sessions/${id}`, token);
  return response;
};
export const checkIfPaymentRequiredApi = async (processId) => {
  const response = await GET(
    `Payments/CheckIfPaymentRequired?ProcessId=${processId}`,
    true
  );
  return response;
};

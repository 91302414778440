import styled from "styled-components";
export const Styles = styled.div`
  h2 {
    font-size: 5.5rem;
    margin-top: 15vh;
  }
  @media (max-width: 1200px) {
    h2 {
      font-size: 5.5rem;
      margin-top: 9vh;
    }
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 5.5rem;
      margin-top: 25vh;
    }
  }
  @media (max-width: 550px) {
    background-position: center;
    h2 {
      font-size: 5.5rem;
      margin-top: 27vh;
    }
  }
`;

import React, { useEffect, useState } from "react";
import BannerWithImage from "../../../components/banner-with-image";
import bannerImage from "../../../assets/images/all-exhibitions.webp";
import { Styles } from "./styles";
import { useSearchParams } from "react-router-dom";
import ExhibitionCard from "../../../components/FED/exhibitions/exhibtion-card";
import { Col, Row } from "react-bootstrap";
import ExhibitionsFilter from "../exhibitions-filter";
import AllExhibitionsLoader from "./loader";
import { GetAllExhibition } from "../../../services/fed/fedExhibitors.service";
import Loader from "../../../components/loader";
import TablePagination from "../../dashboard/components/TablePagination";
import moment from "moment";
const AllExhibitions = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  // states
  const [data, setData] = useState();
  // search fields
  const [search, setSearch] = useState(searchParams.get("keyword") || "");
  const [fromDate, setFromDate] = useState(searchParams.get("fromDate") || "");
  const [toDate, setToDate] = useState(searchParams.get("toDate") || "");
  const [type, setType] = useState(searchParams.get("type") || "");

  // pagination
  const [page, setPage] = useState(searchParams.get("page") || 1);
  const [pageSize, setPageSize] = useState(1);
  const [totalPages, setTotalPages] = useState();

  // UI/UX
  const [loading, setLoading] = useState(true);
  const [refreshData, setRefreshData] = useState(true);

  // Functions
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const getData = async () => {
    setLoading(true);
    const response = await GetAllExhibition(
      page,
      search,
      type,
      moment(fromDate).format("DD/MM/YYYY"),
      moment(toDate).format("DD/MM/YYYY")
    );
    setData(response?.data?.Data);
    setPage(response?.data?.Paging?.CurrentPage);
    setPageSize(response?.data?.Paging?.TotalPages);
    setLoading(false);
  };
  useEffect(() => {
    const currentParams = new URLSearchParams(window.location.search);
    if (search) currentParams.set("keyword", search);
    else currentParams.delete("keyword");
    if (fromDate) currentParams.set("fromDate", fromDate);
    else currentParams.delete("fromDate");
    if (toDate) currentParams.set("toDate", toDate);
    else currentParams.delete("toDate");
    if (type) currentParams.set("type", type);
    else currentParams.delete("type");
    if (page) currentParams.set("page", page);
    else currentParams.delete("page");
    const newParamsString = currentParams.toString();
    if (newParamsString !== window.location.search.substring(1)) {
      setSearchParams(currentParams);
    }
    getData();
  }, [fromDate, toDate, type, page, refreshData]);
  return (
    <Styles>
      <BannerWithImage image={bannerImage} title="" height="55vh" />

      <div className="container">
        <p className="title">جدول المعارض والأسواق</p>
        <ExhibitionsFilter
          toDate={toDate}
          setToDate={setToDate}
          fromDate={fromDate}
          setFromDate={setFromDate}
          search={search}
          setSearch={setSearch}
          setRefreshData={setRefreshData}
          type={type}
          setType={setType}
        />
        {loading ? (
          <Loader backgroundColor={"transparent"} />
        ) : (
          <Row className="list justify-content-center">
            {data?.length > 0 ? (
              data?.map((item, index) => (
                <Col sm={12} md={6} lg={4} key={index} className="mb-4">
                  <ExhibitionCard data={item} />
                </Col>
              ))
            ) : (
              <div className="no-elements">
                <p>لا يوجد بيانات لعرضها</p>
              </div>
            )}
          </Row>
        )}
        <TablePagination
          pageSize={pageSize}
          page={page}
          handleChangePage={handlePageChange}
        />
      </div>
    </Styles>
  );
};

export default AllExhibitions;

import environment from "../../environment";
import { PUBLICGET } from "../http.service";

const Countries = environment.GetAllCountries;
export const getCountriesOptions = async () => {
  const response = await PUBLICGET(`${Countries}`, true);
  return response;
};

const Governorate = environment.GetAllGovernorate;
export const getGovernorateOptions = async () => {
  const response = await PUBLICGET(`${Governorate}`, false);
  return response;
};

const Districts = environment.GetAllDistricts;
export const getDistrictsOptions = async (id) => {
  const response = await PUBLICGET(`${Districts}/${id}`, false);
  return response;
};

const Cities = environment.GetAllCities;
export const getCitiesOptions = async (id) => {
  const response = await PUBLICGET(`${Cities}/${id}`, false);
  return response;
};

const Ages = environment.GetAges;
export const getAgesOptions = async () => {
  const response = await PUBLICGET(`${Ages}`, false);
  return response;
};

const CompanyType = environment.GetCompanyType;
export const getCompanyTypeOptions = async () => {
  const response = await PUBLICGET(`${CompanyType}`, false);
  return response;
};

const GetTType = environment.GetTType;
export const getTTypeOptions = async () => {
  const response = await PUBLICGET(`${GetTType}`, false);
  return response;
};

const GetTradeType = environment.GetTradeType;
export const getTradeTypeOptions = async () => {
  const response = await PUBLICGET(`${GetTradeType}`, false);
  return response;
};

const FeminineCompany = environment.GetFeminineCompany;
export const getFeminineCompanyOptions = async () => {
  const response = await PUBLICGET(`${FeminineCompany}`, false);
  return response;
};

const productTypes = environment.GetProductTypes;
export const getProductTypesOptions = async () => {
  const response = await PUBLICGET(`${productTypes}`, false);
  return response;
};

const originCountries = environment.GetOriginCountries;
export const getOriginCountriesOptions = async () => {
  const response = await PUBLICGET(`${originCountries}`, false);
  return response;
};
// needs change id when back end ready
const sectorsEndpoint = environment.GetSectors;
export const getSectorsOptions = async () => {
  const response = await PUBLICGET(`${sectorsEndpoint}`, false);
  return response;
};
// needs change id when back end ready
// const womenLetEndpoint = environment.GetWomenLedOptions;
const womenLetEndpoint = environment.GetFeminineCompany;
export const getWomenLetOptions = async () => {
  const response = await PUBLICGET(`${womenLetEndpoint}`, false);
  return response;
};
const exhibitionTypesEndpoint = environment.GetExhibitionTypes;
export const GetExhibitionTypesOptions = async () => {
  const response = await PUBLICGET(`${exhibitionTypesEndpoint}`, false);
  return response;
};

import React, { useState } from "react";
import classes from "../textField/textfield.module.css";
import {
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Iconify from "../../Iconify";
import { passwordRegex } from "../../../constants/types";

const CustomPasswordInput = ({
  label,
  value,
  setValue,
  isRequired,
  showError,
  setShowError,
  type = "password",
}) => {
  const theme = useTheme();
  const mediaQuery600 = useMediaQuery(theme.breakpoints.down("600"));
  const [regexError, setRegexError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const compareWithRegex = (value) => {
    const regex = passwordRegex;
    setRegexError(!regex.test(value));
    setShowError(!regex.test(value));
  };
  return (
    <div className={classes.root}>
      <InputLabel
        className={`${classes.inputLabel} ${
          isRequired ? classes.requiredField : ""
        } ${showError ? classes.errorClass : ""}`}
        sx={{
          minWidth: "170px !important",
        }}
      >
        {label}
      </InputLabel>
      <TextField
        type={showPassword ? "text" : "password"}
        value={value}
        helperText={
          regexError
            ? "يجب أن تحتوي كلمة المرور على الأقل حرف كبير، وحرف صغير، ورقم واحد، وأحد الرموز الخاصة (@، #، $، %، ^، &، +، =)"
            : showError
            ? type === "confirm_password"
              ? "تأكيد كلمة المرور غير متطابق مع كلمة المرور"
              : type === "password"
              ? "يجب أن تحتوي كلمة المرور على الأقل حرف كبير، وحرف صغير، ورقم واحد، وأحد الرموز الخاصة (@، #، $، %، ^، &، +، =)"
              : "يرجى ادخال كلمة المرور الحالية"
            : null
        }
        required={isRequired}
        onChange={(e) => {
          setValue(e.target.value);
          compareWithRegex(e.target.value);
        }}
        variant="outlined"
        sx={{
          "&.MuiFormControl-root": {
            width: mediaQuery600 ? "60%" : "60%",
            "& .MuiFormHelperText-root": {
              color: "red",
              textAlign: "right",
            },
          },
          "& .MuiInputBase-input": {
            // background: "#F8F9FA",
            padding: "0.7rem",
            borderRadius: "12px",
            border: "none",
            outline: "none",
          },
          "& .MuiOutlinedInput-root": {
            border: showError ? "1px solid red" : "none",
            background: "rgb(237, 239, 240)",
            borderRadius: "12px",
            "&:hover, &:focus": {
              border: showError ? "1px solid red" : "none",
            },
            "&.Mui-focused": {
              border: showError ? "1px solid red" : "none",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleShowPassword} edge="end">
                <Iconify
                  icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default CustomPasswordInput;

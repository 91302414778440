export const checkElementExistInFirstStep = (data, Id) => {
  let result = false;

  data[0]?.DynamicFormSections?.map((section) => {
    section?.DynamicFormFields?.map((field) => {
      if (field.Id === Id) {
        result = true;
      }
    });
  });

  return result;
};
export const findIdByGuid = (data, guid) => {
  let foundEle = { id: "", PicklistId: "" };
  data?.map((step) => {
    step?.DynamicFormSections?.map((section) => {
      section?.DynamicFormFields?.map((field) => {
        if (field.Guid === guid) {
          foundEle = { id: field.Id, PicklistId: field.PicklistId };
        }
      });
    });
  });
  return foundEle;
};
export const findGuidByName = (data, fieldName) => {
  let guid = "";
  data.map((step) =>
    step?.DynamicFormSections?.map((section) => {
      section.DynamicFormFields.map((field) => {
        if (field?.Name === fieldName) {
          guid = field?.Guid;
        }
      });
    })
  );
  return guid;
};
export const findElementObjectById = (data, fieldId) => {
  let elementObj = {};
  data.map((step) =>
    step?.DynamicFormSections?.map((section) => {
      section.DynamicFormFields.map((field) => {
        if (field?.Id == fieldId) {
          elementObj.Field = field;
          elementObj.StepGuid = step.Guid;
          elementObj.SectionGuid = section.Guid;
        }
      });
    })
  );
  return elementObj;
};
export const findFileValueByGuid = (data, Guid) => {
  let value = [];
  data.map((field) => {
    if (field?.FieldId === Guid) value.push(field?.Value);
  });
  return value;
};
// ********* check field validation *********
export const checkFieldCorrection = (id) => {
  let element = document.getElementById(id);
  let requiredText = document.getElementById(`required-${id}`);
  if (element?.hasAttribute("required")) {
    if (element?.value?.length === 0) {
      requiredText.classList.add("visible-field");
      requiredText.classList.remove("hidden-field");
    } else {
      requiredText.classList.add("hidden-field");
      requiredText.classList.remove("visible-field");
    }
    if (element.type === "time" || element.type === "date") {
      let dateTimeElement = document.getElementById(`date-time-${id}`);
      if (!element?.checkValidity()) {
        dateTimeElement?.classList.add("error-border");
      } else {
        dateTimeElement?.classList.remove("error-border");
      }
    } else {
      if (!element?.checkValidity()) {
        element.classList.add("error-border");
      } else {
        element.classList.remove("error-border");
      }
    }
  }
};
export const findElementDefaultValueByGuid = (data, guid) => {
  let defaultValue;
  data.map((step) => {
    step.DynamicFormSections.map((section) => {
      section.DynamicFormFields.map((field) => {
        if (field.Guid === guid) defaultValue = field;
        // if (field.Guid === guid) defaultValue = field.DefaultValue;
      });
    });
  });
  return defaultValue;
};

export const checkAllConditions = (data, checkConditionsChange) => {
  data?.map((step) => {
    step?.DynamicFormSections?.map((section) => {
      section.DynamicFormFields.map((field) => {
        checkConditionsChange(field.Guid);
      });
    });
  });
};

import React from "react";
import environment from "../../../environment";
import { Styles } from "./styles";

const TagComponent = ({
  Id,
  Value,
  TypeId,
  TypeName,
  PicklistOptionId,
  PicklistIdForValue,
}) => {
  if (TypeId == 6) return <div>{Value}</div>;
  else if (TypeId == 7)
    return <div dangerouslySetInnerHTML={{ __html: Value }}></div>;
  else if (TypeId == 8)
    return (
      <Styles>
        <div className="tag-image border-green">
          <img src={`${environment.IMAGE_URL}/${Value}`} alt="" />
        </div>
      </Styles>
    );
  else if (TypeId == 9)
    return (
      <Styles>
        <div className="tag-image border-green">
          <img src={`${environment.IMAGE_URL}/${Value}`} alt="" />
        </div>
      </Styles>
    );
  // else if (TypeId == 10) return <div>picklist options</div>;
};

export default TagComponent;

import { Grid, Stack } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import StepButton from "../../components/stepsButton";
import ConfirmationBox from "../../../../components/elements/confirmationBox";
import SuccessDialog from "../../components/successDialog";
import useStateRef from "react-usestateref";
import axios from "axios";
import {
  LOGIN,
  SendOTPEmail,
  SendOTPMobile,
  VerifyEmailCode,
  VerifyPhoneCode,
} from "../../../../constants/endpoints";
import {
  errorToaster,
  successToaster,
} from "../../../../helpers/toasterConfiguration";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getEmailVerifiedCookie,
  getMobileVerifiedCookie,
  setAccessTokenCookie,
  setEmailVerifiedCookie,
  setMobileVerifiedCookie,
  setRefreshCookie,
} from "../../../../utils/cookie";
import environment from "../../../../environment";
import UserContext from "../../../../context/UserContext";
import encryptMessage from "../../../../utils/encryptMessage";

const FinalStep = ({
  handleBack,
  phoneLabelValue,
  emailLabelValue,
  passWord,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const { setUserProfile } = useContext(UserContext);

  const [disabledMobile, setDisabledMobile] = useState(false);
  const [timeLeftMobile, setTimeLeftMobile] = useState(0);
  const [disabledEmail, setDisabledEmail] = useState(false);
  const [timeLeftEmail, setTimeLeftEmail] = useState(0);

  useEffect(() => {
    if (timeLeftMobile <= 0) return;

    const timerId = setTimeout(() => {
      setTimeLeftMobile((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearTimeout(timerId); // Clear timeout if the component is unmounted
  }, [timeLeftMobile]);

  useEffect(() => {
    if (timeLeftMobile === 0 && disabledMobile) {
      setDisabledMobile(false);
    }
  }, [timeLeftMobile, disabledMobile]);

  useEffect(() => {
    if (timeLeftEmail <= 0) return;

    const timerId = setTimeout(() => {
      setTimeLeftEmail((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearTimeout(timerId); // Clear timeout if the component is unmounted
  }, [timeLeftEmail]);

  useEffect(() => {
    if (timeLeftEmail === 0 && disabledEmail) {
      setDisabledEmail(false);
    }
  }, [timeLeftEmail, disabledEmail]);

  const [emailCodeVerified, setEmailCodeVerified, emailCodeVerifiedRef] =
    useStateRef(false);
  const [mobileCodeVerified, setMobileCodeVerified, mobileCodeVerifiedRef] =
    useStateRef(false);

  const verifiedEmailToken = getEmailVerifiedCookie();
  const verifiedMobileToken = getMobileVerifiedCookie();
  let verifiedEmail;
  let verifiedMobile;
  useEffect(() => {
    if (verifiedEmailToken) {
      verifiedEmail = JSON.parse(verifiedEmailToken);
    }
    if (verifiedMobileToken) {
      verifiedMobile = JSON.parse(verifiedMobileToken);
    }
  }, [verifiedMobileToken, verifiedEmailToken]);

  const email =
    path === "/confirm-account"
      ? localStorage.getItem("email")
      : emailLabelValue;

  const [
    showErrorEmailValidation,
    setShowErrorEmailValidation,
    showErrorEmailValidationRef,
  ] = useStateRef(false);

  const [
    showErrorPhoneValidation,
    setShowErrorPhoneValidation,
    showErrorPhoneValidationRef,
  ] = useStateRef(false);

  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);

  const inputRefsPhone = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];
  const [valuesPhone, setValuesPhone] = useState(["", "", "", "", "", ""]);

  const inputRefsEmail = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];

  const handleResendPhoneCode = () => {
    setDisabledMobile(true);
    setTimeLeftMobile(30);
    axios
      .post(`${SendOTPMobile}?Email=${email}`)
      .then(() => {
        successToaster("تم ارسال رمز التحقق بنجاح الى رقم الهاتف");
      })
      .catch((error) => {
        errorToaster(error.response.data);
      });
  };

  const handleResendEmailCode = () => {
    setDisabledEmail(true);
    setTimeLeftEmail(30);
    axios
      .post(`${SendOTPEmail}?Email=${email}`)
      .then(() => {
        successToaster("تم ارسال رمز التحقق نجاح الى بريدك الالكتروني");
      })
      .catch((error) => {
        errorToaster(error.response.data);
      });
  };

  const [valuesEmail, setValuesEmail] = useState(["", "", "", "", "", ""]);

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    const isFilledPhone = valuesPhone.every((value) => value.length === 1);
    if (isFilledPhone) {
      setShowErrorPhoneValidation(false);
    } else {
      setShowErrorPhoneValidation(true);
    }

    // const isFilledEmail = valuesEmail.every((value) => value.length === 1);

    // if (isFilledEmail) {
    //   setShowErrorEmailValidation(false);
    // } else {
    //   setShowErrorEmailValidation(true);
    // }

    // !showErrorEmailValidationRef.current &&
    if (!showErrorPhoneValidationRef.current) {
      // if (!emailCodeVerifiedRef.current) {
      //   setSubmitLoading(true);
      //   await axios
      //     .post(
      //       `${VerifyEmailCode}?Email=${email}&Code=${valuesEmail.join("")}`
      //     )
      //     .then((res) => {
      // setEmailCodeVerified(true);
      //       successToaster("تم التحقق بنجاح من البريد لالكتروني");
      //     })
      //     .catch((error) => {
      //       errorToaster(error.response.data);
      //       setShowErrorEmailValidation(true);
      //     })
      //     .finally(() => {
      //       setSubmitLoading(false);
      //     });
      // }
      if (!mobileCodeVerifiedRef.current) {
        setSubmitLoading(true);
        await axios
          .post(
            `${VerifyPhoneCode}?Email=${email}&Code=${valuesPhone.join("")}`
          )
          .then((res) => {
            setMobileCodeVerified(true);
            successToaster("تم التحقق بنجاح من رقم الهاتف");
          })
          .catch((error) => {
            errorToaster(error.response.data);
            setShowErrorPhoneValidation(true);
          })
          .finally(() => {
            setSubmitLoading(false);
          });
      }
      // !showErrorEmailValidationRef.current &&
      if (!showErrorPhoneValidationRef.current) {
        // setOpenSuccessDialog(true);

        const params = {
          Email: emailLabelValue,
          Password: encryptMessage(passWord, environment.PUBKEY),
        };

        const config = {
          headers: {
            "Content-Type": "application/json",
            "X-Version": "2",
          },
        };
        axios.get(LOGIN, { params, ...config }).then((res) => {
          localStorage.setItem("email", emailLabelValue);
          localStorage.setItem(environment.TOKEN_KEY, res?.data?.Token);
          setAccessTokenCookie(res.data.Token);
          setEmailVerifiedCookie(res.data.EmailVerified);
          setMobileVerifiedCookie(res.data.MobileVerified);
          setRefreshCookie(res.data.RefreshToken);
          successToaster("تم تسجيل الدخول بنجاح");
          const redirectURL = JSON.parse(
            localStorage.getItem(environment.redirectURL)
          );
          if (redirectURL) {
            setTimeout(() => {
              navigate(`${redirectURL.pathname}${redirectURL.search}`, {
                replace: true,
              });
            }, 1200);
            setUserProfile("");
            localStorage.removeItem(environment.redirectURL);
          } else {
            if (res.data.Token !== "" && res.data.Token) {
              setTimeout(() => {
                navigate("/dashboard/in-progress", { replace: true });
              }, 1200);
            } else {
              setTimeout(() => {
                navigate("/confirm-account", { replace: true });
              }, 1200);
            }
          }
        });
      }
    }
  };

  const handleConfirm = () => {
    // if (!verifiedEmail) {
    //   const isFilledEmail = valuesEmail.every((value) => value.length === 1);

    //   if (isFilledEmail) {
    //     setShowErrorEmailValidation(false);
    //   } else {
    //     setShowErrorEmailValidation(true);
    //   }

    //   if (!showErrorEmailValidationRef.current) {
    //     axios
    //       .post(
    //         `${VerifyEmailCode}?Email=${email}&Code=${valuesEmail.join("")}`
    //       )
    //       .then(() => {
    //         successToaster("تم تأكيد البريد الالكتروني بنجاح");
    //       })
    //       .catch((error) => {
    //         errorToaster(error.response.data);
    //       })
    //       .finally(() => {
    //         navigate("/", { replace: true });
    //       });
    //   }
    // }

    if (!verifiedMobile) {
      const isFilledPhone = valuesPhone.every((value) => value.length === 1);
      if (isFilledPhone) {
        setShowErrorPhoneValidation(false);
      } else {
        setShowErrorPhoneValidation(true);
      }

      if (!showErrorPhoneValidationRef.current) {
        axios
          .post(
            `${VerifyPhoneCode}?Email=${email}&Code=${valuesPhone.join("")}`
          )
          .then(() => {
            successToaster("تم تأكيد رقم الهاتف بنجاح");
          })
          .catch((error) => {
            errorToaster(error.response.data);
          })
          .finally(() => {
            navigate("/", { replace: true });
          });
      }
    }
  };
  return (
    <Stack
      alignItems={"center"}
      justifyContent={"space-between"}
      marginTop={5}
      marginBottom={1}
      sx={{ flex: 1 }}
    >
      <SuccessDialog
        href={"/"}
        openSuccessDialog={openSuccessDialog}
        setOpenSuccessDialog={setOpenSuccessDialog}
      />
      <Grid
        container
        rowGap={4}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <h2>تأكيد رقم الهاتف</h2>
        </Grid>
        {path === "/confirm-account" ? (
          verifiedMobile ? null : (
            <Grid item xs={12} sm={6}>
              <ConfirmationBox
                label="أدخل الرمز الذي تم إرساله إلى رقم الهاتف"
                labelValue={phoneLabelValue}
                errorMessage="لم تصلني الرسالة النصية"
                showError={showErrorPhoneValidation}
                setShowError={setShowErrorPhoneValidation}
                inputRefs={inputRefsPhone}
                values={valuesPhone}
                setValues={setValuesPhone}
                resendFunction={handleResendPhoneCode}
                disabled={disabledMobile}
                timeLeft={timeLeftMobile}
              />
            </Grid>
          )
        ) : (
          <Grid item xs={12} sm={6}>
            <ConfirmationBox
              label="أدخل الرمز الذي تم إرساله إلى رقم الهاتف"
              labelValue={phoneLabelValue}
              errorMessage="لم تصلني الرسالة النصية"
              showError={showErrorPhoneValidation}
              setShowError={setShowErrorPhoneValidation}
              inputRefs={inputRefsPhone}
              values={valuesPhone}
              setValues={setValuesPhone}
              resendFunction={handleResendPhoneCode}
              disabled={disabledMobile}
              timeLeft={timeLeftMobile}
            />
          </Grid>
        )}

        {/* {path === "/confirm-account" ? (
          verifiedEmail ? null : (
            <Grid item xs={12} sm={6}>
              <ConfirmationBox
                label="أدخل الرمز الذي تم إرساله إلى بريدك الالكتروني"
                labelValue={emailLabelValue}
                errorMessage="لم يصلني رمز على البريد الالكتروني"
                showError={showErrorEmailValidation}
                setShowError={setShowErrorEmailValidation}
                inputRefs={inputRefsEmail}
                values={valuesEmail}
                setValues={setValuesEmail}
                resendFunction={handleResendEmailCode}
                disabled={disabledEmail}
                timeLeft={timeLeftEmail}
              />
            </Grid>
          )
        ) : (
          <Grid item xs={12} sm={6}>
            <ConfirmationBox
              label="أدخل الرمز الذي تم إرساله إلى بريدك الالكتروني"
              labelValue={emailLabelValue}
              errorMessage="لم يصلني رمز على البريد الالكتروني"
              showError={showErrorEmailValidation}
              setShowError={setShowErrorEmailValidation}
              inputRefs={inputRefsEmail}
              values={valuesEmail}
              setValues={setValuesEmail}
              resendFunction={handleResendEmailCode}
              disabled={disabledEmail}
              timeLeft={timeLeftEmail}
            />
          </Grid>
        )} */}
      </Grid>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ margin: "2rem 0 0 0" }}
      >
        {/* {path !== "/confirm-account" && (
          <StepButton
            type="backward"
            text={"رجوع"}
            func={handleBack}
            arrow={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="9"
                viewBox="0 0 7 13"
                fill="none"
                style={{ margin: "0 0.3rem" }}
              >
                <path
                  d="M1 1L5.08579 5.08579C5.86683 5.86683 5.86684 7.13316 5.08579 7.91421L1 12"
                  stroke="#000"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            }
          />
        )} */}
        <StepButton
          disabled={submitLoading}
          type="forward"
          text={path !== "/confirm-account" ? "التالي" : "تأكيد"}
          func={path !== "/confirm-account" ? handleSubmit : handleConfirm}
          arrow={
            path !== "/confirm-account" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="9"
                viewBox="0 0 7 13"
                fill="none"
                style={{ margin: "0 0.3rem" }}
              >
                <path
                  d="M5.5 1L1.41421 5.08579C0.633166 5.86683 0.633165 7.13316 1.41421 7.91421L5.5 12"
                  stroke="var(--second-linear-green)"
                  strokeLinecap="round"
                  strokeWidth="3"
                />
              </svg>
            ) : null
          }
        />
      </Stack>
    </Stack>
  );
};

export default FinalStep;

import { Dialog } from "@mui/material";
import React, { useState } from "react";
import { Button, Col } from "react-bootstrap";
import styled from "styled-components";
const Styles = styled.div`
  .notifications-button {
    border-radius: "10px";
    /* background: linear-gradient(180deg, var(--first-linear-green) 0%, var(--second-linear-green) 100%); */
    color: #f8f9fa;
    text-align: center;
    font-size: "1rem";
    font-weight: 600;
    transition: all 0.5s ease-out;
    border: 1px solid #2b7239;
    color: #2b7239;
  }
  .notifications-button:hover {
    border-radius: "10px";
    border: 1px solid #f8f9fa;
    background: #fff;
    color: #2b7239 !important;

    transition: all 0.5s ease-out;
  }
  .card-container {
    width: 80%;
  }
`;
function SimpleDialog(props) {
  const { onClose, open } = props;
  const notifications = [
    { title: "لوريم ايبسوم ابوةبم نبةموب مةبمةم", date: "12-7-2020 pm" },
    { title: "title test", date: "12-7-2020 pm" },
    { title: "title test", date: "12-7-2020 pm" },
    { title: "title test", date: "12-7-2020 pm" },
    { title: "title test", date: "12-7-2020 pm" },
    { title: "title test", date: "12-7-2020 pm" },
    { title: "title test", date: "12-7-2020 pm" },
  ];
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      sx={{
        "& .MuiPaper-root": {
          overflowY: "visible",
          padding: "2rem",
          borderRadius: "17px",
        },
      }}
    >
      <div className="card-container">
        {notifications?.map((item, index) => (
          <li className="d-flex justify-content-between row">
            <Col sm="8">{item?.title}</Col>
            <Col sm="4">{item?.date}</Col>
          </li>
        ))}
      </div>
    </Dialog>
  );
}
const NotificationsDialog = ({ notifications }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <Styles>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        className="notifications-button"
      >
        عرض الإخطارات
      </Button>
      <SimpleDialog
        open={open}
        onClose={handleClose}
        notifications={notifications}
      />
    </Styles>
  );
};

export default NotificationsDialog;

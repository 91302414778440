import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  TextareaAutosize,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import classes from "./contact.module.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import CircleIcon from "../dashboard/components/CircleIcon";
import { successToaster } from "../../helpers/toasterConfiguration";
import loginBackground from "../../assets/images/bg/loginBg.png";
import FEDLoginBackground from "../../assets/images/bg/FEDLoginBg.png";
import { useMediaQuery as useResponsiveMediaQuery } from "react-responsive";
import { typesOfProjects } from "../../constants/enums";
const ContactUs = () => {
  const isFED = process.env.REACT_APP_ACTIVE_ENV == typesOfProjects.FED;
  const theme = useTheme();
  const mediaQueryMd = useMediaQuery(theme.breakpoints.down("768"));
  const isTablet = useResponsiveMediaQuery({ query: "(max-width: 768px)" });
  const [loading, setLoading] = useState(false);
  const handleSubmit = (values) => {
    setLoading(true);

    setTimeout(() => {
      successToaster("تم إرسال المعلومات بنجاح");
      setLoading(false);
    }, 1500);
  };
  //  Form
  const validationSchema = Yup.object({
    name: Yup.string().required("يرجى إدخال الاسم"),
    title: Yup.string().required("يرجى إدخال العنوان"),
    // message: Yup.string().required(t("يرجى إدخال ")),
    email: Yup.string()
      .required("يرجى ادخال البريد الالكتروني")
      .email("صيغة البريد الالكتروني غير صحيحة"),
  });
  // const form = useFormik({
  //   isInitialValidP: true,
  //   validateOnBlur: true,
  //   validateOnChange: false,
  //   onSubmit: (values) => {
  //     submitForm(values);
  //   },
  //   validationSchema,
  //   initialValues: {
  //     email: "",
  //     name: "",
  //     title: "",
  //     message: "",
  //   },
  // });
  return (
    <Grid
      container
      sx={{
        height: mediaQueryMd ? "90vh" : "80vh",
        paddingTop: isTablet ? "1rem" : "",
        // paddingTop: "221rem",
      }}
    >
      <Grid item xs={mediaQueryMd ? 12 : 6}>
        <Stack
          sx={{
            flex: 1,
            height: "100%",
            padding: { xs: "0 1rem", sm: "0 1rem", md: "0 5rem" },
            // padding: "",
          }}
          spacing={3}
          justifyContent={"center"}
        >
          <Typography
            variant="h6"
            textAlign={"center"}
            className={classes.loginText}
          >
            <CircleIcon size="10" />
            <CircleIcon size="10" />
            تواصل معنا
          </Typography>
          <Typography
            variant="P"
            textAlign={"center"}
            className={classes.loginSubText}
          >
            أرسل إلينا بريداً إلكترونياً وسنعود إليك في أقرب وقت ممكن
          </Typography>
          <Formik
            initialValues={{ email: "", name: "", title: "", message: "" }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(errors) => (
              <Form>
                <Stack spacing={2}>
                  <Field
                    className={`${classes.inputField} ${
                      errors.errors.name ? classes.inputFiledError : ""
                    }`}
                    as={TextField}
                    type="text"
                    name="name"
                    placeholder={"الاسم"}
                    variant="outlined"
                    fullWidth
                    helperText={
                      <ErrorMessage
                        name="name"
                        render={(msg) => (
                          <p
                            style={{
                              color: "red",
                              textAlign: "right",
                              fontSize: "1rem",
                            }}
                          >
                            {msg}
                          </p>
                        )}
                      />
                    }
                    sx={{
                      "& .MuiFormHelperText-root": {
                        textAlign: "right",
                      },
                    }}
                  />
                  <Field
                    className={`${classes.inputField} ${
                      errors.errors.email ? classes.inputFiledError : ""
                    }`}
                    as={TextField}
                    type="email"
                    name="email"
                    placeholder={"البريد الاكتروني"}
                    variant="outlined"
                    fullWidth
                    helperText={
                      <ErrorMessage
                        name="email"
                        render={(msg) => (
                          <p
                            style={{
                              color: "red",
                              textAlign: "right",
                              fontSize: "1rem",
                            }}
                          >
                            {msg}
                          </p>
                        )}
                      />
                    }
                    sx={{
                      "& .MuiFormHelperText-root": {
                        textAlign: "right",
                      },
                    }}
                  />
                  <Field
                    className={`${classes.inputField} ${
                      errors.errors.title ? classes.inputFiledError : ""
                    }`}
                    as={TextField}
                    type="text"
                    name="title"
                    placeholder={"عنوان الرسالة"}
                    variant="outlined"
                    fullWidth
                    helperText={
                      <ErrorMessage
                        name="title"
                        render={(msg) => (
                          <p
                            style={{
                              color: "red",
                              textAlign: "right",
                              fontSize: "1rem",
                            }}
                          >
                            {msg}
                          </p>
                        )}
                      />
                    }
                    sx={{
                      "& .MuiFormHelperText-root": {
                        textAlign: "right",
                      },
                    }}
                  />
                  <Field
                    className={`${classes.inputField} ${
                      errors.errors.message ? classes.inputFiledError : ""
                    }`}
                    as="textarea"
                    type="text"
                    name="message"
                    placeholder={"الرسالة"}
                    variant="outlined"
                    fullWidth
                    sx={{
                      "& .MuiFormHelperText-root": {
                        textAlign: "right",
                      },
                    }}
                  />

                  <Box sx={{ textAlign: "center" }}>
                    <Button
                      // onClick={handleSignIn}
                      type="submit"
                      variant="contained"
                      disabled={loading}
                      className={classes.loginButton}
                    >
                      {loading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        "إرسال"
                      )}
                    </Button>
                  </Box>
                </Stack>
              </Form>
            )}
          </Formik>
        </Stack>
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          display: mediaQueryMd ? "none" : "",
          height: "100%",
          backgroundImage: `url(${
            isFED ? FEDLoginBackground : loginBackground
          })`,
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          marginTop: isTablet ? "4rem" : "",
        }}
      >
        <Stack
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ height: "100%", flex: 1 }}
        >
          <Typography
            variant="h4"
            className={`${classes.imageText} ${classes.welcomeText}`}
          >
            مرحبا بكم في موقع وزارة الاقتصاد و التجارة اللبنانية
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ContactUs;

import styled from "styled-components";
export const Styles = styled.div`
  background-image: ${({ image }) => `url(${image});`};
  background-repeat: no-repeat;
  background-size: cover;
  height: ${({ height }) => (height ? height : "65vh")};
  background-position: bottom center;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  h2 {
    font-size: 8rem;
    color: #fff;
    margin-top: 15vh;
  }
  @media (max-width: 1200px) {
    height: 45vh;
    h2 {
      font-size: 8rem;
      margin-top: 9vh;
    }
  }
  @media (max-width: 900px) {
  }

  @media (max-width: 768px) {
    height: 65vh;
    margin-top: 0rem;

    h2 {
      font-size: 6rem;
      margin-top: 25vh;
    }
  }
  @media (max-width: 550px) {
    height: 65vh;
    background-position: center;
    h2 {
      font-size: 6rem;
      margin-top: 27vh;
    }
  }
`;

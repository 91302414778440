import React, { useEffect, useState } from "react";
import RequestInfo from "../../../components/FED/request-information";
import { Button, Skeleton } from "@mui/material";
import CustomAttachment from "../../../components/elements/attachment";
import CustomTextField from "../../../components/elements/textField";
import { Col, Row } from "react-bootstrap";
import { successToaster } from "../../../helpers/toasterConfiguration";
import { Styles } from "./styles";
import {
  GetFEDTransactionDocumentById,
  submitFEDTransactionDocument,
} from "../../../services/fed/fedExhibitors.service";
import { useNavigate, useParams } from "react-router-dom";
import {
  actionsTypes,
  FED_TransactionDocumentStatus,
} from "../../../constants/enums";
import { reportsCheckFieldsValidation } from "../../../helpers/FEDFieldsValidations";
import FairReportFormLoader from "./FairReportFormLoader";

const ExhibitionAndFairReport = () => {
  const navigate = useNavigate();
  const { transactionId, documentId } = useParams();
  const [data, setData] = useState(false);
  // ui/ux
  const [submitLoading, setSubmitLoading] = useState(null);
  const [loading, setLoading] = useState(true);
  const [disabledButton, setDisabledButton] = useState(null);
  const [validateOnChange, setValidateOnChange] = useState(false);
  // fields
  // State for salesTotal
  const [salesTotal, setSalesTotal] = useState("");
  const [salesTotalError, setSalesTotalError, salesTotalErrorRef] =
    useState(false);

  // State for visitorsCount
  const [visitorsCount, setVisitorsCount] = useState("");
  const [visitorsCountError, setVisitorsCountError, visitorsCountErrorRef] =
    useState(false);

  // State for exhibitorsCount
  const [exhibitorsCount, setExhibitorsCount] = useState("");
  const [
    exhibitorsCountError,
    setExhibitorsCountError,
    exhibitorsCountErrorRef,
  ] = useState(false);
  // State for totalArea
  const [totalArea, setTotalArea] = useState("");
  const [totalAreaError, setTotalAreaError, totalAreaErrorRef] =
    useState(false);
  // State for NumberOfInternationalPavilions
  const [numberOfInternationalPavilions, setNumberOfInternationalPavilions] =
    useState("");
  const [
    numberOfInternationalPavilionsError,
    setNumberOfInternationalPavilionsError,
    numberOfInternationalPavilionsErrorRef,
  ] = useState(false);
  // State for ParticipatingCountries
  const [participatingCountries, setParticipatingCountries] = useState("");
  const [
    participatingCountriesError,
    setParticipatingCountriesError,
    participatingCountriesErrorRef,
  ] = useState(false);
  // state for difficulties
  const [difficulties, setDifficulties] = useState("");
  const [difficultiesError, setDifficultiesError, difficultiesErrorRef] =
    useState(false);
  // state for notes
  const [notes, setNotes] = useState("");

  //  attachment
  const [reportCopy, setReportCopy] = useState(null);
  const [reportCopyName, setReportCopyName] = useState(null);
  const [reportCopyError, setReportCopyError, reportCopyErrorRef] =
    useState(false);
  // Functions
  const backToProfile = () => {
    navigate("/dashboard/in-progress", { replace: true });
  };

  // Calling API

  const submit = async (values) => {
    reportsCheckFieldsValidation(
      reportCopy,
      setReportCopyError,
      exhibitorsCount,
      setExhibitorsCountError,
      visitorsCount,
      setVisitorsCountError,
      salesTotal,
      setSalesTotalError,
      difficulties,
      setDifficultiesError,
      totalArea,
      setTotalAreaError,
      numberOfInternationalPavilions,
      setNumberOfInternationalPavilionsError,
      participatingCountries,
      setParticipatingCountriesError
    );

    if (
      !reportCopyError &&
      !exhibitorsCountError &&
      !visitorsCountError &&
      !salesTotalError &&
      !difficultiesError &&
      !totalAreaError &&
      !numberOfInternationalPavilionsError &&
      !participatingCountriesError
    ) {
      setSubmitLoading(true);
      const requestBody = {
        TransactionId: transactionId,
        DocumentTypeId: actionsTypes.MarketReport,
        File: reportCopy,
        FileName: reportCopyName,
        ExhibitorsCount: exhibitorsCount,
        VisitorsCount: visitorsCount,
        TotalSales: salesTotal,
        Difficulties: difficulties,
        TotalArea: totalArea,
        ParticipatingCountries: participatingCountries,
        NumberOfInternationalPavilions: numberOfInternationalPavilions,
        Notes: notes,
      };
      console.log(requestBody);
      const response = await submitFEDTransactionDocument(requestBody);
      if (response?.status === 200 || response?.status === 204) {
        successToaster(response?.data?.Data);
        backToProfile();
      }
      setSubmitLoading(false);
    }
  };
  const getData = async () => {
    setLoading(true);
    const response = await GetFEDTransactionDocumentById(documentId);
    setData(response.data);
    if (response.data?.StatusId == FED_TransactionDocumentStatus.Rejected) {
      setExhibitorsCount(response.data?.ExhibitorsCount);
      setVisitorsCount(response.data?.VisitorsCount);
      setSalesTotal(response.data?.TotalSales);
      setDifficulties(response.data?.Difficulties);
      setReportCopy(
        `${process.env.REACT_APP_IMAGES_ENDPOINT}/${response?.data?.FileUrl}`
      );
    }
    setLoading(false);
  };
  useEffect(() => {
    if (validateOnChange)
      reportsCheckFieldsValidation(
        reportCopy,
        setReportCopyError,
        // reportCopyName,
        // setReportCopyNameError,
        exhibitorsCount,
        setExhibitorsCountError,
        visitorsCount,
        setVisitorsCountError,
        salesTotal,
        setSalesTotalError,
        difficulties,
        setDifficultiesError,
        totalArea,
        setTotalAreaError,
        numberOfInternationalPavilions,
        setNumberOfInternationalPavilionsError,
        participatingCountries,
        setParticipatingCountriesError
      );
  }, [
    reportCopy,
    exhibitorsCount,
    visitorsCount,
    salesTotal,
    difficulties,
    totalArea,
    numberOfInternationalPavilions,
    participatingCountries,
    notes,
  ]);
  useEffect(() => {
    getData();
  }, []);
  return (
    <Styles>
      <div className="container">
        <RequestInfo
          autoNumber={data?.AutoNumber}
          transactionDate={data?.TransactionDate}
          exhibitionName={data?.ExhibitionName}
          openDate={data?.OpenDate}
          loading={loading}
          status={data?.Status}
          statusId={data?.StatusId}
          rejectionReason={data?.RejectionReason}
        />
        <div className="request-page-section">
          <div className="section-title d-flex justify-content-start">
            <h4>تقرير عن السوق</h4>
          </div>
          {loading ? (
            <FairReportFormLoader />
          ) : (
            <Row className="flex-column">
              <Col xs={12} sm={6} id="input-group">
                <CustomTextField
                  label={"عدد العارضين"}
                  type="number"
                  value={exhibitorsCount}
                  setValue={setExhibitorsCount}
                  isRequired={true}
                  showError={exhibitorsCountError}
                  inReportsForms={true}
                />
              </Col>
              <Col xs={12} sm={6} id="input-group">
                <CustomTextField
                  label={"عدد الزوار"}
                  type="number"
                  value={visitorsCount}
                  setValue={setVisitorsCount}
                  isRequired={true}
                  showError={visitorsCountError}
                  inReportsForms={true}
                />
              </Col>
              <Col xs={12} sm={6} id="input-group">
                <CustomTextField
                  label={"حجم المبيعات او الاعمال و الاتفاقيات "}
                  type="number"
                  value={salesTotal}
                  setValue={setSalesTotal}
                  isRequired={true}
                  showError={salesTotalError}
                  inReportsForms={true}
                />
              </Col>
              <Col xs={12} sm={6} id="input-group">
                <CustomTextField
                  label={"المساحة الكلية (م²)"}
                  type="number"
                  value={totalArea}
                  setValue={setTotalArea}
                  isRequired={true}
                  showError={totalAreaError}
                  inReportsForms={true}
                />
              </Col>
              <Col xs={12} sm={6} id="input-group">
                <CustomTextField
                  label={"عدد الاجنحة الدولية"}
                  type="number"
                  value={numberOfInternationalPavilions}
                  setValue={setNumberOfInternationalPavilions}
                  isRequired={true}
                  showError={numberOfInternationalPavilionsError}
                  inReportsForms={true}
                />
              </Col>
              <Col xs={12} sm={6} id="input-group">
                <CustomTextField
                  label={"الدول المشاركة"}
                  type="number"
                  value={participatingCountries}
                  setValue={setParticipatingCountries}
                  isRequired={true}
                  showError={participatingCountriesError}
                  inReportsForms={true}
                />
              </Col>
              <Col xs={12} sm={6} id="input-group">
                <CustomTextField
                  multiline
                  rows={4}
                  label={"العوائق و الصعوبات"}
                  type="textarea"
                  value={difficulties}
                  setValue={setDifficulties}
                  isRequired={true}
                  showError={difficultiesError}
                  inReportsForms={true}
                />
              </Col>
              <Col xs={12} sm={6} id="input-group">
                <CustomTextField
                  multiline
                  rows={4}
                  label={"ملاحظات"}
                  type="textarea"
                  value={notes}
                  setValue={setNotes}
                  isRequired={false}
                  // showError={notesError}
                  inReportsForms={true}
                />
              </Col>
              {/* <Col xs={12} sm={6} id="input-group">
                <CustomAttachment
                  label="نسخة عن التقرير"
                  value={reportCopy}
                  setValue={setReportCopy}
                  isRequired={true}
                  showError={reportCopyError}
                  setImageName={setReportCopyName}
                  showImage={true}
                />
              </Col> */}
            </Row>
          )}
        </div>
        <div className="request-actions">
          <Button
            sx={{
              borderRadius: "15px",
              background:
                "linear-gradient(180deg, var(--first-linear-green) 0%, var(--second-linear-green) 100%)",
              color: "#FFF",
              textAlign: "center",
              fontFamily: "Cairo",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
              marginLeft: "1rem",
              marginBottom: "1rem",
            }}
            variant="contained"
            onClick={() => {
              setValidateOnChange(true);
              submit();
            }}
            disabled={submitLoading}
          >
            {submitLoading ? (
              <span className="indicator-progress">
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                &nbsp; يتم الحفظ
              </span>
            ) : (
              <>تأكيد</>
            )}
          </Button>
          <Button
            sx={{
              borderRadius: "15px",
              background: "linear-gradient(120deg, #f5f5f5 0%, #9e9e9e 100%)",
              color: "#FFF",
              textAlign: "center",
              fontFamily: "Cairo",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
              marginLeft: "1rem",
              marginBottom: "1rem",
            }}
            variant="contained"
            onClick={() => backToProfile()}
            disabled={submitLoading}
          >
            إلغاء
          </Button>
        </div>
      </div>
    </Styles>
  );
};

export default ExhibitionAndFairReport;

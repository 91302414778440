import styled from "styled-components";

export const Styles = styled.div`
  .services-section {
    background-color: var(--mid-blue);
    .title {
      padding-top: 3rem;
      padding-bottom: 3rem;
      padding-right: 11rem;
      h2 {
        font-weight: bold;
      }
    }
    /* .single-service-container {
      justify-content: center;
    } */
    .content {
      ${({ loadingServices }) =>
        loadingServices ? "padding-right: 0rem;" : "padding-right: 12rem;"}
      /* padding-right: 12rem; */
      padding-left: 0rem;
      padding-bottom: 3rem;
      .single-service {
        display: flex;
        flex-direction: column;
        align-items: start;
        padding: 0rem 0rem 2rem 5rem;
        cursor: pointer;
        img {
          width: 59px;
          height: 59px;
        }
        img:hover {
          box-shadow: 0px 0px 20px 0px rgba(137, 138, 140, 0.13);
          -webkit-box-shadow: 0px 0px 20px 0px rgba(137, 138, 140, 0.13);
          -moz-box-shadow: 0px 0px 20px 0px rgba(137, 138, 140, 0.13);
          opacity: 0.7;

          /* filter: invert(13%) sepia(22%) saturate(5364%) hue-rotate(202deg)
          brightness(95%) contrast(95%); */
          /* filter: invert(28%) sepia(13%) saturate(3730%) hue-rotate(107deg)
          brightness(102%) contrast(99%); */
        }
        p {
          color: var(--dark-blue);
          text-align: start;
          padding-top: 1rem;
          font-weight: 400;
          font-size: 1.2rem;
        }
      }
    }
  }
  /* ************************** Responsive *************************** */

  @media (max-width: 1200px) {
    .services-section .content .single-service {
      padding: 0rem 0rem 2rem 2rem !important;
    }
  }

  @media (max-width: 900px) {
    .services-section {
      .title {
        padding-right: 5rem;
      }
      .content {
        padding-right: 5rem;
        padding-left: 5rem;
        padding-bottom: 3rem;
        .single-service {
          padding: 1rem !important;
        }
      }
    }
  }

  @media (max-width: 1300px) and (min-width: 1025px) {
    .services-section {
      .title {
        padding-right: 14rem;
      }
      .content {
        padding-right: 12rem;
        padding-left: 12rem;
        .single-service {
          padding: 1rem;
        }
      }
    }
  }
  /* @media (max-width: 1024px) and (min-width: 769px) {
    .services-section {
      .title {
        padding-right: 10rem;
      }
      .content {
        padding-right: 10rem;
        padding-left: 10rem;
      }
    }
  } */
  @media (max-width: 1024px) and (min-width: 769px) {
    .services-section {
      .title {
        padding-right: 2rem;
      }
      .content {
        padding-right: 3rem;
        padding-left: 0rem;
        padding-bottom: 3rem;
        .single-service {
          padding: 1rem !important;
        }
      }
    }
    .services-section .content .single-service {
      padding: 0rem 0rem 2rem 2rem !important;
    }
  }
  @media (max-width: 768px) {
    .services-section {
      padding-left: 2rem;
      padding-right: 4rem;
      .title {
        padding-right: 2rem;
      }
      .content {
        padding-right: 1rem;
        padding-left: 1rem;
        .single-service-container {
          justify-content: center;
        }
        .single-service {
          padding: 1rem;
          align-items: center;
        }
      }
    }
  }
  @media (max-width: 550px) {
    .services-section {
      padding-left: 2rem;
      padding-right: 2rem;
      .title {
        padding-right: 1rem;

        justify-content: center;
      }
      .content {
        padding-right: 2rem;
        padding-left: 2rem;

        .single-service {
          padding: 1rem;
          align-items: center;
          p {
            text-align: center;
          }
        }
      }
    }
  }
`;

import React from "react";
// import { Pagination } from "react-data-table-component";
import styled from "styled-components";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPrevious from "@mui/icons-material/SkipPrevious";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
// Custom pagination component with Arabic text
const CustomPagination = styled("div")`
  direction: rtl;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 25px;
  font-size: 0.85rem;
  min-height: 35px;
  select {
    border: 0px;
    margin: 0.5rem;
  }
  .pagination-actions {
    display: flex;
    align-items: center;
    height: 35px;
    gap: 15px;
  }
  @media (max-width: 550px) {
    flex-wrap: wrap;
    align-items: center;
  }
`;

const ArabicPagination = ({
  page,
  setPage,
  hasNext,
  hasPrevious,
  pageSize,
  setPageSize,
  currentDisplayingElements,
  totalElements,
  setTotalElements,
  totalPages,
  setTotalPages,
}) => {
  const handleNextPage = () => {
    // Call your API here
    console.log("Next page clicked");
    // Example API call
    console.log("next page API");

    // onChangePage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    // Call your API here for the previous page if needed
    console.log("Previous page clicked");
    // onChangePage(currentPage - 1);
  };

  return (
    <CustomPagination
    // paginationRowsPerPageOptions={paginationRowsPerPageOptions}
    // rowsPerPage={rowsPerPage}
    // rowCount={rowCount}
    // currentPage={currentPage}
    // onChangePage={onChangePage}
    // onChangeRowsPerPage={onChangeRowsPerPage}
    // {...rest}
    >
      <div className="pagination-actions">
        <SkipNextIcon
          style={{
            cursor: hasPrevious ? "pointer" : "auto",
            color: hasPrevious ? "var(--green)" : "grey",
            fontSize: 30,
          }}
          onClick={() => hasPrevious && setPage(1)}
        />
        <ArrowForwardIosIcon
          style={{
            cursor: hasPrevious ? "pointer" : "auto",
            color: hasPrevious ? "var(--green)" : "grey",
            fontSize: 20,
          }}
          onClick={() => hasPrevious && setPage(page - 1)}
        />
        {/* previous */}
        <ArrowBackIosNewIcon
          style={{
            cursor: hasNext ? "pointer" : "auto",
            color: hasNext ? "var(--green)" : "grey",
            fontSize: 20,
          }}
          onClick={() => hasNext && setPage(page + 1)}
        />
        <SkipPrevious
          style={{
            cursor: hasNext ? "pointer" : "auto",
            color: hasNext ? "var(--green)" : "grey",
            fontSize: 30,
          }}
          onClick={() => hasNext && setPage(totalPages)}
        />
      </div>
      <div>
        {page === 1 ? 1 : page - 1 + pageSize} -{" "}
        {currentDisplayingElements + pageSize * (page - 1)} من {totalElements}{" "}
      </div>
      <div>
        عدد العناصر في الصفحة
        <select onChange={(e) => setPageSize(e.target.value)}>
          {[10, 25].map((item) => (
            <option value={item}>{item}</option>
          ))}
        </select>
      </div>
    </CustomPagination>
  );
};

export default ArabicPagination;

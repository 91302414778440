export const servicesLinks = [
  {
    id: "1",
    links: [
      // "https://www.youtube.com/watch?v=xAHjeaVn4Xg&list=PLA8J4WHduRuM8tRscQbK8A7qLISjUe5Le&index=9",
      "xAHjeaVn4Xg",
    ],
    cpdId: "CPD-006 Till CPD-009",
  },
  {
    id: "3",
    links: [
      // "https://www.youtube.com/watch?v=YYjzsDoJFt8&list=PLA8J4WHduRuM8tRscQbK8A7qLISjUe5Le&index=6",
      "YYjzsDoJFt8",
    ],
    cpdId: "CPD-004",
  },
  {
    id: "4",
    links: [
      // "https://www.youtube.com/watch?v=ysVOXJHgg3M&list=PLA8J4WHduRuM8tRscQbK8A7qLISjUe5Le&index=10",
      "ysVOXJHgg3M",
    ],
    cpdId: "CPD-005",
  },
  {
    id: "5",
    links: [
      // "https://www.youtube.com/watch?v=Jij9bKQahJQ&list=PLA8J4WHduRuM8tRscQbK8A7qLISjUe5Le&index=4",
      "Jij9bKQahJQ",
    ],
    cpdId: "CPD-010 Till CPD-014",
  },
  {
    id: "6",
    links: [
      // "https://www.youtube.com/watch?v=FOFGsGz3PPw&list=PLA8J4WHduRuM8tRscQbK8A7qLISjUe5Le&index=3",
      "FOFGsGz3PPw",
    ],
    cpdId: "CPD-019",
  },
  {
    id: "7",
    links: [
      // "https://www.youtube.com/watch?v=eI1jkaR24JU&list=PLA8J4WHduRuM8tRscQbK8A7qLISjUe5Le&index=7",
      "eI1jkaR24JU",
    ],
    cpdId: "CPD-018",
  },
  {
    id: "8",
    links: [
      // "https://www.youtube.com/watch?v=Rao4G23ZzWQ&list=PLA8J4WHduRuM8tRscQbK8A7qLISjUe5Le&index=5",
      "Rao4G23ZzWQ",
    ],
    cpdId: "CPD-020",
  },
  {
    id: "9",
    links: [
      // "https://www.youtube.com/watch?v=3QciwzAk2f4&list=PLA8J4WHduRuM8tRscQbK8A7qLISjUe5Le&index=1+B2:B17",
      // "https://www.youtube.com/watch?v=7O2bm3iqCqw&list=PLA8J4WHduRuM8tRscQbK8A7qLISjUe5Le&index=2",
      "3QciwzAk2f4",
      "7O2bm3iqCqw",
    ],
    cpdId: "CPD-015,CPD-017",
  },
];

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// import { Autoplay, Navigation, Pagination } from "swiper/modules";
// import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

import { Styles } from "./styles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Autoplay, Navigation, Pagination } from "swiper";
const CustomSlider = ({
  slides,
  SlideContentComponent,
  slidesPerViewObj,
  withNavigation = false,
  withAutoPlay = false,
  nextBtn = null,
  previousBtn = null,
}) => {
  return (
    <Styles>
      {withNavigation && (
        <div className="button-area">
          <div
            className={`swiper-button prev-btn ${
              previousBtn ? previousBtn : "prev-slide-component"
            }`}
          >
            {/* <IoIosArrowBack /> */}
            <ArrowBackIosIcon />
          </div>

          <div
            className={`swiper-button next-btn ${
              nextBtn ? nextBtn : "next-slide-component"
            }`}
          >
            {/* <IoIosArrowForward /> */}
            <ArrowForwardIosIcon />
          </div>
        </div>
      )}
      <Swiper
        navigation={
          withNavigation && nextBtn && previousBtn
            ? {
                nextEl: `.${nextBtn}`,
                prevEl: `.${previousBtn}`,
                disabledClass: "swiper-button-disabled",
              }
            : {
                nextEl: `.next-slide-component`,
                prevEl: `.prev-slide-component`,
                disabledClass: "swiper-button-disabled",
              }
        }
        pagination={true}
        modules={[Autoplay, Navigation, Pagination]}
        autoplay={
          withAutoPlay && {
            delay: 2500,
            disableOnInteraction: false,
          }
        }
        // slidesPerView={3}
        breakpoints={{
          640: {
            slidesPerView: slidesPerViewObj ? slidesPerViewObj.mobile : 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: slidesPerViewObj ? slidesPerViewObj.tablet : 1,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: slidesPerViewObj ? slidesPerViewObj.desktop : 1,
            spaceBetween: 25,
          },
        }}
      >
        {slides?.map((item, index) => (
          <SwiperSlide>
            <SlideContentComponent data={item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Styles>
  );
};

export default CustomSlider;

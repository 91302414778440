export const PersonalAttachmentsValidation = (
  data,
  setPersonConcernedIdentityError,
  setApplicantIdentityError,
  setAuthorizationPhotoError
) => {
  const { personConcernedIdentity, applicantIdentity, authorizationPhoto } =
    data;

  if (personConcernedIdentity === null || personConcernedIdentity === "") {
    setPersonConcernedIdentityError(true);
  } else setPersonConcernedIdentityError(false);

  if (applicantIdentity === null || applicantIdentity === "") {
    setApplicantIdentityError(true);
  } else setApplicantIdentityError(false);

  if (authorizationPhoto === null || authorizationPhoto === "") {
    setAuthorizationPhotoError(true);
  } else setAuthorizationPhotoError(false);
};
export const CompanyAttachmentsValidation = (
  data,
  setPersonConcernedIdentityError,
  setApplicantIdentityError,
  setAuthorizationPhotoError,
  setCommercialPhotoError,
  setCommercialRegisterPhotoError
) => {
  const {
    personConcernedIdentity,
    applicantIdentity,
    authorizationPhoto,
    commercialPhoto,
    commercialRegisterPhoto,
  } = data;

  if (personConcernedIdentity === null || personConcernedIdentity === "") {
    setPersonConcernedIdentityError(true);
  } else setPersonConcernedIdentityError(false);

  if (applicantIdentity === null || applicantIdentity === "") {
    setApplicantIdentityError(true);
  } else setApplicantIdentityError(false);

  if (authorizationPhoto === null || authorizationPhoto === "") {
    setAuthorizationPhotoError(true);
  } else setAuthorizationPhotoError(false);

  if (commercialPhoto === null || commercialPhoto === "") {
    setCommercialPhotoError(true);
  } else setCommercialPhotoError(false);

  if (commercialRegisterPhoto === null || commercialRegisterPhoto === "") {
    setCommercialRegisterPhotoError(true);
  } else setCommercialRegisterPhotoError(false);
};

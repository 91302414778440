import environment from "../../environment";
import { POST, PUBLICGET } from "../http.service";

const userProfile = environment.UserProfile;
export const getUserProfile = async () => {
  const response = await PUBLICGET(`${userProfile}`, true);
  return response;
};

const PendingTransactions = environment.PendingTransactions;
export const getPendingTransactionsFunction = async () => {
  const response = await PUBLICGET(`${PendingTransactions}`, true);
  return response;
};

const CompletedTransactions = environment.CompletedTransactions;
export const getCompletedTransactionsFunction = async () => {
  const response = await PUBLICGET(`${CompletedTransactions}`, true);
  return response;
};

const DraftTransactions = environment.DraftTransactions;
export const getDraftTransactionsFunction = async () => {
  const response = await PUBLICGET(`${DraftTransactions}`, true);
  return response;
};
const confirmEndpoint = environment.confirmStep;
export const sendStepCertificate = async (requestBody, tok) => {
  const response = await POST(`${confirmEndpoint}`, requestBody, true, tok);
  return response;
};

import React, { useEffect, useState } from "react";
import RequestInfo from "../../../components/FED/request-information";
import CustomAttachment from "../../../components/elements/attachment";
import { Styles } from "./styles";
import StepButton from "../../register/components/stepsButton";
import { Button } from "@mui/material";
import {
  GetFEDTransactionDocumentById,
  submitFEDTransactionDocument,
} from "../../../services/fed/fedExhibitors.service";
import { useNavigate, useParams } from "react-router-dom";
import { actionsTypes } from "../../../constants/enums";
import { successToaster } from "../../../helpers/toasterConfiguration";
import { insuranceCheckFieldsValidation } from "../../../helpers/FEDFieldsValidations";

const InsurancePolicy = () => {
  const navigate = useNavigate();
  const { transactionId, documentId } = useParams();
  const [data, setData] = useState(false);
  // ui / ux
  const [submitLoading, setSubmitLoading] = useState(null);
  const [loading, setLoading] = useState(true);
  const [disabledButton, setDisabledButton] = useState(null);
  // fields
  const [insurancePolicyFile, setInsurancePolicyFile] = useState(null);
  const [insurancePolicyFileName, setInsurancePolicyFileName] = useState(null);
  const [
    insurancePolicyFileError,
    setInsurancePolicyFileError,
    insurancePolicyFileErrorRef,
  ] = useState(false);
  const backToProfile = () => {
    navigate("/dashboard/in-progress", { replace: true });
  };
  // Calling API
  // post data
  const submit = async () => {
    insuranceCheckFieldsValidation(
      insurancePolicyFile,
      setInsurancePolicyFileError
    );
    let isInValid = insurancePolicyFileError;
    console.log(isInValid);
    if (!isInValid) {
      setSubmitLoading(true);
      let requestBody = {
        TransactionId: transactionId,
        DocumentTypeId: actionsTypes.InsurancePolicy,
        File: insurancePolicyFile,
        FileName: insurancePolicyFileName,
        // ExhibitorsCount: null,
        // VisitorsCount: null,
        // TotalSales: null,
        // Difficulties: "",
      };
      const response = await submitFEDTransactionDocument(requestBody);
      if (response?.status === 200 || response?.status === 204) {
        successToaster(response?.data?.Data);
        backToProfile();
      }
      setSubmitLoading(false);
    }
  };
  const getData = async () => {
    setLoading(true);
    const response = await GetFEDTransactionDocumentById(documentId);
    setData(response.data);
    setLoading(false);
  };
  useEffect(() => {
    insuranceCheckFieldsValidation(
      insurancePolicyFile,
      setInsurancePolicyFileError
    );
  }, [insurancePolicyFile]);
  useEffect(() => {
    getData();
  }, []);

  return (
    <Styles>
      <div className="container">
        <RequestInfo
          autoNumber={data?.AutoNumber}
          transactionDate={data?.TransactionDate}
          exhibitionName={data?.ExhibitionName}
          openDate={data?.OpenDate}
          loading={loading}
          status={data?.Status}
          statusId={data?.StatusId}
          rejectionReason={data?.RejectionReason}
        />
        <div className="request-page-section">
          <div className="section-title d-flex justify-content-start">
            <h4>رفع نسخة عن بوليصة التأمين</h4>
          </div>
          <CustomAttachment
            label="بوليصة التأمين"
            value={insurancePolicyFile}
            setValue={setInsurancePolicyFile}
            isRequired={true}
            showError={insurancePolicyFileError}
            setImageName={setInsurancePolicyFileName}
          />
        </div>
        <div className="request-actions">
          <Button
            sx={{
              borderRadius: "15px",
              background:
                "linear-gradient(180deg, var(--first-linear-green) 0%, var(--second-linear-green) 100%)",
              color: "#FFF",
              textAlign: "center",
              fontFamily: "Cairo",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
              marginLeft: "1rem",
              marginBottom: "1rem",
            }}
            variant="contained"
            onClick={() => submit()}
            disabled={false}
          >
            {submitLoading ? (
              <span className="indicator-progress">
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                &nbsp; يتم الحفظ
              </span>
            ) : (
              <>تأكيد</>
            )}
          </Button>
          <Button
            sx={{
              borderRadius: "15px",
              background: "linear-gradient(120deg, #f5f5f5 0%, #9e9e9e 100%)",
              color: "#FFF",
              textAlign: "center",
              fontFamily: "Cairo",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
              marginLeft: "1rem",
              marginBottom: "1rem",
            }}
            variant="contained"
            onClick={() => backToProfile()}
            disabled={false}
          >
            إلغاء
          </Button>
        </div>
      </div>
    </Styles>
  );
};

export default InsurancePolicy;

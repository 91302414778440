import { Grid, Stack } from "@mui/material";
import React from "react";
import CustomSelect from "../../../../components/elements/select";
import CustomTextField from "../../../../components/elements/textField";
import CustomPhoneNumber from "../../../../components/elements/phoneNumber";
import StepButton from "../../components/stepsButton";
import { useLocation } from "react-router-dom";

const AddressStep = ({
  PersonalInformationLoading,
  citiesOptionsLoading,
  districtsOptionsLoading,
  governorateOptions,
  districtsOptions,
  citiesOptions,
  handleBack,
  handleStepThree,
  phoneNumber,
  setPhoneNumber,
  phoneNumberError,
  setPhoneNumberError,
  governorate,
  setGovernorate,
  governorateError,
  street,
  setStreet,
  streetError,
  judiciary,
  setJudiciary,
  judiciaryError,
  building,
  setBuilding,
  buildingError,
  town,
  setTown,
  townError,
  floor,
  setFloor,
  floorError,
  POBox,
  setPOBox,
  POBoxError,
}) => {
  const location = useLocation();
  const path = location.pathname;

  return (
    <Stack
      alignItems={"center"}
      justifyContent={"space-between"}
      marginTop={5}
      marginBottom={1}
      sx={{ flex: 1 }}
    >
      <Grid container rowGap={4}>
        {path === "/dashboard/settings" ? null : (
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <h2>العنوان</h2>
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <CustomSelect
            label={"المحافظة"}
            value={governorate}
            setValue={setGovernorate}
            showError={governorateError}
            isRequired={true}
            options={governorateOptions}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <CustomTextField
            label={"الشارع"}
            value={street}
            setValue={setStreet}
            showError={streetError}
            isRequired={true}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <CustomSelect
            isLoading={districtsOptionsLoading}
            label={"القضاء"}
            value={judiciary}
            setValue={setJudiciary}
            showError={judiciaryError}
            isRequired={true}
            options={districtsOptions}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <CustomTextField
            label={"البناية"}
            value={building}
            setValue={setBuilding}
            showError={buildingError}
            isRequired={true}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <CustomSelect
            isLoading={citiesOptionsLoading}
            label={"البلدة"}
            value={town}
            setValue={setTown}
            showError={townError}
            isRequired={true}
            options={citiesOptions}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <CustomTextField
            label={"الطابق"}
            value={floor}
            setValue={setFloor}
            showError={floorError}
            isRequired={true}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <CustomTextField
            label={"ص . ب"}
            value={POBox}
            setValue={setPOBox}
            showError={POBoxError}
            isRequired={false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomPhoneNumber
            label={"رقم الهاتف"}
            isRequired={true}
            value={phoneNumber}
            setValue={setPhoneNumber}
            setPhoneNumberError={setPhoneNumberError}
            type="number"
            showError={phoneNumberError}
          />
        </Grid>
      </Grid>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ margin: "2rem 0 0 0" }}
      >
        {path === "/dashboard/settings" ? null : (
          <StepButton
            type="backward"
            text={"رجوع"}
            func={handleBack}
            arrow={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="9"
                viewBox="0 0 7 13"
                fill="none"
                style={{ margin: "0 0.3rem" }}
              >
                <path
                  d="M1 1L5.08579 5.08579C5.86683 5.86683 5.86684 7.13316 5.08579 7.91421L1 12"
                  stroke="#000"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            }
          />
        )}
        <StepButton
          disabled={PersonalInformationLoading}
          type="forward"
          text={path === "/dashboard/settings" ? "حفظ" : "التالي"}
          func={() => {
            handleStepThree({
              governorate: governorate,
              street: street,
              judiciary: judiciary,
              building: building,
              town: town,
              floor: floor,
              POBox: POBox,
              phoneNumber: phoneNumber,
            });
          }}
          arrow={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="7"
              height="9"
              viewBox="0 0 7 13"
              fill="none"
              style={{ margin: "0 0.3rem" }}
            >
              <path
                d="M5.5 1L1.41421 5.08579C0.633166 5.86683 0.633165 7.13316 1.41421 7.91421L5.5 12"
                stroke="var(--second-linear-green)"
                strokeLinecap="round"
                strokeWidth="3"
              />
            </svg>
          }
        />
      </Stack>
    </Stack>
  );
};

export default AddressStep;

import { Grid, Stack } from "@mui/material";
import React from "react";
import CustomAttachment from "../../../../components/elements/attachment/index";
import StepButton from "../../components/stepsButton";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const AttachmentStep = ({
  PersonalInformationLoading,
  checked1,
  checked2,
  handleBack,
  handleAttachmentStep,
  personConcernedIdentity,
  setPersonConcernedIdentity,
  personConcernedIdentityError,
  applicantIdentity,
  setApplicantIdentity,
  applicantIdentityError,
  authorizationPhoto,
  setAuthorizationPhoto,
  authorizationPhotoError,
  commercialPhoto,
  setCommercialPhoto,
  commercialPhotoError,
  commercialRegisterPhoto,
  setCommercialRegisterPhoto,
  commercialRegisterPhotoError,
  setPersonConcernedIdentityName,
  setApplicantIdentityName,
  setAuthorizationPhotoName,
  setCommercialPhotoName,
  setCommercialRegisterPhotoName,
  step4check,
  //
  IdentitiesOfHeirs,
  setIdentitiesOfHeirs,
  IdentitiesOfHeirsError,
  setIdentitiesOfHeirsName,
  //
  InventoryOfInheritance,
  setInventoryOfInheritance,
  InventoryOfInheritanceError,
  setInventoryOfInheritanceName,
  //
  JudicialRulings,
  setJudicialRulings,
  JudicialRulingsError,
  setJudicialRulingsName,
  //
  OwnerIdentityBack,
  setOwnerIdentityBack,
  OwnerIdentityBackError,
  setOwnerIdentityBackName,
  //
  AuthorizedSignatoryIdentity,
  setAuthorizedSignatoryIdentity,
  AuthorizedSignatoryIdentityError,
  setAuthorizedSignatoryIdentityName,
}) => {
  const location = useLocation();
  const path = location.pathname;
  const isIPadView = useMediaQuery({ query: "(max-width: 1024px)" });

  //
  // IdentitiesOfHeirs : string
  // IdentitiesOfHeirsName : string

  //
  // InventoryOfInheritance : string
  // InventoryOfInheritanceName : string

  //
  // JudicialRulings : string
  // JudicialRulingsName : string

  return (
    <Stack
      alignItems={"center"}
      justifyContent={"space-between"}
      marginTop={5}
      marginBottom={1}
      sx={{ flex: 1 }}
    >
      <Grid
        container
        rowGap={4}
        justifyContent={"center"}
        alignItems={"center"}
      >
        {path === "/dashboard/settings" ? null : (
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <h2>المستندات المطلوبة</h2>
          </Grid>
        )}

        {checked1 && (
          <>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={isIPadView ? 6 : 4}
              sx={{ textAlign: "center" }}
            >
              <CustomAttachment
                label="صورة عن هوية صاحب العلاقة الأمامية"
                value={personConcernedIdentity}
                setValue={setPersonConcernedIdentity}
                isRequired={true}
                showError={personConcernedIdentityError}
                setImageName={setPersonConcernedIdentityName}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={isIPadView ? 6 : 4}
              sx={{ textAlign: "center" }}
            >
              <CustomAttachment
                label="صورة عن هوية صاحب العلاقة الخلفية"
                value={OwnerIdentityBack}
                setValue={setOwnerIdentityBack}
                isRequired={true}
                showError={OwnerIdentityBackError}
                setImageName={setOwnerIdentityBackName}
              />
            </Grid>
            {step4check ? (
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={isIPadView ? 6 : 4}
                sx={{ textAlign: "center" }}
              >
                <CustomAttachment
                  label="صورة عن هوية مقدم الطلب"
                  value={applicantIdentity}
                  setValue={setApplicantIdentity}
                  isRequired={true}
                  showError={applicantIdentityError}
                  setImageName={setApplicantIdentityName}
                />
              </Grid>
            ) : null}
            {step4check ? (
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={isIPadView ? 6 : 4}
                sx={{ textAlign: "center" }}
              >
                <CustomAttachment
                  label="صورة عن وكالة التفويض لانجاز المعاملة"
                  value={authorizationPhoto}
                  setValue={setAuthorizationPhoto}
                  isRequired={true}
                  showError={authorizationPhotoError}
                  setImageName={setAuthorizationPhotoName}
                />
              </Grid>
            ) : null}
          </>
        )}

        {checked2 && (
          <>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={isIPadView ? 6 : 4}
              sx={{ textAlign: "center" }}
            >
              <CustomAttachment
                label="اذاعة تجارية"
                value={commercialPhoto}
                setValue={setCommercialPhoto}
                isRequired={true}
                showError={commercialPhotoError}
                setImageName={setCommercialPhotoName}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={isIPadView ? 6 : 4}
              sx={{ textAlign: "center" }}
            >
              <CustomAttachment
                label="السجل التجاري"
                value={commercialRegisterPhoto}
                setValue={setCommercialRegisterPhoto}
                isRequired={true}
                showError={commercialRegisterPhotoError}
                setImageName={setCommercialRegisterPhotoName}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={isIPadView ? 6 : 4}
              sx={{ textAlign: "center" }}
            >
              <CustomAttachment
                label="صورة عن هوية المفوض بالتوقيع الأمامية"
                value={personConcernedIdentity}
                setValue={setPersonConcernedIdentity}
                isRequired={true}
                showError={personConcernedIdentityError}
                setImageName={setPersonConcernedIdentityName}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={isIPadView ? 6 : 4}
              sx={{ textAlign: "center" }}
            >
              <CustomAttachment
                label="صورة عن هوية المفوض بالتوقيع الخلفية"
                value={OwnerIdentityBack}
                setValue={setOwnerIdentityBack}
                isRequired={true}
                showError={OwnerIdentityBackError}
                setImageName={setOwnerIdentityBackName}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={isIPadView ? 6 : 4}
              sx={{ textAlign: "center" }}
            >
              <CustomAttachment
                label="صورة عن وكالة المفوض بالتوقيع "
                value={AuthorizedSignatoryIdentity}
                setValue={setAuthorizedSignatoryIdentity}
                isRequired={false}
                showError={AuthorizedSignatoryIdentityError}
                setImageName={setAuthorizedSignatoryIdentityName}
              />
            </Grid>
            {step4check ? (
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={isIPadView ? 6 : 4}
                sx={{ textAlign: "center" }}
              >
                <CustomAttachment
                  label="صورة عن هوية مقدم الطلب"
                  value={applicantIdentity}
                  setValue={setApplicantIdentity}
                  isRequired={true}
                  showError={applicantIdentityError}
                  setImageName={setApplicantIdentityName}
                />
              </Grid>
            ) : null}

            {step4check ? (
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={isIPadView ? 6 : 4}
                sx={{ textAlign: "center" }}
              >
                <CustomAttachment
                  label="صورة عن وكالة التفويض لانجاز المعاملة"
                  value={authorizationPhoto}
                  setValue={setAuthorizationPhoto}
                  isRequired={true}
                  showError={authorizationPhotoError}
                  setImageName={setAuthorizationPhotoName}
                />
              </Grid>
            ) : null}
          </>
        )}
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={isIPadView ? 6 : 4}
          sx={{ textAlign: "center" }}
        >
          <CustomAttachment
            label="هويات الورثة"
            value={IdentitiesOfHeirs}
            setValue={setIdentitiesOfHeirs}
            isRequired={false}
            showError={IdentitiesOfHeirsError}
            setImageName={setIdentitiesOfHeirsName}
          />
        </Grid>
        <Grid
          item
          lg={isIPadView ? 6 : 4}
          md={6}
          xs={12}
          sm={6}
          sx={{ textAlign: "center" }}
        >
          <CustomAttachment
            label="حصر إرث"
            value={InventoryOfInheritance}
            setValue={setInventoryOfInheritance}
            isRequired={false}
            showError={InventoryOfInheritanceError}
            setImageName={setInventoryOfInheritanceName}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={isIPadView ? 6 : 4}
          sx={{ textAlign: "center" }}
        >
          <CustomAttachment
            label="حكم قضائي"
            value={JudicialRulings}
            setValue={setJudicialRulings}
            isRequired={false}
            showError={JudicialRulingsError}
            setImageName={setJudicialRulingsName}
          />
        </Grid>
      </Grid>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ margin: "2rem 0 0 0" }}
      >
        {path === "/dashboard/settings" ? null : (
          <StepButton
            type="backward"
            text={"رجوع"}
            func={handleBack}
            arrow={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="9"
                viewBox="0 0 7 13"
                fill="none"
                style={{ margin: "0 0.3rem" }}
              >
                <path
                  d="M1 1L5.08579 5.08579C5.86683 5.86683 5.86684 7.13316 5.08579 7.91421L1 12"
                  stroke="#000"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            }
          />
        )}
        <StepButton
          disabled={PersonalInformationLoading}
          type="forward"
          text={path === "/dashboard/settings" ? "حفظ" : "التالي"}
          func={() =>
            handleAttachmentStep({
              personAccount: {
                personConcernedIdentity: personConcernedIdentity,
                applicantIdentity: applicantIdentity,
                authorizationPhoto: authorizationPhoto,
                // هويات الورثة
                IdentitiesOfHeirs: IdentitiesOfHeirs,

                // حصر إرث
                InventoryOfInheritance: InventoryOfInheritance,

                JudicialRulings: JudicialRulings,
              },
              companyAccount: {
                personConcernedIdentity: personConcernedIdentity,
                applicantIdentity: applicantIdentity,
                authorizationPhoto: authorizationPhoto,
                commercialPhoto: commercialPhoto,
                commercialRegisterPhoto: commercialRegisterPhoto,
                // هويات الورثة
                IdentitiesOfHeirs: IdentitiesOfHeirs,

                // حصر إرث
                InventoryOfInheritance: InventoryOfInheritance,

                JudicialRulings: JudicialRulings,
              },
            })
          }
          arrow={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="7"
              height="9"
              viewBox="0 0 7 13"
              fill="none"
              style={{ margin: "0 0.3rem" }}
            >
              <path
                d="M5.5 1L1.41421 5.08579C0.633166 5.86683 0.633165 7.13316 1.41421 7.91421L5.5 12"
                stroke="var(--second-linear-green)"
                strokeLinecap="round"
                strokeWidth="3"
              />
            </svg>
          }
        />
      </Stack>
    </Stack>
  );
};

export default AttachmentStep;

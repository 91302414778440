import React from "react";
import MessageStep from "../../components/mobile-form/steps/submit-step";

const Submite = () => {
  return (
    <div>
      <MessageStep text="تم حفظ الطلب بنجاح" />
    </div>
  );
};

export default Submite;

import React from "react";
import { Styles } from "./styles";
import { Link } from "react-router-dom";
import PageBanner from "../../components/banner";
import bannerImage from "../../assets/images/Cover.webp";
import FEDBannerImage from "../../assets/images/FEDCover.webp";
import { typesOfProjects } from "../../constants/enums";
import FEDStatisticsReportsContent from "../../components/FED/statistics-reports";
const StatisticsAndReports = () => {
  const isFED = process.env.REACT_APP_ACTIVE_ENV == typesOfProjects.FED;
  return (
    <>
      <PageBanner
        bannerImage={isFED ? FEDBannerImage : bannerImage}
        pageTitle="إحصائيات وتقارير"
        pageURL="/statistics-reports"
      />
      <Styles>
        {isFED ? (
          <FEDStatisticsReportsContent />
        ) : (
          <div>
            تنشر مديرية حماية المستهلك تقارير إحصائية دورية تستند إلى البيانات
            المجمعة من الشكاوى والخدمات التي تعالجها المديرية.
            <br />
            يمكنك التحقق من هذه التقارير وتنزيلها لمعرفة المزيد عن إحصائيات
            الشكاوى، بما في ذلك نوع الشكاوى ونوع الأعمال والتوزيع الجغرافي
            للشكاوى، من بين مؤشرات أخرى.
            <br />
            للمزيد من المعلومات&nbsp;
            <Link
              style={{ textDecoration: "none" }}
              to="https://www.economy.gov.lb/en/what-we-provide/consumer-protection/reports/"
              key="2860"
              className="DialogButton"
            >
              اضغط هنا
            </Link>
          </div>
        )}
      </Styles>
    </>
  );
};

export default StatisticsAndReports;

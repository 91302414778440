import styled from "styled-components";

export const Styles = styled.div`
  .env-cont {
    height: 30vh;
    align-items: center;
    justify-content: center;
    color: var(--green);
  }
`;

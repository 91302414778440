// comment at the end of this file
import React, { useEffect, useState } from "react";
import { Col, Row, FormLabel } from "react-bootstrap";
import { Styles } from "./styles";
import InputComponent from "../../components/mobile-form/input-components";
import {
  CheckOTPCodeForFormData,
  checkChassisNumberValidationMobileForm,
  checkCounterNumberValidationMobileForm,
  getDynamicForm,
  getProductsGrid,
  submitDynamicForm,
  updateRequestDocuments,
} from "../../services/dynamicForm.services";
import { FormConditions } from "../../helpers/FormBuilderConditionsNew";
import { getAllUsers } from "../../services/picklists.services";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Button, Tooltip } from "@mui/material";
import moment from "moment";
import OTPForm from "../../components/mobile-form/steps/otp-form";
import Loader from "../../components/loader";

import StepButton from "../register/components/stepsButton";
import { useNavigate } from "react-router";
import {
  errorToaster,
  successToaster,
} from "../../helpers/toasterConfiguration";
import useStateRef from "react-usestateref";
import { getAccessTokenCookie } from "../../utils/cookie";
import SubmitStepProvider from "../../components/mobile-form/steps/submit-step/SubmitStepProvider";
import { useLocation } from "react-router-dom";
import {
  DynamicFormsTypes,
  returnedToClientActionEnum,
} from "../../constants/enums";
import {
  checkFieldCorrection,
  findFileValueByGuid,
  findGuidByName,
} from "../../helpers/dynamic-form/functionsOnElements";
import environment from "../../environment";
import StepsWidget from "../../components/mobile-form/stepsWidget/StepsWidget";
import NotificationModal from "../../components/modals/notification-modal";

const MobileForm = () => {
  // const token = getAccessTokenCookie();
  const navigate = useNavigate();
  // get token
  const [value, setValue, valueRef] = useStateRef(false);
  // get url params
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [dynamicFormId, setDynamicFormId] = useState(
    queryParams.get("dynamicFormId")
  );
  const [transactionId, setTransactionId] = useState(
    queryParams.get("transactionId")
  );
  const [mobileToken, setMobileToken] = useState(queryParams.get("token"));

  const [processId, setProcessId] = useState(queryParams.get("processId"));
  const [sectorId, setSectorId] = useState(queryParams.get("sectorId"));
  const [subsectoreId, setSubsectoreId] = useState(
    queryParams.get("subsectoreId")
  );
  const [formDataId, setFormDataId] = useState(queryParams.get("formDataId"));

  // Data states
  const [DynamicFormConfirmationType, setDynamicFormConfirmationType] =
    useState();
  const [optimizedData, setoptimizedData] = useState([]);
  const [conditions, setConditions] = useState();
  const [conditionsFields, setConditionsFields] = useState();
  const [dataToReview, setDataToReview] = useState();
  const [dynamicFormDisplayName, setDynamicFormDisplayName] = useState();
  const [usersData, setUsersData] = useState();
  const [defaultSubmitMessage, setDefaultSubmitMessage] = useState(true);
  const [submitMessages, setSubmitMessages] = useState();
  const [successMessageHtml, setSuccessMessageHtml] = useState();
  const [documentUploaded, setDocumentUploaded] = useState();
  const [dataToSubmit, setDataToSubmit] = useState();
  const [transactionProducts, setTransactionProducts] = useState([]);
  // state to represent form if returned to client special case or not
  const [isReturnedToClientForm, setIsReturnedToClientForm] = useState(false);

  // trucks custom forms step
  const [lockSubmitButton, setLockSubmitButton] = useState(false);

  // OTP states
  const [valuesPhone, setValuesPhone] = useState(["", "", "", "", "", ""]);
  const [OTPToken, setOTPToken] = useState("");
  const [OTPStepGuid, setOTPStepGuid] = useState("");
  const [allOTPs, setAllOTPs] = useState([]);

  // this is for links of all input files
  const [documentLinks, setDocumentLinks] = useState([]);
  const [documentLink, setDocumentLink] = useState([]);
  // notification modal status
  const [openModal, setOpenModal] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationWithLoader, setNotificationWithLoader] = useState(false);
  const [notificationisLoading, setNotificationisLoading] = useState(false);
  // Buttons States
  const [activeStep, setActiveStep] = useState(0);
  const [disabledButton, setDisabledButton] = useState(true);
  const [submitStep, setSubmitStep] = useState(0);
  const [activeSubmitStep, setActiveSubmitStep] = useState(false);
  const [disabledMobile, setDisabledMobile] = useState(false);
  const [disabledCheckOTP, setDisabledCheckOTP] = useState(false);
  // checking field value validation
  const [fieldValidationError, setFieldValidationError] = useState(false);
  // UI/UX States
  const [loading, setLoading] = useState(false);
  const [refreshForm, setRefreshForm] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [uploadFilesLoading, setUploadFilesLoading] = useState(false);
  const [uploadFileLoading, setUploadFileLoading] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [draftSubmittingLoading, setDraftSubmittingLoading] = useState(false);
  const [codeValidated, setCodeValidated] = useState(false);
  // this is to check that all files are converted to links
  const [allowNextAfterUploadDocuments, setAllowNextAfterUploadDocuments] =
    useState(false);

  // FUNCTIONS AREA
  // ********* Notification modal Functions *********
  const handleClose = () => setOpenModal(false);
  const handleOpen = () => setOpenModal(true);
  // ********* End Notification modal Functions *********
  // ********* Button's Functions *********
  // check submit button placement
  const checkSubmitButton = (data) => {
    data?.map((step, index) => {
      // if (step.StepType === "Step")
      if (step.DefaultVisible) {
        setSubmitStep(index);
      }
    });
  };
  // check Next & submit button enabled
  const checkClickAble = (data, firstLaunch = false) => {
    let status = false;
    const form = document.getElementById("formControl");
    if (form) {
      data[activeStep]?.DynamicFormSections?.map((section) => {
        if (section.DefaultVisible) {
          section.DynamicFormFields?.map((field) => {
            if (
              field.Mandatory &&
              !field.IsYesNoSingleCheckbox &&
              field.DefaultVisible
            ) {
              if (
                field.FieldType === "YesNoRadioButton" ||
                field.PicklistStyle === "CheckboxList" ||
                field.PicklistStyle === "PrettyStyle"
              ) {
                var radios = document.getElementsByName(field.Name);
                var checkRadioValid = false;
                if (radios?.length > 0) {
                  var i = 0;
                  while (!checkRadioValid && i < radios.length) {
                    if (radios[i].checked) checkRadioValid = true;
                    i++;
                  }
                }
                if (checkRadioValid === false) status = true;
              } else if (
                field.FieldType === "Attachment" ||
                field.FieldType === "OtherAttachments"
              ) {
                let element = document.getElementById(field.Id);
                if (firstLaunch) {
                  // check if field value comes from backend
                  if (field?.Value?.length === 0 || field?.Value === null) {
                    status = true;
                  } else {
                    status = false;
                  }
                } else {
                  // check in case of element filled from user
                  if (
                    (element?.hasAttribute("required") || field.Mandatory) &&
                    field.DefaultVisible
                  ) {
                    // !element?.checkValidity()) &&
                    if (
                      element?.value?.length === 0 &&
                      (field?.Value?.length === 0 || field?.Value === null)
                    ) {
                      status = true;
                    }
                    // else console.log("attach !first false");
                  }
                }
              } else {
                let element = document.getElementById(field.Id);
                if (
                  (element?.hasAttribute("required") || field.Mandatory) &&
                  field.DefaultVisible
                ) {
                  if (
                    element?.value?.length === 0 ||
                    !element?.checkValidity()
                  ) {
                    status = true;
                    // element.classList.add("error-border");
                  } else {
                  }
                  //  element.classList.remove("error-border");
                }
              }
            }
          });
        }
      });
    } else status = true;

    setDisabledButton(status);
  };

  const handleBack = () => {
    for (let i = activeStep - 1; i >= 0; i--) {
      if (optimizedData[i]?.DefaultVisible) {
        setActiveStep(i);
        break;
      }
    }
  };
  // ********* End Button's Functions *********

  const chooseSubmitMessage = () => {
    submitMessages?.map((item) => {
      if (item?.DefaultVisible) {
        setSuccessMessageHtml(item?.SuccessMessage);
      } else if (item?.VisibilityConditionId !== null) {
        let requestBody = {};
        const form = document.getElementById("formControl");
        Array.from(form?.elements)?.forEach((input) => {
          let inputName = input?.name;
          if (input.type === "radio") {
            if (input.checked) {
              requestBody[inputName] = input.value;
            }
          } else requestBody[inputName] = input.value;
        });
        conditions.map((condition) => {
          if (condition.Guid === item?.VisibilityConditionGuid) {
            const result = FormConditions.Execute(condition, requestBody);
            if (result) {
              setSuccessMessageHtml(item?.SuccessMessage);
              setDefaultSubmitMessage(false);
            }
          }
        });
      }
      // });
    });
  };

  // ********* Applying Changes Area *********
  const applyChangesOnStep = (result, type, id) => {
    let newData = optimizedData;
    let element = document.getElementById(id);
    if (element)
      if (type === "VisibilityCondition") {
        if (result === true) {
          element.style.display = "block";
        } else element.style.display = "none";
      }
    newData?.map((step) => {
      if (step.Guid === id) {
        step.DefaultVisible = result;
      }
    });
    setoptimizedData(newData);

    checkSubmitButton(optimizedData);
  };
  const applyChangesOnSection = (result, type, stepId, sectionId) => {
    let newData = optimizedData;
    if (type === "VisibilityCondition") {
      if (result === true) {
        document.getElementById(sectionId).style.display = "flex";
      } else document.getElementById(sectionId).style.display = "none";
    }
    let sectionIndex;
    newData?.map((step) => {
      if (step.Guid === stepId) {
        sectionIndex = step?.DynamicFormSections?.findIndex(
          (section) => section.Guid === sectionId
        );
        step.DynamicFormSections[sectionIndex].DefaultVisible = result;
      }
    });
    newData.map(
      (step) =>
        step.Guid === stepId &&
        step?.DynamicFormSections?.map((section) => {
          if (section.Guid === sectionId)
            section.DynamicFormFields.map((field) => {
              if (result === false)
                document.getElementById(field.Id)?.removeAttribute("required");
            });
        })
    );
    setoptimizedData(newData);
  };
  const applyChangesOnField = (result, type, stepId, sectionId, Guid, id) => {
    let newData = optimizedData;
    if (document.getElementById(Guid) && document.getElementById(id))
      if (type === "VisibilityCondition") {
        if (result === true) {
          document.getElementById(Guid).style.display = "block";
        } else {
          // hide target field and remove requird to open next button
          document.getElementById(Guid).style.display = "none";
          if (document.getElementById(id))
            document.getElementById(id).removeAttribute("required");
        }
      } else {
        if (result === true) {
          document.getElementById(id).required = true;
          if (document.getElementById(`mandatory-mark-${Guid}`))
            document.getElementById(`mandatory-mark-${Guid}`).style.display =
              "block";
        } else {
          document.getElementById(id)?.removeAttribute("required");
          if (document.getElementById(`mandatory-mark-${Guid}`))
            document.getElementById(`mandatory-mark-${Guid}`).style.display =
              "none";
        }
      }
    // change visibility and mandatory status in data
    newData.filter(
      (step) =>
        step?.Guid === stepId &&
        step?.DynamicFormSections?.map((section) => {
          if (section.Guid === sectionId) {
            const filedIndex = section.DynamicFormFields.findIndex(
              (field) => field.Guid === Guid
            );
            if (type === "VisibilityCondition")
              section.DynamicFormFields[filedIndex].DefaultVisible = result;
            else section.DynamicFormFields[filedIndex].Mandatory = result;
          }
        })
    );
    setoptimizedData(newData);
  };
  const applyChangesOnRadio = (result, type, stepId, sectionId, Guid, id) => {
    let newData = optimizedData;
    if (document.getElementById(Guid) && document.getElementById(id))
      if (type === "VisibilityCondition") {
        if (result === true) {
          document.getElementById(Guid).style.display = "block";
        } else {
          document.getElementById(Guid).style.display = "none";
        }
      }
    // change visibility and mandatory status in data
    newData.filter(
      (step) =>
        step?.Guid === stepId &&
        step?.DynamicFormSections?.map((section) => {
          if (section.Guid === sectionId) {
            const filedIndex = section.DynamicFormFields.findIndex(
              (field) => field.Guid === Guid
            );
            if (type === "VisibilityCondition")
              section.DynamicFormFields[filedIndex].DefaultVisible = result;
            else section.DynamicFormFields[filedIndex].Mandatory = result;
          }
        })
    );
    setoptimizedData(newData);
  };
  // find the right element (step, section or field) & execute the correct process
  const findElement = (result, itemId, type) => {
    optimizedData?.map((step) => {
      if (step.Guid === itemId) applyChangesOnStep(result, type, itemId);
      else
        step?.DynamicFormSections?.map((section) => {
          if (section.Guid === itemId) {
            applyChangesOnSection(result, type, step.Guid, section.Guid);
          } else
            section.DynamicFormFields.map((field) => {
              if (field.Guid === itemId) {
                if (
                  field.FieldType === "YesNoRadioButton" ||
                  field.PicklistStyle === "CheckboxList" ||
                  field.PicklistStyle === "PrettyStyle"
                )
                  applyChangesOnRadio(
                    result,
                    type,
                    step.Guid,
                    section.Guid,
                    field.Guid,
                    field.Id
                  );
                else
                  applyChangesOnField(
                    result,
                    type,
                    step.Guid,
                    section.Guid,
                    field.Guid,
                    field.Id
                  );
              }
            });
        });
    });
  };
  // exceuteCondition
  const excuteCondition = (conditionId) => {
    let requestBody = {};
    const form = document.getElementById("formControl");
    // create form data to send it to excute function
    if (form)
      Array.from(form?.elements)?.forEach((input) => {
        let inputName = input?.name;
        if (input.type === "radio") {
          if (input.checked) {
            requestBody[inputName] = input.value;
          }
        } else if (input.type === "checkbox") {
          if (input.checked) {
            requestBody[inputName] = "True";
          } else requestBody[inputName] = "False";
        } else requestBody[inputName] = input.value;
      });
    conditions?.map((condition) => {
      if (condition.Guid === conditionId) {
        const result = FormConditions.Execute(condition, requestBody);
        findElement(result, condition.ItemId, condition.Type);
      }
    });
  };
  // listener on condition change
  const checkConditionsChange = (Guid) => {
    conditionsFields?.map((cf, index) => {
      if (cf.FieldGuid === Guid) {
        cf.ConditionsGuid.map((conditionId) => {
          excuteCondition(conditionId);
        });
      }
    });
  };
  // ********* End Applying Changes Area *********
  const removeViewElements = (obj) => {
    delete obj.OriginCountry;
    delete obj.ProductTypeId;
    delete obj.id;
    return obj;
  };
  // ********* Calling API *********
  // Submit & send APIS
  // submit data returned from clients forms (update documents)

  const handleSubmitUpdateDocumentRequest = async () => {
    setSubmitLoading(true);
    let requestArr = [];
    const dataArr = new Map();
    const form = document.getElementById("formControl");
    Array?.from(form?.elements)?.forEach(async (input) => {
      let requestBody = {};
      // let dataResult = {};
      input.checkValidity();
      // let inputName = input?.name;
      if (input.type === "radio") {
        if (input.checked) {
          requestBody["Value"] = input.value;
          requestBody["FieldId"] = findGuidByName(optimizedData, input.name);
          dataArr.set(input?.name, input?.value);
        }
      } else if (input.type === "time") {
        requestBody["Value"] = moment(input.value, ["H:mm"]).format("h:mm A");
        requestBody["FieldId"] = findGuidByName(optimizedData, input.name);
        dataArr.set(input?.name, input?.value);
      } else if (input.type === "file") {
        let fileValue;
        if (input.hasAttribute("multiple")) {
          fileValue = findFileValueByGuid(
            documentLinks,
            findGuidByName(optimizedData, input.name)
          );
        } else {
          fileValue = findFileValueByGuid(
            documentLink,
            findGuidByName(optimizedData, input.name)
          );
        }
        dataArr.set(input?.name, fileValue);
        // requestBody["Value"] = responseLink;
        // requestBody["FieldId"] = findGuid(input.name);
      } else if (input.type === "checkbox") {
        if (input.value === "IsYesNoSingleCheckbox") {
          if (input.checked) {
            requestBody["Value"] = "True";
            requestBody["FieldId"] = findGuidByName(optimizedData, input.name);
          } else {
            requestBody["Value"] = "False";
            requestBody["FieldId"] = findGuidByName(optimizedData, input.name);
          }
        } else if (input.checked) {
          requestBody["Value"] = input?.value;
          requestBody["FieldId"] = findGuidByName(optimizedData, input.name);
          let selectedValues =
            dataArr.get(input?.name) != undefined
              ? dataArr.get(input?.name)
              : [];
          selectedValues.push(input.value);
          dataArr.set(input?.name, selectedValues);
        }
        // saveUploadedFiles(input, requestBody);
      } else {
        requestBody["Value"] = input.value;
        requestBody["FieldId"] = findGuidByName(optimizedData, input.name);
        dataArr.set(input?.name, input?.value);
      }
      if (requestBody.FieldId && requestBody.Value) {
        requestArr.push(requestBody);
      }
    });
    if (documentLinks?.length > 0)
      documentLinks?.map((link) => {
        requestArr.push(link);
      });
    if (documentLink?.length > 0)
      documentLink?.map((link) => {
        requestArr.push(link);
      });
    let finalRequestBody = {
      FieldsData: requestArr,
      FormDataId: formDataId,
    };
    const res = await updateRequestDocuments(
      finalRequestBody,
      valueRef.current
    );
    if (res.status === 200 || res.status === 204) {
      successToaster("تم حفظ المعلومات بنجاح");
      navigate("/dashboard/in-progress", { replace: true });
    } else {
      // errorToaster("عذرا ... حدث خطأ ما");
      errorToaster(res?.data);
    }
    setSubmitLoading(false);
  };
  // submit data for normal forms (draft & submitted)
  const sendData = async (body) => {
    setSubmitLoading(true);
    const res = await submitDynamicForm(
      formDataId ? "draft" : "new",
      body,
      valueRef.current
    );
    if (res.status === 200 || res.status === 204) {
      // if (dynamicFormId == 20) successToaster("تم حفظ المعلومات بنجاح");
      if (
        DynamicFormConfirmationType ==
        DynamicFormsTypes.DigitalConfirmationNeeded
      ) {
        successToaster("تم حفظ المعلومات بنجاح");
        // navigate afetr submit
        navigate("/submit", { replace: true });
      } else
        successToaster(
          "تم حفظ المعلومات، لتأكيد الملف يرجى رفعه مع الإمضاء و الطوابع"
        );
      setFormDataId(res.data);
      // if (dynamicFormId == 20) setDocumentUploaded(true);
      if (
        DynamicFormConfirmationType ==
          DynamicFormsTypes.DigitalConfirmationNeeded ||
        DynamicFormConfirmationType == DynamicFormsTypes.NoConfirmationNeeded
      )
        setDocumentUploaded(true);
      // chooseSubmitMessage();
      setActiveSubmitStep(true);

      window.scrollTo(0, 0);
    } else {
      // errorToaster("عذرا ... حدث خطأ ما");
      errorToaster(res?.data);
    }
    setSubmitLoading(false);
  };
  const handleCheckVerificationCode = async () => {
    const codeToSend = valuesPhone?.join("");
    optimizedData.map((step) => {
      if (step.Guid == optimizedData[activeStep]?.Guid)
        allOTPs.map((otp) => {
          if (otp.StepGuid == optimizedData[activeStep]?.Guid)
            otp.Code = codeToSend;
        });
    });
    setOTPStepGuid(optimizedData[activeStep]?.Guid);
    const response = await CheckOTPCodeForFormData(OTPToken, codeToSend);
    if (response.status === 200) {
      successToaster("تم التحقق من الرمز بنجاح");
      setCodeValidated(true);
    } else {
      setCodeValidated(false);
      errorToaster("يرجى الرمز من الكود و المحاولة لاحقا");
    }
  };
  // submitting dynamic form to API
  const handleSubmitSecond = () => {
    let requestArr = [];
    const dataArr = new Map();
    const form = document.getElementById("formControl");
    if (activeStep === submitStep) {
      Array?.from(form?.elements)?.forEach(async (input) => {
        let requestBody = {};
        // let dataResult = {};
        input.checkValidity();
        // let inputName = input?.name;
        if (input.type === "radio") {
          if (input.checked) {
            requestBody["Value"] = input.value;
            requestBody["FieldId"] = findGuidByName(optimizedData, input.name);
            dataArr.set(input?.name, input?.value);
          }
        } else if (input.type === "time") {
          requestBody["Value"] = moment(input.value, ["H:mm"]).format("h:mm A");
          requestBody["FieldId"] = findGuidByName(optimizedData, input.name);
          dataArr.set(input?.name, input?.value);
        } else if (input.type === "file") {
          let fileValue;
          if (input.hasAttribute("multiple")) {
            fileValue = findFileValueByGuid(
              documentLinks,
              findGuidByName(optimizedData, input.name)
            );
          } else {
            fileValue = findFileValueByGuid(
              documentLink,
              findGuidByName(optimizedData, input.name)
            );
          }
          dataArr.set(input?.name, fileValue);
          // requestBody["Value"] = responseLink;
          // requestBody["FieldId"] = findGuid(input.name);
        } else if (input.type === "checkbox") {
          if (input.value === "IsYesNoSingleCheckbox") {
            if (input.checked) {
              requestBody["Value"] = "True";
              requestBody["FieldId"] = findGuidByName(
                optimizedData,
                input.name
              );
            } else {
              requestBody["Value"] = "False";
              requestBody["FieldId"] = findGuidByName(
                optimizedData,
                input.name
              );
            }
          } else if (input.checked) {
            requestBody["Value"] = input?.value;
            requestBody["FieldId"] = findGuidByName(optimizedData, input.name);
            let selectedValues =
              dataArr.get(input?.name) != undefined
                ? dataArr.get(input?.name)
                : [];
            selectedValues.push(input.value);
            dataArr.set(input?.name, selectedValues);
          }
          // saveUploadedFiles(input, requestBody);
        } else {
          requestBody["Value"] = input.value;
          requestBody["FieldId"] = findGuidByName(optimizedData, input.name);
          dataArr.set(input?.name, input?.value);
        }
        if (requestBody.FieldId && requestBody.Value) {
          requestArr.push(requestBody);
        }
      });
      if (documentLinks?.length > 0)
        documentLinks?.map((link) => {
          requestArr.push(link);
        });
      if (documentLink?.length > 0)
        documentLink?.map((link) => {
          requestArr.push(link);
        });
      // use it only in dynamic form 30
      let transactionProductsRequestBody = [];
      if (dynamicFormId == 30) {
        let userProductsArr = [];
        transactionProducts?.map((item) => {
          let obj = {
            ProductTypeId: item?.ProductTypeId,
            OriginCountryId: item?.OriginCountryId,
            PacketsNumber: item?.PacketsNumber?.toString(),
            TradeName: item?.TradeName,
          };
          userProductsArr.push(obj);
        });
        transactionProductsRequestBody = userProductsArr;
      }
      let finalRequestBodyWithProducts = {
        DynamicFormId: dynamicFormId,
        // StatusId: 0,
        processId: processId,
        FieldsData: requestArr,
        FormDataId: formDataId,
        OTPTokens: [
          {
            Token: OTPToken,
            Code: valuesPhone?.join(""),
            StepGuid: OTPStepGuid,
          },
        ],
        // FormStatus:
        Status:
          DynamicFormConfirmationType ==
          DynamicFormsTypes.SignatureAndStampNeeded
            ? "Draft"
            : "Submitted",

        TransactionProducts: transactionProductsRequestBody,
      };
      // use in other forms
      let finalRequestBody = {
        DynamicFormId: dynamicFormId,
        // StatusId: 0,
        processId: processId,
        FieldsData: requestArr,
        FormDataId: formDataId,
        OTPTokens: [
          {
            Token: OTPToken,
            Code: valuesPhone?.join(""),
            StepGuid: OTPStepGuid,
          },
        ],
        // FormStatus:

        Status:
          DynamicFormConfirmationType ==
          DynamicFormsTypes.SignatureAndStampNeeded
            ? "Draft"
            : "Submitted",
      };

      // const copyContent = async () => {
      //   try {
      //     await navigator.clipboard.writeText(JSON.stringify(finalRequestBody));
      //   } catch (err) {
      //     console.error("Failed to copy: ", err);
      //   }
      // };
      // copyContent();
      setDataToReview(dataArr);

      if (!allowNextAfterUploadDocuments)
        if (
          DynamicFormConfirmationType ==
          DynamicFormsTypes.SignatureAndStampNeeded
        )
          if (dynamicFormId == 30) {
            sendData(finalRequestBodyWithProducts);
          } else sendData(finalRequestBody);
        else {
          setDataToSubmit(finalRequestBody);
          setActiveSubmitStep(true);
          window.scrollTo(0, 0);
        }
      chooseSubmitMessage();
    } else {
      form.reportValidity();
      if (!disabledButton) {
        for (let i = activeStep + 1; i < optimizedData?.length; i++) {
          if (optimizedData[i]?.DefaultVisible) {
            setActiveStep(i);
            break;
          }
        }
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 300);
      }
    }
  };
  // trucks custom forms step chassis check
  const checkChassisValidationBeforeSubmit = () => {
    const checkChassisValidationAPI = async (chassisId) => {
      const chassisNumber = document.getElementById(chassisId).value;
      const response = await checkChassisNumberValidationMobileForm(
        chassisNumber,
        valueRef.current
      );
      if (response.status === 200) {
        if (!response.data) {
          handleSubmitSecond();
        } else {
          errorToaster("عذراً! لقد قمت بتقديم هذا الطلب مسبقًا ومازال صالحًا");
          setLockSubmitButton(true);
        }
      }
    };

    if (dynamicFormId == 26) {
      checkChassisValidationAPI(181);
    } else if (dynamicFormId == 27) {
      checkChassisValidationAPI(196);
    } else if (dynamicFormId == 28) {
      checkChassisValidationAPI(203);
    }
  };
  // trucks custom forms step number counter check
  const checkCounterNumberValidationBeforeSubmit = async (counterId) => {
    const counterNumber = document.getElementById(counterId).value;
    const response = await checkCounterNumberValidationMobileForm(
      counterNumber,
      valueRef.current
    );
    if (response.status === 200) {
      if (!response.data) {
        handleSubmitSecond();
      } else {
        errorToaster("عذراً! لقد قمت بتقديم هذا الطلب مسبقًا ومازال صالحًا");
        setLockSubmitButton(true);
      }
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      dynamicFormId == "26" ||
      dynamicFormId == "27" ||
      dynamicFormId == "28"
    ) {
      checkChassisValidationBeforeSubmit();
    } else if (dynamicFormId == "29") {
      checkCounterNumberValidationBeforeSubmit(215);
    } else handleSubmitSecond();
  };
  const handleSaveDraft = async (event) => {
    setDraftSubmittingLoading(true);
    event.preventDefault();
    let requestArr = [];
    // let dataArr = [];
    const dataArr = new Map();
    const form = document.getElementById("formControl");

    Array?.from(form?.elements)?.forEach(async (input) => {
      let requestBody = {};
      // let dataResult = {};
      input.checkValidity();
      // let inputName = input?.name;
      if (input.type === "radio") {
        if (input.checked) {
          requestBody["Value"] = input.value;
          requestBody["FieldId"] = findGuidByName(optimizedData, input.name);
          dataArr.set(input?.name, input?.value);
        }
      } else if (input.type === "time") {
        requestBody["Value"] = moment(input.value, ["H:mm"]).format("h:mm A");
        requestBody["FieldId"] = findGuidByName(optimizedData, input.name);
        dataArr.set(input?.name, input?.value);
      } else if (input.type === "file") {
        let fileValue;
        if (input.hasAttribute("multiple")) {
          fileValue = findFileValueByGuid(
            documentLinks,
            findGuidByName(optimizedData, input.name)
          );
        } else {
          fileValue = findFileValueByGuid(
            documentLink,
            findGuidByName(optimizedData, input.name)
          );
        }
        dataArr.set(input?.name, fileValue);
        // requestBody["Value"] = responseLink;
        // requestBody["FieldId"] = findGuid(input.name);
      } else if (input.type === "checkbox") {
        if (input.value === "IsYesNoSingleCheckbox") {
          if (input.checked) {
            requestBody["Value"] = "True";
            requestBody["FieldId"] = findGuidByName(optimizedData, input.name);
          } else {
            requestBody["Value"] = "False";
            requestBody["FieldId"] = findGuidByName(optimizedData, input.name);
          }
        } else if (input.checked) {
          requestBody["Value"] = input?.value;
          requestBody["FieldId"] = findGuidByName(optimizedData, input.name);
          let selectedValues =
            dataArr.get(input?.name) != undefined
              ? dataArr.get(input?.name)
              : [];
          selectedValues.push(input.value);
          dataArr.set(input?.name, selectedValues);
        }
        // saveUploadedFiles(input, requestBody);
      } else {
        requestBody["Value"] = input.value;
        requestBody["FieldId"] = findGuidByName(optimizedData, input.name);
        dataArr.set(input?.name, input?.value);
      }
      if (requestBody.FieldId && requestBody.Value) {
        requestArr.push(requestBody);
      }
    });
    if (documentLinks?.length > 0)
      documentLinks?.map((link) => {
        requestArr.push(link);
      });
    if (documentLink?.length > 0)
      documentLink?.map((link) => {
        requestArr.push(link);
      });
    // use it only in dynamic form 30
    let transactionProductsRequestBody = [];
    if (dynamicFormId == 30) {
      let userProductsArr = [];
      transactionProducts?.map((item) => {
        let obj = {
          ProductTypeId: item?.ProductTypeId,
          OriginCountryId: item?.OriginCountryId,
          PacketsNumber: item?.PacketsNumber?.toString(),
          TradeName: item?.TradeName,
        };
        userProductsArr.push(obj);
      });
      transactionProductsRequestBody = userProductsArr;
    }
    let finalRequestBodyWithProducts = {
      DynamicFormId: dynamicFormId,
      // StatusId: 0,
      processId: processId,
      FieldsData: requestArr,
      FormDataId: formDataId,
      OTPTokens: [
        {
          Token: OTPToken,
          Code: valuesPhone?.join(""),
          StepGuid: OTPStepGuid,
        },
      ],
      // FormStatus:
      Status: "Draft",
      TransactionProducts: transactionProductsRequestBody,
    };
    let finalRequestBody = {
      DynamicFormId: dynamicFormId,
      // StatusId: 0,
      processId: processId,
      FieldsData: requestArr,
      FormDataId: formDataId,
      OTPTokens: [
        {
          Token: OTPToken,
          Code: valuesPhone?.join(""),
          StepGuid: OTPStepGuid,
        },
      ],
      // FormStatus:

      Status: "Draft",
    };
    // const copyContent = async () => {
    //   try {
    //     await navigator.clipboard.writeText(JSON.stringify(finalRequestBody));
    //   } catch (err) {
    //     console.error("Failed to copy: ", err);
    //   }
    // };
    // copyContent();
    // setDataToReview(dataArr);
    // sendData(finalRequestBody);

    const res = await submitDynamicForm(
      formDataId ? "draft" : "new",
      dynamicFormId == 30 ? finalRequestBodyWithProducts : finalRequestBody,
      valueRef.current
    );
    if (res.status === 200 || res.status === 204) {
      successToaster(
        "تم حفظ المسودة بنجاح يمكنك متابعة إدخال البيانات في وقت لاحق"
      );
      //   navigate after save draft
      navigate("/draft", { replace: true });
    } else errorToaster("عذرا ... حدث خطأ ما");
    setDraftSubmittingLoading(false);
  };
  // Get APIS
  const getProductsGridData = async () => {
    const response = await getProductsGrid(transactionId);
    if (response?.status == 200) {
      const newArray = response?.data?.map((item, index) => {
        return { ...item, Id: index };
      });
      setTransactionProducts(newArray);
    }
  };
  const getData = async () => {
    setLoading(true);
    if (formDataId && transactionId && dynamicFormId == 30) {
      getProductsGridData();
    }
    const response = await getDynamicForm(dynamicFormId, formDataId);
    if (response.status === 200) {
      if (
        (response?.data?.DynamicFormConfirmationType ==
          DynamicFormsTypes.SignatureAndStampNeeded ||
          dynamicFormId == 37) &&
        !valueRef.current
      ) {
        localStorage.setItem(environment.redirectURL, JSON.stringify(location));
        navigate("/login", { replace: true });
      } else {
        setoptimizedData(response?.data?.Steps);
        setConditions(response?.data?.Conditions);
        setConditionsFields(response?.data?.ConditionsFields);
        // setDynamicFormId(response?.data?.DynamicFormId);
        setDynamicFormDisplayName(response?.data?.DynamicFormDisplayName);
        setSubmitMessages(response?.data?.SuccessMessages);
        setDynamicFormConfirmationType(
          response?.data?.DynamicFormConfirmationType
        );
        setIsReturnedToClientForm(
          response?.data?.TransactionStatusId == returnedToClientActionEnum
        );
        // sort Steps
        response?.data?.Steps?.sort((a, b) => a.Order - b.Order);
        // sort Sections
        response?.data?.Steps?.map((step) =>
          step?.DynamicFormSections?.sort((a, b) => a.Order - b.Order)
        );
        // sort Fields
        response?.data?.Steps?.map((step) =>
          step?.DynamicFormSections?.map((section) =>
            section?.DynamicFormFields?.sort((a, b) => a.Order - b.Order)
          )
        );
        checkSubmitButton(response?.data?.Steps);
        setLoading(false);
        setTimeout(() => {
          checkClickAble(response?.data?.Steps, true);
        }, 1500);
        // checkClickAble(response?.data?.Steps);
      }
    } else {
      navigate("/404", { replace: true });
    }
  };
  const getUsers = async () => {
    setUserLoading(true);
    const response = await getAllUsers();
    if (response.status === 200) {
      setUsersData(response.data);
      setUserLoading(false);
    }
  };
  // ********* End Calling API *********

  useEffect(() => {
    if (activeStep !== 0) checkClickAble(optimizedData);
  }, [activeStep]);
  // useEffect(() => {
  //   if (formDataId)
  //     setTimeout(() => {
  //       checkClickAble(optimizedData);
  //     }, 2000);
  // }, []);

  useEffect(() => {
    // optimizedData[activeStep]?.DynamicFormSections?.map((section) => {
    optimizedData?.map((step) => {
      step?.DynamicFormSections?.map((section) => {
        section.DynamicFormFields.map((field) => {
          checkConditionsChange(field?.Guid);
        });
      });
    });
  }, [activeStep]);

  useEffect(() => {
    setTimeout(() => {
      optimizedData?.map((step) => {
        step?.DynamicFormSections?.map((section) => {
          section.DynamicFormFields.map((field) => {
            checkConditionsChange(field.Guid);
          });
        });
      });
    }, 1000);
  }, []);
  useEffect(() => {
    // if (token || dynamicFormId == 20) {
    getData();
    getUsers();
    // } else navigate("/login", { replace: true });
  }, [refreshForm]);
  useEffect(() => {
    setValue(queryParams.get("token"));
  }, []);

  return (
    <Styles>
      {loading ? (
        <Loader />
      ) : activeSubmitStep ? (
        <>
          <SubmitStepProvider
            documentUploaded={documentUploaded}
            defaultSubmitMessage={defaultSubmitMessage}
            fieldsData={dataToReview}
            data={optimizedData}
            setActiveSubmitStep={setActiveSubmitStep}
            setActiveStep={setActiveStep}
            usersData={usersData}
            setDocumentUploaded={setDocumentUploaded}
            dynamicFormDisplayName={dynamicFormDisplayName}
            dynamicFormId={dynamicFormId}
            dataToSubmit={dataToSubmit}
            sendData={sendData}
            submitLoading={submitLoading}
            setSubmitLoading={setSubmitLoading}
            formDataId={formDataId}
            documentLink={documentLink}
            documentLinks={documentLinks}
            successMessageHtml={successMessageHtml}
            DynamicFormConfirmationType={DynamicFormConfirmationType}
            processId={processId}
            refreshForm={refreshForm}
            setRefreshForm={setRefreshForm}
            transactionProducts={transactionProducts}
          />
        </>
      ) : (
        <div id="top-of-page">
          <StepsWidget
            steps={optimizedData}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            submitStep={submitStep}
            dynamicFormId={dynamicFormId}
            dynamicFormDisplayName={dynamicFormDisplayName}
            uploadFileLoading={uploadFileLoading}
            uploadFilesLoading={uploadFilesLoading}
            disabledButton={disabledButton}
            allowNextAfterUploadDocuments={allowNextAfterUploadDocuments}
            submitLoading={submitLoading}
          />

          <form id="formControl" action="#" className=" p-2 m-2">
            <>
              {optimizedData?.map(
                (step, index) =>
                  step?.DefaultVisible &&
                  (step?.StepType === "Step" ? (
                    <div className={activeStep !== index && "hidden-step"}>
                      {step?.DynamicFormSections?.map((section) => (
                        <Row
                          id={section.Guid}
                          className={
                            section?.DefaultVisible
                              ? "visible-section"
                              : "hidden-section"
                          }
                        >
                          <div className="text-center mb-3">
                            <h4>{section?.Name}</h4>
                          </div>
                          {section?.DynamicFormFields?.map(
                            ({
                              Id,
                              Guid,
                              Name,
                              FieldType,
                              Label,
                              Suffix,
                              Prefix,
                              Mandatory,
                              MandatoryConditionGuid,
                              MandatoryCondition,
                              DefaultVisible,
                              VisibilityConditionGuid,
                              VisibilityCondition,
                              Tooltip: customeTooltip,
                              Placeholder,
                              Value,
                              DefaultValue,
                              MultipleValues,
                              CustomFormat,
                              MinValidationMessage,
                              MaxValidationMessage,
                              MinCharCount,
                              MaxCharCount,
                              NumberType,
                              MinNumValue,
                              MaxNumValue,
                              MinDateValue,
                              MaxDateValue,
                              MinTimeValue,
                              MaxTimeValue,
                              PicklistStyle,
                              PicklistId,
                              PicklistFilterType,
                              PicklistFilterFieldGuid,
                              PicklistFilterParentOptionId,
                              PicklistMultipleSelection,
                              IsYesNoSingleCheckbox,
                              YesLabel,
                              NoLabel,
                              OtherAttachmentsLimit,
                              AttachmentFileSizeLimit,
                              AttachmentFileAllowedFormats,
                              Htmlconent,
                              DropdownListInfoBoxTag,
                              DropdownListFieldGuid,
                              PicklistFilterTag,
                              PicklistFieldFilterTag,
                              LoadFormDataSourceFieldId,
                              LoadFormDataBaseFieldGuid,
                              LoadFormDataLoadByUser,
                              LoadFormDataSourceDynamicFormId,
                              DropDownListExternalDataApilink,
                              DropDownListExternalDataLabelName,
                              DropDownListExternalDataValueName,
                              AttachmentFileSourceLink,
                            }) => (
                              <Col
                                xs="12"
                                md={section?.ColumnsSize}
                                id={Guid}
                                // style={{
                                //   display: DefaultVisible ? "block" : "none",
                                // }}
                                className={
                                  DefaultVisible
                                    ? "visible-field mt-3"
                                    : "hidden-field mt-3"
                                }
                              >
                                {FieldType === "Label" ? (
                                  <Row>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: Htmlconent,
                                      }}
                                    ></div>
                                  </Row>
                                ) : (
                                  <Row>
                                    {Label &&
                                      FieldType !== "DropdownListInfoBox" && (
                                        <Col
                                          xm="12"
                                          md={
                                            section?.ColumnsSize === 12
                                              ? "3"
                                              : "4"
                                          }
                                          lg={
                                            section?.ColumnsSize === 12
                                              ? "3"
                                              : "4"
                                          }
                                        >
                                          <FormLabel for={Name} className="">
                                            {Label}
                                            {!IsYesNoSingleCheckbox && (
                                              <span
                                                className="text-red"
                                                id={`mandatory-mark-${Guid}`}
                                                style={{
                                                  display: Mandatory
                                                    ? "contents"
                                                    : "none",
                                                }}
                                              >
                                                *
                                              </span>
                                            )}
                                            {customeTooltip && (
                                              <Tooltip
                                                title={customeTooltip}
                                                placement="left"
                                              >
                                                <InfoOutlinedIcon fontSize="small" />
                                              </Tooltip>
                                            )}
                                          </FormLabel>
                                        </Col>
                                      )}
                                    <Col
                                      xm="12"
                                      md={
                                        FieldType === "Grid"
                                          ? "12"
                                          : FieldType !== "DropdownListInfoBox"
                                          ? section?.ColumnsSize === 12
                                            ? "9"
                                            : "8"
                                          : section?.ColumnsSize === 12
                                          ? "12"
                                          : "12"
                                      }
                                      lg={
                                        FieldType === "Grid"
                                          ? "12"
                                          : FieldType !== "DropdownListInfoBox"
                                          ? section?.ColumnsSize === 12
                                            ? "9"
                                            : "8"
                                          : section?.ColumnsSize === 12
                                          ? "12"
                                          : "12"
                                      }
                                    >
                                      <InputComponent
                                        Name={Name}
                                        id={Id}
                                        Guid={Guid}
                                        FieldType={FieldType}
                                        Mandatory={Mandatory}
                                        Visibility={DefaultVisible}
                                        Placeholder={Placeholder}
                                        DefaultValue={
                                          formDataId ? Value : DefaultValue
                                        }
                                        MultipleValues={MultipleValues}
                                        CustomFormat={CustomFormat}
                                        PicklistStyle={PicklistStyle}
                                        PicklistId={PicklistId}
                                        PicklistFilterType={PicklistFilterType}
                                        PicklistFilterFieldGuid={
                                          PicklistFilterFieldGuid
                                        }
                                        PicklistFilterParentOptionId={
                                          PicklistFilterParentOptionId
                                        }
                                        PicklistMultipleSelection={
                                          PicklistMultipleSelection
                                        }
                                        IsYesNoSingleCheckbox={
                                          IsYesNoSingleCheckbox
                                        }
                                        YesLabel={YesLabel}
                                        NoLabel={NoLabel}
                                        MinValidationMessage={
                                          MinValidationMessage
                                        }
                                        MaxValidationMessage={
                                          MaxValidationMessage
                                        }
                                        MinCharCount={MinCharCount}
                                        MaxCharCount={MaxCharCount}
                                        MinNumValue={MinNumValue}
                                        MaxNumValue={MaxNumValue}
                                        MinDateValue={MinDateValue}
                                        MaxDateValue={MaxDateValue}
                                        MinTimeValue={MinTimeValue}
                                        MaxTimeValue={MaxTimeValue}
                                        OtherAttachmentsLimit={
                                          OtherAttachmentsLimit
                                        }
                                        AttachmentFileSizeLimit={
                                          AttachmentFileSizeLimit
                                        }
                                        AttachmentFileAllowedFormats={
                                          AttachmentFileAllowedFormats
                                        }
                                        data={optimizedData}
                                        usersData={usersData}
                                        userLoading={userLoading}
                                        checkClickAble={checkClickAble}
                                        checkConditionsChange={
                                          checkConditionsChange
                                        }
                                        checkFieldCorrection={
                                          checkFieldCorrection
                                        }
                                        columnSize={section?.ColumnsSize}
                                        documentLinks={documentLinks}
                                        setDocumentLinks={setDocumentLinks}
                                        documentLink={documentLink}
                                        setDocumentLink={setDocumentLink}
                                        setAllowNextAfterUploadDocuments={
                                          setAllowNextAfterUploadDocuments
                                        }
                                        setUploadFilesLoading={
                                          setUploadFilesLoading
                                        }
                                        uploadFilesLoading={uploadFilesLoading}
                                        setUploadFileLoading={
                                          setUploadFileLoading
                                        }
                                        uploadFileLoading={uploadFileLoading}
                                        activeStep={activeStep}
                                        sectorId={sectorId}
                                        subsectoreId={subsectoreId}
                                        DropdownListInfoBoxTag={
                                          DropdownListInfoBoxTag
                                        }
                                        DropdownListFieldGuid={
                                          DropdownListFieldGuid
                                        }
                                        PicklistFilterTag={PicklistFilterTag}
                                        PicklistFieldFilterTag={
                                          PicklistFieldFilterTag
                                        }
                                        queryParams={queryParams}
                                        LoadFormDataSourceFieldId={
                                          LoadFormDataSourceFieldId
                                        }
                                        LoadFormDataBaseFieldGuid={
                                          LoadFormDataBaseFieldGuid
                                        }
                                        LoadFormDataLoadByUser={
                                          LoadFormDataLoadByUser
                                        }
                                        LoadFormDataSourceDynamicFormId={
                                          LoadFormDataSourceDynamicFormId
                                        }
                                        dynamicFormId={dynamicFormId}
                                        setoptimizedData={setoptimizedData}
                                        setLoading={setLoading}
                                        DropDownListExternalDataApilink={
                                          DropDownListExternalDataApilink
                                        }
                                        DropDownListExternalDataLabelName={
                                          DropDownListExternalDataLabelName
                                        }
                                        DropDownListExternalDataValueName={
                                          DropDownListExternalDataValueName
                                        }
                                        handleOpen={handleOpen}
                                        handleClose={handleClose}
                                        setNotificationWithLoader={
                                          setNotificationWithLoader
                                        }
                                        setNotificationisLoading={
                                          setNotificationisLoading
                                        }
                                        setNotificationMessage={
                                          setNotificationMessage
                                        }
                                        setFieldValidationError={
                                          setFieldValidationError
                                        }
                                        // important for grid table
                                        applyChangesOnField={
                                          applyChangesOnField
                                        }
                                        transactionProducts={
                                          transactionProducts
                                        }
                                        setTransactionProducts={
                                          setTransactionProducts
                                        }
                                        lockAllFieldsExceptAttachments={
                                          isReturnedToClientForm
                                        }
                                      />
                                      {FieldType === "Attachment" &&
                                        AttachmentFileSourceLink?.length >
                                          0 && (
                                          <a
                                            href={AttachmentFileSourceLink}
                                            target="_blank"
                                            className="suffix-text"
                                            style={{ textDecoration: "none" }}
                                            id="download-link"
                                          >
                                            تحميل الملف المرفق{" "}
                                          </a>
                                        )}
                                      <span className="suffix-text">
                                        {Suffix}
                                      </span>
                                      <span
                                        id={`required-${Id}`}
                                        className="text-red error-message hidden-field"
                                      >
                                        هذا الحقل مطلوب
                                      </span>
                                      <span id={`validate-${Id}`}></span>
                                    </Col>
                                  </Row>
                                )}
                              </Col>
                            )
                          )}
                          {section?.DefaultVisible && (
                            <hr className="mt-3 mb-3" />
                          )}
                        </Row>
                      ))}
                    </div>
                  ) : step?.StepType === "OTP" ? (
                    <div className={activeStep !== index && "hidden-step"}>
                      <OTPForm
                        stepGuid={optimizedData[activeStep]?.Guid}
                        PhoneNumberFieldGuid={
                          optimizedData[activeStep]?.PhoneNumberFieldGuid
                        }
                        data={optimizedData}
                        handleBack={handleBack}
                        activeStep={activeStep}
                        submitButtonState={disabledButton}
                        submitButtonLabel={
                          activeStep === submitStep ? "Finish" : "Next"
                        }
                        handleSubmit={handleSubmit}
                        valuesPhone={valuesPhone}
                        setValuesPhone={setValuesPhone}
                        OTPToken={OTPToken}
                        setOTPToken={setOTPToken}
                        disabledMobile={disabledMobile}
                        setDisabledMobile={setDisabledMobile}
                        disabledCheckOTP={disabledCheckOTP}
                        setDisabledCheckOTP={setDisabledCheckOTP}
                        codeValidated={codeValidated}
                        setCodeValidated={setCodeValidated}
                        allOTPs={allOTPs}
                        setAllOTPs={setAllOTPs}
                      />
                    </div>
                  ) : (
                    <div
                      className="p-2"
                      dangerouslySetInnerHTML={{
                        __html: optimizedData[activeStep]?.StepMessage,
                      }}
                    ></div>
                  ))
              )}
            </>
            <div className="text-center mt-4">
              {disabledButton && (
                <div className="submit-instruction text-red">
                  يرجى تعبئة جميع الحقول المطلوبة بشكل صحيح لمتابعة باقي الخطوات
                </div>
              )}
            </div>
            <div className="text-center">
              {activeStep !== 0 && (
                <StepButton
                  type="backward"
                  text={"رجوع"}
                  textSize="1.4rem"
                  func={handleBack}
                  disabled={activeStep === 0}
                  arrow={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="9"
                      height="11"
                      viewBox="0 0 7 13"
                      fill="none"
                      style={{ margin: "0 0.3rem" }}
                    >
                      <path
                        d="M1 1L5.08579 5.08579C5.86683 5.86683 5.86684 7.13316 5.08579 7.91421L1 12"
                        stroke="black"
                        strokeWidth="3"
                        strokeLinecap="round"
                      />
                    </svg>
                  }
                />
              )}
              {dynamicFormId != 20 && !isReturnedToClientForm && (
                <StepButton
                  id="draft-button"
                  type="dynamic-forward"
                  text={
                    draftSubmittingLoading ? (
                      <span className="indicator-progress">
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        &nbsp; يتم الحفظ
                      </span>
                    ) : (
                      "الحفظ كمسودة"
                    )
                  }
                  textSize="1.4rem"
                  func={handleSaveDraft}
                  disabled={draftSubmittingLoading}
                >
                  {draftSubmittingLoading ? (
                    <span className="indicator-progress">
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      &nbsp; يتم الحفظ
                    </span>
                  ) : (
                    "الحفظ كمسودة"
                  )}
                </StepButton>
              )}
              {/* <StepButton
                type="dynamic-forward"
                text={
                  draftSubmittingLoading ? (
                    <span className="indicator-progress">
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      &nbsp; يتم الحفظ
                    </span>
                  ) : (
                    "الحفظ كمسودة"
                  )
                }
                textSize="1.4rem"
                func={handleSaveDraft}
                disabled={draftSubmittingLoading}
              /> */}
              {/* disabledMobile && */}
              {!codeValidated &&
              optimizedData[activeStep]?.StepType === "OTP" ? (
                <Button
                  sx={{
                    borderRadius: "15px",
                    background:
                      "linear-gradient(180deg, var(--first-linear-green) 0%, var(--second-linear-green) 100%)",
                    color: "#FFF",
                    textAlign: "center",
                    fontFamily: "Cairo",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "normal",

                    marginLeft: "1rem",
                  }}
                  disabled={disabledCheckOTP}
                  variant="contained"
                  onClick={handleCheckVerificationCode}
                >
                  {submitLoading ? (
                    <span className="indicator-progress">
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      &nbsp; يتم التحقق
                    </span>
                  ) : (
                    "التحقق من الرمز"
                  )}
                </Button>
              ) : (
                <Button
                  sx={{
                    borderRadius: "15px",
                    background:
                      "linear-gradient(180deg, var(--first-linear-green) 0%, var(--second-linear-green) 100%)",
                    color: "#FFF",
                    textAlign: "center",
                    fontFamily: "Cairo",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "normal",

                    marginLeft: "1rem",
                  }}
                  variant="contained"
                  onClick={
                    isReturnedToClientForm
                      ? handleSubmitUpdateDocumentRequest
                      : handleSubmit
                  }
                  disabled={
                    lockSubmitButton ||
                    uploadFileLoading ||
                    uploadFilesLoading ||
                    disabledButton ||
                    allowNextAfterUploadDocuments ||
                    submitLoading ||
                    fieldValidationError ||
                    (dynamicFormId == 30 && transactionProducts?.length == 0)
                  }
                >
                  {submitLoading ? (
                    <span className="indicator-progress">
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      &nbsp; يتم الحفظ
                    </span>
                  ) : activeStep === submitStep ? (
                    <>
                      حفظ وتأكيد الطلب
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="9"
                        height="11"
                        viewBox="0 0 7 13"
                        fill="none"
                        style={{ margin: "0 0.3rem" }}
                      >
                        <path
                          d="M5.5 1L1.41421 5.08579C0.633166 5.86683 0.633165 7.13316 1.41421 7.91421L5.5 12"
                          stroke={
                            uploadFileLoading ||
                            uploadFilesLoading ||
                            disabledButton ||
                            allowNextAfterUploadDocuments ||
                            submitLoading
                              ? "#2B7239"
                              : "#fff"
                          }
                          strokeLinecap="round"
                          strokeWidth="2"
                        />
                      </svg>
                    </>
                  ) : (
                    <>
                      التالي
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="9"
                        height="11"
                        viewBox="0 0 7 13"
                        fill="none"
                        style={{ margin: "0 0.3rem" }}
                      >
                        <path
                          d="M5.5 1L1.41421 5.08579C0.633166 5.86683 0.633165 7.13316 1.41421 7.91421L5.5 12"
                          stroke={
                            uploadFileLoading ||
                            uploadFilesLoading ||
                            disabledButton ||
                            allowNextAfterUploadDocuments ||
                            submitLoading
                              ? "#2B7239"
                              : "#fff"
                          }
                          strokeLinecap="round"
                          strokeWidth="2"
                        />
                      </svg>
                    </>
                  )}
                </Button>
              )}
            </div>
          </form>
        </div>
      )}
      <NotificationModal
        isOpen={openModal}
        handleClose={handleClose}
        handleOpen={handleOpen}
        message={notificationMessage}
        withloader={notificationWithLoader}
        isLoading={notificationisLoading}
      />
    </Styles>
  );
};

export default MobileForm;
// these IDs represent custom dynamic forms [26,27,28,29] , // (comment: trucks custom forms step) is the comment represent this step
// 1. request API to check chassis number before submit -> (checkChassisValidationBeforeSubmit) is the function responsible for forms [26,27,28]
// 2. request API to check chassis number before submit -> (checkCounterNumberValidationBeforeSubmit) is the function responsible for form [29]
// 3. if API returns false we submit the form
// 4. if API returns true we lock the submit and show message to user.
//  (lockSubmitButton) ths state represent the result of the API calling, it's used only button disabled property

import React, { useState } from "react";
import DataTable from "react-data-table-component";
import styled from "styled-components";
import ArabicPagination from "./CustomPagination";

const Styles = styled.div`
  .rdt_Table {
    direction: rtl; /* Reverse table direction */
  }
  ::-webkit-scrollbar {
    width: 12px; /* Set the width of the scrollbar */
  }

  /* Change the color of the scrollbar track */
  ::-webkit-scrollbar-track {
    background: red !important; /* Change the background color */
  }

  /* Change the color of the scrollbar thumb */
  ::-webkit-scrollbar-thumb {
    background: #888 !important; /* Change the color of the thumb */
  }
  .rdt_Pagination {
    direction: ltr;
    /* #pagination-first-page {
      svg {
        color: var(--green) !important;
        fill: var(--green);
      }
    } */
    button:disabled {
      cursor: unset;
      color: rgba(0, 0, 0, 0.18);
      svg {
        fill: rgba(0, 0, 0, 0.18);
      }
    }
    svg {
      color: var(--green);
      fill: var(--green);
    }
  }
  .sc-tagGq,
  .sc-esYiGF,
  .iUTKVK,
  .kCdswP {
    direction: rtl;
  }
  /* .sc-kdBSHD .kDMEDp */

  .no-records-message {
    min-height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    @media (max-width: 576px) {
      justify-content: start;
      width: 93%;
    }
  }
`;
const CustomDataTable = ({
  columns = [],
  data,
  refreshData = true,
  onRowClicked,
  pagination = false,
  page,
  setPage,
  hasNext,
  hasPrevious,
  pageSize,
  setPageSize,
  totalElements,
  setTotalElements,
  totalPages,
  setTotalPages,
}) => {
  const handlePageChange = (page) => {
    setPage(page);
  };
  const handlePerPageChange = (pageSize) => {
    setPageSize(pageSize);
  };
  const customStyles = {
    headCells: {
      style: {
        textAlign: "center",
        color: "var(--second-linear-green)",
        fontWeight: "bold",
        fontSize: "1rem",
      },
    },
  };

  return (
    <Styles>
      <div dir="rtl">
        <DataTable
          columns={columns}
          data={data}
          striped
          center
          persistTableHead
          customStyles={customStyles}
          onRowClicked={onRowClicked}
          noDataComponent={
            <div className="pt-3 pb-3 no-records-message">
              لم يتم إدخال بيانات بعد
            </div>
          }
          pagination={false}
          paginationPerPage={pageSize}
        />
        {pagination && (
          <ArabicPagination
            page={page}
            setPage={setPage}
            hasNext={hasNext}
            hasPrevious={hasPrevious}
            pageSize={pageSize}
            setPageSize={setPageSize}
            currentDisplayingElements={data?.length}
            totalElements={totalElements}
            setTotalElements={setTotalElements}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
          />
        )}
      </div>
    </Styles>
  );
};

export default CustomDataTable;

import React, { useEffect, useState } from "react";
import { Col, Form, FormGroup, Row } from "react-bootstrap";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Styles } from "./styles";
import { InputAdornment, MenuItem, Select, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { GetExhibitionTypesOptions } from "../../../services/register/picklists.services";
const ExhibitionsFilter = ({
  toDate,
  setToDate,
  fromDate,
  setFromDate,
  search,
  type,
  setType,
  setSearch,
  setRefreshData,
}) => {
  // data
  const [typesOptions, setTypesOptions] = useState([]);
  // ui / ux
  const [typesLoading, setTypesLoading] = useState(true);
  //   functions
  const handleKeywordChange = (e) => {
    if (e.key === "Enter") {
      setRefreshData((prev) => !prev);
    }
  };
  // Calling API
  const getTypesData = async () => {
    setTypesLoading(true);
    const response = await GetExhibitionTypesOptions();
    setTypesOptions(response.data);
    setTypesLoading(false);
  };
  useEffect(() => {
    getTypesData();
  }, []);
  return (
    <Styles>
      <Row>
        <Col sm={12} md={6} lg={3}>
          <FormGroup
            id="input-group"
            className="input-group col-5 date-custome-component"
          >
            <Form.Label
              className="form-control date-placeholder"
              htmlFor={"fromDate"}
              id={`date-picker`}
              role="button"
              onClick={() => {
                let ele = document.getElementById("fromDate");
                ele.popperPlacement = "bottom left";
                ele.showPicker();
                document.getElementById("fromDate").showPicker();
              }}
            >
              {fromDate ? (
                fromDate
              ) : (
                <>
                  <CalendarMonthIcon sx={{ marginLeft: ".5rem" }} />
                  من تاريخ
                </>
              )}
            </Form.Label>
            <Form.Control
              id={"fromDate"}
              name={"fromDate"}
              type={"date"}
              className="p-2 mb-0 rounded-0 form-control date-picker"
              //   min={MinDateValue?.substring(0, 10)}
              //   max={MaxDateValue?.substring(0, 10)}
              //   defaultValue={format("YYYY-MM-DD")}
              onChange={(event) => {
                setFromDate(event.target.value);
              }}
              //   onBlur={() => checkFieldCorrection(id, FieldType)}
              popperPlacement="bottom"
            />
          </FormGroup>
        </Col>
        <Col sm={12} md={6} lg={3}>
          <FormGroup
            id="input-group"
            className="input-group col-5 date-custome-component"
          >
            <Form.Label
              className="form-control date-placeholder"
              htmlFor={"fromDate"}
              id={`date-picker`}
              role="button"
              onClick={() => {
                let ele = document.getElementById("toDate");
                ele.popperPlacement = "bottom left";
                ele.showPicker();
                document.getElementById("toDate").showPicker();
              }}
            >
              {toDate ? (
                toDate
              ) : (
                <>
                  <CalendarMonthIcon sx={{ marginLeft: "0.5rem" }} />
                  إلى تاريخ
                </>
              )}
            </Form.Label>
            <Form.Control
              id={"toDate"}
              name={"toDate"}
              type={"date"}
              className="p-2 mb-0 rounded-0 form-control date-picker"
              //   min={MinDateValue?.substring(0, 10)}
              //   max={MaxDateValue?.substring(0, 10)}
              //   defaultValue={format("YYYY-MM-DD")}
              onChange={(event) => {
                setToDate(event.target.value);
              }}
              //   onBlur={() => checkFieldCorrection(id, FieldType)}
              popperPlacement="bottom"
            />
          </FormGroup>
        </Col>
        <Col sm={12} md={6} lg={3}>
          <Select
            value={""}
            onChange={(event) => setType(event.target.value)}
            sx={{
              borderRadius: "12px",
              "&.MuiInputBase-root": {
                width: "100%",
                border: "0",
              },
              "& .MuiSelect-select": {
                background: "#F8F9FA",
                padding: "0.7rem",
                borderRadius: "12px",
                // "&:hover": {
                //   backgroundColor: "#67d57d",
                // },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
                outline: "none",
              },
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  "& .MuiMenuItem-root": {
                    textWrap: "pretty",
                  },
                  "& .MuiMenuItem-root.Mui-selected": {
                    backgroundColor: "var(--second-linear-green)",
                  },
                  "& .MuiMenuItem-root:hover": {
                    backgroundColor: "var(--first-linear-green)",
                  },
                  "& .MuiMenuItem-root.Mui-selected:hover": {
                    backgroundColor: "var(--med-linear-green)",
                  },

                  // maxHeight: 'unset',
                },
              },
            }}
            displayEmpty
          >
            <MenuItem value="">
              <em>نوع المعرض</em>
            </MenuItem>

            {typesOptions?.map((item) => (
              <MenuItem
                value={item.Id}
                key={item.Id}
                // onClick={() => {
                //   // setSelectedSector(item?.Name);
                // }}
              >
                {item.Name}
              </MenuItem>
            ))}
          </Select>
        </Col>
        <Col sm={12} md={6} lg={3}>
          <TextField
            name={"CompanyName"}
            type={"text"}
            value={search}
            required={true}
            onChange={(event) => setSearch(event.target.value)}
            onKeyDown={(e) => handleKeywordChange(e)}
            variant="outlined"
            placeholder="بحث"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{ color: "#000", cursor: "pointer" }}
                    onClick={() => setRefreshData((prev) => !prev)}
                  />
                </InputAdornment>
              ),
            }}
            sx={{
              "&.MuiFormControl-root": {
                width: "100%",
                "& .MuiFormHelperText-root": {
                  color: "#d1202f",
                  textAlign: "right",
                },
              },
              "& .MuiInputBase-input": {
                background: "#F8F9FA",
                padding: "0.7rem",
                borderRadius: "12px",
                outline: "none",
                border: "0",
                "&::placeholder": {
                  color: "black",
                  opacity: 1,
                  fontSize: "0.9rem",
                },
              },
              "& .MuiOutlinedInput-root": {
                backgroundColor: "#F8F9FA",
                border: "none",
                "&:hover, &:focus": {
                  border: "none",
                },
                "&.Mui-focused": {
                  border: "none",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                  outline: "none",
                  borderRadius: "12px",
                },
              },
            }}
          />
        </Col>
      </Row>
    </Styles>
  );
};

export default ExhibitionsFilter;

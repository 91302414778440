import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop() {
  const location = useLocation();
  const { state } = location;
  useEffect(() => {
    if (!state) window.scrollTo(0, 0);
  }, [location]);

  return null;
}

export default ScrollToTop;

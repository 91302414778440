import React from "react";
import AboutUs from ".";

const Responsibilities = () => {
  return (
    <AboutUs>
      <p>
        تسعى مديرية حماية المستهلك التابعة لوزارة الاقتصاد والتجارة إلى توفير
        بيئة تجارية آمنة للمستهلكين والشركات على حد سواء، بهدف ضمان حقوقهم. من
        خلال فريقنا المتفاني والمؤهل من المفتشين/المفتشات، نعمل جاهدين على تحقيق
        أعلى معايير الحماية للمستهلكين.
      </p>
    </AboutUs>
  );
};

export default Responsibilities;

import React from "react";

const CircleIcon = ({ size = "20", CustomStyle, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 10 10"
      fill="none"
      style={{ margin: "0 0.1rem", ...CustomStyle }}
    >
      <circle cx="5" cy="5" r="5" fill="url(#paint0_linear_175_177)" />
      <defs>
        <linearGradient
          id="paint0_linear_175_177"
          x1="5"
          y1="0"
          x2="5"
          y2="11.7632"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color ? color : "var(--first-linear-green)"} />
          <stop
            offset="1"
            stopColor={color ? color : "var(--second-linear-green)"}
          />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CircleIcon;

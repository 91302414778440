import { InputLabel, Skeleton } from "@mui/material";
import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
const Styles = styled.div`
  .inputLabel {
    align-self: flex-start !important;
    min-width: 130px !important;
    white-space: nowrap !important;
    padding-left: 0.5rem !important;
    color: #000 !important;
    font-family: Cairo !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 188.9% !important; /* 34.002px */
  }
  .inputField input {
    text-align: left !important;
  }
  #input-group {
    margin-bottom: 0;
  }
`;
const FairReportFormLoader = () => {
  return (
    <Styles>
      <Row className="flex-column">
        <Col xs={12} sm={6} id="input-group">
          <InputLabel
            className="inputLabel"
            sx={{
              minWidth: "170px !important",
            }}
          >
            عدد العارضين
          </InputLabel>
          <Skeleton sx={{ width: "100%", height: "65px" }} />
        </Col>
        <Col xs={12} sm={6} id="input-group">
          <InputLabel
            className="inputLabel"
            sx={{
              minWidth: "170px !important",
            }}
          >
            عدد الزوار
          </InputLabel>
          <Skeleton sx={{ width: "100%", height: "65px" }} />
        </Col>
        <Col xs={12} sm={6} id="input-group">
          <InputLabel
            className="inputLabel"
            sx={{
              minWidth: "170px !important",
            }}
          >
            حجم المبيعات او الاعمال و الاتفاقيات
          </InputLabel>
          <Skeleton sx={{ width: "100%", height: "65px" }} />
        </Col>
        <Col xs={12} sm={6} id="input-group">
          <InputLabel
            className="inputLabel"
            sx={{
              minWidth: "170px !important",
            }}
          >
            المساحة الكلية (م²)
          </InputLabel>
          <Skeleton sx={{ width: "100%", height: "65px" }} />
        </Col>
        <Col xs={12} sm={6} id="input-group">
          <InputLabel
            className="inputLabel"
            sx={{
              minWidth: "170px !important",
            }}
          >
            عدد الاجنحة الدولية
          </InputLabel>
          <Skeleton sx={{ width: "100%", height: "65px" }} />
        </Col>
        <Col xs={12} sm={6} id="input-group">
          <InputLabel
            className="inputLabel"
            sx={{
              minWidth: "170px !important",
            }}
          >
            الدول المشاركة
          </InputLabel>
          <Skeleton sx={{ width: "100%", height: "65px" }} />
        </Col>
        <Col xs={12} sm={6} id="input-group" style={{ height: "250px" }}>
          <InputLabel
            className="inputLabel"
            sx={{
              minWidth: "170px !important",
            }}
          >
            العوائق و الصعوبات
          </InputLabel>
          <Skeleton sx={{ width: "100%", height: "100%" }} />
        </Col>
        <Col xs={12} sm={6} id="input-group" style={{ height: "250px" }}>
          <InputLabel
            className="inputLabel"
            sx={{
              minWidth: "170px !important",
            }}
          >
            ملاحظات
          </InputLabel>
          <Skeleton sx={{ width: "100%", height: "100%" }} />
        </Col>
        <Col xs={12} sm={6} id="input-group" style={{ height: "250px" }}>
          <Skeleton sx={{ width: "100%", height: "100%" }} />
        </Col>
      </Row>
    </Styles>
  );
};
export default FairReportFormLoader;

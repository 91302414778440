import environment from "../environment";
import { GET } from "./http.service";

const endpoint = environment.PICKLISTS_ENDPOINT;
const users_endpoint = environment.USERS_ENDPOINT;
export const getPicklists = async () => {
  const response = await GET(`${endpoint}`, false);
  return response;
};
export const getAllUsers = async () => {
  const response = await GET(`${users_endpoint}`, true);
  return response;
};
export const getExternalLists = async (DropDownListExternalDataApilink) => {
  const end = DropDownListExternalDataApilink.replace(/\u200B/g, "");
  const list_endpoint = end.replace(/^\/+/, "");
  const response = await GET(`${list_endpoint}`, false);
  return response;
};

import React from "react";
import { BannerStyles } from "./styles";
import { Link } from "react-router-dom";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import CircleIcon from "../../pages/dashboard/components/CircleIcon";
import { Breadcrumbs, Stack, Typography } from "@mui/material";

const PageBanner = ({ bannerImage, pageTitle, pageURL }) => {
  return (
    <BannerStyles>
      <div className="page-banner">
        <img src={bannerImage} alt="" />
      </div>
      <div className="breadcrumbs">
        <Breadcrumbs
          separator={
            <NavigateBeforeIcon fontSize="small" style={{ color: "#fff" }} />
          }
          aria-label="breadcrumb"
        >
          <Link
            style={{
              color: "#fff",
              textAlign: "center",
              fontFamily: "Cairo",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "1.2rem",
              lineHeight: "188.9%" /* 30.224px */,
              textDecoration: "none",
            }}
            to={"/"}
          >
            الصفحة الرئيسية
          </Link>
          <Link
            style={{
              color: "#194522",
              textAlign: "center",
              fontFamily: "Cairo",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "1.2rem",
              lineHeight: "188.9%" /* 30.224px */,
              textDecoration: "none",
            }}
            href={pageURL}
          >
            {pageTitle}
          </Link>
        </Breadcrumbs>
      </div>
      <div className="title">
        <Stack flex={1}>
          <Stack direction={"row"} alignItems={"center"} spacing={3}>
            <CircleIcon size="10" CustomStyle={{ marginTop: "0.5rem" }} />
            <CircleIcon size="10" CustomStyle={{ marginTop: "0.5rem" }} />
            &nbsp; &nbsp;
            <Typography
              variant="h4"
              sx={{
                color: "#fff",
                textAlign: "center",
                fontFamily: "Cairo",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "normal",
              }}
            >
              {pageTitle}
            </Typography>
          </Stack>
        </Stack>
      </div>
    </BannerStyles>
  );
};

export default PageBanner;

import React, { useEffect, useRef, useState } from "react";
import { Styles } from "./Styles";
import { element } from "prop-types";
import { Col, FormLabel, Modal, Row } from "react-bootstrap";
import StepButton from "../../../pages/register/components/stepsButton";
import { getPicklists } from "../../../services/picklists.services";
import { useReactToPrint } from "react-to-print";
import logo from "../../../assets/images/logo_rtl.png";
import "./print.css";
import { Button, Checkbox, FormControlLabel, InputLabel } from "@mui/material";
import {
  errorToaster,
  successToaster,
} from "../../../helpers/toasterConfiguration";
import {
  uploadAssignedDocument,
  uploadBasePdf,
} from "../../../services/dynamicForm.services";
import {
  DynamicFormsTypes,
  allowedFilesExtensions,
} from "../../../constants/enums";
import environment from "../../../environment";
import { useNavigate } from "react-router-dom";
import useStateRef from "react-usestateref";
import GoogleRecaptcha from "../../common/GoogleRecapcha";
import GridTable from "../../dynamic-form/grid-table";
import { useLocation } from "react-router-dom";
import html2canvas from "html2canvas";
import html2pdf from "html2pdf.js";
import {
  checkIfPaymentRequiredApi,
  getMobileSessionDetails,
} from "../../../services/payment/payment.service";
import { CheckFileTypeValidation } from "../../../helpers/checkFileValidation";

const ReviewData = ({
  // form,
  fieldsData,
  data,
  setActiveSubmitStep,
  setActiveStep,
  usersData,
  setDocumentUploaded,
  dynamicFormDisplayName,
  dynamicFormId,
  dataToSubmit,
  sendData,
  submitLoading,
  setSubmitLoading,
  formDataId,
  documentLink,
  documentLinks,
  DynamicFormConfirmationType,
  processId,
  refreshForm,
  setRefreshForm,
  transactionProducts,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // States
  const [allPicklists, setAllPicklists] = useState();
  const [assignedFile, setAssignedFile] = useState([]);
  const [dataConfirmed, setDataConfirmed] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(false);
  // UI/UX
  const [pdfLoading, setPdfLoading] = useState(false);

  // check if payment required
  const [loading, setLoading] = useState(false);
  // file validation error message
  const [showFilesErrorMessage, setShowFilesErrorMessage] = useState(false);
  // get session data from our server
  const [getSessionLoading, setGetSessionLoading] = useState(false);

  // get token
  const [value, setValue, valueRef] = useStateRef(false);

  //   Ref
  const componentRef = useRef();
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });

  // *************** Print Functions ******************
  const options = {
    useJsPdf: true,
    cb: null,
    margin: 20,
  };
  const marginY = options?.margin ?? 20;
  const el = document.getElementById("printable-area");
  const printPDF = () => {
    html2pdf()
      .set({
        margin: [marginY, 0],
        image: { type: "png", quality: 0.99 },
        html2canvas: {
          useCORS: true,
          scrollX: 0,
          scrollY: 0,
          scale: 2,
        },
        enableLinks: true,
        jsPDF: {
          unit: "pt",
        },
        callback: (pdf) => {
          // Access the blob result here
          const blob = pdf.output("blob");
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "document.pdf";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        },
        pagebreak: {
          mode: "avoid-all",
        },
      })
      .from(el)
      .save(`${"fileName"}.pdf`);
  };
  // Convert blob to base64 functions
  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        if (reader.result) {
          resolve(reader.result.split(",")[1]); // Extract base64 string from data URL
        } else {
          reject(new Error("Unable to convert blob to base64"));
        }
      };
      reader.onerror = () => {
        reject(new Error("FileReader error"));
      };
    });
  };
  function captureDivAsImageBlob() {
    const divElement = document.getElementById("printable-area");
    setPdfLoading(true);
    html2canvas(divElement)
      .then((canvas) => {
        return new Promise((resolve, reject) => {
          canvas.toBlob((blob) => {
            if (blob) {
              resolve(blob);
            } else {
              reject(new Error("Unable to create blob"));
            }
          }, "application/pdf");
        });
      })
      .then(async (blob) => {
        // Send blob to API
        const base = await blobToBase64(blob);
        const requestBody = { base64Pdf: base };
        const response = await uploadBasePdf(requestBody, false);
        if (response.status === 200) {
          window.location.href = `${environment.IMAGE_URL}/${response?.data?.Url}`;
        }
      })
      .then((base64String) => {
        setPdfLoading(false);
      })
      .catch((err) => console.error("Error capturing div:", err));
  }
  // captcha variables
  const [captchaValue, setCaptchaValue] = useState("");
  const [captchaExpired, setCaptchaExpired] = useState(false);
  // captcha
  function getCaptchaInfo(val) {
    setCaptchaValue(val.value);
    setCaptchaExpired(val.expired);
  }
  //   Functions
  const handleChange = (event) => {
    setDataConfirmed(event.target.checked);
  };
  const handleBack = () => {
    setActiveStep(0);
    setActiveSubmitStep(false);
    navigate(
      `/dynamic-form?dynamicFormId=${dynamicFormId}&processId=${processId}&formDataId=${formDataId}`
    );
    setRefreshForm(!refreshForm);
  };

  const findPicklistItem = (selectedPickListItems, isArray) => {
    let label = "";
    if (isArray) {
      selectedPickListItems.map((singleItem, index) => {
        allPicklists?.map((picklist) => {
          picklist?.Options?.map((option) => {
            if (option?.Id == singleItem) {
              label += option?.Name;
              if (index < selectedPickListItems?.length - 1) label += ", ";
            }
          });
        });
      });
    } else {
      allPicklists?.map((picklist) => {
        picklist?.Options?.map((option) => {
          if (option?.Id == selectedPickListItems) {
            label = option?.Name;
          }
        });
      });
    }
    return label;
  };
  const findUserItem = (picklistItemId) => {
    let label = "";
    usersData?.map((user) => {
      if (user?.Id == picklistItemId) label = user?.UserName;
    });

    return label;
  };
  // payment function
  const CheckIfPaymentRequired = async () => {
    setLoading(true);
    const response = await checkIfPaymentRequiredApi(processId);
    if (response?.status === 200) {
      setPaymentStatus(response?.data);
    }
    setLoading(false);
  };

  const handlePayment = async () => {
    if ((!captchaExpired && captchaValue !== "") || true) {
      // Call API to get session details
      // order unique id
      // session id
      setGetSessionLoading(true);
      const sessionResponse = await getMobileSessionDetails(
        processId,
        valueRef.current
      );

      if (sessionResponse?.status === 200) {
        const orderUniqueId = sessionResponse?.data?.OrderId;
        const sessionId = JSON.parse(sessionResponse?.data?.Data).session?.id;
        window.location.href = `/payment.html?dynamicFormId=${dynamicFormId}&processId=${processId}&formDataId=${formDataId}&sessionId=${sessionId}&orderId=${orderUniqueId}&isMobile=true&token=${valueRef.current}`;
      } else setGetSessionLoading(false);
    }
  };
  const submitRequest = async (files) => {
    if ((!captchaExpired && captchaValue !== "") || true) {
      setSubmitLoading(true);
      const formData = new FormData();
      formData.append("processId", processId);
      formData.append("formDataId", formDataId);
      formData.append("assignedDocument", assignedFile[0]);
      const response = await uploadAssignedDocument(formData, valueRef.current);
      if (response?.status === 200) {
        successToaster("تم رفع الوثيقة بنجاح");
        navigate("/submit", { replace: true });
      } else {
        errorToaster("عذرا ... حدث خطأ ما");
      }
      setSubmitLoading(false);
    }
  };
  const uploadAssignedFile = () => {
    let form = document.createElement("form");
    form.enctype = "multipart/form-data";
    let input = document.createElement("input");
    input.type = "file";
    input.accept = ".pdf, image/*";
    input.onchange = () => {
      let files = Array.from(input.files);
      //   handleShowModal();
      const isFileCorrect = CheckFileTypeValidation(
        input.files[0],
        allowedFilesExtensions
      );
      if (isFileCorrect) {
        setShowFilesErrorMessage(false);
        setAssignedFile(files);
      } else setShowFilesErrorMessage(true);
      window.scrollTo(8000, 8000);
    };
    form.appendChild(input);
    input.click();
  };
  const getData = async () => {
    const response = await getPicklists();
    if (response.status === 200) setAllPicklists(response.data);
  };
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setValue(queryParams.get("token"));
    CheckIfPaymentRequired();
  }, []);

  return (
    <Styles>
      {DynamicFormConfirmationType ==
        DynamicFormsTypes.SignatureAndStampNeeded && (
        <div className="text-center buttons-container">
          <Button
            sx={{
              borderRadius: "15px",
              background:
                "linear-gradient(180deg, var(--first-linear-green) 0%, var(--second-linear-green) 100%)",
              color: "#FFF",
              textAlign: "center",
              fontFamily: "Cairo",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
            }}
            variant="contained"
            onClick={() => {
              // printPDF();
              captureDivAsImageBlob();
            }}
            // onClick={downloadPDF}
            // onClick={handlePrint}
            id="print-button"
          >
            {pdfLoading ? (
              <span className="indicator-progress">
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            ) : (
              <>طباعة</>
            )}
          </Button>
          {paymentStatus ? (
            <Button
              sx={{
                borderRadius: "15px",
                background:
                  "linear-gradient(180deg, var(--first-linear-green) 0%, var(--second-linear-green) 100%)",
                color: "#FFF",
                textAlign: "center",
                fontFamily: "Cairo",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "normal",

                marginLeft: "1rem",
              }}
              variant="contained"
              onClick={() => handlePayment()}
            >
              {loading ? (
                <span className="indicator-progress">
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              ) : submitLoading || getSessionLoading ? (
                <span className="indicator-progress">
                  يتم الإرسال &nbsp;
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              ) : (
                <span>الدفع والمتابعة</span>
              )}
            </Button>
          ) : (
            <Button
              sx={{
                borderRadius: "15px",
                background:
                  "linear-gradient(180deg, var(--first-linear-green) 0%, var(--second-linear-green) 100%)",
                color: "#FFF",
                textAlign: "center",
                fontFamily: "Cairo",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "normal",
              }}
              variant="contained"
              onClick={uploadAssignedFile}
              id="upload-button"
            >
              رفع النموذج مع الإمضاء و الطابع
            </Button>
          )}
          <StepButton
            id="cancel-button"
            type="dynamic-forward"
            text={
              <a
                // className="back-link"
                href={`/dynamic-form?dynamicFormId=${dynamicFormId}&processId=${processId}&formDataId=${formDataId}`}
              >
                إلغاء
              </a>
            }
            // textSize="2.4rem"
            // func={handleBack}
            disabled={submitLoading}
          ></StepButton>
        </div>
      )}

      <div ref={componentRef} id="printable-area" style={{ direction: "rtl" }}>
        <div className="print-form" ref={componentRef}>
          <div className="print-logo">
            <img src={logo} alt="وزارة التجارة و الاقتصاد" />
          </div>
          <div className="print-title">{dynamicFormDisplayName}</div>
          <div className="print-body">
            {data?.map(
              (step, index) =>
                step?.DefaultVisible &&
                step?.StepType === "Step" && (
                  <div>
                    {step?.DynamicFormSections?.map((section) => (
                      <Row
                        id={section.Guid}
                        style={{
                          display: section?.DefaultVisible ? "block" : "none",
                        }}
                      >
                        {section?.DynamicFormFields?.map(
                          ({
                            Id,
                            Guid,
                            Name,
                            FieldType,
                            Label,
                            Suffix,
                            Prefix,
                            Mandatory,
                            MandatoryConditionGuid,
                            MandatoryCondition,
                            DefaultVisible,
                            VisibilityConditionGuid,
                            VisibilityCondition,
                            Tooltip: customeTooltip,
                            Placeholder,
                            DefaultValue,
                            CustomFormat,
                            MinValidationMessage,
                            MaxValidationMessage,
                            MinCharCount,
                            MaxCharCount,
                            NumberType,
                            MinNumValue,
                            MaxNumValue,
                            MinDateValue,
                            MaxDateValue,
                            MinTimeValue,
                            MaxTimeValue,
                            PicklistStyle,
                            PicklistId,
                            PicklistFilterType,
                            PicklistFilterFieldGuid,
                            PicklistFilterParentOptionId,
                            YesLabel,
                            NoLabel,
                            OtherAttachmentsLimit,
                          }) =>
                            FieldType !== "Label" &&
                            FieldType !== "LoadFormData" &&
                            FieldType !== "DropdownListInfoBox" &&
                            (FieldType === "Grid" ? (
                              transactionProducts?.length > 0 ? (
                                <Col
                                  xs="12"
                                  // md={section?.ColumnsSize}
                                  md="12"
                                  id={Guid}
                                  style={{
                                    display: DefaultVisible ? "block" : "none",
                                  }}
                                >
                                  <GridTable
                                    transactionProducts={transactionProducts}
                                    viewOnly={true}
                                  />
                                </Col>
                              ) : null
                            ) : (
                              <Col
                                xs="12"
                                // md={section?.ColumnsSize}
                                md="12"
                                id={Guid}
                                style={{
                                  display: DefaultVisible ? "block" : "none",
                                }}
                              >
                                <Row>
                                  {Label && (
                                    <Col xm="12" sm={"4"} md={"4"} lg={"4"}>
                                      <FormLabel
                                        for={Name}
                                        className="field-title"
                                      >
                                        {Label}:
                                      </FormLabel>
                                    </Col>
                                  )}
                                  <Col xm="12" sm={"8"} md={"8"} lg={"8"}>
                                    <FormLabel
                                      for={Name}
                                      className="field-value"
                                    >
                                      {FieldType === "DropdownList" ? (
                                        <div className="">
                                          {fieldsData?.get(Name)?.length ===
                                            0 && "لم يتم الإدخال"}
                                          {Array.isArray(fieldsData.get(Name))
                                            ? findPicklistItem(
                                                fieldsData.get(Name),
                                                true
                                              )
                                            : findPicklistItem(
                                                fieldsData.get(Name),
                                                false
                                              )}
                                        </div>
                                      ) : FieldType === "UserList" ? (
                                        <div className="">
                                          {/* {fieldsData.get(Name)} */}
                                          {findUserItem(fieldsData.get(Name))
                                            ? findUserItem(fieldsData.get(Name))
                                            : "لم يتم الإدخال"}
                                        </div>
                                      ) : FieldType === "YesNoRadioButton" ? (
                                        <>
                                          {fieldsData.get(Name) === "True"
                                            ? "نعم"
                                            : "لا"}
                                        </>
                                      ) : FieldType === "PhoneNumber" ? (
                                        <div className="phone-number">
                                          {fieldsData.get(Name)}
                                        </div>
                                      ) : FieldType === "Attachment" ||
                                        FieldType === "OtherAttachments" ? (
                                        <>
                                          {fieldsData.get(Name)?.length > 0 ? (
                                            <>
                                              {fieldsData
                                                .get(Name)
                                                ?.map((item, index) => (
                                                  <>
                                                    <a
                                                      href={`${environment.IMAGE_URL}${item}`}
                                                      download
                                                      target="_blank"
                                                      style={{
                                                        // textDecoration: "none",
                                                        color: "#000",
                                                        wordBreak: "break-all",
                                                      }}
                                                    >
                                                      {item.substring(
                                                        item.lastIndexOf("/") +
                                                          1,
                                                        item.indexOf("_")
                                                      )}
                                                      {item.substring(
                                                        item.lastIndexOf(".")
                                                      )}
                                                    </a>
                                                    <br />
                                                  </>
                                                ))}
                                            </>
                                          ) : (
                                            "لم يتم الإدخال"
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {fieldsData.get(Name)
                                            ? fieldsData.get(Name)
                                            : "لم يتم الإدخال"}
                                        </>
                                      )}
                                    </FormLabel>
                                  </Col>
                                </Row>
                              </Col>
                            ))
                        )}
                        {section?.DefaultVisible && (
                          <hr className="mt-3 mb-3" />
                        )}
                      </Row>
                    ))}
                  </div>
                )
            )}
          </div>

          {DynamicFormConfirmationType ==
            DynamicFormsTypes.SignatureAndStampNeeded &&
            !paymentStatus && (
              <div className="print-footer">
                <div className="assignment">الإمضاء</div>
                <div className="stamp">
                  طابع
                  <br />
                  20,000 LL
                </div>
              </div>
            )}
        </div>
      </div>
      {/* <ComponentToPrint /> */}

      {DynamicFormConfirmationType ==
      DynamicFormsTypes.SignatureAndStampNeeded ? (
        <div className="text-center mb-4 ">
          {showFilesErrorMessage ? (
            <div className="submit-instruction text-red">
              يرجى رفع الملف على شكل صورة أو ملف pdf
            </div>
          ) : assignedFile?.length === 0 ? (
            <div className="submit-instruction text-red">
              يرجى رفع الوثيقة موقعة لإتمام الطلب
            </div>
          ) : (
            <div className="submit-instruction text-green">
              تم رفع الوثيقة بنجاح يمكنك إتمام الطلب الان
            </div>
          )}
        </div>
      ) : (
        DynamicFormConfirmationType ==
          DynamicFormsTypes.DigitalConfirmationNeeded && (
          <div className="d-flex mb-4">
            <FormControlLabel
              className="customSingleCheckbox"
              control={
                <Checkbox checked={dataConfirmed} onChange={handleChange} />
              }
            />
            <InputLabel sx={{ whiteSpace: "pre-wrap", paddingLeft: "1rem" }}>
              أقر ان كل المعلومات الواردة اعلاه هي صحيحة ودقيقة وانا اتحمل كامل
              المسؤولية عن افادتها الى وزارة الاقتصاد والتجارة
            </InputLabel>
          </div>
        )
      )}
      <div className="text-center mt-4">
        <GoogleRecaptcha getCaptchaInfo={getCaptchaInfo} />
      </div>
      <div className="text-center signature-buttons-container">
        {paymentStatus ? (
          <Button
            // id="submit-button"
            sx={{
              borderRadius: "15px",
              background:
                "linear-gradient(180deg, var(--first-linear-green) 0%, var(--second-linear-green) 100%)",
              color: "#FFF",
              textAlign: "center",
              fontFamily: "Cairo",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",

              marginLeft: "1rem",
            }}
            // disabled={
            //   captchaExpired ||
            //   captchaValue === "" ||
            //   captchaValue === null ||
            //   captchaValue === undefined ||
            //   (DynamicFormConfirmationType ==
            //   DynamicFormsTypes.DigitalConfirmationNeeded
            //     ? !dataConfirmed || submitLoading
            //     : DynamicFormConfirmationType ==
            //       DynamicFormsTypes.SignatureAndStampNeeded
            //     ? assignedFile?.length === 0 || submitLoading
            //     : false)

            // }
            variant="contained"
            onClick={
              () => handlePayment()
              // DynamicFormConfirmationType ==
              // DynamicFormsTypes.SignatureAndStampNeeded
              //   ? submitRequest()
              //   : sendData(dataToSubmit)
            }
          >
            {loading ? (
              <span className="indicator-progress">
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            ) : submitLoading || getSessionLoading ? (
              <span className="indicator-progress">
                يتم الإرسال &nbsp;
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            ) : (
              <span>الدفع والمتابعة</span>
            )}
          </Button>
        ) : (
          <Button
            // id="submit-button"
            sx={{
              borderRadius: "15px",
              background:
                "linear-gradient(180deg, var(--first-linear-green) 0%, var(--second-linear-green) 100%)",
              color: "#FFF",
              textAlign: "center",
              fontFamily: "Cairo",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",

              marginLeft: "1rem",
            }}
            disabled={
              // captchaExpired ||
              // captchaValue === "" ||
              // captchaValue === null ||
              // captchaValue === undefined ||
              DynamicFormConfirmationType ==
              DynamicFormsTypes.DigitalConfirmationNeeded
                ? !dataConfirmed || submitLoading
                : DynamicFormConfirmationType ==
                  DynamicFormsTypes.SignatureAndStampNeeded
                ? assignedFile?.length === 0 || submitLoading
                : false
            }
            variant="contained"
            onClick={
              () =>
                DynamicFormConfirmationType ==
                DynamicFormsTypes.SignatureAndStampNeeded
                  ? submitRequest()
                  : sendData(dataToSubmit)
              // dynamicFormId == 20 ? sendData(dataToSubmit) : submitRequest()
            }
          >
            {loading ? (
              <span className="indicator-progress">
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            ) : submitLoading || getSessionLoading ? (
              <span className="indicator-progress">
                يتم الإرسال &nbsp;
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            ) : (
              <span>حفظ و إرسال الطلب</span>
            )}
          </Button>
        )}
        {/* <Button
          // id="submit-button"
          sx={{
            borderRadius: "15px",
            background: "linear-gradient(180deg, var(--first-linear-green) 0%, var(--second-linear-green) 100%)",
            color: "#FFF",
            textAlign: "center",
            fontFamily: "Cairo",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "normal",

            marginLeft: "1rem",
          }}
          disabled={
            captchaExpired ||
            captchaValue === "" ||
            captchaValue === null ||
            captchaValue === undefined ||
            (DynamicFormConfirmationType ==
            DynamicFormsTypes.DigitalConfirmationNeeded
              ? !dataConfirmed || submitLoading
              : DynamicFormConfirmationType ==
                DynamicFormsTypes.SignatureAndStampNeeded
              ? assignedFile?.length === 0 || submitLoading
              : false)
          }
          variant="contained"
          onClick={
            () =>
              DynamicFormConfirmationType ==
              DynamicFormsTypes.SignatureAndStampNeeded
                ? submitRequest()
                : sendData(dataToSubmit)
            // dynamicFormId == 20 ? sendData(dataToSubmit) : submitRequest()
          }
        >
          {submitLoading ? (
            <span className="indicator-progress">
              يتم الإرسال &nbsp;
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          ) : (
            <span>حفظ و إرسال الطلب</span>
          )}
        </Button> */}
        <StepButton
          // id="draft-button"
          id="cancel-button"
          type="dynamic-forward"
          // text={"إلغاء"}
          text={
            <a
              // className="back-link"
              href={`/dynamic-form?dynamicFormId=${dynamicFormId}&processId=${processId}&formDataId=${formDataId}`}
            >
              إلغاء
            </a>
          }
          textSize="1.4rem"
          // func={handleBack}
          disabled={submitLoading}
        ></StepButton>
        {/* <Button
          sx={{
            borderRadius: "15px",
            background: "linear-gradient(180deg, var(--first-linear-green) 0%, var(--second-linear-green) 100%)",
            color: "#FFF",
            textAlign: "center",
            fontFamily: "Cairo",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "normal",
            width: "15%",
            marginRight: "1rem",
          }}
          disabled={submitLoading}
          variant="contained"
          onClick={handleBack}
        >
          إلغاء
        </Button> */}
      </div>
    </Styles>
  );
};

export default ReviewData;

import React from "react";
import YouTubeEmbed from "react-youtube-embed";

import styled from "styled-components";
export const Styles = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 720px;
  .youtube-Iframe {
    border-radius: 5px;
  }
  @media (max-width: 1200px) {
    width: 720px;
  }
  @media (max-width: 900px) {
    width: 720px;
  }

  @media (max-width: 768px) {
    width: 720px;
  }
  @media (max-width: 720px) {
    width: 90%;
  }
`;
const VideoPlayer = ({ id }) => {
  return (
    <Styles>
      <YouTubeEmbed id={id} />
    </Styles>
  );
};

export default VideoPlayer;

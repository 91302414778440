export const reportsCheckFieldsValidation = (
  reportCopy,
  setReportCopyError,
  exhibitorsCount,
  setExhibitorsCountError,
  visitorsCount,
  setVisitorsCountError,
  salesTotal,
  setSalesTotalError,
  difficulties,
  setDifficultiesError,
  totalArea,
  setTotalAreaError,
  numberOfInternationalPavilions,
  setNumberOfInternationalPavilionsError,
  participatingCountries,
  setParticipatingCountriesError
) => {
  // ignore attachment
  // if (reportCopy === null || reportCopy === "") {
  //   setReportCopyError(true);
  // } else setReportCopyError(false);

  if (exhibitorsCount === null || exhibitorsCount === "") {
    setExhibitorsCountError(true);
  } else setExhibitorsCountError(false);

  if (visitorsCount === null || visitorsCount === "") {
    setVisitorsCountError(true);
  } else setVisitorsCountError(false);

  if (salesTotal === null || salesTotal === "") {
    setSalesTotalError(true);
  } else setSalesTotalError(false);

  if (difficulties === null || difficulties === "") {
    setDifficultiesError(true);
  } else setDifficultiesError(false);
  if (totalArea === null || totalArea === "") {
    setTotalAreaError(true);
  } else setTotalAreaError(false);
  if (
    numberOfInternationalPavilions === null ||
    numberOfInternationalPavilions === ""
  ) {
    setNumberOfInternationalPavilionsError(true);
  } else setNumberOfInternationalPavilionsError(false);
  if (participatingCountries === null || participatingCountries === "") {
    setParticipatingCountriesError(true);
  } else setParticipatingCountriesError(false);
};
export const insuranceCheckFieldsValidation = (
  reportCopy,
  setReportCopyError
) => {
  if (reportCopy === null || reportCopy === "") {
    setReportCopyError(true);
  } else setReportCopyError(false);
};

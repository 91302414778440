import styled from "styled-components";
export const Styles = styled.div`
  height: 60vh;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  .container {
    /* margin-top: 2rem; */
    p {
      font-size: 2rem;
    }
    .re-request {
      p {
        padding-top: 1rem;
        padding-bottom: 1rem;
        font-size: 1.2rem;
      }
    }
  }
`;

import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { SITE_KEY } from "../../constants/site_key";

const language = "en";

function GoogleRecaptcha({ getCaptchaInfo }) {
  const [value, setValue] = useState("");
  const [expired, setExpired] = useState(false);

  useEffect(() => {
    getCaptchaInfo({
      value: value,
      expired: expired,
    });
  }, [value, expired, getCaptchaInfo]);

  function onChange(val) {
    setValue(val);
    setExpired(false);
    if (val === null) {
      setValue("");
      setExpired(true);
    }
  }

  return (
    <>
      <ReCAPTCHA
        style={{ display: "inline-block" }}
        onChange={onChange}
        sitekey={SITE_KEY}
        hl={"ar"}
      />
    </>
  );
}

export default GoogleRecaptcha;

export const findElementId = (data, id) => {
  let parentId;
  data.map((step) => {
    step.DynamicFormSections.map((section) => {
      section.DynamicFormFields.map((field) => {
        if (field.Guid === id) parentId = field.Id;
      });
    });
  });
  return parentId;
};

import styled from "styled-components";

export const Styles = styled.div`
  .tag-image {
    width: 30%;
    padding: 1rem;
    img {
      width: 100%;
      height: 15vh;
      padding: 0rem;
      margin-bottom: 0.2rem;
      object-fit: contain;

      /* height: 200px; */
    }
  }
`;

import React, { useContext, useEffect, useState } from "react";

import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { Col, Form, FormGroup, FormLabel } from "react-bootstrap";
import {
  Box,
  Button,
  Chip,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import { getPicklists } from "../../../services/picklists.services";
import {
  GetExhibitionTypesOptions,
  getSectorsOptions,
} from "../../../services/register/picklists.services";
import { successToaster } from "../../../helpers/toasterConfiguration";
import {
  GetExhibitorDetails,
  addExhibitor,
  addNewExhibition,
  updateExhibitor,
} from "../../../services/fed/fedExhibitors.service";
import { Styles } from "./styles";
import BannerWithImage from "../../../components/banner-with-image";
import bannerImage from "../../../assets/images/all-exhibitions.webp";
import { useNavigate } from "react-router-dom";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { getAccessTokenCookie } from "../../../utils/cookie";
import UserContext from "../../../context/UserContext";
import axios from "axios";
import { USERPROFILE } from "../../../constants/endpoints";
import { RefreshTokenFunction } from "../../../services/refreshToken.services";
import moment from "moment";
import CustomAttachment from "../../../components/elements/attachment";
const AddExhibition = (props) => {
  // get token
  const token = getAccessTokenCookie();

  //
  const navigate = useNavigate();
  const { onHide, isEdit, transactionId, editRowIndex, setRefreshData } = props;
  // data
  const [data, setData] = useState({});
  const [userProfile, setUserProfile] = useState({});
  const [sectorOptions, setSectorOptions] = useState([]);
  const [typesOptions, setTypesOptions] = useState([]);
  // ui/ux
  const [loading, setLoading] = useState(false);
  const [sectorLoading, setSectorLoading] = useState(true);
  const [typesLoading, setTypesLoading] = useState(true);
  const [validateOnChange, setValidateOnChange] = useState(false);
  // Fields
  const [exhibitorImage, setExhibitorImage] = useState("");
  const [exhibitorImageName, setExhibitorImageName] = useState("");
  const [
    exhibitorImageError,
    setExhibitorImageError,
    setExhibitorImageErrorRef,
  ] = useState(false);

  //   functions
  const handleSectorChange = (event) => {
    console.log(event);
    form.setFieldValue("SectorId", event?.target?.value);
    if (validateOnChange) form.validateForm();
  };
  const handleTypeChange = (event) => {
    form.setFieldValue("TypeId", event?.target?.value);
    if (validateOnChange) form.validateForm();
  };
  //  Form
  const validationSchema = Yup.object({
    // profileImage: Yup.string().required(t("Profile image is required")),
    Name: Yup.string().required("اسم المعرض مطلوب"),
    SectorId: Yup.array().required("اسم القطاع مطلوب"),
    Location: Yup.string().required("العنوان مطلوب"),
    Orgenizer: Yup.string().required("اسم المنظم مطلوب"),
    Products: Yup.string().required("حقل المنتجات مطلوب"),
    TypeId: Yup.string().required("الحقل مطلوب"),
    FromDate: Yup.string().required("الحقل مطلوب"),
    ToDate: Yup.string().required("الحقل مطلوب"),
    ExhibitorImage: Yup.string().required("الحقل مطلوب"),
    // ExhibitorImageName: Yup.string(),
  });
  //   const isFieldsValid = () => {
  //     return (
  //       form.values.CompanyName?.length !== 0 &&
  //       form.values.SectorId?.length !== 0 &&
  //       form.values.Products?.length !== 0 &&
  //       form.values.TypeId?.length !== 0
  //     );
  //   };

  const submitForm = async (values) => {
    setLoading(true);
    // const pass = isFieldsValid();
    const pass = true;
    if (pass) {
      let requestBody = {
        // Id: 0,
        Name: form.values.Name,
        FromDate: moment(form.values.FromDate).format("DD/MM/YYYY"),
        ToDate: moment(form.values.ToDate).format("DD/MM/YYYY"),
        SectorId: form.values.SectorId.map((item) => item.Id),
        Location: form.values.Location,
        Orgenizer: form.values.Orgenizer,
        TypeId: form.values.TypeId,
        Products: form.values.Products,
        ExhibitionImage: form.values.ExhibitorImage,
        ExhibitionImageName: exhibitorImageName,
      };
      const response = await addNewExhibition(requestBody);
      if (response.status === 200 || response.status === 204) {
        successToaster(response?.Data?.Message || "تم إرسال الطلب بنجاح");
        form.resetForm();
        navigate("/", { replace: true });
      }
    }

    setLoading(false);
  };
  const form = useFormik({
    isInitialValidP: false,
    validateOnBlur: validateOnChange,
    validateOnChange: validateOnChange,
    onSubmit: (values) => {
      submitForm(values);
    },
    validationSchema,
    initialValues: {
      Name: "",
      FromDate: "",
      ToDate: "",
      SectorId: [],
      Location: "",
      TypeId: null,
      Products: "",
      Orgenizer:
        userProfile.TypeId === 1
          ? userProfile.FirstName + " " + userProfile.LastName
          : userProfile.TradeName,
      ExhibitorImageName: "",
      ExhibitorImage: "",
    },
  });

  // Calling API
  //   const getData = async () => {
  //     setLoading(true);
  //     const response = await GetExhibitorDetails(editRowIndex);
  //     form.setFieldValue("CompanyName", response?.data?.CompanyName);
  //     form.setFieldValue("SectorId", response?.data?.SectorId);
  //     form.setFieldValue("Products", response?.data?.Products);
  //     form.setFieldValue("TypeId", response?.data?.WomenLedId);
  //     setData(response.data);
  //     setLoading(false);
  //   };
  const getProfileData = async () => {
    axios
      .get(USERPROFILE, {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-Version": "2",
        },
      })
      .then((response) => {
        setUserProfile(response.data);
        if (response?.data?.TypeId === 1)
          form.setFieldValue(
            "Orgenizer",
            response.data?.FirstName + " " + response.data?.LastName
          );
        else {
          form.setFieldValue("Orgenizer", response.data?.LegalName);
        }
      })
      .catch((error) => {
        if (error.status === 401) {
          RefreshTokenFunction();
        }
      });
  };
  const getSectorData = async () => {
    setSectorLoading(true);
    const response = await getSectorsOptions();
    setSectorOptions(response.data);
    setSectorLoading(false);
  };
  const getTypesData = async () => {
    setTypesLoading(true);
    const response = await GetExhibitionTypesOptions();
    setTypesOptions(response.data);
    setTypesLoading(false);
  };
  useEffect(() => {
    if (exhibitorImage) {
      console.log("added");
      form.setFieldValue("ExhibitorImage", exhibitorImage);
      setExhibitorImageError(false);
    } else {
      console.log("removed");
      form.setFieldValue("ExhibitorImage", null);
      setExhibitorImageError(true);
    }
  }, [exhibitorImage]);
  useEffect(() => {
    if (token) {
      getProfileData();
      getSectorData();
      getTypesData();
    } else {
      navigate("/login", { replace: true });
    }
  }, []);
  return (
    <Styles>
      <BannerWithImage image={bannerImage} title="" height="55vh" />

      <div className="container">
        <p className="title">إضافة معرض</p>
        <Formik>
          <Form
            onSubmit={form.handleSubmit}
            className="w-100 row justify-content-start"
          >
            {" "}
            <Col sm="12" md="12" lg="6">
              <FormGroup id="input-group" className="row">
                <Col sm="12" md="12" lg="3">
                  <FormLabel for={"TypeId"} required className="">
                    النوع<span className="text-red">*</span>
                  </FormLabel>
                </Col>
                <Col sm="12" md="12" lg="9">
                  <Select
                    value={loading ? "" : form.values.TypeId}
                    onChange={handleTypeChange}
                    sx={{
                      borderRadius: "12px",
                      "&.MuiInputBase-root": {
                        width: "100%",
                        border: form.errors.TypeId
                          ? "1px solid var(--red)"
                          : "0",
                      },
                      "& .MuiSelect-select": {
                        background: "rgb(237, 239, 240)",
                        padding: "0.7rem",
                        borderRadius: "12px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                        outline: "none",
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          "& .MuiMenuItem-root": {
                            textWrap: "pretty",
                          },
                          "& .MuiMenuItem-root.Mui-selected": {
                            backgroundColor: "var(--second-linear-green)",
                          },
                          "& .MuiMenuItem-root:hover": {
                            backgroundColor: "var(--first-linear-green)",
                          },
                          "& .MuiMenuItem-root.Mui-selected:hover": {
                            backgroundColor: "var(--med-linear-green)",
                          },
                          // maxHeight: 'unset',
                        },
                      },
                    }}
                  >
                    {typesOptions?.map((item) => (
                      <MenuItem
                        value={item.Id}
                        key={item.Id}
                        onClick={() => {
                          //   setSelectedSector(item?.Name);
                        }}
                      >
                        {item.Name}
                      </MenuItem>
                    ))}
                  </Select>
                  <div className="error-msg">{form.errors.TypeId}</div>
                </Col>
              </FormGroup>
            </Col>
            <Col sm="12" md="12" lg="6">
              <FormGroup id="input-group" className="row">
                <Col sm="12" md="12" lg="3">
                  <FormLabel for={"Name"} required className="">
                    اسم المعرض<span className="text-red">*</span>
                  </FormLabel>
                </Col>
                <Col sm="12" md="12" lg="9">
                  <TextField
                    name={"Name"}
                    type={"text"}
                    value={loading ? "" : form.values.Name}
                    required={true}
                    onChange={form.handleChange}
                    variant="outlined"
                    sx={{
                      "&.MuiFormControl-root": {
                        width: "100%",
                        "& .MuiFormHelperText-root": {
                          color: "#d1202f",
                          textAlign: "right",
                        },
                      },
                      "& .MuiInputBase-input": {
                        background: "var(--field-BG)",
                        padding: "0.7rem",
                        borderRadius: "12px",
                        outline: "none",
                        border: form.errors.Name ? "1px solid var(--red)" : "0",
                      },
                      "& .MuiOutlinedInput-root": {
                        border: "none",
                        "&:hover, &:focus": {
                          border: "none",
                        },
                        "&.Mui-focused": {
                          border: "none",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                          outline: "none",
                          borderRadius: "12px",
                        },
                      },
                    }}
                  />
                  <div className="error-msg">{form.errors.Name}</div>
                </Col>
              </FormGroup>
            </Col>
            <Col sm="12" md="12" lg="6">
              <FormGroup id="input-group" className="row">
                <Col sm="12" md="12" lg="3">
                  <FormLabel for={"FromDate"} required className="">
                    من تاريخ<span className="text-red">*</span>
                  </FormLabel>
                </Col>
                <Col sm="12" md="12" lg="9">
                  <FormGroup
                    id="input-group"
                    className="input-group col-5 date-custome-component"
                  >
                    <Form.Label
                      className="form-control date-placeholder"
                      htmlFor={"FromDate"}
                      id={`date-picker`}
                      role="button"
                      onClick={() => {
                        let ele = document.getElementById("FromDate");
                        ele.popperPlacement = "bottom left";
                        ele.showPicker();
                        document.getElementById("FromDate").showPicker();
                      }}
                    >
                      {form.values.FromDate ? (
                        form.values.FromDate
                      ) : (
                        <>
                          <CalendarMonthIcon sx={{ marginLeft: "0.5rem" }} />
                          اليوم - الشهر - العام
                        </>
                      )}
                    </Form.Label>
                    <Form.Control
                      id={"FromDate"}
                      name={"FromDate"}
                      type={"date"}
                      className="p-2 mb-0 rounded-0 form-control date-picker"
                      onChange={form.handleChange}
                      popperPlacement="bottom"
                    />
                  </FormGroup>
                  {/* <TextField
                    name="FromDate"
                    type="date"
                    value={form?.values?.FromDate}
                    onChange={form.handleChange}
                    variant="outlined"
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "&.MuiFormControl-root": {
                        width: "100%",
                        "& .MuiFormHelperText-root": {
                          color: "#d1202f",
                          textAlign: "right",
                        },
                      },
                      "& .MuiInputBase-input": {
                        background: "var(--field-BG)",
                        padding: "0.7rem",
                        borderRadius: "12px",
                        outline: "none",
                        border: form.errors.FromDate
                          ? "1px solid var(--red)"
                          : "0",
                      },
                      "& .MuiOutlinedInput-root": {
                        border: "none",
                        "&:hover, &:focus": {
                          border: "none",
                        },
                        "&.Mui-focused": {
                          border: "none",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                          outline: "none",
                          borderRadius: "12px",
                        },
                      },
                    }}
                  /> */}
                  <div className="error-msg">{form.errors.FromDate}</div>
                </Col>
              </FormGroup>
            </Col>
            <Col sm="12" md="12" lg="6">
              <FormGroup id="input-group" className="row">
                <Col sm="12" md="12" lg="3">
                  <FormLabel for={"ToDate"} required className="">
                    إلى تاريخ<span className="text-red">*</span>
                  </FormLabel>
                </Col>
                <Col sm="12" md="12" lg="9">
                  <FormGroup
                    id="input-group"
                    className="input-group col-5 date-custome-component"
                  >
                    <Form.Label
                      className="form-control date-placeholder"
                      htmlFor={"ToDate"}
                      id={`date-picker`}
                      role="button"
                      onClick={() => {
                        let ele = document.getElementById("ToDate");
                        ele.popperPlacement = "bottom left";
                        ele.showPicker();
                        document.getElementById("ToDate").showPicker();
                      }}
                    >
                      {form.values.ToDate ? (
                        form.values.ToDate
                      ) : (
                        <>
                          <CalendarMonthIcon sx={{ marginLeft: "0.5rem" }} />
                          اليوم - الشهر - العام
                        </>
                      )}
                    </Form.Label>
                    <Form.Control
                      id={"ToDate"}
                      name={"ToDate"}
                      type={"date"}
                      className="p-2 mb-0 rounded-0 form-control date-picker"
                      onChange={form.handleChange}
                      popperPlacement="bottom"
                    />
                  </FormGroup>

                  <div className="error-msg">{form.errors.ToDate}</div>

                  {/* <TextField
                    name="ToDate"
                    type="date"
                    value={form?.values?.ToDate}
                    onChange={form.handleChange}
                    variant="outlined"
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "&.MuiFormControl-root": {
                        width: "100%",
                        "& .MuiFormHelperText-root": {
                          color: "#d1202f",
                          textAlign: "right",
                        },
                      },
                      "& .MuiInputBase-input": {
                        background: "var(--field-BG)",
                        padding: "0.7rem",
                        borderRadius: "12px",
                        outline: "none",
                        border: form.errors.ToDate
                          ? "1px solid var(--red)"
                          : "0",
                      },
                      "& .MuiOutlinedInput-root": {
                        border: "none",
                        "&:hover, &:focus": {
                          border: "none",
                        },
                        "&.Mui-focused": {
                          border: "none",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                          outline: "none",
                          borderRadius: "12px",
                        },
                      },
                    }}
                  /> */}
                </Col>
              </FormGroup>
            </Col>
            <Col sm="12" md="12" lg="6">
              <FormGroup id="input-group" className="row">
                <Col sm="12" md="12" lg="3">
                  <FormLabel for={"SectorId"} required className="">
                    القطاع
                    <span className="text-red">*</span>
                  </FormLabel>
                </Col>
                <Col sm="12" md="12" lg="9">
                  <Select
                    multiple
                    value={loading ? [] : form.values.SectorId}
                    onChange={handleSectorChange}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value?.Id} label={value?.Name} />
                        ))}
                      </Box>
                    )}
                    sx={{
                      borderRadius: "12px",
                      "&.MuiInputBase-root": {
                        width: "100%",
                        border: form.errors.SectorId
                          ? "1px solid var(--red)"
                          : "0",
                      },
                      "& .MuiSelect-select": {
                        background: "rgb(237, 239, 240)",
                        padding: "0.7rem",
                        borderRadius: "12px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                        outline: "none",
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          "& .MuiMenuItem-root": {
                            textWrap: "pretty",
                          },
                          "& .MuiMenuItem-root.Mui-selected": {
                            backgroundColor: "var(--second-linear-green)",
                          },
                          "& .MuiMenuItem-root:hover": {
                            backgroundColor: "var(--first-linear-green)",
                          },
                          "& .MuiMenuItem-root.Mui-selected:hover": {
                            backgroundColor: "var(--med-linear-green)",
                          },
                          // maxHeight: 'unset',
                        },
                      },
                    }}
                  >
                    {sectorOptions?.map((item) => (
                      <MenuItem
                        value={item}
                        key={item.Id}
                        onClick={() => {
                          //   setSelectedSector(item?.Name);
                        }}
                      >
                        {item.Name}
                      </MenuItem>
                    ))}
                  </Select>
                  <div className="error-msg">{form.errors.SectorId}</div>
                </Col>
              </FormGroup>
            </Col>
            <Col sm="12" md="12" lg="6">
              <FormGroup id="input-group" className="row">
                <Col sm="12" md="12" lg="3">
                  <FormLabel for={"Location"} required className="">
                    المكان<span className="text-red">*</span>
                  </FormLabel>
                </Col>
                <Col sm="12" md="12" lg="9">
                  <TextField
                    value={loading ? "" : form.values.Location}
                    name={"Location"}
                    type={"text"}
                    required={true}
                    onChange={form.handleChange}
                    variant="outlined"
                    sx={{
                      "&.MuiFormControl-root": {
                        width: "100%",
                        "& .MuiFormHelperText-root": {
                          color: "#d1202f",
                          textAlign: "right",
                        },
                      },
                      "& .MuiInputBase-input": {
                        background: "var(--field-BG)",
                        padding: "0.7rem",
                        borderRadius: "12px",
                        outline: "none",
                        border: form.errors.Location
                          ? "1px solid var(--red)"
                          : "0",
                      },
                      "& .MuiOutlinedInput-root": {
                        border: "none",
                        "&:hover, &:focus": {
                          border: "none",
                        },
                        "&.Mui-focused": {
                          border: "none",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                          outline: "none",
                          borderRadius: "12px",
                        },
                      },
                    }}
                  />
                  <div className="error-msg">{form.errors.Location}</div>
                </Col>
              </FormGroup>
            </Col>{" "}
            <Col sm="12" md="12" lg="6">
              <FormGroup id="input-group" className="row">
                <Col sm="12" md="12" lg="3">
                  <FormLabel for={"Orgenizer"} required className="">
                    المنظم<span className="text-red">*</span>
                  </FormLabel>
                </Col>
                <Col sm="12" md="12" lg="9">
                  <TextField
                    disabled
                    //   id={id}
                    value={loading ? "" : form.values.Orgenizer}
                    name={"Orgenizer"}
                    type={"text"}
                    required={true}
                    onChange={form.handleChange}
                    variant="outlined"
                    //   helperText={
                    //     Boolean(form.errors.Orgenizer) &&
                    //     ValidateOnChange &&
                    //     form.errors.Orgenizer
                    //   }
                    sx={{
                      "&.MuiFormControl-root": {
                        width: "100%",
                        "& .MuiFormHelperText-root": {
                          color: "#d1202f",
                          textAlign: "right",
                        },
                      },
                      "& .MuiInputBase-input": {
                        background: "var(--field-BG)",
                        padding: "0.7rem",
                        borderRadius: "12px",
                        outline: "none",
                        border: form.errors.Orgenizer
                          ? "1px solid var(--red)"
                          : "0",
                      },
                      "& .MuiOutlinedInput-root": {
                        border: "none",
                        "&:hover, &:focus": {
                          border: "none",
                        },
                        "&.Mui-focused": {
                          border: "none",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                          outline: "none",
                          borderRadius: "12px",
                        },
                      },
                    }}
                  />
                  <div className="error-msg">{form.errors.Orgenizer}</div>
                </Col>
              </FormGroup>
            </Col>
            <Col sm="12" md="12" lg="6">
              <FormGroup id="input-group" className="row">
                <Col sm="12" md="12" lg="3">
                  <FormLabel for={"Products"} required className="">
                    المعروضات<span className="text-red">*</span>
                  </FormLabel>
                </Col>
                <Col sm="12" md="12" lg="9">
                  <TextField
                    //   id={id}
                    value={loading ? "" : form.values.Products}
                    name={"Products"}
                    type={"text"}
                    //   defaultValue={DefaultValue}
                    required={true}
                    onChange={form.handleChange}
                    variant="outlined"
                    //   helperText={
                    //     Boolean(form.errors.Products) &&
                    //     ValidateOnChange &&
                    //     form.errors.Products
                    //   }
                    sx={{
                      "&.MuiFormControl-root": {
                        width: "100%",
                        "& .MuiFormHelperText-root": {
                          color: "#d1202f",
                          textAlign: "right",
                        },
                      },
                      "& .MuiInputBase-input": {
                        background: "var(--field-BG)",
                        padding: "0.7rem",
                        borderRadius: "12px",
                        outline: "none",
                        border: form.errors.Products
                          ? "1px solid var(--red)"
                          : "0",
                      },
                      "& .MuiOutlinedInput-root": {
                        border: "none",
                        "&:hover, &:focus": {
                          border: "none",
                        },
                        "&.Mui-focused": {
                          border: "none",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                          outline: "none",
                          borderRadius: "12px",
                        },
                      },
                    }}
                  />
                  <div className="error-msg">{form.errors.Products}</div>
                </Col>
              </FormGroup>
            </Col>
            <Col sm="12" md="12" lg="6">
              <FormGroup id="input-group" className="row">
                <Col sm="12" md="12" lg="3">
                  {/* <FormLabel for={"exhibitorImage"} required className="">
                    صورة المعرض<span className="text-red">*</span>
                  </FormLabel> */}
                </Col>
                <Col sm="12" md="12" lg="9">
                  <CustomAttachment
                    label="صورة المعرض"
                    value={exhibitorImage}
                    setValue={setExhibitorImage}
                    isRequired={true}
                    showError={validateOnChange && exhibitorImageError}
                    setImageName={setExhibitorImageName}
                    showImage={true}
                  />
                </Col>
              </FormGroup>
            </Col>
          </Form>
        </Formik>

        <div className="d-flex justify-content-center mb-4">
          <Button
            type="submit"
            sx={{
              borderRadius: "15px",
              background:
                "linear-gradient(180deg, var(--first-linear-green) 0%, var(--second-linear-green) 100%)",
              color: "#FFF",
              textAlign: "center",
              fontFamily: "Cairo",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",

              marginLeft: "1rem",
            }}
            variant="contained"
            // onClick={submitForm}
            onClick={() => {
              setValidateOnChange(true);
              form.handleSubmit();
            }}
            disabled={false}
          >
            {loading ? (
              <span className="indicator-progress">
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            ) : isEdit ? (
              "تحديث البيانات"
            ) : (
              "إضافة"
            )}
          </Button>
          <Button
            sx={{
              borderRadius: "15px",
              background: "linear-gradient(120deg, #f5f5f5 0%, #9e9e9e 100%)",
              color: "#FFF",
              textAlign: "center",
              fontFamily: "Cairo",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",

              marginLeft: "1rem",
            }}
            variant="contained"
            onClick={() => {
              navigate("/", { replace: false });
            }}
            disabled={false}
          >
            إلغاء
          </Button>
        </div>
      </div>
    </Styles>
  );
};

export default AddExhibition;

import styled from "styled-components";

export const Styles = styled.div`
  width: 100%;
  background: transparent;
  padding: 0 !important;
  margin-top: -3rem;

  .navbar-nav {
    flex-direction: row;
  }
  .nav-item:hover,
  .active {
    span {
      color: var(--green) !important;
      font-weight: bold;
    }
  }

  .nav-item:last-child a {
    border-left: 0;
  }

  @media (max-width: 1200px) {
    margin-top: -2.5rem;
  }
  @media (max-width: 900px) {
    margin-top: -2rem;
  }
  @media (max-width: 820px) {
    margin-top: -2rem;
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 550px) {
    margin-top: -2rem;
  }
`;
export const NavStyles = styled.div`
  display: flex;
`;
export const NavLinkStyles = styled.div`
  a {
    text-decoration: none;
    font-size: 2rem;
    line-height: 30px;
    color: #ededed !important;
    font-weight: 400;
    padding: 0rem;
    padding-left: 8rem !important;
    padding-right: 8rem !important;
    border-left: 1px solid #ededed;
  }
  @media (max-width: 1450px) {
    a {
      padding-left: 6.5rem !important;
      padding-right: 6.5rem !important;
    }
  }
  @media (max-width: 1400px) {
    a {
      padding-left: 6rem !important;
      padding-right: 6rem !important;
    }
  }
  @media (max-width: 1300px) {
    a {
      padding-left: 5rem !important;
      padding-right: 5rem !important;
    }
  }
  @media (max-width: 1200px) {
    a {
      font-size: 2rem;
      line-height: 30px;
      padding-left: 4rem !important;
      padding-right: 4rem !important;
    }
  }
  @media (max-width: 900px) {
    a {
      font-size: 1.6rem;
    }
  }
  @media (max-width: 820px) {
    a {
      font-size: 1.5rem;
      padding-left: 3rem !important;
      padding-right: 3rem !important;
    }
  }
  @media (max-width: 780px) {
    a {
      font-size: 1.5rem;
      padding-left: 3rem !important;
      padding-right: 3rem !important;
    }
  }

  @media (max-width: 768px) {
    a {
      font-size: 1.7rem;
      line-height: 30px;
      padding-left: 3rem !important;
      padding-right: 3rem !important;
    }
  }
  @media (max-width: 720px) {
    a {
      font-size: 1.7rem;
      line-height: 30px;
      padding-left: 2rem !important;
      padding-right: 2rem !important;
    }
  }
  @media (max-width: 550px) {
    a {
      font-size: 1.2rem;
      line-height: 30px;
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
  }
  @media (max-width: 400px) {
    a {
      font-size: 1rem;
      line-height: 30px;
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
  }
`;

import { Box, Button, Stack, Typography } from "@mui/material";
import classes from "./optionOne.module.css";
import { createTheme, ThemeProvider, useMediaQuery } from "@mui/material";
import { useMediaQuery as useResponsiveMediaQuery } from "react-responsive";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { MyDocument } from "../../print-to-pdf";
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0, // Extra small devices (portrait phones)
      sm: 600, // Small devices (landscape phones)
      md: 991, // Medium devices (tablets)
      lg: 1280, // Large devices (desktops)
      xl: 1920, // Extra large devices (large desktops)
    },
  },
});

const O1HeroSection = ({ pathname }) => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const isIPadView = useResponsiveMediaQuery({ query: "(max-width: 1024px)" });

  const scrollToSection = (id) => {
    const targetSection = document.getElementById(id);
    targetSection.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <ThemeProvider theme={theme}>
      <Stack
        direction={isSmallScreen ? "column" : "row"}
        height={isSmallScreen ? "max-content" : "90vh"}
        // height={isIPadView ? "60vh" : isSmallScreen ? "max-content" : "90vh"}
      >
        <Box
          width={"100%"}
          position="relative"
          className={`${classes.Buisness}  ${classes.option2BgBuisness}`}
        >
          <div className={classes.backDrop1} />
          <Stack
            className={classes.hero_text_box}
            spacing={6}
            alignItems={"center"}
          >
            <Typography variant="h2" className={classes.hero_text}>
              أصحاب الأعمال والمحترفون
            </Typography>
            <Typography
              variant="p"
              className={classes.hero_sub_text}
              sx={{ width: "70%" }}
            >
              يمكنكم الان تقديم معاملاتكم عبر المنصة الإلكترونية الجديدة لمديرية
              حماية المستهلك للإستفادة من الخدمات بشكلٍ أسرع وأفضل.
            </Typography>
            <Button
              variant="outlined"
              className={classes.hero_button}
              onClick={() => scrollToSection("servicesSection")}
            >
              ارسال طلب
            </Button>
          </Stack>
        </Box>
        <Box
          width={"100%"}
          position="relative"
          className={`${classes.Consumer} ${classes.option3BgConsumer}`}
        >
          <div className={classes.backDrop2} />
          <Stack
            className={classes.hero_text_box}
            spacing={6}
            alignItems={"center"}
            sx={{ marginTop: isIPadView ? "13.3rem" : "11.7rem" }}
          >
            <Typography variant="h2" className={classes.hero_text}>
              المستهلكون
            </Typography>
            <Typography variant="p" className={classes.hero_sub_text}>
              قم بالمساهمة في حماية حقوقك ومجتمعك من خلال الإبلاغ عن حالات
              الاحتيال والممارسات التجارية غير القانونية عبر المنصة الإلكترونية
              الجديدة.{" "}
            </Typography>
            <Button
              variant="outlined"
              className={classes.hero_button}
              onClick={() => scrollToSection("complaintsSection")}
            >
              تقديم شكوى
            </Button>
          </Stack>
        </Box>
      </Stack>
      {/* <PDFDownloadLink document={<MyDocument />} fileName="somename.pdf">
        {({ blob, url, loading, error }) =>
          loading ? "Loading document..." : "Download now!"
        }
      </PDFDownloadLink> */}
    </ThemeProvider>
  );
};

export default O1HeroSection;

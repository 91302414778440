import React, { useEffect, useState } from "react";
import { Styles } from "./styles";
import CustomDataTable from "../../common/data-table";
import { useMediaQuery } from "react-responsive";
import { tableDemoData } from "../../../constants/demoData";
import { loadingColumns } from "./columns";
import { GetMarketReports } from "../../../services/fed/fedExhibitors.service";
import { Page } from "@react-pdf/renderer";

const FEDStatisticsReportsContent = () => {
  const isMobileView = useMediaQuery({ query: "(max-width: 550px)" });
  const [data, setData] = useState([]);
  // ui / ux

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrevious, setHasPrevious] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [totalElements, setTotalElements] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [refreshData, setRefreshData] = useState(false);
  const columns = [
    {
      name: "المعرض",
      selector: (row) => row.ExhibitionName,
      width: isMobileView ? "200px" : "auto",
      wrap: true,
    },
    {
      name: "من تاريخ",
      selector: (row) => row.FromDate,
      width: isMobileView ? "180px" : "auto",
      wrap: true,
    },
    {
      name: "إلى تاريخ",
      selector: (row) => row.ToDate,
      width: "auto",
      wrap: true,
    },
    {
      name: "المكان",
      selector: (row) => row.ExhibitionPlace,
      width: "auto",
      wrap: true,
    },
    {
      name: "عدد الزوار",
      selector: (row) => row.VisitorsCount,
      width: "auto",
      wrap: true,
    },
    {
      name: "عدد العارضين",
      selector: (row) => row.ExhibitorsCount,
      width: "auto",
      wrap: true,
    },
    {
      name: "حجم المبيعات",
      selector: (row) => row.TotalSales,
      width: "auto",
      wrap: true,
    },
  ];

  const getData = async () => {
    setLoading(true);
    const response = await GetMarketReports(page, pageSize);
    console.log(response.data?.Data);
    if (response.status === 200) {
      console.log(response.data);
      setData(response.data?.Data);
      setPage(response.data?.Paging?.CurrentPage);
      setHasNext(response.data?.Paging?.HasNext);
      setHasPrevious(response.data?.Paging?.HasPrevious);
      setPageSize(response.data?.Paging?.PageSize);
      setTotalElements(response.data?.Paging?.Total);
      setTotalPages(response.data?.Paging?.TotalPages);
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };
  useEffect(() => {
    getData();
  }, [page, refreshData]);
  useEffect(() => {
    setPage(1);
    setRefreshData(!refreshData);
  }, [pageSize]);
  return (
    <Styles>
      <CustomDataTable
        columns={loading ? loadingColumns : columns}
        data={loading ? new Array(10).fill("") : data}
        pagination={true}
        page={page}
        setPage={setPage}
        hasNext={hasNext}
        hasPrevious={hasPrevious}
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalElements={totalElements}
        setTotalElements={setTotalElements}
        totalPages={totalPages}
        setTotalPages={setTotalPages}

        // onRowClicked={(row) => {
        //   handleRowClicked(row);
        // }}
      />
    </Styles>
  );
};

export default FEDStatisticsReportsContent;

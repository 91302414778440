import React, { useEffect, useState } from "react";
import { getExternalLists } from "../../../../services/picklists.services";

const listEnums = {};

const DropdownListExternalData = ({
  DropDownListExternalDataApilink,
  Name,
  Id,
  Guid,
  Mandatory,
  Placeholder,
  checkConditionsChange,
  checkClickAble,
  checkFieldCorrection,
  DropDownListExternalDataLabelName,
  DropDownListExternalDataValueName,
  lockAllFieldsExceptAttachments,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const getList = async () => {
    setLoading(true);
    const response = await getExternalLists(DropDownListExternalDataApilink);
    if (response.status === 200) {
      setData(response?.data?.Sectors);
    }
    setLoading(false);
  };
  useEffect(() => {
    getList();
  }, []);
  return (
    <div>
      <div className="custom-select d-flex">
        {loading && (
          <div className="picklist-loader-dropdown pt-2 pe-2">
            <div
              class="spinner-border spinner-border-sm text-success"
              role="status"
            ></div>
          </div>
        )}
        <select
          name={Name}
          id={Id}
          className={
            loading ? "custome-loading-select p-2 mb-0 w-100" : "p-2 mb-0 w-100"
          }
          onChange={() => {
            checkConditionsChange(Guid);
            checkClickAble(data);
            checkFieldCorrection(Id);
          }}
          onBlur={() => checkFieldCorrection(Id)}
          required={Mandatory}
          disabled={loading || lockAllFieldsExceptAttachments}
        >
          {loading && <option selected value=""></option>}
          <option value="">{Placeholder}</option>
          {data?.map((item) => (
            <option value={item[DropDownListExternalDataValueName]}>
              {item[DropDownListExternalDataLabelName]}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default DropdownListExternalData;

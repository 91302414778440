import styled from "styled-components";

export const Styles = styled.div`
  padding-top: 2vh;
  .print-form {
    padding: 2rem;
    border: 1px solid var(--grey);
    min-height: 400px;
    .print-title {
      font-size: 2rem;
      text-align: center;
    }
  }
  .buttons-container a {
    text-decoration: none;
    color: #016938;
  }
  .signature-buttons-container #cancel-button,
  .buttons-container #cancel-button {
    background: transparent !important;
    color: #016938 !important;
    font-size: 1.4rem;
    width: 5%;
    padding-left: 1rem;
    padding-right: 1rem;
    a {
      color: #016938 !important;
      text-decoration: none;
    }
  }
  .signature-buttons-container #cancel-button::before,
  .buttons-container #cancel-button::before {
    content: "";
    position: absolute;
    top: 80%;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #016938; /* Replace with your desired color */
    transform: scaleX(0);
    transition: transform 0.3s cubic-bezier(0.2, 0.8, 0.2, 1); /* Add transition property for smooth animation */
  }
  .signature-buttons-container #cancel-button:hover::before,
  .buttons-container #cancel-button:hover::before {
    transform: scaleX(1); /* Expand the line on hover */
  }

  .buttons-container #print-button {
    width: 15%;
  }
  .buttons-container #upload-button {
    width: 30%;
  }
  .signature-buttons-container {
    button {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
  }
  @media (max-width: 768px) {
    padding-top: 2rem;
    .buttons-container {
      display: flex;
      flex-direction: row;
    }
    .print-form {
      padding: 1rem;
      margin: 1rem;
    }
    .buttons-container {
      align-items: center;
    }
    .buttons-container button {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
    .signature-buttons-container #cancel-button,
    .buttons-container #cancel-button,
    .buttons-container #print-button {
      width: 40%;
    }
    .buttons-container #upload-button {
      width: 80%;
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .signature-buttons-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      button {
        width: auto;
        padding-left: 3rem !important;
        padding-right: 3rem;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }
    @media (max-width: 500px) {
      padding-top: 2rem;
      .buttons-container {
        display: flex;
        flex-direction: column;
      }
      .signature-buttons-container {
        flex-direction: column;
        align-items: center;
        button {
          width: auto;
          padding-left: 3rem !important;
          padding-right: 3rem !important;
          margin-left: 1rem !important;
          margin-right: 1rem !important;
          margin-top: 0.5rem !important;
          margin-bottom: 0.5rem !important;
        }
      }
    }
  }
`;

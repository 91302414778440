import { Pagination } from "@mui/material";
import React from "react";

const TablePagination = ({ pageSize, page, handleChangePage }) => {
  return (
    <Pagination
      sx={{
        // direction: "ltr",
        "& .MuiPagination-ul": {
          justifyContent: "center",
        },
        "& .MuiSvgIcon-root": {
          transform: "rotate(180deg)",
        },
      }}
      count={pageSize ? pageSize : 1}
      page={page}
      onChange={handleChangePage}
    />
  );
};

export default TablePagination;

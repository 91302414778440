const config = {
  //API ENDPOINTS
  DYNAMIC_FORM_ENDPOINT: "DynamicForms",
  SAVE_DYNAMIC_FORM_ENDPOINT: "Transactions",
  PICKLISTS_ENDPOINT: "Picklists/AllWithOptions",
  USERS_ENDPOINT: "Users/All",
  SAVE_FILE_ENDPOINT: "MediaFile/_SaveFile",
  SAVE_FILES_ENDPOINT: "MediaFile/_SaveFiles",
  SAVE_BLOB_ENDPOINT: "MediaFile/_SaveBlobFile",
  // products grid data
  productsGrid: "Transactions/TransactionProducts",
  // check chassis number validation
  chassisCheck: "Transactions/CheckChasissNumber",
  counterNumberCheck: "Transactions/CheckCounterNumber",
  //Registration
  ProcessCategories: "ProcessCategories/Public/All?processType=3267",
  ComplaintsSectionUrl: "Picklists/Public/Complaints",
  ComplaintsUrl: "Picklists/Public/Options/106",
  Process: "Processes/Public/All/",
  // picklists
  GetAllCountries: "Countries/All",
  GetAllGovernorate: "Picklists/Public/Options/112",
  GetAllDistricts: "Picklists/Public/Options/118",
  GetAllCities: "Picklists/Public/Options/119",
  GetAges: "Picklists/Public/Options/104",
  GetCompanyType: "Picklists/Public/Options/133",
  GetTType: "Picklists/Public/Options/131",
  GetTradeType: "Picklists/Public/Options/134",
  GetFeminineCompany: "Picklists/Public/Options/132",
  GetProductTypes: "Picklists/Public/OptionsWithTags/203",
  GetOriginCountries: "Picklists/Public/Options/204",
  GetWomenLedOptions: "Picklists/Public/Options/1170",
  GetSectors: "Picklists/Public/Options/106",
  GetExhibitionTypes: "Picklists/Public/Options/704",

  VerifyEmailCode: `Users/VerifyAccount/Email`,
  VerifyPhoneCode: `Users/VerifyAccount/Mobile`,
  // User Profile
  UserProfile: "Users/Profile",
  PendingTransactions: "Transactions/Pending",
  CompletedTransactions: "Transactions/Completed",
  DraftTransactions: "Transactions/Draft",
  formFields: "DynamicForms/GetFormFieldsData/",
  confirmStep: "Transactions/ConfirmStep",
  TransactionsDocumentById: "Transactions/GetFED_TransactionDocumentById",
  PostFEDTransactionDocument: "Transactions/FED_TransactionDocument",
  PostFEDTransactionDocumentDraft: "Transactions/SetExhibitorAsDraft",
  // exhibitors
  exhibitorDetails: "Transactions/GetExhibitor",
  AddExhibitor: "Transactions/Exhibitor",
  UpdateExhibitor: "Transactions/Exhibitor",
  DeleteExhibitor: "Transactions/Exhibitor",
  AddNewExhibition: "Exhibitions/Create",
  AllExhibition: "Exhibitions/GetAllExhibitions",
  HomeExhibition: "Exhibitions/GetHomeExhibitions",
  MarketReports: "Transactions/GetAllMarketReport",
  //LOCAL STORAGE KEYS
  TOKEN_KEY: "TOKEN",
  REFRESH_TOKEN_KEY: "refreshToken",
  LANGUAGE_KEY: "lang",
  THEME_KEY: "theme",
  DIRECTION_KEY: "dir",
  CLAIMS_KEY: "claims",
  redirectURL: "redirectURL",
  //CONSTANT
  RefreshToken: "Users/RefreshToken",
  API_KEY: "",
  PUBKEY:
    "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAtmQNstR3fesXTrzMOhHl\n" +
    "lHBBEBPMEwj0dIeV0sjpxL43a/DNv6JegPc5aNq4zoaCF0n5A/0RsO8pamXNX2bF\n" +
    "rud9SDcrQga5HsUUcV66F6qZKXpPTMewrCc7J7g8eSYZr+jBz8dt6sN+uoqK8u/S\n" +
    "gF+AYMSLoS9Ch++EgLb6y+e3zYrWTpB1Acu89vanaGjiceastFv20BdGOwoLc/xF\n" +
    "GJGmwIynlxv3o2WOqKB0bCYkdrCpD73XxzU9n2fbNXth7VcmLSrCZ70SE/UgPSrM\n" +
    "CxYOHRXtwL9oTl6K3A+aaJgXftiYr7aIdB6pISaoBNzHrMAgq6nePsYFCT0z444a\n" +
    "lQIDAQAB",
  PUBKEYOLD:
    "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAy6zbCQLVUZHIp4Sr/9I9\n" +
    "eHfBE4adXx5YuBeNwVqAuLz7dpUmUEBRyUgmMHkymJZI14f1YVq2yyar2OkPlu8I\n" +
    "7JU/9v+A5lPkxT70b7qneeWAEDf6SHZxw3NoX3DtI/gMx9PTL6OGSD32WsWv3tGI\n" +
    "6H7OgBPgGieAqofSVPc2at9lWrxj/3x6IRl0M85wKjh3aHv2ltaTJb38EY5C5EMw\n" +
    "/6IIZ2wgrnVBDfFlt8Vmx6PQFjoypv2mpEUF5fpgEgN/AEDVXYEQCjRod5lCZ1au\n" +
    "LhirZxGF+onH10mNZPuxYjmS2rlJ6MUeH6UhNUcR+rKYCej+CaP7PATP0NVuAAmj\n" +
    "jwIDAQAB",
  // ********************** API_URL **********************
  // API_URL: "https://dw.cloudsystems.tech/api/Api",
  // our active domain
  // API_URL: "https://dw.cloudsystems.tech/api/api",
  API_URL: process.env.REACT_APP_API_ENDPOINT,
  // API_URL: "https://cpd-api.cloudcode.me/Api",
  // API_URL: "https://cpd-api.economy.gov.lb/api",
  // ********************** IMAGE_URL **********************
  // IMAGE_URL: "https://dw.cloudsystems.tech/admin",
  // our active domain
  // IMAGE_URL: "https://cpd-admin.cloudcode.me",
  IMAGE_URL: process.env.REACT_APP_IMAGES_ENDPOINT,
  // IMAGE_URL: "https://cpd-admin.economy.gov.lb/",
  // ********************** PUBLIC_URL **********************
  // PUBLIC_URL: "https://dw.cloudsystems.tech/PublicApis/Api",
  // our active domain
  PUBLIC_URL: "https://cpd-api.cloudcode.me/Api",
  // PUBLIC_URL: "https://cpd-api.economy.gov.lb/api",

  // PORTAL_URL: "https://consumer-protection.economy.gov.lb",
  PORTAL_URL: "https://cpd.economy.gov.lb/",
};

const dev = {
  // API_URL: "https://dw.cloudsystems.tech/api/Api",
};

const acc = {
  // API_URL: "https://dw.cloudsystems.tech/api/Api",
};

const test = {
  // API_URL: "https://dw.cloudsystems.tech/api/Api",
};
const envs = {
  dev,
  acc,
  test,
};
const environment = {
  ...config,
  ...envs[`${process.env.REACT_APP_ENV}`],
};

export default environment;

import React from "react";
import { Styles } from "./styles";
import { useMediaQuery } from "react-responsive";
import { A11y, Navigation, Pagination, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useEffect } from "react";
const StepsWidget = (props) => {
  const {
    steps,
    activeStep,
    setActiveStep,
    submitStep,
    dynamicFormId,
    dynamicFormDisplayName,
    uploadFileLoading,
    uploadFilesLoading,
    disabledButton,
    allowNextAfterUploadDocuments,
    submitLoading,
  } = props;
  const isMobileView = useMediaQuery({ query: "(max-width: 576px)" });
  const moveStep = (index) => {
    if (index < activeStep) {
      setActiveStep(index);
    } else if (index > activeStep && index < steps.length) setActiveStep(index);
    // const form = document.getElementById("formControl");
    // if (form.checkValidity()) {
    //   setActiveStep(index);
    // } else {
    //   form.reportValidity();
    // }
  };
  const handleBack = () => {
    if (activeStep != 0) setActiveStep(activeStep - 1);
  };
  const handleNext = () => {
    if (
      uploadFileLoading ||
      uploadFilesLoading ||
      disabledButton ||
      allowNextAfterUploadDocuments ||
      submitLoading ||
      activeStep === steps.length - 1
    )
      console.log();
    else setActiveStep(activeStep + 1);
  };
  return (
    <Styles stepsLength={steps?.length}>
      {isMobileView ? (
        <>
          {dynamicFormId == 20 && (
            <div
              className={
                dynamicFormId == 20 ? "steps-container mb-3" : "steps-container"
              }
            >
              <div
                className={
                  dynamicFormId == 20
                    ? "form-title step-title-green text-center mb-3"
                    : "form-title step-title-green text-center"
                }
              >
                {dynamicFormDisplayName}
              </div>
            </div>
          )}
          <div className="d-flex">
            {/* prev */}
            {dynamicFormId == 20 && (
              <div onClick={handleBack}>
                <ArrowForwardIosIcon
                  style={{
                    color: activeStep === 0 ? "grey" : "var(--green)",
                  }}
                />
              </div>
            )}
            <div className="steps-container w-100">
              <div id={steps[activeStep]?.Guid}>
                <div className="step-container justify-content-center ">
                  <span
                    className={"step-title step-title-green"}
                    // id="slide"
                  >
                    {activeStep === 0 && dynamicFormId != 20
                      ? dynamicFormDisplayName
                      : steps[activeStep]?.Name}
                  </span>
                </div>
              </div>
            </div>
            {/* next */}
            {dynamicFormId == 20 && (
              <div onClick={handleNext}>
                <ArrowBackIosIcon
                  style={{
                    color:
                      uploadFileLoading ||
                      uploadFilesLoading ||
                      disabledButton ||
                      allowNextAfterUploadDocuments ||
                      submitLoading ||
                      activeStep === steps?.length - 1
                        ? "grey"
                        : "var(--green)",
                  }}
                />
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div
            className={
              dynamicFormId == 20 ? "steps-container mb-3" : "steps-container"
            }
          >
            <div
              className={
                dynamicFormId == 20
                  ? "form-title step-title-green text-center mb-3"
                  : "form-title step-title-green text-center"
              }
            >
              {dynamicFormDisplayName}
            </div>
          </div>
          {dynamicFormId == 20 && (
            <div className="steps-container">
              {steps?.map((step, index) => (
                <div
                  id={step.Guid}
                  style={{
                    display: `${step?.DefaultVisible ? "block" : "none"}`,
                  }}
                >
                  <div className="step-container">
                    <span
                      className={
                        index <= activeStep
                          ? "step-title step-title-green"
                          : "step-title step-title-grey"
                      }
                    >
                      {index === 0 && dynamicFormId != 20
                        ? dynamicFormDisplayName
                        : step?.Name}
                    </span>
                    {index != submitStep && (
                      <div
                        className={
                          index < activeStep
                            ? "divider divider-green"
                            : "divider divider-grey"
                        }
                      >
                        <hr></hr>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </Styles>
  );
};

export default StepsWidget;

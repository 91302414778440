import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { Skeleton } from "@mui/material";

import { RefreshTokenFunction } from "../../../../services/refreshToken.services";
import axios from "axios";
import environment from "../../../../environment";
import { handleError } from "../../../../services/error.service";
import { getAccessTokenCookie } from "../../../../utils/cookie";
import TablePagination from "../../components/TablePagination";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "../../confirm-dialog";
import DashboardTableActions from "../../table-actions";

const tableHeaderStyle = {
  background:
    "linear-gradient(180deg, var(--first-linear-green) 0%, var(--second-linear-green) 100%);",
  backgroundClip: "text",
  textAlign: "center",
  fontFamily: "Cairo",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "188.9%",
  color: "transparent",
};
const tableCellStyle = {
  color: "#000",
  textAlign: "center",
  fontFamily: "Cairo",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "188.9%",
};
const DashboardInProgressOrders = () => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [refreshData, setRefreshData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const token = getAccessTokenCookie();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(1);
  const [activeRow, setActiveRow] = useState(false);
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleRowClick = (dynamicId, id, transactionId) => {
    if (dynamicId == 30) {
      navigate(`/dashboard/review-order/${id}?transactionId=${transactionId}`);
    } else navigate(`/dashboard/review-order/${id}`);
  };

  // `${environment.PUBLIC_URL}/${environment.PendingTransactions}?Page=${page}`,
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `${environment.API_URL}/${environment.PendingTransactions}?Page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 401) {
          RefreshTokenFunction();
        }
        if (response.status === 200 || response.status === 204) {
          setData(response.data.Data);
          setPageSize(response.data.Paging.TotalPages);
        }
      })
      .catch((error) => {
        handleError(error?.response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [page, refreshData]);
  return (
    <TableContainer component={Paper} sx={{ flex: 1, boxShadow: "none" }}>
      <Table
        sx={{
          border: "none",
          boxShadow: "none",
          "& .MuiTableCell-root": {
            border: "none",
          },
          borderCollapse: "separate",
          borderSpacing: " 0 15px",
          width: "max-content",
          minWidth: "100%",
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell sx={tableHeaderStyle}>الطلب</TableCell>
            <TableCell sx={tableHeaderStyle}>تاريخ تقديم الطلب</TableCell>
            <TableCell sx={tableHeaderStyle}>رقم المرجع</TableCell>
            <TableCell sx={tableHeaderStyle}>موعد التسليم</TableCell>
            <TableCell sx={tableHeaderStyle}>الإجراءات</TableCell>
            <TableCell sx={tableHeaderStyle}>{/* اخطارات */}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <>
              <TableRow>
                <TableCell sx={tableCellStyle}>
                  <Skeleton width={"100%"} />
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <Skeleton width={"100%"} />
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <Skeleton width={"100%"} />
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <Skeleton width={"100%"} />
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <Skeleton width={"100%"} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={tableCellStyle}>
                  <Skeleton width={"100%"} />
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <Skeleton width={"100%"} />
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <Skeleton width={"100%"} />
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <Skeleton width={"100%"} />
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <Skeleton width={"100%"} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={tableCellStyle}>
                  <Skeleton width={"100%"} />
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <Skeleton width={"100%"} />
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <Skeleton width={"100%"} />
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <Skeleton width={"100%"} />
                </TableCell>
                <TableCell sx={tableCellStyle}>
                  <Skeleton width={"100%"} />
                </TableCell>
              </TableRow>
            </>
          ) : data?.length === 0 ? (
            <TableRow sx={{ textAlign: "center" }}>
              <TableCell>
                <h5>لا يوجد</h5>
              </TableCell>
            </TableRow>
          ) : (
            data?.map((row) => (
              <TableRow
                // onClick={() => handleRowClick(row.DynamicFormDataId)}
                key={row.Id}
                onMouseOver={() => setActiveRow(row?.Id)}
                onMouseLeave={() => setActiveRow(null)}
                sx={{
                  cursor: "pointer",
                  borderRadius: "15px !important",
                  background: "#F8F9FA",
                  "&:hover": {
                    background:
                      "linear-gradient(180deg, var(--first-linear-green) 0%, var(--second-linear-green) 100%)",
                    "& .MuiTableCell-root": {
                      color: "#FFF",
                      // fontWeight: 700,
                    },
                  },
                  "& .MuiTableRow-root": {
                    margin: "1rem 0",
                  },
                }}
              >
                <TableCell
                  sx={{
                    ...tableCellStyle,
                    borderTopRightRadius: "15px",
                    borderBottomRightRadius: "15px",
                  }}
                  onClick={() =>
                    handleRowClick(
                      row?.DynamicFormId,
                      row?.DynamicFormDataId,
                      row?.Id
                    )
                  }
                >
                  {row.ProcessName}
                </TableCell>
                <TableCell
                  sx={tableCellStyle}
                  onClick={() =>
                    handleRowClick(
                      row?.DynamicFormId,
                      row?.DynamicFormDataId,
                      row?.Id
                    )
                  }
                >
                  {row.CreationDate}
                </TableCell>
                <TableCell
                  sx={tableCellStyle}
                  onClick={() =>
                    handleRowClick(
                      row?.DynamicFormId,
                      row?.DynamicFormDataId,
                      row?.Id
                    )
                  }
                >
                  {row.ReferenceNumber}
                </TableCell>
                <TableCell
                  sx={tableCellStyle}
                  onClick={() =>
                    handleRowClick(
                      row?.DynamicFormId,
                      row?.DynamicFormDataId,
                      row?.Id
                    )
                  }
                >
                  {row.DeliveryDate}
                </TableCell>
                <TableCell
                  sx={{
                    ...tableCellStyle,
                    borderTopLeftRadius: "15px",
                    borderBottomLeftRadius: "15px",
                    display: "flex",
                    minHeight: "65px",
                    color: "#FFF",

                    "& button": {
                      color: activeRow == row?.Id && "#FFF !important",
                    },

                    // backgroundColor: "#F8F9FA",
                  }}
                >
                  {row.CurrentStepId &&
                    (row.ProcessId == 14 || row.ProcessId == 15) && (
                      <ConfirmDialog
                        Id={row.Id}
                        CurrentStepId={row.CurrentStepId}
                        ProcessId={row.ProcessId}
                        refreshData={refreshData}
                        setRefreshData={setRefreshData}
                      />
                    )}

                  {/* {row.Notifications} */}
                  <DashboardTableActions
                    row={row}
                    setRefreshData={setRefreshData}
                  />
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      <TablePagination
        pageSize={pageSize}
        page={page}
        handleChangePage={handleChangePage}
      />
    </TableContainer>
  );
};

export default DashboardInProgressOrders;

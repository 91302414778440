import { useContext, useState } from "react";
import PropTypes from "prop-types";
import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
  useNavigate,
} from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Button,
  CircularProgress,
} from "@mui/material";
//
import Iconify from "../../../components/Iconify";
import drafts from "../../../assets/images/dashboardIcons/drafts.svg";
import draftsActive from "../../../assets/images/dashboardIcons/draftsActive.svg";
import FEDDraftsActive from "../../../assets/images/dashboardIcons/FEDdraftActive.png";

import saved from "../../../assets/images/dashboardIcons/saved.svg";
import savedActive from "../../../assets/images/dashboardIcons/savedActive.svg";
import FEDSavedActive from "../../../assets/images/dashboardIcons/FEDSavedActive.png";

import inprogress from "../../../assets/images/dashboardIcons/inprogress.svg";
import inprogressActive from "../../../assets/images/dashboardIcons/inprogressActive.svg";
import FEDInprogressActive from "../../../assets/images/dashboardIcons/FEDInProgressActive.png";
import logoutIcon from "../../../assets/images/dashboardIcons/logout.png";
import { removeAccessTokenCookie } from "../../../utils/cookie";
import UserContext from "../../../context/UserContext";
import { typesOfProjects } from "../../../constants/enums";
// ----------------------------------------------------------------------

export const ListItemStyle = styled((props) => (
  <ListItemButton disableGutters {...props} sx={{ textAlign: "center" }} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: "relative",
  // justifyContent: "center",
  textTransform: "capitalize",
  paddingLeft: theme.spacing(3),
  marginRight: "3rem",
  // paddingRight: theme.spacing(1.5),
  color: theme.palette.text.secondary,
  borderTopRightRadius: "40px",
  borderBottomRightRadius: "40px",
  "&:before": {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: "none",
    position: "absolute",
    borderTopLeftRadius: "40px",
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main,
  },
  "&:hover": {
    backgroundColor: "#fff",
    borderTopRightRadius: "40px",
    borderBottomRightRadius: "40px",
    "& .MuiListItemText-root": {
      color: "#000",
    },
  },
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

function NavItem({ item, active }) {
  const isActiveRoot = active(item.path);
  const { title, path, icon, info, children, activeIcon } = item;
  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: "#495676",
    fontWeight: "fontWeightMedium",
    backgroundColor: "#fff",
    "&:before": { display: "block", bgcolor: "transparent" },
  };

  const activeSubStyle = {
    color: "#495676",
    fontWeight: "fontWeightMedium",
  };
  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText
            disableTypography
            primary={title}
            sx={{
              color: "#000",
              textAlign: "center",
              fontFamily: "Cairo",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "188.9%" /* 37.78px */,
            }}
          />
          {info && info}
          <Iconify
            icon={
              open
                ? "eva:arrow-ios-downward-fill"
                : "eva:arrow-ios-forward-fill"
            }
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path } = item;
              const isActiveSub = active(path);

              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle),
                  }}
                >
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: "flex",
                        borderRadius: "50%",
                        alignItems: "center",
                        justifyContent: "center",
                        bgcolor: "text.disabled",
                        transition: (theme) =>
                          theme.transitions.create("transform"),
                        ...(isActiveSub && {
                          transform: "scale(2)",
                          bgcolor: "primary.main",
                        }),
                      }}
                    />
                  </ListItemIconStyle>
                  <ListItemText
                    disableTypography
                    primary={title}
                    sx={{
                      color: "#000",
                      textAlign: "center",
                      fontFamily: "Cairo",
                      fontSize: "20px",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "188.9%" /* 37.78px */,
                    }}
                  />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
        marginTop: "0.5rem",
        "& .activeIconOnHover": {
          display: "none",
        },
        "&:hover": {
          "& .activeIconOnHover": {
            display: "block",
          },
          "& .normalIconOnHover": {
            display: "none",
          },
        },
      }}
    >
      <ListItemIconStyle>
        {isActiveRoot ? (
          <img src={activeIcon} alt={"icon"} width={"40px"} height={"40px"} />
        ) : (
          <>
            <img
              className="activeIconOnHover"
              src={activeIcon}
              alt={"icon"}
              width={"40px"}
              height={"40px"}
            />
            <img
              src={icon}
              className="image normalIconOnHover"
              alt={"icon"}
              width={"20px"}
              height={"20px"}
            />
          </>
        )}
      </ListItemIconStyle>
      <ListItemText
        disableTypography
        primary={title}
        sx={{
          flex: "unset",
          color: isActiveRoot ? "#000" : "#fff",
          textAlign: "center",
          fontFamily: "Cairo",
          fontSize: "20px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "188.9%" /* 37.78px */,
        }}
      />
      {info && info}
    </ListItemStyle>
  );
}

/* NavSection.propTypes = {
  navConfig: PropTypes.array,
}; */

export default function NavSection({ ...other }) {
  const isFED = process.env.REACT_APP_ACTIVE_ENV == typesOfProjects.FED;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { setUserProfile } = useContext(UserContext);

  const match = (path) =>
    path ? !!matchPath({ path, end: false }, pathname) : false;
  let icon = logoutIcon;
  const sidebarConfig = [
    // {
    //   title: "الطلبات",
    //   path: "/dashboard/orders",
    //   icon: sidebarOrdersIcons,
    //   activeIcon: sidebarOrdersIconsActive,
    // },
    {
      title: "طلبات قيد المعالجة",
      path: "/dashboard/in-progress",
      icon: inprogress,
      activeIcon: isFED ? FEDInprogressActive : inprogressActive,
    },
    {
      title: "مسودات",
      path: "/dashboard/drafts",
      icon: drafts,
      activeIcon: isFED ? FEDDraftsActive : draftsActive,
    },
    {
      title: "طلبات محفوظة",
      path: "/dashboard/completed",
      icon: saved,
      activeIcon: isFED ? FEDSavedActive : savedActive,
    },
  ];

  const [logOutLoading, setLogoutLoading] = useState(false);

  const handleLogout = () => {
    setLogoutLoading(true);
    removeAccessTokenCookie();
    localStorage.removeItem("TOKEN");
    setLogoutLoading(false);
    setUserProfile({});
    navigate("/");
  };

  return (
    <Box {...other} sx={{ position: "relative" }}>
      <List disablePadding>
        {sidebarConfig.map((item) => (
          <NavItem key={item.title} item={item} active={match} />
        ))}
        <Box
          sx={{
            marginTop: "0.5rem",
            "& .imgDiv": {
              width: "35px",
              height: "35px",
            },
            "&:hover": {
              "& .imgDiv": {
                background: "var(--first-linear-green)",
                borderRadius: "50%",
              },
              "& .activeIconOnHover": {
                marginTop: "0.5rem",
                background: "var(--first-linear-green)",
                borderRadius: "50%",
              },
            },
          }}
        >
          <ListItemStyle path="#" onClick={handleLogout}>
            <ListItemIconStyle>
              <div className="imgDiv">
                <img
                  src={icon}
                  className="image activeIconOnHover"
                  alt={"icon"}
                  width={"20px"}
                  height={"20px"}
                />
              </div>
            </ListItemIconStyle>
            <ListItemText
              disableTypography
              primary="تسجيل الخروج"
              sx={{
                flex: "unset",
                color: "#fff",
                textAlign: "center",
                fontFamily: "Cairo",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "188.9%" /* 37.78px */,
                "&:hover": {
                  background: "transparent",
                  color: "#000",
                },
              }}
            />
          </ListItemStyle>
        </Box>
      </List>
    </Box>
  );
}

import React, { useEffect, useState } from "react";

import { Button } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import CustomDataTable from "../../common/data-table";
import AddNewProduct from "../../modals/add-new-product";
import {
  getOriginCountriesOptions,
  getProductTypesOptions,
} from "../../../services/register/picklists.services";
import { findElementObjectById } from "../../../helpers/dynamic-form/functionsOnElements";
import { useMediaQuery } from "react-responsive";
const GridTable = ({
  transactionProducts,
  setTransactionProducts,
  data,
  applyChangesOnField,
  checkClickAble,
  viewOnly = false,
}) => {
  const isMobileView = useMediaQuery({ query: "(max-width: 550px)" });
  const [currentId, setCurrentId] = useState(transactionProducts?.length || 0);
  const [editRowValues, setEditRowValues] = useState({});
  const [productTypesOptions, setProductTypesOptions] = useState([]);
  const [originCountriesOptions, setOriginCountriesOptions] = useState([]);
  const [editRowIndex, setEditRowIndex] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const onAdd = (emptyObject) => {
    setTransactionProducts((prev) => [...prev, emptyObject]);
  };

  const onDelete = (row) => {
    const newArray = transactionProducts.filter((item) => item?.Id != row?.Id);
    setTransactionProducts(newArray);
    const elementObj = findElementObjectById(data, row?.productRelatedField);
    const { StepId, SectionId, Field } = elementObj;
    applyChangesOnField(
      false,
      "VisibilityCondition",
      StepId,
      SectionId,
      Field?.Guid,
      Field?.Id
    );
    applyChangesOnField(
      false,
      "MandatoryCondition",
      StepId,
      SectionId,
      Field?.Guid,
      Field?.Id
    );
    setTimeout(() => {
      checkClickAble(data);
    }, 5500);
  };
  const columns = [
    {
      name: "نوع البضاعة",
      selector: (row) => row.ProductType,
      width: isMobileView ? "200px" : "auto",
      wrap: true,
    },
    {
      name: "الاسم التجاري",
      selector: (row) => row.TradeName,
      width: isMobileView ? "180px" : "auto",
      wrap: true,
    },
    {
      name: "بلد المنشأ",
      selector: (row) => row.OriginCountry,
      width: "auto",
      wrap: true,
    },
    {
      name: "عدد الطرود",
      selector: (row) => row.PacketsNumber,
      width: "auto",
      wrap: true,
    },
    {
      cell: (row, cell) => (
        <div>
          <EditIcon
            onClick={() => {
              row.index = row.index;
              setEditRowValues(row);
              setEditRowIndex(row?.Id);
              setIsEdit(true);
              setModalShow(true);
            }}
            style={{ color: "var(--second-linear-green)", cursor: "pointer" }}
          />
          <ClearIcon
            onClick={() => {
              setEditRowIndex(row?.Id);
              onDelete(row);
            }}
            style={{ color: "var(--red)", cursor: "pointer" }}
          />
        </div>
      ),
      name: "الاجراءات",
      //   center: true,
      //   width: "30ch",
    },
  ];
  const viewOnlyColumns = [
    {
      name: "نوع البضاعة",
      selector: (row) => row.ProductType,
      width: isMobileView ? "200px" : "auto",
      wrap: true,
    },
    {
      name: "الاسم التجاري",
      selector: (row) => row.TradeName,
      width: isMobileView ? "180px" : "auto",
      wrap: true,
    },
    {
      name: "بلد المنشأ",
      selector: (row) => row.OriginCountry,
      width: "auto",
      wrap: true,
    },
    {
      name: "عدد الطرود",
      selector: (row) => row.PacketsNumber,
      width: "auto",
      wrap: true,
    },
  ];
  const handleRowClicked = (row) => {
    const rowIndex = transactionProducts.findIndex(
      (item) => item.ProductTypeId === row.ProductTypeId
    );
    // setEditRowIndex(rowIndex);
  };
  const getProductTypesList = async () => {
    const response = await getProductTypesOptions();
    if (response?.status === 200) {
      setProductTypesOptions(response?.data);
    }
  };
  const getOriginCountriesList = async () => {
    const response = await getOriginCountriesOptions();
    if (response?.status === 200) {
      setOriginCountriesOptions(response?.data);
    }
  };
  useEffect(() => {
    getProductTypesList();
    getOriginCountriesList();
  }, []);
  return (
    <div className="containe">
      <CustomDataTable
        columns={viewOnly ? viewOnlyColumns : columns}
        data={transactionProducts}
        onRowClicked={(row) => {
          handleRowClicked(row);
        }}
      />
      <div className="d-flex justify-content-center"></div>
      {!viewOnly && (
        <Button
          sx={{
            borderRadius: "15px",
            background:
              "linear-gradient(180deg, var(--first-linear-green) 0%, var(--second-linear-green) 100%)",
            color: "#FFF",
            textAlign: "center",
            fontFamily: "Cairo",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "normal",
            marginLeft: "1rem",
            marginRight: "0rem !important",
            marginTop: "1rem !important",
          }}
          variant="contained"
          onClick={() => setModalShow(true)}
          disabled={false}
        >
          +
        </Button>
      )}

      <AddNewProduct
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={transactionProducts}
        setData={setTransactionProducts}
        onAdd={onAdd}
        onDelete={onDelete}
        currentId={currentId}
        setCurrentId={setCurrentId}
        productTypesOptions={productTypesOptions}
        originCountriesOptions={originCountriesOptions}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        editRowValues={editRowValues}
        editRowIndex={editRowIndex}
        formData={data}
        applyChangesOnField={applyChangesOnField}
        checkClickAble={checkClickAble}
      />
    </div>
  );
};

export default GridTable;
// "TransactionProducts":[
//     {
//         "ProductTypeId":3225,
//         "OriginCountryId":3228,
//         "PacketsNumber":"6",
//         "TradeName":"TradeName"
//     },
//     {
//         "ProductTypeId":3226,
//         "OriginCountryId":3229,
//         "PacketsNumber":"12",
//         "TradeName":"TradeName 2"
//         }
//     ],

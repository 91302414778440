import React from "react";
import { Styles } from "./styles";

const BannerWithImage = ({ image, title, height }) => {
  return (
    <Styles image={image} height={height}>
      <h2>{title}</h2>
    </Styles>
  );
};

export default BannerWithImage;

import styled from "styled-components";
export const Styles = styled.div`
  margin: 1rem 0;
  .uploader-placeholder {
    font-size: 0.9rem !important;
    /* margin-top: 0.6rem; */
  }
  .date-placeholder {
    font-size: 0.9rem !important;
    margin-top: 0;
    margin-bottom: 0;
    padding-right: 0.5rem !important;
  }
  .calender-icon {
    z-index: 100;
  }
  .date-custome-component {
    display: flex;

    label {
      width: 100%;
      z-index: 100;
      .MuiSvgIcon-root {
        margin-top: -0.3rem;
      }
    }
    .date-picker {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      height: 0px;
    }
  }
  .input-group .form-control {
    background-color: #f8f9fa !important;
  }
  em {
    font-style: normal;
    font-size: 0.9rem !important;
  }
`;

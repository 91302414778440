import { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import DashboardSidebar from "./components/DashboardSidebar";
import DashboardNavbar from "./components/DashboardNavbar";
import { useMediaQuery, useTheme } from "@mui/material";
import { getAccessTokenCookie, setUserIdCookie } from "../../utils/cookie";
import UserContext from "../../context/UserContext";
import { RefreshTokenFunction } from "../../services/refreshToken.services";
import axios from "axios";
import { USERPROFILE } from "../../constants/endpoints";
//

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 120;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled("div")({
  display: "flex",
  //   minHeight: "100%",
  overflow: "hidden",
  paddingTop: "1.2rem",
  position: "relative",
});

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const theme = useTheme();
  const mediaQuery768 = useMediaQuery(theme.breakpoints.down("769"));

  const MainStyle = styled("div")(({ theme }) => ({
    flexGrow: 1,
    overflow: "auto",
    //   minHeight: "100%",
    paddingTop: mediaQuery768 ? "14rem" : APP_BAR_MOBILE + 24,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up("lg")]: {
      paddingTop: APP_BAR_DESKTOP + 60,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  }));
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const token = getAccessTokenCookie();
  const { setUserProfile } = useContext(UserContext);

  useEffect(() => {
    if (!token) {
      navigate("/login", { replace: true });
    } else {
      // getUserProfileData();
      axios
        .get(USERPROFILE, {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-Version": "2",
          },
        })
        .then((response) => {
          setUserProfile(response.data);
          setUserIdCookie(response.data.UserId);
        })
        .catch((error) => {
          if (error.status === 401) {
            RefreshTokenFunction();
          }
        });
    }
  }, [token]);
  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />

      <DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      />

      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}

import React, { useEffect, useState } from "react";
import { Styles } from "./styles";
import { Button } from "@mui/material";
import {
  getPaidOrderDetails,
  retrieveOrderFn,
  savePaymentTransaction,
  saveServiceRecord,
} from "../../../services/payment/payment.service";
import {
  errorToaster,
  successToaster,
} from "../../../helpers/toasterConfiguration";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import useStateRef from "react-usestateref";
import { getAccessTokenCookie } from "../../../utils/cookie";
const RetrieveOrderPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const formDataId = queryParams.get("formDataId");
  const processId = queryParams.get("processId");
  const orderId = queryParams.get("orderId");
  const isMobile = queryParams.get("isMobile");

  // get token
  const [value, setValue, valueRef] = useStateRef(false);
  const [errorStep, setErrorStep] = useState(null);
  const [refreshData, setRefreshData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const saveServiceRecordFn = async () => {
    setSaveLoading(true);
    const requestBody = {
      FormDataId: formDataId,
      ProcessId: processId,
      OrderId: orderId,
      // OrderId: data?.id,
      // Amount: data?.amount,
      // CreationTime: data?.creationTime,
      // Currency: data?.currency,
      // Description: data?.description,
      // IpAddress: data?.device?.ipAddress,
      // CardBrand: data?.sourceOfFunds?.provided?.card?.brand,
      // FundingMethod: data?.sourceOfFunds?.provided?.card?.fundingMethod,
      // NameonCard: data?.sourceOfFunds?.provided?.card?.nameOnCard,
      // Number: data?.sourceOfFunds?.provided?.card?.number,
      // AuthenticationStatus: data?.authenticationStatus,
      // AuthenticationCode:
      //   data?.transaction &&
      //   data?.transaction[1]?.transaction?.authorizationCode,
      // Receipt: data?.transaction && data?.transaction[1]?.transaction?.receipt,
    };
    const response = await saveServiceRecord(requestBody, valueRef.current);
    if (
      response.status === 200 ||
      response.status === 201 ||
      response.status === 204
    ) {
      successToaster("تمت العملية بنجاح");
      if (isMobile == "true") {
        navigate("/submit", { replace: true });
      } else if (isMobile == "false") {
        navigate("/dashboard/in-progress", { replace: true });
      }
      // 3. CALL save the payment transaction
    } else errorToaster(response?.data);
    setSaveLoading(false);
  };
  const getOrderDetailsFn = async () => {
    setLoadingDetails(true);
    const response = await getPaidOrderDetails(orderId);
    if (response.status === 200) {
      saveServiceRecordFn(response?.data);
    } else setErrorStep(1);
    setLoadingDetails(false);
  };

  useEffect(() => {
    // getOrderDetailsFn();
    setTimeout(() => {
      saveServiceRecordFn();
    }, 500);
  }, [refreshData]);
  useEffect(() => {
    if (isMobile == "true") {
      setValue(queryParams.get("token"));
    } else setValue(getAccessTokenCookie());
  }, []);
  return (
    <Styles>
      <div className="container">
        <div className="text-center">
          {/* <button onClick={() => setRefreshData(!refreshData)}>refersh</button>
          <button onClick={() => saveServiceRecordFn()}>button</button> */}
          <p className="">يتم الآن تسجيل عملية الدفع</p>{" "}
          {/* {isLoading && (
            <>
              <p className="">يتم الآن تسجيل عملية الدفع</p>{" "}
              <div className="text-center picklist-loader-dropdown pt-2 pe-2">
                <div
                  className="spinner-border spinner-border-lg text-success"
                  role="status"
                ></div>
              </div>
            </>
          )} */}
          <div className="re-request">
            <p className="">في حال حدوث أي مشكلة يمكنك إعادة الطلب</p>
            <Button
              sx={{
                borderRadius: "15px",
                background:
                  "linear-gradient(180deg, var(--first-linear-green) 0%, var(--second-linear-green) 100%)",
                color: "#FFF",
                textAlign: "center",
                fontFamily: "Cairo",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "normal",

                marginLeft: "1rem",
              }}
              disabled={saveLoading || loadingDetails}
              variant="contained"
              onClick={() => setRefreshData(!refreshData)}
            >
              {false ? (
                <span className="indicator-progress">
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  &nbsp; يتم التحقق
                </span>
              ) : (
                "إعادة الطلب"
              )}
            </Button>
          </div>
        </div>
      </div>
    </Styles>
  );
};

export default RetrieveOrderPage;
